import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
import {ROUTES} from "@/scripts/constants";
import {saveAs} from 'file-saver';

export const useInvoiceStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'invoice',
    // a function that returns a fresh state
    state: () => ({
        invoices: [],
        invoiceVersion: 0,
        invoiceNumber: null,
        invoiceData: {
            client: {
                address: {}
            },
            additionalData: {
                due_date: null,
                period: null
            }
        },
        invoiceClientEvents: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 20,
            filters: {},
            // sortBy: 'age',
            // sortType: 'desc',
            sortField: 'invoice_date',
            sortOrder: -1,
        },
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        search(search) {
            return this.apiClient.get(`/invoices/search`, {params: {search}})
        },
        store(invoice) {
            return this.apiClient.post(`/invoices`, {invoice}).then(({data}) => {
                this.$router.push({
                    name: ROUTES.INVOICES.EDIT,
                    params: {
                        id: data.id
                    }
                })
            })
        },
        clone(id) {
            return this.apiClient.post(`/invoices`, { from_id: id })
        },
        update(invoice, id, transactionId = null) {
            if (transactionId) {
                invoice.transaction_id = transactionId
            }
            return this.apiClient.put(`/invoices/${id}`, {invoice})
        },
        get(id, clientId, includeAvailableProductBillingPeriods = false) {
            return this.apiClient.get(`/invoices/${id}`, {
                params: {
                    client_id: clientId,
                    available_product_billing_periods: includeAvailableProductBillingPeriods ? 1 : ''
                }
            })
        },
        async index(filters = [], csv= false) {
            this.loading = true
            const params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }
            if(filters.length > 0) {
                filters.forEach((val) => {
                    params[val.key] = 1
                })
            }
            if (csv) {
                params["csv"] = csv
            }
            if (this.serverOptions.filters.status) {
                if(this.serverOptions.filters.status.value) {
                    params['status'] = this.serverOptions.filters.status.value
                    Helpers.addQueryToParams({status: params['status']}, params)
                    await Helpers.setUrlQuery({status: this.serverOptions.filters.status.value})
                } else {
                    await Helpers.removeQueryFromUrl("status")
                }
            }
            if (this.serverOptions.filters.number) {
                if(this.serverOptions.filters.number.value) {
                    params['number'] = this.serverOptions.filters.number.value
                    Helpers.addQueryToParams({number: params['number']}, params)
                    await Helpers.setUrlQuery({number: this.serverOptions.filters.number.value})
                } else {
                    await Helpers.removeQueryFromUrl("number")
                }
            }

            if (this.serverOptions.filters.name?.value) {
                Helpers.addQueryToParams({name: this.serverOptions.filters.name.value}, params)
                params['name'] = this.serverOptions.filters.name?.value
            } else {
                await Helpers.removeQueryFromUrl("name")
            }

            if (this.serverOptions.filters.client_id?.value) {
                Helpers.addQueryToParams({client_id: this.serverOptions.filters.client_id.value}, params)
                params['client_id'] = this.serverOptions.filters.client_id?.value
            } else {
                await Helpers.removeQueryFromUrl("client_id")
            }
            if (this.serverOptions.sortField) {
                Helpers.addQueryToParams({
                    sort_by: this.serverOptions.sortField,
                    sort_order: this.serverOptions.sortOrder
                }, params)
            }
            await Helpers.setUrlQuery(params)

            return this.apiClient.get(`/invoices`, {params}).then(({data}) => {
                if (data.filename) {
                    saveAs(Helpers.textToBlob(data), data.filename);
                } else {
                    this.invoices = data.invoices
                    this.itemLength = data.itemLength
                }
                this.loading = false
            })
        }
    },
})

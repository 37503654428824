<template>
  <div class="col-12 col-lg-3 mb-3">
    <div class="form-floating">
      <Field id="contact.title" name="contact.title"
             placeholder="{{$t('contact.title')}}" class="form-control"
             :class="{'is-invalid': errors['contact.title']}"/>
      <label for="contact.title">{{ $t('contacts.title') }}</label>
      <div v-if="errors['contact.title']" class="invalid-feedback">
        {{ errors['contact.title'] }}
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-3 mb-3">
    <div class="form-floating">
      <Field name="contact.pronouns"
             v-slot="{ field, handleChange }"
      >
        <Multiselect
            :options=pronounOptions
            :searchable="true"
            v-model="field.value"
            @change="handleChange"
            :class="{'is-invalid': errors['contact.pronouns']}"
            :create-option="true"
            :placeholder="$t('contacts.pronouns')"
        />
      </Field>
      <div v-if="errors['contact.pronouns']" class="invalid-feedback">
        {{ errors['contact.pronouns'] }}
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 mb-3">
    <Field v-slot="{ field }" name="contact.send_newsletter" >
      <label>
        <input type="checkbox" name="contact.send_newsletter"  @change="setFieldValue('contact.send_newsletter', field.value)" v-model="field.value" :value="true" />
        {{ $t('contacts.send_newsletter') }}
      </label>
    </Field>
  </div>
  <div class="col-12 col-lg-6 mb-3">
    <div class="form-floating">
      <Field id="contact.first_name" name="contact.first_name"
             placeholder="{{$t('contact.first_name')}}" class="form-control"
             :class="{'is-invalid': errors['contact.first_name']}"/>
      <label for="contact.first_name">{{ $t('contacts.first_name') }}</label>
      <div v-if="errors['contact.first_name']" class="invalid-feedback">
        {{ errors['contact.first_name'] }}
      </div>
    </div>
  </div>  
  <div class="col-12 col-lg-6 mb-3">
    <div class="form-floating">
      <Field id="contact.last_name" name="contact.last_name"
             placeholder="{{$t('contact.last_name')}}" class="form-control"
             :class="{'is-invalid': errors['contact.last_name']}"/>
      <label for="contact.last_name">{{ $t('contacts.last_name') }}</label>
      <div v-if="errors['contact.last_name']" class="invalid-feedback">
        {{ errors['contact.last_name'] }}
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 mb-3">
    <div class="form-floating">
      <Field id="contact.phone"
             name="contact.phone"
             placeholder="{{$t('contacts.phone')}}" class="form-control"/>
      <label for="contact.phone">{{ $t('contacts.phone') }}</label>
    </div>
  </div>
  <div class="col-12 col-lg-6 mb-3">
    <div class="form-floating">
      <Field id="contact.phone_mobile"
             name="contact.phone_mobile"
             placeholder="{{$t('contacts.phone_mobile')}}" class="form-control"/>
      <label for="contact.phone_mobile">{{ $t('contacts.phone_mobile') }}</label>
    </div>
  </div>
  <div class="col-12 col-lg-6 mb-3">
    <div class="form-floating">
      <Field
             id="contact.email"
             name="contact.email"
             placeholder="{{$t('contacts.email')}}" class="form-control"/>
      <label for="contact.email">{{ $t('contacts.email') }}</label>
    </div>
  </div>
  <div class="col-12 col-lg-6 mb-3">
    <div class="form-floating">
      <Field
          as="textarea"
          id="contact.info"
          name="contact.info"
          placeholder="{{$t('contacts.info')}}" class="form-control"/>
      <label for="contact.info">{{ $t('contacts.info') }}</label>
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";
import Multiselect from '@vueform/multiselect'

export default {
  name: 'ContactFormFields',
  components: {
    Field,
    Multiselect,
  },
  props: {
    errors: {
      type: Object,
      default: () => {
      },
    },
    setFieldValue: {
      type: Function,
      default: () => {
      },
    },
  },
  setup() {
  },
  data() {
    return {
    }
  },
  created() {

  },
  computed: {
    pronounOptions() {
      let parsedMessages = {}
      Object.keys(this.$i18n.messages[this.$i18n.locale].contacts.pronoun_options).forEach((element) => {
        parsedMessages[element] = this.$t(`contacts.pronoun_options.${element}`)
      })
      return parsedMessages
    },
  }
}
</script>

<template>
  <div class="px-4 py-3 prepare-delete-hover" :class="{'delete-hover': hoverDelete}">
    <div class="timeline-header">
      <nav aria-label="breadcrumb" v-if="isParent || isHeadlineItem || differentEventable">
        <ol class="breadcrumb p-0 m-0">
          <template v-if="showClient && client">
            <li class="breadcrumb-item">
              <router-link :to="routerLinkTo('Client', client.id)" class="color-inherit">
                {{ client.kdnr }} {{ client.name }}
              </router-link>
            </li>
          </template>
          <template v-if="item.eventable">
            <li class="breadcrumb-item">
              <router-link :to="routerLinkTo(item.eventable_type, item.eventable.id)" class="color-inherit">
                <small>[{{ eventableLabel1(item) }}]</small>
                {{ eventableLabel2(item) }}
              </router-link>
            </li>
          </template>
        </ol>
      </nav>
      <div class="d-flex justify-content-between">
        <div :data-id="item.id">
          <i class="fa-xl fa-fw d-inline-block"
             :style="`color: ${clientEventType?.color}`"
             :class="clientEventType?.icon"
             :title="clientEventType?.name"
             @click="toggleChildCollapse"
          ></i>
          <!--        <small><span class="text-muted">-->
          <!--            ({{ setupStore.getClientEventTypeById(item.client_event_type_id)?.name }})-->
          <!--          &lt;!&ndash;                {{ item.parent_id }} > {{ item.id }}&ndash;&gt;-->
          <!--          </span></small>-->
          <span class="date mx-2 fs-5" style="width:8em"
                @click="showDateUserString = !showDateUserString">{{ formatDate(item.created_at, 'short2') }}</span>
          <div v-if="isParent || isHeadlineItem" class="d-inline-block username ms-1">
            <template v-if="isParent">
              <router-link class="operation-item fs-5" :to="{name: ROUTES.CLIENT_EVENTS.SHOW, params: {id: item.id}}">
                {{ item.title }}
              </router-link>
            </template>
            <template v-else>
              {{ item.title }}
            </template>
          </div>
          <span v-else class="ms-1">{{ item.title }}</span>
          <small class="time text-muted d-inline-block ms-1">{{ formatDate(item.created_at, 'time') }}</small>
          <small class="text-muted d-inline-block ms-3" v-if="showDateUserString">{{
              item.zoho_date_user_string
            }}</small>
        </div>
        <div class="d-flex align-items-center">
          <!--          <span>{{ item.admin2_import_key }}</span>-->
          <UserAvatar :id="item.user_id"/>
          <span class="text-muted ps-1 pe-3">{{ useAllUsersStore.user(item.user_id)?.name }} </span>
          <SecondaryActionMenu key-suffix="123">
            <template v-if="userStore.user.hasPermission(PERMISSIONS.CLIENT_EVENTS.ADMIN)">
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="clone"
              >
                <i class="fa-solid fa-fw fa-clone"></i> {{ $t('actions.clone') }}
              </a>
            </template>

            <a href="#"
               @click.prevent="handleEdit"
               class="text-nowrap dropdown-item"
            >
              <i class="fa-solid fa-fw fa-message-pen"></i> {{ $t('client_events.message_edit') }}
            </a>

            <template v-if="userStore.user.hasPermission(PERMISSIONS.CLIENT_EVENTS.DELETE)">
              <div class="dropdown-divider"></div>
            </template>

            <template v-if="userStore.user.hasPermission(PERMISSIONS.CLIENT_EVENTS.ADMIN)">
              <router-link
                  class="dropdown-item"
                  :to="{name: ROUTES.CLIENT_EVENTS.EDIT, params: {id: item.id}}"
              >
                <i class="fa-solid fa-fw" :class="classAdminIcon"></i> {{ $t('client_events.edit.admin') }}
              </router-link>
            </template>

            <template v-if="userStore.user.hasPermission('client_events.delete')">
              <a href="#"
                 @click.prevent="destroyEvent"
                 class="text-danger dropdown-item"
                 @mouseover="setDeleteHover(true)"
                 @mouseleave="setDeleteHover(false)">
                <i class="fa-solid fa-fw fa-trash"></i> {{ $t('client_events.delete') }}
              </a>
            </template>
          </SecondaryActionMenu>
          <button class="btn btn-secondary-outline mx-0 px-0" @click="scrollToFirst">
            <i class="fal fa-fw fa-arrow-up-to-dotted-line"></i>
          </button>
          <button class="btn btn-secondary-outline mx-0 px-0" @click="scrollToLast">
            <i class="fal fa-fw fa-arrow-down-to-dotted-line"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="timeline-content pt-2" style="margin-left: 39px">
      <template v-if="editing.info">
        <ClientEventForm mode="update"
                         :initial-values="{info: field_info, title: item.title, client_event_type_id: item.client_event_type_id, ticket_link: item.ticket_link}"
                         :item-key="item.id"
                         :parent-event="item"/>
      </template>
      <template v-else>
        <p style="white-space: pre-wrap;" v-if="field_info">
          <span v-html="linkAttachments(autolink(field_info))"/>
        </p>
      </template>
      <template
          v-if="clientEventType?.key === 'renewal' && item && item.has_product_billing_periods">
        <ProductBillingPeriodsTable :client_event_id="item.id" :renewal-actions="true" :force-hide-client="true" :key="`ProductBillingPeriodsTable-${item.id}`" />
      </template>
      <template v-if="item.ticket_link">
        <a :href="item.ticket_link" target="_blank" class="d-flex">
          <template v-if="item.ticket_link.includes('/mantis/')">
            <img src="https://www.manetservice.de/mantis/images/favicon.ico" class="me-2" alt="Mantis-Icon">
          </template>
          {{ item.ticket_link.replace('https://www.manetservice.de/mantis/view.php?id=', '#') }}
        </a>
      </template>
      <template v-if="item.file">
        <div>
          <!--          Anhang: &lt;!&ndash; FIXME: i18n&ndash;&gt;-->
          <router-link :to="{ name: ROUTES.CLIENT_EVENTS.SHOW, params: {id: item.id}, query: {preview: 1}}"
                       target="_blank"
                       class="btn btn-sm btn-outline-secondary">
            <i class="fa-solid fa-file me-2"></i>
            {{ item.file.filename }}
          </router-link>
        </div>
      </template>

      <template v-if="runable">
        <button class="mt-3 btn btn-warning" type="button" @click="clientEventStore.run(item.id)">
          {{ $t('client_events.run') }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";
import {useSetupStore} from "@/stores/setup";
import {useAllUsersStore} from "@/stores/all_users";
import {useClientEventStore} from "@/stores/client_event";
import {useClientStore} from "@/stores/client";
import {useUserStore} from "@/stores/user";
import {Helpers} from "@/helpers";
import ClientEventForm from "@/components/ClientEventForm.vue";
import {CLIENT_EVENT_TYPES, NANO_EVENT_TYPES, PERMISSIONS, ROUTES} from "@/scripts/constants";
import ProductBillingPeriodsTable from "@/components/tables/ProductBillingPeriodsTable.vue";
import SecondaryActionMenu from "@/components/SecondaryActionMenu.vue";

export default {
  //from https://www.bootdey.com/snippets/view/bs4-timeline
  name: 'ComponentClientEventTimelineItem',
  components: {
    SecondaryActionMenu,
    ProductBillingPeriodsTable,
    ClientEventForm,
    UserAvatar,
  },
  props: {
    isHeadlineItem: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    parentItem: {
      type: Object,
    },
    showClient: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      clientStore: useClientStore(),
      clientEventStore: useClientEventStore(),
      setupStore: useSetupStore(),
      useAllUsersStore: useAllUsersStore(),
      userStore: useUserStore(),
    }
  },
  beforeUnmount() {
    this.emitListenerUnbind()
  },
  created() {
    this.setupStore.getClientEventTypes()
    this.reset()
    this.emitListenerUnbind = Helpers.emitter.on(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED, () => {
      this.editing.info = false
    })

  },
  watch: {
    'item.id'() {
      this.reset()
    },
  },
  data() {
    return {
      hoverDelete: false,
      editing: {
        info: false,
      },
      data: {
        info: '',
      },
      showDateUserString: false,
    }
  },
  methods: {
    scrollToFirst() {
      const firstElement = this.$el.closest('.timeline-body')
      firstElement.scrollIntoView({ behavior: 'smooth' })
    },
    scrollToLast() {
      const lastElement = this.$el.closest('.timeline-body').querySelector('.client-event-append-js')
      lastElement.scrollIntoView({ behavior: 'smooth'})
    },
    setDeleteHover(value) {
      if (this.isChild) {
        this.hoverDelete = value
      } else {
        this.$emit('hoverDelete', value, this.item.parent_id || this.item.id)
      }
    },
    toggleChildCollapse() {
      // console.log("emitting", this.item.id)
      this.$emit('toggleChildCollapse', this.item.id)
    },
    reset() {
      this.clientStore.loadClient(this.item.client_id)
      // this.field_info = this.item.info
    },
    handleEdit() {
      if (confirm(this.$t('client_events.confirm_edit', {date: this.$d(this.item.created_at)}))) {
        this.editing.info = true
      }
    },
    clone() {
      const id = this.item.id
      this.clientEventStore.clone(id).then(({data}) => {
        Helpers.emitter.emit('success', this.$t('client_events.clone.success'))
        this.$router.push({name: ROUTES.CLIENT_EVENTS.EDIT, params: {id: data.id}})
      })
    },
    destroyEvent() {
      const msg = this.$t('client_events.delete') + '?'
      if (!confirm(msg)) {
        return
      }
      this.clientEventStore.delete(this.item.id).then((data) => {
        const onEventPage = this.$route.name === ROUTES.CLIENT_EVENTS.SHOW && parseInt(this.$route.params.id, 10) === this.item.id
        if (onEventPage) {
          const route = this.routerLinkTo(this.item.eventable_type, this.item.eventable_id)
          this.$router.push(route)
        } else {
          Helpers.emitter.emit(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED)
        }
        Helpers.emitter.emit('success', data.message)
      })
    },
    linkAttachments(text) {
      const clientEvent = this.item
      if (!clientEvent) {
        return text
      }
      const headline = clientEvent?.attachment_headline
      if (!headline) {
        return text
      }
      const matchData = text.match(new RegExp(`${headline}(.*)`, 's'))
      if (!matchData) {
        return text
      }
      const oldFooter = matchData[0]
      const files = matchData[1].trim().split("\n")
      let newFooter = oldFooter
      for (let i = 0; i < files.length; i++) {
        const fileName = files[i]
        if (fileName.length) {
          const routeOptions = this.routerLinkTo('ClientEvent', clientEvent.id, {preview: 1, attachment_name: fileName})
          const route = this.$router.resolve(routeOptions)
          const fileNameWithOptionalTrailingNewLine = new RegExp(`${fileName}\n?`)
          newFooter = newFooter.replace(fileNameWithOptionalTrailingNewLine, `<a href="${route.fullPath}" class="btn btn-sm btn-outline-primary d-inline-block me-2">${fileName}</a>`)
        }
      }
      return text.replace(oldFooter, newFooter)
    },
    // handleBlur() {
    //   this.editing.info = false
    //   if (this.field_info != this.item.info) {
    //     const payload = {
    //         info: this.field_info,
    //     }
    //     this.clientEventStore.update(this.item.id, payload)
    //     Helpers.emitter.emit('success', this.$t('client_events.updated'))
    //   }
    // },
    routerLinkTo(item_type, item_id, query = null) {
      let routeName
      let paramName = 'id'
      switch (item_type) {
        case 'Invoice':
          routeName = ROUTES.DUNNING.EDIT
          paramName = 'invoice_id'
          break;
        case 'Product':
          routeName = ROUTES.PRODUCTS.SHOW
          paramName = 'product_id'
          break;
        case 'Contract':
          routeName = ROUTES.CONTRACTS.SHOW
          break;
        case 'Client':
          routeName = ROUTES.CLIENTS.SHOW
          break;
        case 'ClientEvent':
          routeName = ROUTES.CLIENT_EVENTS.SHOW
          break;
        default:
          console.error(`unknown item_type: ${item_type}`)
          // fallback
          return {name: ROUTES.DASHBOARD}
      }
      return {
        name: routeName,
        params: {[paramName]: item_id},
        query
      }
    },
    eventableLabel1(item) {
      switch (item.eventable_type) {
        case 'Client':
          return this.$t('clients.client')
        case 'Contract':
          return this.$t('contracts.contract')
        case 'Product':
          return this.$t('products.product')
        case 'Invoice':
          return this.$t('invoices.invoice')
      }
      return `${item.eventable_type}#${item.eventable.id}`
    },
    eventableLabel2(item) {
      const number = item.eventable.number || `-- ${this.$t('draft')} ${item.eventable.id} --`
      switch (item.eventable_type) {
        case 'Client':
          return `${item.eventable.kdnr} ${item.eventable.name}`
        case 'Contract':
          return item.eventable.title
        case 'Product':
          return item.eventable.name
        case 'Invoice':
          return number
      }
      return item.eventable.title
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    isParent() {
      return !this.item.parent_id;
    },
    isChild() {
      return !this.isParent;
    },
    field_info() {
      return this.item.info
    },
    client() {
      return this.clientStore.findClient(this.item.client_id)
    },
    clientEventType() {
      return this.setupStore.getClientEventTypeById(this.item.client_event_type_id)
    },
    differentEventable() {
      if (!this.item.eventable_id || !this.parentItem?.eventable_id) {
        return
      }
      return this.parentItem.eventable_id !== this.item.eventable_id || this.parentItem.eventable_type !== this.item.eventable_type
    },
    runable() {
      switch (this.clientEventType?.key) {
        case CLIENT_EVENT_TYPES.INVOICE_CANCELED:
          return !this.differentEventable
        default:
          return false;
      }
    },
  },
}
</script>
<template>
  <div class="row">
    <h2>{{ $t('welcome') }} {{ name }}, {{ $t('dashboard.fullsearch') }}</h2>
    <FullTextSearch class="my-3 col-12"/>
    <div v-if="!currentOrganization.id">
      {{ $t("dashboard.organizations_empty") }}
      <br>
      <router-link :to="{name: ROUTES.ORGANIZATIONS.CREATE}" class="btn btn-primary">
        {{ $t('create') }} {{ $t('organizations.organization') }}
      </router-link>
    </div>

    <div :class="cardClassesSmall" v-if="showDefault">
      <PlacetelCalls />
    </div>

    <div :class="cardClassesSmall">
      <QuickLinks :show-default="showDefault"/>
    </div>

    <DownloadList :class="cardClassesSmall" />

    <div :class="cardClassesSmall" v-if="showDefault">
      <BootstrapCard headline="Going live?!">
        <ul>
          <li>
            LetterXpress-<b>Briefversand noch im Test-Modus</b> -
            Upload zu LetterXpress funktioniert automatisch,
            ABER man muss sich noch mal einloggen, konrollieren und versenden.
          </li>
          <li>
            Notwendige Aufräumarbeiten:
            <a href="https://gitlab.manet-marketing.de/tantum/tantum-doc/-/issues/14" target="_blank">#14</a>, <a
              href="https://gitlab.manet-marketing.de/tantum/tantum-doc/-/issues/18" target="_blank">#18</a>
          </li>
        </ul>
      </BootstrapCard>
    </div>

    <div :class="cardClassesSmall" v-if="showDefault">
      <BootstrapCard headline="Workflows">
        <ul>
          <li>
            <collapsed-content id="workflow_receipt" label="Lieferantenrechnung">
              <ul>
                <li>Scannen mit Quickscan-App in Nextcloud</li>
                <li>Sebastian überträgt von NC in Tantum</li>
                <li>Bezahlen mit Deutsche-Bank-App</li>
              </ul>
            </collapsed-content>
          </li>
          <li class="pb-1">
            <collapsed-content id="workflow_renewal" label="Verlängerung">
              <template v-if="userStore.user.hasPermission(PERMISSIONS.PRODUCTS.ADMIN)">
                <button class="btn btn-warning btn-sm ms-2" type="button" @click="triggerJobs">
                  Manuell auslösen
                </button>
              </template>
              <ol class="collapse" id="workflow_renewal">
                <li>Tantum erkennt x Wochen vorher, dass der Leistungszeitraum erneuert werden muss</li>
                <li>"Herrenlose" Leistungszeiträume werden A) automatisch vorhandenen (importieren) Verlängerungen
                  zugewiesen ODER B) es werden neue VL angelegt.
                </li>
                <li>Wenn ein LZR quatsch ist (weil gekündigt / Anwalt), sollte der eigentlich nur gelöscht werden und
                  das
                  Produkt so eingestellt werden, dass kein neuer erzeugt wird.
                </li>
              </ol>
            </collapsed-content>
          </li>
          <li>
            <collapsed-content id="workflow_tariff_change"
                               label="Tarif-Änderung - z.B. bei E-Mail (FIXME: Ausformulieren & testen)">
              <ul class="collapse" id="workflow_tariff_change">
                <li>Wann neuen Vertrag anlegen?<br>
                  Wenn wir die Zustimmung des Kd. zu neuen Vertragsbedingungen oder AGB benötigen, sollten wir ihn gut
                  informieren und sein OK einholen => Dann neuer Vertrag.<br>
                  Bei Erweiterungen im Rahmen des bestehenden Vertrages => Kein neuer Vertrag
                </li>
                <li>Wenn neuer Vertrag:<br>
                  Haben wir den Kunden gut informiert?<br>
                  Hat der Kunde zugestimmt?<br>
                  Wenn ja: neuen Vertrag mit Auftragsbestätigung und allen alten Produkten + neuem/geändertem Produkt
                  (Produkte laufen dann im neuen Vertrag weiter - werden übernommen)
                </li>
                <li>Neues Produkt anlegen<br>
                  (ggf. bauen wir noch "klonen von 5 GB")<br>
                  neuen ProduktTyp: 10 GB<br>
                  Startet "jetzt"<br>
                </li>
                <li>Altes Produkt: Endet "jetzt". <br>
                  ggf. Gutschrift erzeugen (müssen wir noch festlegen, ob/wann wir das wollen)
                </li>
              </ul>
            </collapsed-content>
          </li>
        </ul>
      </BootstrapCard>
    </div>
  </div>

  <div class="row mt-3" v-if="userStore.user.hasPermission(PERMISSIONS.PRODUCTS.ADMIN)">
    <!--    <div class="col-12 col-xl-6">-->
    <!--      <h2>Fehlende Features</h2>-->
    <!--      <ul>-->
    <!--        <li>-->
    <!--          <UserAvatar :id="2"/>-->
    <!--          Belege: Aktuelle Standardintegration auf ClientEvent-Voodoo umbauen-->
    <!--        </li>-->
    <!--        <li>-->
    <!--          Teilrechnungen bei unterschiedlichen Laufzeiten:<br>-->
    <!--          1880: <br>-->
    <!--          Hosting und Vermarktung Ihrer Website auf www.mvp.de <br>-->
    <!--          Laufzeit: 19.04.2022 - 18.04.2023 - Teilbetrag 11/12<br>-->

    <!--          Hosting Ihrer Domain www.hotel-kaehler.de <br>-->
    <!--          LZ: 20.02.2023 - 19.02.2024 - Teilbetrag 1/12-->
    <!--        </li>-->
    <!--        <li>Rechnungsstorno wie bei 1072</li>-->
    <!--      </ul>-->
    <!--    </div>-->

    <div class="col-12 col-xl-6">
      <BootstrapCard headline="Features">
        <ul>
          <li> Buchhaltung: Liste von abzurechnenden Kunden (Kunden mit ProductBillingPeriodStatus = billing)</li>
          <li>{{ $tc('client_events.client_events', 2) }}: Wiedervorlage (ResubmissionDate) und zugeordneter Benutzer
            setzen / entfernen
          </li>
          <li>{{ $tc('client_events.client_events', 2) }}-Liste: mir zugewiesen / sortiert nach WV-Datum</li>
          <li>Kunde: Wissen, wo man ist: Breadcrumb + Kundennummer/-name im TITLE</li>
          <li>Kunde: schnell im Kunden navigieren</li>
          <li>Kunde: hat Tool-Menü - ZohoCRM-Custom-Links (Admin2, Mantis-Jobs, ...) + Admin2-Quick-Links (Domain-Tool,
            ..)
          </li>
          <li>Kunde: Breadcrumb (schnell wissen, wo ich bin / direkt zum Kunden springen)</li>
          <li>Kundenberater: verlängern (Notizen + Status der ProductBillingPeriods aktualisieren</li>
          <li>Kundenberater: Ansicht, was muss verlängert werden (entspricht Liste der eingestellten Wiedervorlagen)
          </li>
          <li>Buchhaltung: Rechnungen/Mahnwesen</li>
          <li>Produkt-Pakete: Eigentliches Produkt = Paket, aber Komponenten stehen auf der Rechnung (in einer Position)
          </li>
        </ul>
      </BootstrapCard>
    </div>

    <div :class="cardClassesSmall">
      <BootstrapCard headline="Zurückgestellte Features" class="h-100">
        <ul>
          <li>
            Buchhaltung: Lieferantenrechnungen bezahlen <br>
          </li>
          <li>Lieferantenrechnung: Tantum könnte Nextcloud-Ordner lesen / leeren</li>
          <li>
            Hinterlegen von AGB, Kündigungsfrist, Mindestvertragslaufzeit - vgl. <a
              href="https://gitlab.manet-marketing.de/tantum/tantum-doc/-/issues/8#vertr%C3%A4ge" target="_blank">tantum/tantum-doc#8</a>
          </li>
          <li>Rechnungsstellung für bestimmte Rechnungen vollständig automatisieren</li>
        </ul>
      </BootstrapCard>
    </div>

    <div class="mb-3"></div>
  </div>
</template>

<script>
import {Helpers} from "@/helpers";
import {PERMISSIONS, ROUTES, SPECIAL_ROLES} from "@/scripts/constants";
import FullTextSearch from "@/components/FullTextSearch.vue";
import {useInvoiceStore} from "@/stores/invoice";
import {useClientStore} from "@/stores/client";
import {useReceiptStore} from "@/stores/receipt";
import {useUserStore} from "@/stores/user";
import BootstrapCard from "@/components/BootstrapCard.vue";
import DownloadList from "@/components/DownloadList.vue";
import CollapsedContent from "@/components/CollapsedContent.vue";
import QuickLinks from "@/pages/dashboard/components/QuickLinks.vue";
import PlacetelCalls from "@/pages/dashboard/components/PlacetelCalls.vue";
import {setMapStoreSuffix} from "pinia";

require('dayjs/locale/de')

export default {
  name: "DashboardPage",
  components: {
    QuickLinks,
    CollapsedContent,
    BootstrapCard,
    DownloadList,
    FullTextSearch,
    PlacetelCalls,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      userStore: useUserStore(),
      invoiceStore: useInvoiceStore(),
      receiptStore: useReceiptStore(),
    }
  },
  data() {
    return {
      cardClassesSmall: 'col-12 col-xl-3 mb-3',
    }
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    showDefault() {
      return this.userStore.user.specialRole !== SPECIAL_ROLES.taxOffice
    },
    currentOrganization() {
      return this.userStore.user.organization;
    },
    name() {
      return this.userStore.user.name
    },
  },
  created() {
    this.setTitle('Dashboard')
    // this.organizationStore.get();
    // this.name = this.userStore.user.name;
  },
  methods: {
    setMapStoreSuffix,
    triggerJobs() {
      this.userStore.triggerJobs().then(() => {
        Helpers.emitter.emit('success', "Jobs triggered")
      })
    },
  },
}
</script>

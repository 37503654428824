<template>
  <div class="row">
    <div class="col-12">
      <h2>{{$t('direct_debit_mandates.edit')}}</h2>
    </div>
    <div class="col-12 mt-5">
      <Form v-if="initialDdm" @submit="updateDdm" v-slot="{ errors, setFieldValue }" :validation-schema="schema" :initial-values="initialDdm" class="row">
        <DirectDebitMandateFormFields :ddm="ddm" :errors="errors" :setFieldValue="setFieldValue" :client="client" />
        <div class="col-12 col-lg-3">
          <button class="btn btn-success mt-3" type="submit">
            {{ $t('save') }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {useDirectDebitMandateStore} from "@/stores/direct_debit_mandates";
import {useClientStore} from "@/stores/client";
import {Form} from "vee-validate";
import DirectDebitMandateFormFields from "./components/FormFields.vue"
import {DIRECT_DEBIT_MANDATE_FORM_SCHEMA_EDIT} from "@/scripts/schemas";
export default {
  name: 'DirectDebitMandateEdit',
  components: {
    Form,
    DirectDebitMandateFormFields
  },
  setup() {
    return {
      directDebitMandateStore: useDirectDebitMandateStore(),
      clientStore: useClientStore(),
    }
  },
  props: {
  },
  data() {
    return {
      ddm: null,
      schema: DIRECT_DEBIT_MANDATE_FORM_SCHEMA_EDIT,
    }
  },
  computed: {
    client() {
      return this.clientStore.client
    },
    initialDdm() {
      return this.ddm
    }
  },

  created() {
    this.directDebitMandateStore.show(this.$route.params.id).then(({data}) => {
      this.ddm = data.direct_debit_mandate
      this.setCurrentClientById(this.ddm.client_id)
    })
  },
  methods: {
    updateDdm(data) {
      this.directDebitMandateStore.update(this.$route.params.id, data)
    }
  },
  watch: {
  }
}
</script>

<template>

  <!-- data = Contact -->
  <DataTable stripedRows :lazy="true"
             :paginator="true"
             :rows="contactStore.serverOptions.rows"
             :totalRecords="contactStore.itemLength"
             dataKey="id"
             :value="contactStore.contacts"
             :loading="contactStore.loading"
             paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
             :rowsPerPageOptions="[10,20,50]"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
             @page="onPage($event)"
             @sort="onSort($event)"
             @filter="onFilter($event)"
             v-model:filters="filters"
             filterDisplay="row"
             responsiveLayout="scroll">
    <TableColumn :header="this.$t('contacts.salutation')">
      <template #body="{data}">
        {{ salutation(data.pronouns) }}
      </template>
    </TableColumn>
    <TableColumn :header="this.$t('contacts.title')">
      <template #body="{data}">
        {{ data.title }}
      </template>
    </TableColumn>
    <TableColumn field="first_name" :sortable="true" :header="this.$t('contacts.first_name')">
      <template #filter="{filterModel,filterCallback}">
        <input type="text" v-model="filterModel.value" @input="filterCallback()" class="form-control"/>
      </template>
    </TableColumn>
    <TableColumn field="last_name" :sortable="true" :header="this.$t('contacts.last_name')">
      <template #filter="{filterModel,filterCallback}">
        <input type="text" v-model="filterModel.value" @input="filterCallback()" class="form-control"/>
      </template>
    </TableColumn>
    <TableColumn field="phone" :sortable="true" :header="this.$t('contacts.phone')">
      <template #body="{data}">
        <PhoneNumber :number="data.phone" :title="data.phone_geo_name"/>
      </template>
    </TableColumn>
    <TableColumn field="phone_mobile" :sortable="true" :header="this.$t('contacts.phone_mobile')">
      <template #body="{data}">
        <PhoneNumber :number="data.phone_mobile"/>
      </template>
    </TableColumn>
    <TableColumn field="email" :sortable="true" :header="this.$t('contacts.email')">
      <template #filter="{filterModel,filterCallback}">
        <input type="text" v-model="filterModel.value" @input="filterCallback()" class="form-control"/>
      </template>
    </TableColumn>
    <TableColumn :header="this.$t('contacts.send_newsletter')" class="text-center">
      <template #body="{data}">
        <show-bool :value="data.send_newsletter" />
      </template>
    </TableColumn>
    <TableColumn :header="this.$t('products.operations')">
      <template #body="{data}">
        <div class="operations-wrapper">
          <router-link class="operation-item" :to="{name: ROUTES.CONTACTS.SHOW, params: {id: data.id}}"
                       :title="$t('contacts.show.headline')">
            <i class="fas fa-fw fa-eye"/>
          </router-link>
          <router-link class="operation-item" :to="{name: ROUTES.CONTACTS.EDIT, params: {id: data.id}}"
                       :title="$t('contacts.edit.headline')">
            <i class="fas fa-fw fa-edit"/>
          </router-link>
        </div>
      </template>
    </TableColumn>
  </DataTable>
</template>

<script>

import {useContactStore} from "@/stores/contact";
import {useSetupStore} from "@/stores/setup";
import {ROUTES} from "@/scripts/constants";
import {FilterMatchMode} from "primevue/api";
import PhoneNumber from "@/components/PhoneNumber.vue";
import ShowBool from "@/components/ShowBool.vue";

export default {
  name: 'ContactsTable',
  components: {
    ShowBool,
    PhoneNumber,
  },
  setup() {
    return {
      contactStore: useContactStore(),
      setupStore: useSetupStore(),
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: "contact",
    },
  },
  data() {
    return {
      expandAll: false,
      filters: {
        'first_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'last_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'email': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      },
    }
  },
  created() {
    if (this.$route.query.first_name || this.$route.query.last_name) {
      if (this.$route.query.first_name) {
        this.filters.first_name.value = this.$route.query.first_name
      }
      if (this.$route.query.last_name) {
        this.filters.last_name.value = this.$route.query.last_name
      }
      if (this.$route.query.email) {
        this.filters.email.value = this.$route.query.email
      }
      this.onFilter({filters: this.filters})
    } else {
      this.index()
    }
    this.setTitle(this.$tc('contacts.contact', 2).capitalize())
  },
  computed: {
    ROUTES() {
      return ROUTES
    }

  },
  methods: {
    onPage(event) {
      this.contactStore.serverOptions = event
      this.index()
    },
    onSort(event) {
      this.contactStore.serverOptions.sortField = event.sortField
      this.contactStore.serverOptions.sortOrder = event.sortOrder
      this.index()
    },
    onFilter(data) {
      this.contactStore.serverOptions.filters = data.filters
      this.contactStore.index()
    },
    index() {
      let clientId = this.client_id
      if (this.$route.query.client_id || this.client_id) {
        clientId = this.$route.query.client_id || this.client_id
      }
      this.contactStore.index(clientId)
    },
  },
  watch: {}
}
</script>

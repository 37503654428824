<template>
  <div v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.VIEW)">
    <!-- FIXME: PERMISSIONS.DOWNLOAD -->
    <BootstrapCard headline="Downloads">
      <ul class="list-group list-group-flush">
        <template v-for="(downloadData, index) in downloadStore.downloads" v-bind:key="index">
          <li class="list-group-item">
            <a :href="downloadData.stream_url" class="card-link d-block">
              {{ downloadData.name }}
            </a>
          </li>
        </template>
      </ul>
    </BootstrapCard>
  </div>
</template>

<script>
import {PERMISSIONS} from "@/scripts/constants";
import {useDownloadStore} from "@/stores/download";
import {useUserStore} from "@/stores/user";
import BootstrapCard from "@/components/BootstrapCard.vue";

export default {
  name: "DownloadList",
  components: {
    BootstrapCard,
  },
  setup() {
    return {
      downloadStore: useDownloadStore(),
      userStore: useUserStore(),
    }
  },
  data() {
    return {}
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
  },
  created() {
    this.downloadStore.index()
  },
  methods: {
    download(url) {
      window.location.href = url
    },
  },
}
</script>

<template>
  <div>
    <template v-if="!item.parent_id">
      <a class="btn btn-primary btn-sm" @click.prevent="showDialog = !showDialog">
        <i class="fas fa-edit"></i>
      </a>
    </template>
    <Dialog v-if="showDialog" v-model:visible="showDialog" :style="{width: '40em'}" :modal="true" class="p-fluid">
      <template #header>
        <h3>{{ $t('client_events.resubmission_dialog.headline') }}</h3>
      </template>
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <div class="d-inline-block me-3">{{ $t('client_events.resubmission_dialog.current') }}:</div>
            <UserAvatar :id="item.assigned_user_id"/>
            {{ formatDate(item.resubmission_date, 'short2') }}
          </div>
          <div>
            <a href="#" class="btn btn-secondary btn-sm" @click.prevent="clear"><i class="fas fa-times"></i>
              {{ $t('client_events.resubmission_dialog.action_clear') }}</a>
          </div>
        </div>
        <div class="mt-3 d-flex flex-wrap">
          <template :key="index" v-for="(user, index) in allUsersStore.activeUsers">
            <UserButton class="mb-2" :user="user" :active="newAssignedUserId === user.id"
                        @userChanged="changeUser"/>
          </template>
        </div>
        <div class="mt-3">
          <form @submit.prevent="save" ref="form"> <!-- Form + Submit to save on ENTER -->
            <input type="date" v-model="newResubmissionDate" :required="!!newAssignedUserId"/>
            <input type="text" v-model="newAssignedUserId" :required="!!newResubmissionDate" style="visibility: hidden">
            <input type="submit" class="d-none">
          </form>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-end">
          <!--          <button class="btn btn-primary" @click="clear">{{ $t('client_events.resubmission_dialog.action_clear') }}</button>-->
          <button class="btn btn-primary" @click="save">{{
              $t('client_events.resubmission_dialog.action_save')
            }}
          </button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import UserAvatar from "@/components/UserAvatar.vue";
import {useAllUsersStore} from "@/stores/all_users";
import {useClientEventStore} from "@/stores/client_event";
import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import UserButton from "@/components/UserButton.vue";
import {Helpers} from "@/helpers";
import {NANO_EVENT_TYPES} from "@/scripts/constants";

export default {
  name: 'ClientEventTimelineItemResubmissionDialog',
  components: {
    Dialog,
    UserAvatar,
    UserButton,
  },
  props: {
    item: {
      type: Object,
    },
  },
  setup() {
    return {
      clientStore: useClientStore(),
      clientEventStore: useClientEventStore(),
      setupStore: useSetupStore(),
      allUsersStore: useAllUsersStore(),
    }
  },
  created() {
    this.setupStore.getClientEventTypes()
  },
  updated() {
    this.init()
  },
  watch: {},
  data() {
    return {
      showDialog: false,
      newAssignedUserId: null,
      newResubmissionDate: null,
    }
  },
  methods: {
    init() {
      this.newAssignedUserId = this.item.assigned_user_id
      this.newResubmissionDate = this.item.resubmission_date
    },
    clear() {
      this.newAssignedUserId = null
      this.newResubmissionDate = null

    },
    triggerForm() {
      // this.$refs.foo.submit()
    },
    save(e) {
      e.preventDefault();

      if (!this.$refs.form.reportValidity()) {
        console.error("form validation failed")
        return;
      }

      this.clientEventStore.update(this.item.id, {
        resubmission_date: this.newResubmissionDate || '',
        assigned_user_id: this.newAssignedUserId || '',
      }).then(() => {
        Helpers.emitter.emit('success', this.$t('client_events.resubmission_dialog.success'))
        Helpers.emitter.emit(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED)
      })

      this.showDialog = false
    },
    changeUser(newId) {
      this.newAssignedUserId = newId
    }
  },
  computed: {},
}
</script>
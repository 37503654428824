<template>
  <div class="dropdown">
    <button class="btn btn-secondary-outline dropdown-toggle" type="button" :id="id" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fa-solid fa-fw fa-ellipsis-vertical"></i>
    </button>
    <div class="dropdown-menu" :aria-labelledby="id">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondaryActionMenu',
  props: {
    keySuffix: String,
  },
  computed: {
    id() {
      return `clientEventSecondaryActionMenu-${this.keySuffix}`
   }
  },
  setup() {
    return {
    }
  },
}
</script>

<style scoped>
.dropdown-toggle:after {
  content: none; /* hide dropdown-icon */
}
</style>
<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('show') }}</h2>
    </div>
  </div>
</template>

<script>
import {useOrganizationStore} from "@/stores/organization";

export default {
  name: 'OrganizationsShow',
  setup() {
    return {
      organizationStore: useOrganizationStore(),
    }
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {
    store() {

    }
  }
}
</script>

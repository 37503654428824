import {defineStore} from 'pinia'
import Swal from 'sweetalert2'
import User from '../models/User'
import {AUTH_TOKEN_NAME, LAST_FULL_PATH, PREVIOUS_AUTH_TOKEN_NAME, ROUTES, SPECIAL_ROLES} from "@/scripts/constants";

export const useUserStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'user',
    // a function that returns a fresh state
    state: () => ({
        user: typeof localStorage[AUTH_TOKEN_NAME] === "undefined" ? null : User.from(localStorage[AUTH_TOKEN_NAME]),
        users: [],
        created: false,
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 20,
            sortBy: 'age',
            sortType: 'desc',
        },
        tokenRefresh: false,
    }),
    // optional getters
    getters: {
        currentUser() {
            this.autoLoad()
            return this.users.find((u) => u.id === this.user.id)
        },
        sipUid() {
            return this.currentUser?.sip_uid
        },
        sipProvider() {
            return this.currentUser?.sip_provider
        },
    },
    // optional actions
    actions: {
        createUser(token) {
            localStorage.tantum_auth_token = token
            this.user = User.from(token)
        },
        login(provider, email, password) {
            return this.apiClient.post(`/auth/login`, {email, password})
        },
        logout() {
            if (localStorage.getItem(PREVIOUS_AUTH_TOKEN_NAME)) {
                this.loginSuccessful(localStorage.getItem(PREVIOUS_AUTH_TOKEN_NAME))
                localStorage.removeItem(PREVIOUS_AUTH_TOKEN_NAME)
            } else {
                this.user.logout()
                this.user = null
            }
            // this.$router.push({name: ROUTES.LOGIN})
        },
        restorePath() {
            const lastFullPath = sessionStorage.getItem(LAST_FULL_PATH)
            if (lastFullPath) {
                this.$router.replace(lastFullPath)
                sessionStorage.removeItem(LAST_FULL_PATH)
                return true
            }
            return null
        },
        loginSuccessful(token) {
            if (!token) {
                this.loginFailed()
                return
            }
            this.createUser(token)
            this.error = false
            if (!this.restorePath()) {
                if (this.user.specialRole && this.user.specialRole === SPECIAL_ROLES.taxOffice) {
                    this.$router.push({name: ROUTES.ACCOUNTS.INDEX})
                } else {
                    this.$router.push({name: ROUTES.DASHBOARD})
                }
            }
        },
        register(userData) {
            return this.apiClient.post(`/users`, {user: userData}).then(({data}) => {
                if (data.error) {
                    Swal.fire({
                        title: 'Error!',
                        text: JSON.stringify(data.message),
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    this.created = true;
                }
            })
        },
        autoRefresh() {
            if (this.user?.expiresSoon()) {
                this.refreshAccessToken()
            }
        },
        refreshAccessToken() {
            if (this.tokenRefresh) {
                return
            }
            if (!this.user.refreshToken) {
                console.log("no access token available => skipping refresh")
                return
            }
            this.tokenRefresh = true
            console.log("refreshing access token")
            return this.apiClient.put(`/auth`, {refresh_token: this.user.refreshToken}).then(({data}) => {
                console.log("got response: ", data.token)
                this.createUser(data.token)
            }).finally(() => this.tokenRefresh = false)
        },
        confirm(uuid) {
            return this.apiClient.get(`/account_confirmation/${uuid}`);
        },
        index() {
            this.loading = true
            return this.apiClient.get(`/users`, {
                params: {
                    page: this.serverOptions.page + 1,
                    size: this.serverOptions.rows
                }
            }).then(({data}) => {
                this.users = data.users
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        autoLoad() {
            if (this.users.length || this.loading) {
                return
            }
            this.index()
        },
        show(id) {
            return this.apiClient.get(`/users/${id}`)
        },
        update(user, id, permissions) {
            return this.apiClient.put(`/users/${id}`, {user, permissions})
        },
        impersonate(id) {
            return this.apiClient.get(`/users/${id}/impersonate`)
        },
        triggerJobs() {
            return this.apiClient.post(`/product_billing_periods/trigger_jobs`, {})
        },
        call(number) {
            return this.apiClient.post(`/calls`, {target: number})
        },
    },
})

<template>
  <DataTable stripedRows :lazy="true"
             :paginator="false"
             :rows="transactionStore.serverOptions.rows"
             :totalRecords="transactionStore.itemLength"
             dataKey="id"
             :value="account_transactions"
             :loading="transactionStore.loading"
             responsiveLayout="scroll">
    <TableColumn field="booking_date" :header="this.$t('transactions.booking_date')">
      <template #body="{ data }">
        <div class="">
          <template v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.VIEW)">
            <router-link :to="{
              name: ROUTES.ACCOUNTS.SHOW,
              params: {id: data.account_id},
              query: {
                start: data.raw_booking_date,
                end: data.raw_booking_date,
              }
            }" :title="`${this.$t('transactions.value_date')}: ${data.value_date}`" :data-id="data.id">
              {{ data.booking_date }}
            </router-link>
          </template>
          <template v-else>
            {{ data.booking_date }}
          </template>
        </div>
      </template>
    </TableColumn>
    <TableColumn field="counterparty_name" :header="this.$t('transactions.counterparty_name')">
    </TableColumn>
    <TableColumn field="remittance_info" :header="this.$t('transactions.remittance_info')">
    </TableColumn>
    <TableColumn class="currency" field="amount" :header="this.$t('transactions.amount')">
    </TableColumn>
    <TableColumn field="note" :header="this.$t('transactions.note')" style="max-width: 200px">
      <template #editor="{ data, field }">
        <input v-model="data[field]" autofocus class="w-100"/>
      </template>
    </TableColumn>
    <TableColumn :header="this.$tc('receipts.receipt', 2)">
      <template #body="{data}">
        <div>
          <template v-if="data.receipts.length > 0">
            <a :href="receipt.file_url" :key="index" :title="receipt.file_name"
               v-for="(receipt, index) in data.receipts" target="_blank">
              <i class="fas fa-receipt"/> <span v-html="receipt.file_name.truncate(25)"></span>
            </a>
          </template>
          <template :key="index" v-for="(invoice, index) in data.invoices">
            <router-link class="d-block"
                         :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: invoice.id}}"
                         @click.prevent="showInvoice(invoice.id)"
            >
              <i class="fas fa-file-invoice-dollar"/> {{ invoice.number }}
            </router-link>
          </template>
        </div>
      </template>
    </TableColumn>
    <ColumnGroup type="footer">
      <TableRow>
        <TableColumn :colspan="3" footerStyle="text-align:right">
          <template #footer>
            <i class="fa-regular fa-sigma"></i>
          </template>
        </TableColumn>
        <TableColumn :footer="formatCurrency(rowSums.amount)" footerStyle="text-align:right"/>
      </TableRow>
    </ColumnGroup>
  </DataTable>
</template>
<script>
import {PERMISSIONS, ROUTES} from "@/scripts/constants";
import {useUserStore} from "@/stores/user";
import {useTransactionStore} from "@/stores/transactions";

export default {
  name: 'AccountTransactionsTable',
  components: {},
  setup() {
    return {
      transactionStore: useTransactionStore(),
      userStore: useUserStore(),
    }
  },
  data() {
    return {}
  },
  props: {},
  created() {
      this.getTransactions()
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    account_transactions() {
      return this.transactionStore.transactions.map((transaction) => {
        return {
          booking_date: this.$d(transaction.booking_date, 'short2'),
          raw_booking_date: transaction.booking_date,
          value_date: this.$d(transaction.value_date, 'short2'),
          counterparty_name: transaction.counterparty_name,
          remittance_info: transaction.remittance_info,
          amount: this.formatCurrency(transaction.amount),
          raw_amount: transaction.amount,
          tags: transaction.tags,
          note: transaction.note,
          receipts: transaction.receipts,
          invoices: transaction.invoices,
          id: transaction.id,
          audited: transaction.audited,
          account_id: transaction.account_id,
        }
      })
    },
    rowSums() {
      const sums = {
        amount: 0,
      }
      for (let i = 0; i < this.transactionStore.transactions.length; i++) {
        const amount = parseFloat(this.transactionStore.transactions[i].amount)
        if (!isNaN(amount)) {
          sums.amount += amount
        }
      }
      return sums
    },
  },
  methods: {
    getTransactions() {
      this.transactionStore.getForInvoice(this.$route.params.invoice_id)
    },
  },
}
</script>
<style lang="scss">
.tags-filter-multiselect {
  width: 120px !important;
}
</style>
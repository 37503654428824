<template>
  <div class="row">
    <div class="col-12 d-flex">
      <h2>{{ $tc('products.product', 2).capitalize() }}</h2>
      <div class="ms-3" v-if="client_id">
        <router-link class="btn btn-primary btn-sm" :to="{name: ROUTES.PRODUCTS.CREATE, query:{ client_id }}">
          <i class="fa-solid fa-circle-plus"></i> {{ $t('products.create.headline') }}
        </router-link>
      </div>
    </div>
    <div class="col-12">
      <ProductsTable
          :client_id="client_id"
          :paginator="true"
          :rows="productStore.serverOptions.rows"
          :totalRecords="productStore.itemLength"
          dataKey="id"
          :value="productStore.products"
          :loading="productStore.loading"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll">
      </ProductsTable>
    </div>
  </div>
</template>

<script>
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import ProductsTable from "@/components/tables/ProductsTable.vue";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ProductsIndex',
  setup() {
    return {
      productStore: useProductStore(),
      setupStore: useSetupStore(),
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    ProductsTable,
  },
  data() {
    return {
    }
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
  },
  created() {
    this.setTitle(this.$tc('products.product', 2).capitalize())
  },
  methods: {
    // currency(value) {
    //   const formatter = new Intl.NumberFormat('de-DE', {
    //     style: 'currency',
    //     currency: 'EUR'
    //   });
    //   return formatter.format(value);
    // },
  }
}
</script>

<template>
    <div class="date d-flex flex-wrap align-items-end">
    <div :class="{'w-100': forceWrapAfterDate, 'me-2': !forceWrapAfterDate}">
        <div class="badge text-nowrap d-inline-block"
             :style="`background:var(${dateColor(item.resubmission_date)}); color: white; margin-left:auto`">
          <template v-if="item.resubmission_date">
            <i class="fa-solid fa-exclamation-triangle me-1"></i>
            <template v-if="showLabel"> {{ $t('client_events.resubmission') }}</template>
          </template>
          <template v-else>
            <template v-if="showLabel"> {{ $t('client_events.no_resubmission') }}</template>
          </template>
          {{ formatDate(item.resubmission_date, 'short2') }}
        </div>
      </div>
      <UserAvatar :id="item.assigned_user_id" class="user d-block mt-2"/>
      <ClientEventTimelineItemResubmissionDialog :item="item" class="mt-2 ms-2" />
      <slot />
    </div>
</template>

<script>
import {useAllUsersStore} from "@/stores/all_users";
import {useSetupStore} from "@/stores/setup";
import UserAvatar from "@/components/UserAvatar.vue";
import ClientEventTimelineItemResubmissionDialog from "@/components/ClientEventTimelineItemResubmissionDialog.vue";


export default {
  //from https://www.bootdey.com/snippets/view/bs4-timeline
  name: 'ClientEventTimelineItemResubmissionPreview',
  components: {
    ClientEventTimelineItemResubmissionDialog,
    UserAvatar,
  },
  props: {
    item: {    },
    forceWrapAfterDate: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      setupStore: useSetupStore(),
      useAllUsersStore: useAllUsersStore(),
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {},
  created() {
  }
}
</script>
<style lang="scss">

</style>
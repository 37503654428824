import axios from 'axios';
import Swal from 'sweetalert2'
import {AUTH_TOKEN_NAME} from "./constants";
import {useSetupStore} from "@/stores/setup";
import {useUserStore} from "@/stores/user";

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl = null) => {
    // let lastRequest = null;
    const setupStore = useSetupStore()
    const options = {
        baseURL: baseUrl,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const client = axios.create(options);

    client.interceptors.request.use(
        (requestConfig) => {
            setupStore.axiosLoading += 1
            // lastRequest = requestConfig
            return requestConfig
        },
        (requestError) => {
            // oops
            return Promise.reject(requestError);
        },
    );

    client.interceptors.request.use((config) => {
        const token = localStorage[AUTH_TOKEN_NAME]
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config
    });

    // // Add a request interceptor
    // client.interceptors.request.use(
    //     requestConfig => requestConfig,
    //     (requestError) => {
    //         // oops
    //         return Promise.reject(requestError);
    //     },
    // );

    // Add a response interceptor
    client.interceptors.response.use(
        (response) => {
            setupStore.axiosLoading -= 1
            return response
        },
        (error) => {
            setupStore.axiosLoading -= 1
            console.error(JSON.stringify(error))
            if (!error.response) {
                Swal.fire(
                    error.message,
                    error.message,
                    'error'
                )
                return Promise.reject(error);
            }
            if (error.response.status === 401) {
                // try refresh... if it fails then user will be logged out
                const userStore = useUserStore();
                Swal.fire( // FIXME: I18n
                    'Session abgelaufen',
                    'Leider konnte die Session nicht automatisch verlängert werden ...',
                    'error'
                )
                userStore.logout()
            }
            if (error.response.status >= 500) {
                //oops
                Swal.fire(
                    'Fehler',
                    error.response.data.message,
                    'error'
                )
            }
            return Promise.reject(error);
        },
    );

    return client;
};

class ApiClient {
    constructor(baseUrl = null) {
        this.client = getClient(baseUrl);
    }

    get(url, conf = {}) {
        return this.client.get(url, conf)
    }

    delete(url, conf = {}) {
        return this.client.delete(url, conf)
    }

    head(url, conf = {}) {
        return this.client.head(url, conf)
    }

    options(url, conf = {}) {
        return this.client.options(url, conf)
    }

    post(url, data = {}, conf = {}) {
        return this.client.post(url, data, conf)
    }

    put(url, data = {}, conf = {}) {
        return this.client.put(url, data, conf)
    }

    patch(url, data = {}, conf = {}) {
        return this.client.patch(url, data, conf)
    }
}

export {ApiClient};

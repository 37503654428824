<template>
  <div class="row">
    <div class="col-12 d-flex">
      <h2>{{ $t('clients.direct_debit_mandates') }}</h2>
      <div class="ms-3">
        <router-link class="btn btn-primary btn-sm"
                     :to="{name: ROUTES.DIRECT_DEBIT_MANDATES.CREATE, query: {client_id: client_id}}">
          <i class="fas fa-file-circle-plus"/> {{ $t('direct_debit_mandates.create') }}
        </router-link>
      </div>
    </div>
    <div class="col-12">
      <DirectDebitMandatesTable :client_id="clientId" :paginator="true"/>
    </div>
  </div>
</template>

<script>

import DirectDebitMandatesTable from "@/components/tables/DirectDebitMandatesTable";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'DirectDebitMandatesIndex',
  components: {
    DirectDebitMandatesTable,
  },
  setup() {
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  created() {
  },
  computed: {
    ROUTES(){
      return ROUTES
    },
    clientId() {
      if (this.$route.query.client_id || this.client_id) {
        return this.$route.query.client_id || this.client_id
      }
      return null
    }
  },
  methods: {
  },
  watch: {}
}
</script>

import {defineStore} from 'pinia'
import {useClientStore} from "@/stores/client";
export const useGlobalStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'global',
    // a function that returns a fresh state
    state: () => ({
        document: {
            title: 'TantumCRM'
        }
    }),
    // optional getters
    getters: {

    },
    // optional actions
    actions: {
        setTitle(title = 'title missing') {
            this.document.title = title
            this.setDocumentTitle()
        },
        setDocumentTitle() {
            if(!useClientStore().client.isE) {
                document.title = `${useClientStore().client.kdnr} ${useClientStore().client.name} - ${this.document.title}`
            } else {
                document.title = this.document.title
            }
        },
    },
})

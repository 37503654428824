<template>
  <div class="d-flex col-12">
    <h1>
      {{ visibleNumberOfClientEvents.toLocaleString() }}
      <template v-if="visibleNumberOfClientEvents != totalNumberOfClientEvents">
        <small class="opacity-50">/ {{ totalNumberOfClientEvents.toLocaleString() }}</small>
      </template>
      {{ $tc('client_events.client_events', totalNumberOfClientEvents) }}

      <template v-if="clientEventStore.mode == 'tree'">
        in
        {{ numberOfParentClientEvents.toLocaleString() }}
        {{ $tc('client_events.client_event_threads', numberOfParentClientEvents) }}
      </template>
    </h1>
    <div class="ms-2" v-if="newEventRef">
      <a href="#new_event" @click.prevent="newEventRef?.scrollIntoView({ behavior: 'smooth' })"
         class="btn btn-primary btn-sm">
        <i class="fas fa-plus fa-fw"></i>
      </a>
    </div>
  </div>
</template>

<script>

import {useClientEventStore} from "@/stores/client_event";


export default {
  name: 'ComponentClientEventTimelineHeader',
  components: {},
  props: {
    newEventRef: {
      type: Object
    }
  },
  setup() {
    return {
      clientEventStore: useClientEventStore(),
    }
  },
  created() {
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    numberOfParentClientEvents() {
      return this.clientEventStore.client_events.length
    },
    visibleNumberOfClientEvents() {
      return this.clientEventStore.client_events.length + this.clientEventStore.client_events.map(ce => ce.children?.length || 0).reduce((a, b) => a + b, 0)
    },
    totalNumberOfClientEvents() {
      return this.clientEventStore.itemLength
    },
  },
}
</script>

<style lang="scss">
</style>
<template>
  <div class="wrapper" v-if="isLoggedIn">
    <div class="top-wrapper" :class="{'client-tab-nav-active': clientIsSet}">
      <Navbar />
      <BreadcrumbNav />
    </div>
    <div class="content-wrapper d-flex">
      <div class="content flex-grow-1 w-100">
        <div class="container-fluid">
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper bg-blue" v-else>
      <Login />
  </div>
</template>

<script>
import {defineAsyncComponent} from 'vue'
import {useUserStore} from "@/stores/user";
import {useClientStore} from "@/stores/client";
import BreadcrumbNav from "@/components/layout/BreadcrumbNav";
import Login from "@/pages/Login"

const Navbar = defineAsyncComponent(() =>
    import('./components/layout/Navbar.vue')
)
export default {
  setup() {
    const userStore = useUserStore()
    const clientStore = useClientStore()
    return {
      userStore,
      clientStore,
    }
  },
  components: {
    Navbar,
    BreadcrumbNav,
    Login
  },
  name: "App",
  data() {
    return {
      sidebarActive: false,
    }
  },
  methods: {
    logout(e) {
      e.preventDefault()
    },
  },
  created() {
    this.userStore.autoRefresh();
    setInterval(this.userStore.autoRefresh, 5 * 60 * 1000)
    this.userStore.restorePath()
  },
  computed: {
    isLoggedIn() {
      return !!this.userStore.user;
    },
  }
}
</script>

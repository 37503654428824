<template>
  <div class="p-4">
    <h2>{{ $t('organizations.create.header') }}</h2>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">{{ $t('organizations.create.headline') }}</h5>
        <Form @submit="store()" v-slot="{ errors }" :validation-schema="schema" class="row register-form h-100">
          <div class="col-12 mb-3">
            <div class="form-floating">
              <Field v-model="organization.name" id="organization.name" name="organization.name"
                     placeholder="{{$t('organizations.name')}}" class="form-control"
                     :class="{'is-invalid': errors['organization.name']}"/>
              <label for="organization.name">{{ $t('organizations.name') }}</label>
              <div v-if="errors['organization.name']" class="invalid-feedback">
                {{ errors['organization.name'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="address.street" id="address.street" name="address.street"
                     placeholder="{{$t('addresses.street')}}" class="form-control"
                     :class="{'is-invalid': errors['address.street']}"/>
              <label for="address.street">{{ $t('addresses.street') }}</label>
              <div v-if="errors['address.street']" class="invalid-feedback">
                {{ errors['address.street'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="address.postcode" id="address.postcode" name="address.postcode"
                     placeholder="{{$t('addresses.postcode')}}" class="form-control"
                     :class="{'is-invalid': errors['address.postcode']}"/>
              <label for="address.postcode">{{ $t('addresses.postcode') }}</label>
              <div v-if="errors['address.postcode']" class="invalid-feedback">
                {{ errors['address.postcode'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="address.city" id="address.city" name="address.city" placeholder="{{$t('addresses.city')}}"
                     class="form-control" :class="{'is-invalid': errors['address.city']}"/>
              <label for="address.city">{{ $t('addresses.city') }}</label>
              <div v-if="errors['address.city']" class="invalid-feedback">
                {{ errors['address.city'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field class="form-select" name="address.country_code" as="select" id="address.country_code"
                     v-model="address.country_code" :class="{'is-invalid': errors['address.country_code']}">
                <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
              </Field>
              <label for="address.country_code">{{ $t('addresses.country') }}</label>
              <div v-if="errors['address.country_code']" class="invalid-feedback">
                {{ errors['address.country_code'] }}
              </div>
            </div>
          </div>
          <div class="my-3"></div>
          <div class="col-12 col-lg-4 mb-3">
            <div class="form-floating">
              <Field v-model="organization.ceo" id="organization.ceo" name="organization.ceo"
                     placeholder="{{$t('organizations.ceo')}}" class="form-control"
                     :class="{'is-invalid': errors['organization.ceo']}"/>
              <label for="organization.ceo">{{ $t('organizations.ceo') }}</label>
              <div v-if="errors['organization.ceo']" class="invalid-feedback">
                {{ errors['organization.ceo'] }}
              </div>
            </div>
          </div>
          <div class="my-3"></div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="organization.email" id="organization.email" name="organization.email"
                     placeholder="{{$t('organizations.email')}}" class="form-control"
                     :class="{'is-invalid': errors['organization.email']}"/>
              <label for="organization.email">{{ $t('organizations.email') }}</label>
              <div v-if="errors['organization.email']" class="invalid-feedback">
                {{ errors['organization.email'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="organization.phone" id="organization.phone" name="organization.phone"
                     placeholder="{{$t('organizations.phone')}}" class="form-control"
                     :class="{'is-invalid': errors['organization.phone']}"/>
              <label for="organization.phone">{{ $t('organizations.phone') }}</label>
              <div v-if="errors['organization.phone']" class="invalid-feedback">
                {{ errors['organization.phone'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="organization.vat_id" id="organization.vat_id" name="organization.vat_id"
                     placeholder="{{$t('organizations.vat_id')}}" class="form-control"
                     :class="{'is-invalid': errors['organization.vat_id']}"/>
              <label for="organization.vat_id">{{ $t('organizations.vat_id') }}</label>
              <div v-if="errors['organization.vat_id']" class="invalid-feedback">
                {{ errors['organization.vat_id'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="organization.tax_id" id="organization.tax_id" name="organization.tax_id"
                     placeholder="{{$t('organizations.tax_id')}}" class="form-control"
                     :class="{'is-invalid': errors['organization.tax_id']}"/>
              <label for="organization.tax_id">{{ $t('organizations.tax_id') }}</label>
              <div v-if="errors['organization.tax_id']" class="invalid-feedback">
                {{ errors['organization.tax_id'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="organization.register_number" id="organization.register_number"
                     name="organization.register_number" placeholder="{{$t('organizations.register_number')}}"
                     class="form-control" :class="{'is-invalid': errors['organization.register_number']}"/>
              <label for="organization.register_number">{{ $t('organizations.register_number') }}</label>
              <div v-if="errors['organization.register_number']" class="invalid-feedback">
                {{ errors['organization.register_number'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-floating">
              <Field v-model="organization.register_court" id="organization.register_court"
                     name="organization.register_court" placeholder="{{$t('organizations.register_court')}}"
                     class="form-control" :class="{'is-invalid': errors['organization.register_court']}"/>
              <label for="organization.register_court">{{ $t('organizations.register_court') }}</label>
              <div v-if="errors['organization.register_court']" class="invalid-feedback">
                {{ errors['organization.register_court'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import {useOrganizationStore} from "@/stores/organization";
import {useSetupStore} from "@/stores/setup";
import {Field, Form} from 'vee-validate';
import * as Yup from "yup";
import {Helpers} from "@/helpers";

export default {
  name: 'OrganizationsCreate',
  components: {
    Field,
    Form,
  },
  setup() {
    return {
      organizationStore: useOrganizationStore(),
      setupStore: useSetupStore(),
    }
  },
  data() {
    return {
      organization: {
        name: '',
        email: '',
        phone: '',
        ceo: '',
        vat_id: '',
        tax_id: '',
        register_court: '',
        register_number: '',
      },
      address: {
        street: '',
        postcode: '',
        country_code: '',
        city: '',
      },
      schema: Yup.object().shape({
        organization: Yup.object().shape({
          name: Yup.string().required(this.$t('validation.required')),
          ceo: Yup.string().required(this.$t('validation.required')),
          vat_id: Yup.string().required(this.$t('validation.required')),
          tax_id: Yup.string().required(this.$t('validation.required')),
        }),
        address: Yup.object().shape({
          street: Yup.string().required(this.$t('validation.required')),
          postcode: Yup.string().required(this.$t('validation.required')),
          country_code: Yup.string().required(this.$t('validation.required')),
          city: Yup.string().required(this.$t('validation.required')),
        }),
      })
    }
  },
  created() {
    this.setupStore.getCountries()
  },
  computed: {
    countries() {
      return this.setupStore.countries
    }
  },
  methods: {
    store() {
      this.organizationStore.store(this.organization, this.address).then(({data}) => {
        this.userStore.createUser(data.access_token)
        Helpers.emitter.emit('success', this.$t('organizations.create.success'))
      })
    },

  }
}
</script>

<template>
  <div class="avatar">
    <img :src="imageSrc" v-if="imageSrc" :title="user?.name">
  </div>
</template>

<script>
// import {useSetupStore} from "@/stores/setup";
import {useAllUsersStore} from "@/stores/all_users";

export default {
  name: 'UserAvatar',
  components: {},
  props: {
    // pass either url or number
    url: String,
    id: Number,
  },
  setup() {
    return {
      // setupStore: useSetupStore(),
      allUsersStore: useAllUsersStore(),
    }
  },
  data() {
    return {}
  },
  created() {
    if (this.id) {
      this.allUsersStore.autoLoad();
    }
  },
  computed: {
    user() {
      return this.allUsersStore.user(this.id);
    },
    imageSrc() {
      if (this.url) {
        return this.url;
      }
      if (this.user) {
        return this.user.avatar_url
      } else {
        return false
      }
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.avatar {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 30px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
<template>
  <div class="row" v-if="!loadingAccounts">
    <div class="col-12">
      <h2>{{$t('accounts.link_new')}}</h2>
    </div>
    <div class="col-12 mt-3" v-if="newAccounts.length > 0">
      <h2>{{$t('accounts.found')}}</h2>
      <div class="d-flex align-items-center my-3" v-for="(account, key) in newAccounts" :key="key">
        <div class="me-3">{{account.iban}}</div>
        <button class="btn btn-primary" @click="createAccount(account)"> {{$t('create')}} </button>
      </div>
    </div>
    <div class="col-12" v-else>
      <div class="row">
        <div class="col-12 col-lg-6 me-3">
          <input class="form-control" @input="searchBank" />
        </div>
        <div class="col-12 col-lg-3">
          <button class="btn btn-secondary" @click="requisitionBank({id: 'SANDBOXFINANCE_SFIN0000'})">{{$t('accounts.create_sandbox')}}</button>
        </div>
        <div class="col-12 mt-3">
          <div class="d-flex align-items-center my-3" v-for="(bank, key) in filteredBanks" :key="key">
            <img class="bank-logo me-3" :src=bank.item.logo />
            <div class="me-3">{{bank.item.name}}</div>
            <div class="me-3">{{bank.item.bic}}</div>
            <button class="btn btn-primary" @click="requisitionBank(bank.item)"> {{$t('accounts.request_requisition')}} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useAccountStore} from "@/stores/account";
import {useAccountProviderStore} from "@/stores/account_provider";
import Fuse from 'fuse.js'
import {Helpers} from "@/helpers";
import {ROUTES} from "@/scripts/constants";
export default {
  name: 'AccountsCreate',
  components: {
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      accountProviderStore: useAccountProviderStore(),
    }
  },
  data() {
    return {
      loadingAccounts: false,
      banks: [],
      newAccounts: [],
      searchString: ''
    }
  },
  computed: {
    filteredBanks() {
      const options = {
        includeScore: true,
        threshold: 0.2,
        // Search in `author` and in `tags` array
        keys: ['name', 'bic']
      }
      const fuse = new Fuse(this.banks, options)
      return fuse.search(this.searchString)
    }
  },

  created() {
    if (this.$route.query.code) {
      //deutsche bank
      const state = atob(this.$route.query.state)
      const accountProviderId = JSON.parse(state).accountProviderId
      this.accountProviderStore.connect(accountProviderId, this.$route.query.code).then(({data}) => {
        this.accountStore.accounts = data.accounts
        Helpers.emitter.emit('success', data.message)
        this.$router.push({name: ROUTES.ACCOUNTS.INDEX})
      })
    } else {
      //nordigen
      this.accountProviderStore.getBanks().then(({data}) => {
        this.banks = data.banks
      })
      if (this.$route.query.ref) {
        this.loadingAccounts = true
        this.accountProviderStore.getAccounts(this.$route.query.ref).then(({data}) => {
          this.newAccounts = data.accounts
          this.loadingAccounts = false
        })
      }
    }

  },
  methods: {
    searchBank(e) {
      this.searchString = e.target.value
    },
    requisitionBank(bank) {
      this.accountProviderStore.requisitionNordigen(bank).then(({data}) => {
        window.location.replace(data.link)
      })
    },
    createAccount(account) {
      this.accountStore.store(account).then(() => {
        this.$router.push({name: ROUTES.ACCOUNTS.INDEX})
      })
    }
  },
  watch: {
  }
}
</script>
<style lang="scss">
.bank-logo {
  height: 40px;
}
</style>

<template>
  <div class="list-group-item contact d-flex justify-content-between">
    <div>
      <span v-html="checkForMarking(contact.first_name)"/> <span v-html="checkForMarking(contact.last_name)"/>
      <template v-if="contact.email">
        - <span v-html="checkForMarking(contact.email)"/>
      </template>
      <template v-if="contact.phone">
        - <span v-html="checkForMarking(contact.phone)"/>
      </template>
      <template v-if="contact.mobile_phone">
        - <span v-html="checkForMarking(contact.mobile_phone)"/>
      </template>
    </div>
    <router-link class="ms-3 btn btn-primary btn-sm"
                 :to="{name: ROUTES.CONTACTS.SHOW, params: {id: contact.id}}">
      <i class="fa-solid fa-users-between-lines"></i> {{ $t('contacts.info') }}
    </router-link>
  </div>
</template>
<script>

import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContactButton',
  props: {
    searchString: String,
    contact: Object,
  },
  setup() {
    return {
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  methods: {
    checkForMarking(text) {
      if (text) {
        const regex = new RegExp(this.searchString, 'gi');
        text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
        return text.replace(regex, '<mark class="highlight">$&</mark>');
      }
      return ''
    },
  },
}
</script>
<template>
  <div class="row">
    <div class="col-12 mt-3">
      <h2>{{ $t('contracts.create.headline') }}</h2>
    </div>
    <ContractsForm @submitted="store" :contract="{ products: [] }"/>
  </div>
</template>

<script>
import {useContractStore} from "@/stores/contract";

import ContractsForm from "./Form";
import {Helpers} from "@/helpers";
import {useClientStore} from "@/stores/client";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContractsCreate',
  setup() {
    return {
      clientStore: useClientStore(),
      contractStore: useContractStore(),
    }
  },
  components: {
    ContractsForm
  },
  data() {
    return {
      contract: {
        title: '',
        contract_status_id: null,
      },
      client: {
        id: null
      }
    }
  },
  created() {
    this.clientStore.setCurrentClientById(this.$route.query.client_id, this.$t('contracts.create.headline'))
  },
  computed: {},
  watch: {
  },
  methods: {
    store(values) {
      values.contract.client_id = this.clientStore.client.id
      if (values.products) {
        values.contract.products_attributes = values.products
        delete values.products
      }
      this.contractStore.store(values).then(({data}) => {
        Helpers.emitter.emit('success', this.$t('contracts.create.success'))
        this.$router.push({name: ROUTES.CONTRACTS.SHOW, params: {id: data.id}})
      })
    }
  }
}
</script>

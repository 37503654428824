<template>
  <div>
    <template v-if="showForm">
      <ClientEventForm :parent-event="parentEvent" :eventable-type="eventableType" :eventable-id="eventableId"/>
    </template>
    <template v-else>
      <div class="ms-5">
        <button class="client-event-append-js operation-item btn btn-sm btn-primary mb-3 scroll-margin-top"
                @click.prevent="showForm = true"
        >
          <i class="fas fa-plus me-1"/>
          <template v-if="eventableType">
            {{ $t('client_events.new') }}
          </template>
          <template v-else>
            {{ $t('client_events.append') }}
          </template>
        </button>
      </div>
    </template>
  </div>
</template>

<script>

import ClientEventForm from "@/components/ClientEventForm.vue";
import {Helpers} from "@/helpers";

export default {
  name: 'ClientEventAppendButton',
  components: {ClientEventForm},

  data() {
    return {
      showForm: false
    }
  },
  props: {
    parentId: {
      type: Number,
    },
    parentEvent: {
      type: Object,
    },
    eventableType: {
      type: String,
    },
    eventableId: {
      type: Number,
    },
  },
  created() {
    Helpers.emitter.on('success', () => {
      this.showForm = false
    })
  }
}
</script>
<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $tc('users.user', 2).capitalize() }}</h2>
    </div>
    <div class="col-12">

      <DataTable stripedRows :lazy="true"
                 :paginator="true"
                 :rows="userStore.serverOptions.rows"
                 :totalRecords="userStore.itemLength"
                 dataKey="id"
                 :value="userStore.users"
                 :loading="userStore.loading"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 :rowClass="({ active }) => active ? '' : 'opacity-50'"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 @page="onPage($event)"
                 responsiveLayout="scroll">
        <TableColumn field="id" :header="this.$t('users.id')"></TableColumn>
        <TableColumn field="name" :header="this.$t('users.name')">
          <template #body="slotProps">
            <UserAvatar :url="slotProps.data.avatar_url"/>
            {{ slotProps.data.name }}
          </template>
        </TableColumn>
        <TableColumn field="email" :header="this.$t('users.email')"></TableColumn>
        <TableColumn field="active" :header="this.$t('users.active')">
          <template #body="{data}">
            <show-bool :value="data.active" />
          </template>
        </TableColumn>
        <TableColumn :header="this.$t('users.operations')">
          <template #body="slotProps">
            <div class="operations-wrapper">
              <router-link class="operation-item" :to="{name: ROUTES.USERS.EDIT, params: {id: slotProps.data.id}}"
                           :title="$t('users.edit.headline')">
                <i class="fas fa-edit"/>
              </router-link>
              <a href="#" class="operation-item" @click.prevent="impersonateUser(slotProps.data.id)">
                <i class="fa-solid fa-right-to-bracket"></i>
              </a>
            </div>
          </template>
        </TableColumn>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/user";
import UserAvatar from "@/components/UserAvatar.vue";
import {AUTH_TOKEN_NAME, PREVIOUS_AUTH_TOKEN_NAME, ROUTES} from "@/scripts/constants";
import ShowBool from "@/components/ShowBool.vue";

export default {
  name: 'UsersIndex',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  setup() {
    return {
      userStore: useUserStore(),
    }
  },
  components: {
    ShowBool,
    UserAvatar: UserAvatar,
  },
  data() {
    return {
    }
  },
  created() {
    this.getUsers()
    this.setTitle(this.$tc('users.user', 2).capitalize())
  },
  methods: {
    getUsers() {
      this.userStore.index()
    },
    impersonateUser(id) {
      this.userStore.impersonate(id).then(({data}) => {
          localStorage.setItem(PREVIOUS_AUTH_TOKEN_NAME, localStorage.getItem(AUTH_TOKEN_NAME))
          this.userStore.loginSuccessful(data.token)
      })
    },
    onPage(event) {
      this.userStore.serverOptions = event
      this.getUsers()
    },
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 d-flex">
      <h2>{{ $tc('invoices.invoice', 2).capitalize() }}</h2>
      <div class="ms-3" v-if="client_id">
        <router-link class="btn btn-primary btn-sm" :to="{name: ROUTES.INVOICES.CREATE, query:{ client_id }}">
          <i class="fa-solid fa-circle-plus"></i> {{ $t('invoices.create') }}
        </router-link>
      </div>
    </div>
    <InvoicesTable class="col-12" :client_id="client_id" />
  </div>
</template>

<script>
import {ROUTES} from "@/scripts/constants";
import InvoicesTable from "@/components/tables/InvoicesTable.vue";

export default {
  name: 'InvoicesIndex',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  components: {
      InvoicesTable
  },
  setup() {
    return {
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      {{ $t('invoices.preview') }}
    </div>
    <div class="card-body p-0">
      <FilePreview :src="this.dunningStore.previewData" />
    </div>
  </div>
</template>

<script>
import {useDunningStore} from "@/stores/dunning";
import FilePreview from "@/components/FilePreview.vue";

export default {
  setup() {
    return {
      dunningStore: useDunningStore(),
    }
  },
  components: {
    FilePreview,
  },
  props: {
    id: Number,
    version: {
      type: Number,
      default: 0,
    },
  },
  created() {
    if (this.id) {
      this.dunningStore.preview(this.id)
    }
  },
  watch: {
    version() {
      this.dunningStore.preview(this.id, null, this.version)
    }
  },
  computed: {
  },
}
</script>

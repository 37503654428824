<template>
  <div class="row">
    <div class="col-12 col-lg-1">
      {{ $t('invoices.position') }}
    </div>
    <div class="col-12 col-lg-7">
      {{ $t('invoices.content') }}
    </div>
    <div class="col-12 col-lg-4">
      {{ $t('invoices.config') }}
    </div>
  </div>

  <FieldArray name="invoice.positions" v-slot="{ fields, move, insert, push, remove }" ref="fa">
    <Sortable
        :list="fields"
        item-key="key"
        @end="endSort($event, move)"
    >
      <template #item="{element, index}">
        <div class="draggable" :key="`draggable-${element.key}`">
          <fieldset class="row" :key="element.key">
            <hr class="mt-3"/>
            <div class="col-12 col-lg-1">
              {{ index + 1 }}
              <div class="mt-3">
                <div class="btn-toolbar" role="toolbar" aria-label="FIXME">
                  <div class="btn-group me-2 mb-2" role="group" aria-label="FIXME">
                    <button type="button" class="btn btn-sm btn-secondary"
                            @click.prevent="remove(index); $emit('removeProductBillingPeriodId', element.value.product_billing_period_id)"
                            :title="$t('invoices.remove_position')">
                      <i class="fa-solid fa-fw fa-trash"></i>
                    </button>

                    <button class="btn btn-sm btn-info" type="button"
                            @click="insert(index + 1, clonePosition(fields, index))"
                            :title="$t('actions.clone')">
                      <i class="fa-solid fa-fw fa-clone"></i>
                    </button>
                  </div>

                  <template v-if="!element.isFirst">
                    <div class="btn-group me-2 mb-2" role="group" aria-label="FIXME">
                      <button class="btn btn-sm btn-info" type="button" @click="move(index, 0)"
                              :title="$t('actions.move-top')">
                        <i class="fa-solid fa-fw fa-arrow-up-to-line"></i>
                      </button>
                      <button class="btn btn-sm btn-info" type="button" @click="move(index, index - 1)"
                              :title="$t('actions.clone-to-end')">
                        <i class="fa-solid fa-fw fa-arrow-up"></i>
                      </button>
                    </div>
                  </template>
                  <template v-if="!element.isLast">
                    <div class="btn-group me-2 mb-2" role="group" aria-label="FIXME">
                      <button class="btn btn-sm btn-info" type="button" @click="move(index, fields.length - 1)"
                              :title="$t('actions.clone-to-end')">
                        <i class="fa-solid fa-fw fa-arrow-down-to-line"></i>
                      </button>
                      <button class="btn btn-sm btn-info" type="button" @click="move(index, index + 1)"
                              :title="$t('actions.clone-to-end')">
                        <i class="fa-solid fa-fw fa-arrow-down"></i>
                      </button>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-7">
              <div class="d-flex" v-html="checkInvoiceLabel(fields[index].value)"></div>
              <Field :name="`invoice.positions[${index}].content`">
                <TextEditor
                    v-model="element.value.content"
                    :class="{'is-invalid': errors[`invoice.positions[${index}].content`]}"
                />
                <div v-if="errors[`invoice.positions[${index}].content`]" class="invalid-feedback">
                  {{ errors[`invoice.positions[${index}].content`] }}
                </div>
              </Field>
            </div>

            <div class="col-12 col-lg-4">
              <div class="row align-items-end h-100">
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-floating">
                    <Field :name="`invoice.positions[${index}].count`"
                           :class="{'is-invalid': errors[`invoice.positions[${index}].count`]}"
                           @update:modelValue="element.value.count = Number(element.value.count)"
                           class="form-control hide-number-specific-btns"
                           type="number"
                           placeholder=""/>
                    <label :for="`invoice.positions[${index}].count`">{{ $t('invoices.count') }}</label>
                    <div v-if="errors[`invoice.positions[${index}].count`]" class="invalid-feedback">
                      {{ errors[`invoice.positions[${index}].count`] }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-floating">
                    <Field class="form-select"
                           :class="{'is-invalid': errors[`invoice.positions[${index}].type`]}"
                           :name="`invoice.positions[${index}].type`"
                           as="select"
                           :id="`invoice.positions[${index}].type`">
                      <option v-for="(type, index) in invoiceTypes" :key="index" :value="type">{{
                          $t(`invoices.${type}`)
                        }}
                      </option>
                    </Field>
                    <label :for="`invoice.positions[${index}].type`" class="form-label">{{
                        $t('invoices.type')
                      }}</label>
                    <div v-if="errors[`invoice.positions[${index}].type`]" class="invalid-feedback">
                      {{ errors[`invoice.positions[${index}].type`] }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-floating">
                    <Field :name="`invoice.positions[${index}].price`"
                           :class="{'is-invalid': errors[`invoice.positions[${index}].price`]}"
                           class="form-control hide-number-specific-btns"
                           type="number"
                           placeholder=""/>
                    <label :for="`invoice.positions[${index}].price`">{{ $t('invoices.price') }}</label>
                    <div v-if="errors[`invoice.positions[${index}].price`]" class="invalid-feedback">
                      {{ errors[`invoice.positions[${index}].price`] }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-floating">
                    <Field :name="`invoice.positions[${index}].vat`"
                           :class="{'is-invalid': errors[`invoice.positions[${index}].vat`]}"
                           class="form-control hide-number-specific-btns"
                           type="number"
                           @update:modelValue="element.value.vat = Number(element.value.vat)"
                           placeholder=""/>
                    <label :for="`invoice.positions[${index}].vat`">{{ $t('invoices.vat') }}</label>
                    <div v-if="errors[`invoice.positions[${index}].vat`]" class="invalid-feedback">
                      {{ errors[`invoice.positions[${index}].vat`] }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-floating">
                    <Field :name="`invoice.positions[${index}].item_number`"
                           :class="{'is-invalid': errors[`invoice.positions[${index}].item_number`]}"
                           class="form-control hide-number-specific-btns"
                           type="number"
                           placeholder=""/>
                    <label :for="`invoice.positions[${index}].item_number`">{{ $t('invoices.item_number') }}</label>
                    <div v-if="errors[`invoice.positions[${index}].item_number`]" class="invalid-feedback">
                      {{ errors[`invoice.positions[${index}].item_number`] }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-check">
                    <Field :name="`invoice.positions[${index}].pagebreak`"
                           :id="`invoice.positions[${index}].pagebreak`"
                           class="form-check-input"
                           type="checkbox"
                           :value="true"
                           placeholder=""/>
                    <label class="form-check-label"
                           :for="`invoice.positions[${index}].pagebreak`">{{ $t('invoices.pagebreak') }}</label>
                  </div>
                </div>
                <Field type="hidden" :name="`invoice.positions[${index}].product_billing_period_id`"/>
              </div>
            </div>


          </fieldset>
        </div>
      </template>
    </Sortable>

    <div class="row">
      <hr class="mt-3"/>
      <div class="col-12 col-lg-4">
        <button class="btn btn-info" type="button" @click="push(newPosition())">
          {{ $t('invoices.add_position') }}
        </button>
      </div>
    </div>
  </FieldArray>


</template>

<script>
import TextEditor from "../../../components/TextEditor.vue";
import {INVOICE_TYPES} from "@/scripts/constants";
import {v4 as uuidv4} from "uuid";
import {FieldArray, Field, useFieldArray} from "vee-validate";
import {Sortable} from "sortablejs-vue3";
import {useProductBillingPeriodStore} from "@/stores/product_billing_period";

export default {
  name: 'PositionEditor',
  components: {
    Sortable,
    TextEditor,
    FieldArray,
    Field
  },
  emits: ['removeProductBillingPeriodId'],
  setup() {
    return {
      productBillingPeriodStore: useProductBillingPeriodStore(),
    }
  },
  props: {
    errors: {
      type: Object,
      default() {
        return {}
      }
    },
    // modelValue: {
    //   type: Object,
    //   default() {
    //     return {}
    //   }
    // },
  },
  data() {
    return {
      uuidv4,
      invoiceTypes: INVOICE_TYPES,
    }
  },
  created() {

  },
  computed: {
    // localModelValue: {
    //   get() {
    //     return this.modelValue
    //   },
    //   set(newValue) {
    //     this.$emit('update:modelValue', newValue)
    //   },
    // },
  },
  methods: {
    useFieldArray,
    newPosition() {
      return {content: '', type: '', uuid: uuidv4(), count: 1, price: '', vat: 19, product_billing_period_id: null};
    },
    endSort(e, move) {
      // console.log(`sorted: ${e.oldIndex} => ${e.newIndex}`)
      move(e.oldIndex, e.newIndex)
    },
    clonePosition(fields, index) {
      const lastPosition = fields[index]?.value
      if (lastPosition) {
        const clonedPosition = Object.assign({}, lastPosition)
        clonedPosition.uuid = uuidv4()
        clonedPosition.content = `${clonedPosition.content} COPY`
        return clonedPosition
      }
      return this.newPosition()
    },
    expectedInvoiceLabel(productBillingPeriodId) {
      if (!productBillingPeriodId) {
        return
      }
      const pbp = this.productBillingPeriodStore.copy_of_product_billing_periods.find(pbp => pbp.id === productBillingPeriodId)
      if (pbp) {
        return pbp.invoice_label
      }
    },
    checkInvoiceLabel(fieldValue) {
      const pbpId = fieldValue.product_billing_period_id
      if (!pbpId) {
        return "missing id"
      }
      const prefix = `ProductBillingPeriod#${pbpId}`
      const expectedInvoiceLabel = this.expectedInvoiceLabel(pbpId)
      if (!expectedInvoiceLabel) {
        return `${prefix} not found`
      }
      const invoiceLabel = fieldValue.content
      if (invoiceLabel.includes(expectedInvoiceLabel)) {
        return
      } else {
        return `<div class="opacity-50 me-3">${prefix} - expected invoice label:</div><input class="border-0 flex-grow-1" value="${expectedInvoiceLabel}" readonly>`
      }
    },
  }
}
</script>

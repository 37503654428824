import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";

export const useAccountTransactionTagStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'account_transaction_tag',
    // a function that returns a fresh state
    state: () => ({
        tags: [],
        itemLength: 0,
        serverOptions: {
            page: 0,
            rows: 20,
            sortBy: 'age',
            sortType: 'desc',
        }
    }),
    // optional getters
    getters: {

    },
    // optional actions
    actions: {
        index(query = null, overwriteRowCount = null) {
            let params = {
                page: this.serverOptions.page + 1,
                size: overwriteRowCount || this.serverOptions.rows //dirty workaround to show all tags on analytics page
            }
            if(query) {
                Helpers.addQueryToParams(query, params)
            }
            return this.apiClient.get(`/account_transaction_tags`, {params}).then(({data}) => {
                this.tags = data.account_transaction_tags
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        store(tag) {
            return this.apiClient.post(`/account_transaction_tags`, {account_transaction_tag: tag})
        },
        update(tag) {
            return this.apiClient.put(`/account_transaction_tags/${tag.id}`, {account_transaction_tag: tag})
        },
        delete(id) {
            return this.apiClient.delete(`/account_transaction_tags/${id}`).then(() => {
                this.tags = this.tags.filter(tag => tag.id !== id)
                this.itemLength--
            })
        },
    },
})

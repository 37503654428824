import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
import {ROUTES} from "@/scripts/constants";
import {saveAs} from 'file-saver';

export const useDunningStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'dunning',
    // a function that returns a fresh state
    state: () => ({
        invoices: [],
        invoiceData: {
            client: {
                address: {}
            },
            additionalData: {
                due_date: null,
                period: null
            }
        },
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 20,
            sortBy: 'age',
            sortType: 'desc',
        },
        previewData: null,
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        mapInvoiceData(invoiceData, positions) {
            return {
                positions: positions,
                due_date: invoiceData.additionalData.due_date,
                period: invoiceData.additionalData.period,
                date: invoiceData.additionalData.date,
                data: {
                    client: invoiceData.client,
                    address: invoiceData.address,
                }
            }
        },
        store(positions, invoiceData) {
            return this.apiClient.post(`/invoices`, {invoice: this.mapInvoiceData(invoiceData, positions)}).then(({data}) => {
                this.$router.push({
                    name: ROUTES.INVOICES.EDIT,
                    params: {
                        id: data.id
                    }
                })
            })
        },
        update(positions, invoiceData, id) {
            return this.apiClient.put(`/invoices/${id}`, {invoice: this.mapInvoiceData(invoiceData, positions)}).then(({data}) => {
                console.log(data)
                // }).catch(({error, message}) => {
                //     console.error(error, message)
            });
        },
        show(id) {
            return this.apiClient.get(`/invoices/${id}`)
        },
        preview(invoiceId, type, version) {
            return this.apiClient.get(`/invoices/${invoiceId}/pdf`, {params: {type, version}}).then(({data}) => {
                this.previewData = Helpers.base64ToBlob(data)
            }).catch((e) => {
                this.previewData = new Blob([e.message], {
                    type: 'text/plain'
                });
            })
        },
        pdf(invoice) {
            return this.apiClient.get(`/invoices/${invoice.id}/pdf`).then(({data}) => {
                saveAs(Helpers.base64ToBlob(data), data.filename || `${invoice.number}`);
            })
        },
        proceedInvoice(invoiceId, payload) {
            if (!invoiceId) {
                throw new Error("missing invoiceId!")
            }
            return this.apiClient.put(`/invoices/${invoiceId}/proceed`, payload).then((x) => {
                console.log(x)
            })
        },
        setInvoiceFixed(invoiceId) {
            return this.apiClient.put(`/invoices/${invoiceId}/fixed`)
        },
        setInvoicePaid(invoiceId, payload) {
            return this.apiClient.put(`/invoices/${invoiceId}/paid`, payload)
        },
        getInvoices() {
            this.loading = true
            return this.apiClient.get(`/invoices/dunning`, {
                params: {
                    page: this.serverOptions.page + 1,
                    size: this.serverOptions.rows
                }
            }).then(({data}) => {
                this.invoices = data.invoices
                this.itemLength = data.itemLength
                this.loading = false
            })
        }
    },
})

import {defineStore} from 'pinia'
import {useClientStore} from "@/stores/client";
import {serialize} from 'object-to-formdata';

export const useClientEventStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'client_event',
    // a function that returns a fresh state
    state: () => ({
        client_event: null,
        client_events: [],
        client_event_due_stats: {},
        itemLength: 0,
        loading: false,
        // mode: 'flat', // includes all events, single event has no children in data set
        mode: 'tree', // skips children in items, but accessable via item.children
        serverOptions: {
            page: 1,
            rowsPerPage: 20,
            sortBy: 'age',
            sortType: 'desc',
        }
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        index(client_id, filters = {}, kdnr = null) {
            this.loading = true
            return this.apiClient.get(`/client_events`, {
                params: {
                    client_id,
                    page: this.serverOptions.page,
                    size: this.serverOptions.rowsPerPage,
                    filters: filters,
                    kdnr,
                    mode: this.mode,
                }
            }).then(({data}) => {
                this.client_events = data.client_events
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        get(id) {
            return this.apiClient.get(`/client_events/${id}`)
        },
        getClientEvent(id, headline, callback = null) {
            this.get(id).then(({data}) => {
                this.client_event = data.client_event;
                const clientId = data.client_event?.client_id
                if (clientId) {
                    useClientStore().setCurrentClientById(clientId, headline)
                }
                if (typeof callback === 'function') {
                    callback(data)
                }
            });
        },
        store(data) {
            const formData = serialize({client_event: data}, {indices: true, nullsAsUndefineds: true})
            const conf = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            return this.apiClient.post(`/client_events`, formData, conf)
        },
        clone(id) {
            return this.apiClient.post(`/client_events`, { from_id: id })
        },
        update(id, data) {
            // console.log('data', JSON.stringify(data))
            // data.children_attributes[0][file] = data.children_attributes[0][file][0] -> do this server side!
            const formData = serialize({client_event: data}, {indices: true, nullsAsUndefineds: true})
            // console.log('formData', formData)
            const conf = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            return this.apiClient.put(`/client_events/${id}`, formData, conf)
        },
        run(id) {
            return this.apiClient.post(`/client_events/${id}/run`)
        },
        delete(id) {
            return this.apiClient.delete(`/client_events/${id}`)
        },
        getDueStats() {
                return this.apiClient
                    .get(`/client_events/due_stats`)
                    .then(({data}) => {
                    this.client_event_due_stats = data.client_event_due_stats
                })
        },
    },
})

export default {
  "actions": {
    "export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export CSV"])},
    "clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clone"])},
    "initiate_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiate call from my phone"])}
  },
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "quick_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick create"])},
  "day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["day"]), _normalize(["days"])])},
  "common": {
    "installment_separator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
  },
  "dashboard": {
    "organizations_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't created any organization yet. You need at least one organization to start using tantum crm!"])},
    "fullsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do you want to find something?"])},
    "search_intentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoices, contacts, clients"])}
  },
  "product_types": {
    "type": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Product type"]), _normalize(["Product types"])])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "invoice_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice label"])},
    "invoice_label_context": {
      "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setup"])},
      "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recurring"])}
    },
    "setup_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup fee"])},
    "list_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List price"])},
    "duration_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (months)"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
    "number_of_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])}
  },
  "products": {
    "create": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create product"])}
    },
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit product details"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product updated successfully"])}
    },
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product successfully cloned"])}
    },
    "merge_by_name": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merge (by name)"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products successfully merged"])}
    },
    "copy_product_type_to_product_billing_periods": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy product type to product billing periods"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Really update all product billing periods?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product type successfully copied"])}
    },
    "assign_contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign contracts"])},
    "product": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["product"]), _normalize(["products"])])},
    "package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
    "parent_product_select_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belongs to package: "])},
    "parent_product_select_blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- does not belong to any package -"])},
    "package_components": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package components"])},
    "product_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product status"])},
    "product_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product type"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show product"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent id"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product id"])},
    "setup_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup price"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring price / Duration"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "contract_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract ID"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of release"])},
    "end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of deletion"])},
    "product_termination_reason_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination reason"])},
    "product_renewal_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product renewal setting"])},
    "product_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "product_payment_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment cycle"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "invoice_label_intro_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice item text (prepend)"])},
    "invoice_label_outro_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice item text (append)"])},
    "invoice_label_once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice item text (once)"])},
    "invoice_label_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice item text (recurring)"])},
    "zoho_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZohoCRM Info"])},
    "admin2_product_config_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin2-Product-ID"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add product"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create credit note for paid remaining term"])},
    "setOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activate (Release date today)"])}
  },
  "product_billing_periods": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "product_billing_period": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Billing period"]), _normalize(["Billing periods"])])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "product_billing_period_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing periods"])},
    "product_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product type"])},
    "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
    "scheduled_billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled billing date"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment №"])},
    "number_of_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of installments"])},
    "new_number_of_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split to x installments"])},
    "add_to_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to invoice"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit billing period"])},
    "edit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing period updated successfully"])},
    "start_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts on"])},
    "end_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends on"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related invoices"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "still_need_renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are still some open product billing periods."])},
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing period successfully cloned."])}
    }
  },
  "organizations": {
    "organization": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["organization"]), _normalize(["organizations"])])},
    "create": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create organization"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter details about your organization."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization updated successfully"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone numbers"])},
    "vat_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])},
    "register_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register ID"])},
    "register_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Court"])}
  },
  "addresses": {
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])}
  },
  "accounts": {
    "link_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link new account"])},
    "import_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import transactions"])},
    "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found the following account:"])},
    "connect_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establish connection to bank"])},
    "disconnect_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect bank"])},
    "create_sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create sandbox account"])},
    "request_requisition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request requisition"])},
    "account": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["account"]), _normalize(["accounts"])])},
    "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select last month"])},
    "tag": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tag"]), _normalize(["tags"])])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auth account"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show account"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of institute"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "analytics": {
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarter"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])}
    },
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "search_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for tags"])},
    "search_invoices": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search for matching invoice <span class='amount'>", _interpolate(_named("amount")), "</span>"])},
    "search_receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for matching receipt"])}
  },
  "account_transaction_tags": {
    "include_positive_amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match positive transaction amount"])},
    "include_negative_amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match negative transaction amount"])},
    "manual_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual tag (will be skipped by autotagger)"])},
    "autotagging_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autotagging settings"])}
  },
  "contracts": {
    "contract": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["contract"]), _normalize(["contracts"])])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "duration_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (months)"])},
    "disable_renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable contract renewal"])},
    "important_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important notes"])},
    "contract_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract date"])},
    "contract_status": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Status"]), _normalize(["Statuses"])])},
    "product": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Product"]), _normalize(["Products"])])},
    "product_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overwrite position with product template"])},
    "termination_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received Cancellation on"])},
    "products_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product category"])},
    "termination_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose reason of contract termination"])},
    "create": {
      "base_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter base data for contracts"])},
      "termination_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter data for cancellation"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure contract products"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create client contract"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract documents"])},
      "documents_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add contract documents afterwards using notes!"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract created successfully"])}
    },
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit contract details"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract updated successfully"])}
    },
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show contract"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract status"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "setOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivieren (Onlinestellung heute)"])}
  },
  "dunning": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunning edit"])},
    "edit_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunning - previous invoice"])},
    "edit_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunning - subsequent invoice"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunning"])}
  },
  "invoices": {
    "invoice": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["invoice"]), _normalize(["invoices"])])},
    "invoice_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice headline (default: Invoice)"])},
    "additional_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional text"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create invoice"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "original_invoice_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original invoice ID"])},
    "client_kdnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kdnr"])},
    "client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client name"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "add_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add position"])},
    "remove_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove position"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "net_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net total"])},
    "gross_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross total"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["label"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])},
    "item_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item number"])},
    "pagebreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagebreak after this position"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieces"])},
    "wholesale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save invoice"])},
    "positions_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add invoice positions"])},
    "data_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose invoice data"])},
    "additional_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose additional data"])},
    "current_due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current due date"])},
    "current_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current email address"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due at"])},
    "over_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over due"])},
    "due_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["due date in days"])},
    "sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent at"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "search_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search for client"])},
    "product_billing_periods_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product billing periods"])},
    "edit": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit invoice"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice updated successfully"])}
    },
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice successfully cloned"])}
    },
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Invoice"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF view"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update invoice"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze invoice"])},
    "send_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice via mail"])},
    "send_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice via email"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date"])},
    "resubmission_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resubmission"])},
    "resubmission_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmission by days"])},
    "missing_delivery_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No automatic sending possible because no delivery method configured for client"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "statuses": {
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fixed"])},
      "invoice_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent"])},
      "invoice_reminded1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reminded1"])},
      "invoice_reminded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reminded2"])},
      "outsourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["outsourced"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
      "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canceled"])},
      "cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cleared"])}
    },
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "payment_methods": {
      "sepa_direct_debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct debit"])},
      "bank_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])}
    },
    "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proceed"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice"])},
    "resend_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend invoice"])},
    "reminder1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reminder"])},
    "reminder1_sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder sent at"])},
    "reminder1_due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date of reminder"])},
    "reminder2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last reminder"])},
    "reminder2_sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last reminder sent at"])},
    "reminder2_due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date of last reminder"])},
    "source_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handover to lawyer"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wait"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search invoice"])},
    "set_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["set paid"])},
    "via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via email"])},
    "via_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via mail"])},
    "dunning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dunning"])},
    "dunning_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dunning"])},
    "amount_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amounts not equal!"])}
  },
  "client_events": {
    "confirm_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Better add new note!\nReally change the event from ", _interpolate(_named("date")), "?"])},
    "client_events": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Activity"]), _normalize(["Activities"])])},
    "client_event_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of activity"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "timeline_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline time"])},
    "client_event_threads": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thread"]), _normalize(["Threads"])])},
    "show_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all users"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent ID"])},
    "eventable_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventable type"])},
    "eventable_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventable ID"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show activitiy filters"])},
    "filters_applied": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["filter applied"]), _normalize(["filters applied"])])},
    "filter_labels": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by type"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["text search"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by creator"])},
      "assigned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by assigned user"])},
      "has_resubmission_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has resubmission date"])},
      "resubmission_date_gte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmission after"])},
      "resubmission_date_lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmission before"])}
    },
    "filter_link_labels": {
      "my_resubmissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My open activities"])}
    },
    "no_resubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No resubmission"])},
    "resubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmission"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit activity"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit (Admin)"])}
    },
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity successfully cloned"])}
    },
    "message_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit message"])},
    "append_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Append info"])},
    "create_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New info"])},
    "update_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update info"])},
    "ticket_link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to bug tracker"])},
    "append_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "append_submit_with_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["✓ (Save and clear resubmission)"])},
    "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new event"])},
    "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Append"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info created"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete event"])},
    "appended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info appended"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info updated"])},
    "resubmission_dialog": {
      "action_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmission"])},
      "action_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmission saved"])}
    },
    "mode_tree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representation: group by thread"])},
    "mode_flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representation: chronological"])}
  },
  "direct_debit_mandates": {
    "direct_debit_mandate": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Direct debit mandate"]), _normalize(["Direct debit mandates"])])},
    "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ... "])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create mandate"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate created"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit mandate"])},
    "mandate_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate date"])},
    "account_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of bank"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate reference"])},
    "creditor_uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creditor uuid"])},
    "recurring_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid for recurring payments?"])},
    "recurring_payments_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recurring payments"])},
    "one_time_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid for one time payments?"])},
    "one_time_payments_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one time payments"])},
    "upload_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution! Uploading an additional document will overwrite the previous one. That's fine for correcting a previous mistake when filling the form. If client got a new mandate you should rather create a new one to supersede the current one."])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ended on"])}
  },
  "clients": {
    "client": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["client"]), _normalize(["clients"])])},
    "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Client lead"]), _normalize(["Client leads"])])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client overview"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client history"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client products"])},
    "product_billing_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing periods"])},
    "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client contracts"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client contacts"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client name"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create client contract"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "kdnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client number"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id of parent client"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "invoice_delivery_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice delivery method"])},
    "direct_debit_mandates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct debit mandates"])},
    "invoice_mail_delivery_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra charge for mail"])},
    "billing_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing company"])},
    "billing_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing contact"])},
    "billing_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invoice to email address"])},
    "billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional info for billing"])},
    "revenue_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue per year"])},
    "debtor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debtor"])},
    "payment_performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment performance"])},
    "payment_performance_bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poor"])},
    "payment_performance_good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "vat_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "register_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register ID"])},
    "register_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Court"])},
    "phones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone numbers"])},
    "phone_number": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Phone number"]), _normalize(["Phone numbers"])])},
    "phone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label (public)"])},
    "phone_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note (internal)"])},
    "phone_publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publish"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address label (z.B. jetty)"])},
    "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal address"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])},
    "manet_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manet Url"])},
    "client_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client type"])},
    "client_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client status"])},
    "subdomain": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Subdomain"]), _normalize(["Subdomains"])])},
    "subdomains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdomains"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show client details"])},
    "legal_info_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info & privacy policy"])},
    "invoice_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice address"])},
    "legal_info_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info - type (CMS2 only)"])},
    "legal_info_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info - name"])},
    "legal_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info - legal form"])},
    "legal_info_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info - phone"])},
    "legal_info_fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info - fax"])},
    "legal_info_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info - email"])},
    "legal_info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal info - additional info"])},
    "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Representative"])},
    "representative_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function of the legal representative"])},
    "data_protection_officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data protection officer"])},
    "privacy_policy_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy version"])},
    "social_media_profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media"])},
    "social_media_profiles_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "social_media_profiles_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])},
    "social_media_profiles_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save client"])},
    "parent_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set parent client"])},
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit client details"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client updated successfully"])}
    },
    "create": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create client"])},
      "base_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter client base data"])},
      "additional_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter additional client data"])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add contact(s)"])},
      "add_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add subdomain"])},
      "remove_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove subdomain"])},
      "add_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add phone"])},
      "remove_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove phone"])},
      "add_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add contact"])},
      "remove_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove contact"])},
      "create_new_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new contact"])},
      "add_social_media_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add social media profile"])},
      "remove_social_media_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove social media profile"])},
      "contact_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "dummy_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen Contact"])},
      "add_existing_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search existing contact"])},
      "search_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search contact by last name"])},
      "contact_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active?"])},
      "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send newsletter?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client created successfully"])}
    }
  },
  "messages": {
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["messages"])}
  },
  "users": {
    "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["user"]), _normalize(["users"])])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "sip_uid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipuid e.g. 7771234567", "@", "fpbx.de"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "phone_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone"])},
    "special_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Role"])},
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User permissions"])},
      "override_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Override user permissions with preset"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User updated successfully"])}
    },
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])}
  },
  "transactions": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "audited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audited?"])},
    "booking_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking date"])},
    "counterparty_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counterparty"])},
    "last_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions last updated at"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "remittance_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "upload_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file on area or upload via dialog"])},
    "value_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value date"])}
  },
  "contacts": {
    "contact": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["contact"]), _normalize(["contacts"])])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "phone_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "send_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send newsletter?"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "pronouns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronouns"])},
    "pronoun_options": {
      "he_him": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["he/him"])},
      "she_her": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["she/her"])},
      "they_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["they/them"])}
    },
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "salutations": {
      "he_him": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Mr."])},
      "she_her": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Ms."])},
      "dey_denen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Mx."])},
      "they_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Mx."])}
    },
    "show": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    },
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Contact"])}
    },
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create contact"])},
    "zoho_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZohoCRM Info"])}
  },
  "receipts": {
    "receipt": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["receipt"]), _normalize(["receipts"])])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add receipt"])},
    "creditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creditor"])},
    "paid_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid at"])},
    "resubmission_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmission at"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "is_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned"])},
    "is_not_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not assigned"])},
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
    "assign_and_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign & set invoice paid"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search receipt"])}
  },
  "auth": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "login_manet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Manet"])},
    "success": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration successful! :)"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your mails in order to activate your account."])}
    }
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
    "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords not matching."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email."])}
  },
  "confirm": {
    "confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Thank you for activating your account. You can ", _interpolate(_list(0)), " now."])},
    "confirmed_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
    "denied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Activating your account has faled. Please ", _interpolate(_list(0)), " us if you need assistance."])},
    "denied_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])}
  },
  "conversations": {
    "template": {
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select message template"])},
      "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure message template"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send template"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace placeholders"])}
    },
    "set_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])},
    "search_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search client"])},
    "assign_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign client"])}
  }
}
<template>
  <span>
    <template v-if="number">
      <a :href="`tel:${number}`" @click.prevent="call" v-if="userStore.sipUid">
        <i class="fa-solid fa-fw fa-lg fa-mobile-retro" :title="$t('actions.initiate_call', {provider: userStore.sipProvider})"></i>
      </a>
    </template>
    {{ number }}
    <template v-if="info">
      <small class="fw-normal">{{ info }}</small>
    </template>
  </span>
</template>

<script>
import {useUserStore} from "@/stores/user";
import {Helpers} from "@/helpers";

export default {
  name: 'PhoneNumber',
  props: {
    number: {
      type: String,
    },
    info: {
      type: String,
    }
  },
  created() {
    this.userStore.index()
  },
  setup() {
    return {
      userStore: useUserStore(),
    }
  },
  data() {
    return {
      showForm: false
    }
  },
  methods: {
    call() {
      this.userStore.call(this.number).then(() => {
        Helpers.emitter.emit('success', this.$t('user.call.success'))
      }).catch(({response}) => {
        Helpers.emitter.emit('error', response.data.messages)
      })
    },
  },
}
</script>
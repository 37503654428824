<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('foo') }}</h2>
    </div>
    <div class="col-12">

    </div>
  </div>
</template>

<script>
import {useAccountStore} from "@/stores/account";

export default {
  name: 'AccountsCallback',
  setup() {
    return {
      accountStore: useAccountStore(),
    }
  },
  data() {
    return {
    }
  },
  created() {

  },
}
</script>

<template>
  <div>
      <BootstrapCard class="mt-3 justify" :headline="$t('contacts.show.headline')">
        <template #actions v-if="contact">
          <router-link :to="{ name: ROUTES.CONTACTS.EDIT, query: { id: $route.query.id } }"
                       class="btn btn-sm btn-primary"
                       :title="$t('contacts.edit.headline')"
          >
            <i class="fas fa-fw fa-lg fa-edit"/>
          </router-link>
        </template>
        <template v-if="contact">
          <!--          <show-row :label="$t('contacts.debug')">{{ contact }}</show-row>-->
          <show-row :label="$t('contacts.salutation')">{{ salutation(contact.pronouns) }}</show-row>
          <show-row :label="$t('contacts.title')">{{ contact.title }}</show-row>
          <show-row :label="$t('contacts.first_name')">{{ contact.first_name }}</show-row>
          <show-row :label="$t('contacts.last_name')">{{ contact.last_name }}</show-row>
          <show-row :label="$t('contacts.phone')">
            <phone-number :number="contact.phone" :info="contact.phone_geo_name"/>
          </show-row>
          <show-row :label="$t('contacts.phone_mobile')">
            <phone-number :number="contact.phone_mobile" info=""/>
          </show-row>
          <show-row :label="$t('contacts.email')">{{ contact.email }}</show-row>
          <show-row :label="$t('contacts.info')">{{ contact.info }}</show-row>
          <show-row :label="$t('contacts.send_newsletter')">
            <show-bool :value="contact.send_newsletter"/>
          </show-row>
        </template>
      </BootstrapCard>
      <BootstrapCard class="mt-3" :headline="$tc('clients.client', 0)">
        <ClientsTable v-if="contactLoaded"/>
      </BootstrapCard>
  </div>
</template>

<script>
import {useContactStore} from "@/stores/contact";
import {Helpers} from "@/helpers";
import ClientsTable from "@/components/tables/ClientsTable";
import BootstrapCard from "@/components/BootstrapCard.vue";
import ShowRow from "@/components/ShowRow.vue";
import ShowBool from "@/components/ShowBool.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContactEdit',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  components: {
    PhoneNumber,
    ShowBool,
    ShowRow,
    BootstrapCard,
    ClientsTable,
  },
  setup() {
    return {
      contactStore: useContactStore(),
    }
  },
  data() {
    return {
      contact: null,
      clientIds: [],
      contactLoaded: false,
    }
  },
  created() {
    this.contactStore.show(this.$route.params.id).then(({data}) => {
      this.contact = data.contact
      this.clientIds = data.contact.client_ids
      this.contactLoaded = true
    })
  },

  methods: {
    update(values) {
      this.contactStore.update(this.$route.params.id, values).then(({data}) => {
        Helpers.emitter.emit('success', data.message)
      })
    },
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 mt-3">
      <template v-if="clientStore.client?.id">
        <Form @submit="createProduct" v-slot="{ errors, setFieldValue }" :validation-schema="schema"
              :initial-values="initialData">
          <ProductsFormFields :createMode="true" :showAddButtons="false"
                             :showRemoveButtons="false"
                             :errors="errors" :set-field-value="setFieldValue"/>
          <div class="mt-3">
            <button class="btn btn-success" type="submit" :disabled="submitting">
              {{ $t('save') }}
            </button>
          </div>
        </Form>
      </template>
    </div>
  </div>
</template>

<script>
import {Form} from "vee-validate";
import ProductsFormFields from "./ProductsFormFields.vue"
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import * as Yup from "yup";
import {PRODUCT_FORM_SCHEMA_FUNC} from "@/scripts/schemas";
import {useClientStore} from "@/stores/client";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ProductsCreate',
  setup() {
    return {
      clientStore: useClientStore(),
      productStore: useProductStore(),
      setupStore: useSetupStore(),
    }
  },
  props: {},
  components: {
    ProductsFormFields,
    Form
  },
  data() {
    return {
      submitting: false,
      schema: Yup.object().shape({
        products: PRODUCT_FORM_SCHEMA_FUNC(this.setupStore)
      }),
      clientId: 0,
      initialData: {
        products: [
          {
            product_type_id: null,
            product_status_id: null,
            name: "",
            client_id: null,
            start_at: "",
            end_at: "",
            description: "",
            product_termination_reason_id: null,
            disable_renewal: null,
          },
        ],
      },
    }
  },
  created() {
    const clientId = parseInt(this.$route.query.client_id, 10)
    this.clientStore.setCurrentClientById(clientId, this.$t('products.create.headline'))
    this.initialData.products.forEach((product) => {
      product.client_id = clientId
      if (!product.client_id) {
        throw "missing client_id"
      }
    })
    this.setupStore.getProductTypes()
    this.setupStore.getProductStatuses()
    this.setupStore.getProductTerminationReasons()
    this.setupStore.getProductPaymentCycles()
    this.setupStore.getPaymentMethods()
    this.setupStore.getProductDurations()
  },
  computed: {},
  watch: {},
  methods: {
    createProduct(data) {
      this.submitting = true
      this.productStore.store(data.products[0]).then(({data}) => {
        this.submitting = false
        this.$router.push({
          name: ROUTES.PRODUCTS.SHOW,
          params: {
            product_id: data.id
          }
        })
      })
    }
  }
}
</script>

import {createI18n} from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key).default
        }
    })
    return messages
}

const datetimeFormats = {
    'en': {
        datetime: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        },
        // "short2" to prevent collision with often used name "short"
        short2: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        },
        wday: {
            weekday: 'short'
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        },
    },
    'de': {
        datetime: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        },
        short2: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        },
        wday: {
            weekday: 'short'
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
        },
    }
}


const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
    datetimeFormats
})
export default i18n

<template>
  <div class="dropzone-wrapper">
    <div class="dropzone">
      <div v-bind="getRootProps()"
           class="d-flex align-items-center justify-content-center flex-column p-3 hover-pointer">
        <input v-bind="getInputProps()"/>
        <template v-if="assetData.length">
          <div class="d-flex flex-column mt-3" v-for="(asset, index) in assetData" v-bind:key="index">
            <!-- {{asset}}-->
            <template v-if="showPreview">
              <vue-pdf-embed :page="1" :source="asset.url" v-if="asset.file.type === 'application/pdf'"/>
            </template>
            <template v-else>
              {{ asset.file.name }}
            </template>
            <img class="w-100" :src="asset.url" v-if="asset.file.type === 'image/jpeg'"/>
          </div>
        </template>
        <template v-else>
          <p class="text-center">
            {{text}}
          </p>
          <p class="icon-wrapper">
            <a href="#" class="btn btn-primary btn-sm" @click.prevent> <!-- prevent: don't open link -->
              <i class="fa-solid fa-file-arrow-up"></i>
            </a>
          </p>
          <p class="text-center hint">
            ({{ acceptFiles.join(' | ') }})
          </p>
        </template>
      </div>
    </div>
    <div class="col-12" v-if="showButtons">
      <button class="btn btn-outline-warning" @click="abort">abbrechen</button>
      <button class="btn btn-warning" @click="upload" :disabled="!uploadEnabled">hochladen</button>
    </div>
  </div>
</template>

<script>
import {useDropzone} from "vue3-dropzone";
import {v4 as uuidv4} from "uuid";
import VuePdfEmbed from 'vue-pdf-embed'
import i18n from "@/i18n";
export default {
  name: "DropZone",
  data() {
    return {
      getRootProps: null,
      getInputProps: null,
      files: [],
    }
  },
  components: {
    VuePdfEmbed
  },
  props: {
    showPreview: {
      type: Boolean,
      default: true,
    },
    uploadEnabled: {
      type: Boolean,
      default: () => true
    },
    text: {
      type: String,
      default: () => i18n.global.t('transactions.upload_text')
    },
    showButtons: {
      type: Boolean,
      default: () => true
    },
    acceptFiles: {
      type: Array,
      default: () => ['.csv'],
    },
    multiple: {
      type: Boolean,
      default: () => true
    },
  },
  created() {
    const options = {
      multiple: this.multiple,
      accept: this.acceptFiles,
      onDrop: this.onDrop
    }
    const {getRootProps, getInputProps} = useDropzone(options);
    this.getRootProps = getRootProps
    this.getInputProps = getInputProps
  },
  computed: {
    assetData() {
      const ad = [];
      const files = this.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        ad.push({
          file,
          url: URL.createObjectURL(file),
          caption: this.captionFromFileName(file.path),
          uuid: file.uuid,
        })
      }
      return ad;
    }
  },
  setup() {

  },
  methods: {
    captionFromFileName(filename) {
      if (filename) {
        const fileWithoutExtension = filename.replace(/\.[^/.]+$/g, "")
        return fileWithoutExtension.replaceAll(/[._-]/g, ' ');
      }
    },
    abort() {
      this.$emit('abort')
    },
    upload() {
      this.$emit('upload', this.files)
    },
    onDrop(acceptFiles) {
      this.files = acceptFiles;
      this.files.forEach((file) => {
        file.uuid = uuidv4();
      })
      this.$emit('onDrop', this.files)
    },
    reset() {
      this.files = []
    }
  }
};
</script>

<style scoped lang="scss">
@import "src/scss/app/components/_constants";
.dropzone-wrapper {
  &.is-invalid {
    .dropzone {
      border: 1px solid $tantum_red;
    }
  }
  .dropzone {
    background: $tantum_gray_borders;
    //box-shadow: 0 0 6px #00000029;
    border-radius: 5px;
    border-style: dashed;
    border-width: 1px;
    border-color: black;
    color: black;

    p {
      margin: 0;
      padding: 0;
    }

    .icon-wrapper {
      font-size: 28px;
    }

    .hint {
      font-size: 10px;
    }

    .status {
      background: rgba(255, 255, 255, 0.5);
      width: 100%;
      height: 100%;
      display: none;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5rem;
      }
    }

    .uploaded {
      .status {
        display: block;
      }
    }

    &.is-invalid {
      border: 1px solid $tantum_red;
    }
  }
}

</style>
<template>
  <div>

    <div class="card">
      <div class="card-header">
        <div class="d-flex mt-3">
          <Toggle v-model="showFilters" id="ce-filters-container" class="pe-2"/>
          <label for="ce-filters-container" class="me-2">
            {{ $t('client_events.filter') }}
          </label>
          <template v-if="currentFilterCount">
            <div class="d-inline-block ms-3 text-muted">
              <small>{{ currentFilterCount }} {{ $tc('client_events.filters_applied', currentFilterCount) }}</small>
            </div>
          </template>
        </div>

        <div class="d-flex">
          <template v-if="!clientIsSet">
            <div class="d-flex ">
              <select
                  v-model="clientEventStore.serverOptions.rowsPerPage"
                  @change="filterResults"
                  class="form-control w-auto me-2">
                <option>20</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
              <label class="form-label my-auto">
                {{
                  $tc(clientEventStore.mode === 'tree' ? 'client_events.client_event_threads' : 'client_events.client_events', 2)
                }}
              </label>
            </div>
          </template>
        </div>
      </div>
      <div class="card-body" v-show="showFilters">

        <div class="row">
          <div class="col-12 my-3">
            <label class="d-block">{{ $t('client_events.filter_labels.type') }}</label>
            <ClientEventTypeSelect2 :multiple="true"
                                    v-model="filteredByEventTypes"
                                    @update:modelValue="filterResults"/>
          </div>

          <div class="col-12 col-xl-6">
            {{ $t(`client_events.filter_labels.assigned_users`) }}
            <UserSelect
                        :multiple="true"
                        v-model="filteredByAssignedUsers"
                        @update:modelValue="filterResults"/>
          </div>

          <div class="col-12 col-xl-6">
            {{ $t(`client_events.filter_labels.users`) }}
            <UserSelect
                        :multiple="true"
                        v-model="filteredByUsers"
                        @update:modelValue="filterResults"/>
          </div>

          <div class="col-12 col-lg-3 col-xl-2 mt-3">
            <label for="ce-rd-toggle" class="me-2">
              {{ $t('client_events.filter_labels.has_resubmission_date') }}
            </label>
            <Toggle v-model="filteredByHasResubmissionDate" id="ce-rd-toggle" onLabel="WV" offLabel="alle"
                    @change="filterResults" :classes="{container: 'mt-2'}"/>
          </div>

          <ClientEventTimelineFilterDate class="col-12 col-lg-3 col-xl-2 mt-3" mode="resubmission_date_gte"
                                         @valueChanged="filterResubmissionDateGte"/>
          <ClientEventTimelineFilterDate class="col-12 col-lg-3 col-xl-2 mt-3" mode="resubmission_date_lte"
                                         @valueChanged="filterResubmissionDateLte"/>
          <!--    <Toggle v-model="filteredByHasResubmissionDate"  :onLabel="WV" :offLabel="alle" @change="filterResults" :classes="{container: ''}"/>-->

          <div class="col-12 col-lg-6 mt-3">
            <label for="ce-text-search" class="me-2">
              {{ $t('client_events.filter_labels.text') }}
            </label>
            <input type="search" id="ce-text-search" v-model="filteredByText" @keyup="filterResults" class="form-control w-100">
          </div>
        </div>
      </div>

      <div class="card-footer">
        <Toggle v-model="clientEventStore.mode" @change="filterResults" class="mode-toggle my-auto me-5"
                falseValue="flat" :offLabel="$t('client_events.mode_flat')"
                trueValue="tree" :onLabel="$t('client_events.mode_tree')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {useClientEventStore} from "@/stores/client_event";
import {useSetupStore} from "@/stores/setup";
import Toggle from '@vueform/toggle'
import ClientEventTimelineFilterDate from "@/components/ClientEventTimelineFilterDate.vue";
import {Helpers} from "@/helpers";
import {NANO_EVENT_TYPES} from "@/scripts/constants";
import UserSelect from "@/components/UserSelect.vue";
import ClientEventTypeSelect2 from "@/components/ClientEventTypeSelect2.vue";

export default {
  name: 'ClientEventTimelineFilters',
  components: {
    ClientEventTypeSelect2,
    UserSelect,
    ClientEventTimelineFilterDate,
    Toggle,
  },
  setup() {
    return {
      clientEventStore: useClientEventStore(),
      setupStore: useSetupStore(),
    }
  },
  data() {
    return {
      currentFilterCount: 0,
      filtering: false,
      showFilters: false,
      filteredByEventTypes: [],
      filteredByUsers: [],
      filteredByAssignedUsers: [],
      filteredByHasResubmissionDate: false,
      filteredByText: '',
      filteredByResubmissionDateGte: null,
      filteredByResubmissionDateLte: null,
      emitListenerUnbind: null,
    }
  },
  created() {
    this.initFromParams()
    this.emitListenerUnbind = Helpers.emitter.on(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED, this.filterResults)
    this.filterResults()
  },
  beforeUnmount() {
    this.emitListenerUnbind()
  },
  computed: {},
  methods: {
    initFromParams() {
      const filters = JSON.parse(this.$route.query.filters || 'null') || {}
      if (filters.event_types) {
        this.filteredByEventTypes = filters.event_types
      }
      if (filters.users) {
        this.filteredByUsers = filters.users
      }
      if (filters.assigned_users) {
        this.filteredByAssignedUsers = filters.assigned_users
      }
      if (filters.resubmission_date_present) {
        this.filteredByHasResubmissionDate = filters.resubmission_date_present
      }
    },
    _filterResults() {
      const filters = {}
      if (this.filteredByEventTypes.length) {
        filters.event_types = this.filteredByEventTypes
      }
      if (this.filteredByUsers.length) {
        filters.users = this.filteredByUsers
      }
      if (this.filteredByAssignedUsers.length) {
        filters.assigned_users = this.filteredByAssignedUsers
      }
      if (this.filteredByHasResubmissionDate) {
        filters.resubmission_date_present = 1
      }
      if (this.filteredByResubmissionDateGte) {
        filters.resubmission_date_gte = this.filteredByResubmissionDateGte
      }
      if (this.filteredByResubmissionDateLte) {
        filters.resubmission_date_lte = this.filteredByResubmissionDateLte
      }
      if (this.filteredByText.length) {
        filters.text_contains = this.filteredByText
      }
      this.currentFilterCount = Object.keys(filters).length
      if (this.currentFilterCount) {
        const query = {filters: JSON.stringify(filters)}
        if (this.$route.query.tab) {
          query['tab'] = this.$route.query.tab
        }
        this.$router.push({query})
        this.clientEventStore.index(this.$route.params.id, filters)
      } else {
        this.clientEventStore.index(this.$route.params.id)
      }
      this.filtering = false
    },
    filterResults() {
      if (this.filtering) {
        clearTimeout(this.filtering)
      }
      this.filtering = setTimeout(this._filterResults, 100);
    },
    toggleEventType(id) {
      if (this.filteredByEventTypes.includes(id)) {
        this.filteredByEventTypes = this.filteredByEventTypes.filter(item => item !== id)
      } else {
        this.filteredByEventTypes.push(id)
      }
      this.filterResults()
    },
    filterUser(ids) {
      this.filteredByUsers = ids;
      this.filterResults()
    },
    filterAssignedUser(ids) {
      this.filteredByAssignedUsers = ids;
      this.filterResults()
    },
    filterResubmissionDateGte(date) {
      this.filteredByResubmissionDateGte = date
      this.filterResults()
    },
    filterResubmissionDateLte(date) {
      this.filteredByResubmissionDateLte = date
      this.filterResults()
    },
  },
  watch: {},
  mounted() {
    this.$cable.subscribe({
      channel: "DefaultChannel",
      // room: "public",
    });
  },
  channels: {
    DefaultChannel: {
      connected() {
        // console.log("ws connected")
      },
      rejected() {
        // console.log("ws rejected")
      },
      received(data) {
        if (data.event === 'updated' && data.model === 'ClientEvent') {
          this.filterResults()
        } else {
          console.debug("ws received unknown event", data)
        }
      },
      disconnected() {
        // console.log("ws disconnected")
      },
    },
  },
}
</script>

<style lang="scss">
.mode-toggle {
  --toggle-width: 15rem;
}
</style>
<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $tc('receipts.receipt', 2).capitalize() }}</h2>
    </div>
    <div class="col-12">
      <router-link class="btn btn-primary" :to="{name: ROUTES.RECEIPTS.CREATE}">
        {{ $t('receipts.create') }}
      </router-link>
    </div>
    <div class="col-12 pt-4">
      <DataView :total-records="this.receiptStore.itemLength" :value="receiptStore.receipts" layout="grid" :paginator="true" :rows="9" :lazy="true" @page="onPage($event)">
        <template #header>
          <div class="d-flex">
            <span class="me-2">Filter</span>
            <div class="col-12 col-lg-4">
              <span class="badge interactive me-2"
                    :class="{'bg-dark': !activeFilters.includes(filter), 'bg-success': activeFilters.includes(filter)}"
                    :key="index" v-for="(filter, index) in filters"
                    @click="toggleFilter(filter)">
                    {{ filter.name }}
              </span>
            </div>
            <div class="col-12 col-lg-4">
              <input type="text" @change="textSearch" />
            </div>
          </div>
        </template>
        <template #grid="{data}">
          <DataViewContent :data="data" class="mt-3"/>
        </template>
      </DataView>
    </div>
  </div>
</template>

<script>
import {useReceiptStore} from "@/stores/receipt";
import DataView from "primevue/dataview";
import DataViewContent from "./components/DataViewContent.vue";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ReceiptsIndex',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  setup() {
    return {
      receiptStore: useReceiptStore(),
    }
  },
  components: {
    DataView,
    DataViewContent,
  },
  data() {
    return {
      filters: [
        {key: 'assigned', name: this.$t('receipts.is_assigned')},
        {key: 'not_assigned', name: this.$t('receipts.is_not_assigned')},
      ],
      searchTerm: null,
      activeFilters: [],
      timer: null
    }
  },
  created() {
    this.activeFilters = this.filters
    this.getReceipts(this.activeFilters)
    this.setTitle(this.$tc('receipts.receipt', 2).capitalize())
  },
  methods: {
    getReceipts(activeFilters, search) {
      this.receiptStore.index(activeFilters, search)
    },
    onPage(event) {
      this.receiptStore.serverOptions = event
      this.getReceipts(this.activeFilters, this.searchTerm)
    },
    toggleFilter(filter) {
      if (this.activeFilters.find(element => element.id === filter.id)) {
        this.activeFilters = this.activeFilters.filter(item => item.id !== filter.id)
      } else {
        this.activeFilters.push(filter)
      }
      this.getReceipts(this.activeFilters)
    },
    textSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.searchTerm = e.target.value
        this.getReceipts(this.filters, e.target.value)
      }, 500);
    }
  }
}
</script>

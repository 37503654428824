<template>
  <div>
    <template v-if="availableDirectDebitMandates.length">
      <div class="form-floating">

        <Field name="invoice.direct_debit_mandate_id"
               :class="{'is-invalid': errors[`invoice.direct_debit_mandate_id`]}"
               class="form-select" as="select" id="invoice.direct_debit_mandate_id">
          <option :value="null">-</option>
          <option v-for="ddm in availableDirectDebitMandates" :key="ddm.id" :value="ddm.id">
            #{{ ddm.id }} {{ ddm.reference || formatDate(ddm.mandate_date) }} {{ }}
            {{ ddm.one_time_payments ? '✓' : '×' }}
            {{ $t('direct_debit_mandates.one_time_payments_short') }}
            {{ ddm.one_time_payments ? '✓' : '×' }}
            |
            {{ ddm.recurring_payments ? '✓' : '×' }}
            {{ $t('direct_debit_mandates.recurring_payments_short') }}
            {{ ddm.recurring_payments ? '✓' : '×' }}
          </option>
        </Field>
        <label for="invoice.direct_debit_mandate_id">{{
            $tc('direct_debit_mandates.direct_debit_mandate', 1)
          }}</label>
        <div v-if="errors[`invoice.direct_debit_mandate_id`]" class="invalid-feedback">
          {{ errors[`invoice.direct_debit_mandate_id`] }}
        </div>
      </div>
    </template>
    <template v-else>
      <Field type="hidden" name="invoice.direct_debit_mandate_id" value=""/>
      {{ $t('direct_debit_mandates.missing') }}
    </template>
    <template v-if="client && client.id">
      <router-link class="btn btn-info btn-sm mt-2"
                   :to="{name: ROUTES.CLIENTS.SHOW, params: { id: client.id }, query: { tab: 'direct_debit_mandates' }}"
                   target="_blank">
        {{ $tc('direct_debit_mandates.direct_debit_mandate', 2) }}
      </router-link>
    </template>
  </div>
</template>

<script>

import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import {Field} from "vee-validate";
import {useDirectDebitMandateStore} from "@/stores/direct_debit_mandates";
import dayjs from "dayjs";
import {ROUTES} from "@/scripts/constants";

export default {
  setup() {
    return {
      setupStore: useSetupStore(),
      clientStore: useClientStore(),
      directDebitMandateStore: useDirectDebitMandateStore(),
    }
  },
  components: {
    Field,
  },
  props: {
    ddms: {
        type: Array,
        default() {
            return []
        }
    },
    errors: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      search: null,
    }
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    client() {
      return this.clientStore.client
    },
    availableDirectDebitMandates() {
      const dayAfterTomorrow = dayjs().add(2, 'days')
      const filtered = this.ddms.filter((ddm) => {
        return !ddm.end_date || dayjs(ddm.end_date).isAfter(dayAfterTomorrow)
      })
      return filtered
    }
  },
  created() {
    this.setupStore.getPaymentMethods()
    const clientId = this.client.id
    if (!clientId) {
      throw "missing client_id"
    }
  },
  methods: {},
  watch: {}
}
</script>

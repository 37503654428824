<template>
  <div class="col-12 mt-3">
    <!-- ensure client and contract are properly loaded -->
    <template v-if="client?.id && (isCreatePage || contract.id)">
      <Form @submit="submit" v-slot="{ errors, values, setFieldValue }" :validation-schema="schema"
            :initial-values="{contract: contract, products: contract.products}">
        <div class="card">
          <div class="card-header">
            {{ $t('contracts.create.base_data') }}
          </div>
          <div class="card-body ">
            <div class="row">
              <div class="col-12">
                <h5>{{ $t('clients.client').capitalize() }}:</h5>
                <ul>
                  <li><b>{{ $t('clients.name') }}:</b> {{ client.name }}</li>
                  <li><b>{{ $t('clients.email') }}:</b> {{ client.email }}</li>
                </ul>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field id="contract.title" name="contract.title"
                         class="form-control"
                         :class="{'is-invalid': errors['contract.title']}"/>
                  <label for="contract.title">{{ $t('contracts.title') }}</label>
                  <div v-if="errors['contract.title']" class="invalid-feedback">
                    {{ errors['contract.title'] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <a class="btn btn-sm btn-primary me-1 mt-1" @click.prevent="addToTitle($event, values, setFieldValue)">Website
                  inkl. Vermarktung
                  (Vollkunde)</a>
                <a class="btn btn-sm btn-primary me-1 mt-1" @click.prevent="addToTitle($event, values, setFieldValue)">Vermarktung
                  inkl. Website
                  (Vermarktungskunde)</a>
                <a class="btn btn-sm btn-primary me-1 mt-1" @click.prevent="addToTitle($event, values, setFieldValue)">Vermarktung
                  inkl. Verlinkung
                  (ABL-Kunde)</a>
                <a class="btn btn-sm btn-primary me-1 mt-1" @click.prevent="addToTitle($event, values, setFieldValue)">Relaunch</a>
                <a class="btn btn-sm btn-primary me-1 mt-1" @click.prevent="addToTitle($event, values, setFieldValue)">Domain</a>
                <a class="btn btn-sm btn-primary me-1 mt-1" @click.prevent="addToTitle($event, values, setFieldValue)">Mailkonten</a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-xl-3 mb-3">
                <div class="form-floating">
                  <Field name="contract.contract_status_id" class="form-select" as="select"
                         id="invoice.contract_status_id">
                    <template v-for="contractStatus in contractStatuses" :key="contractStatus.id">
                      <option :value="contractStatus.id">{{ contractStatus.name }}</option>
                    </template>
                  </Field>
                  <label for="contract_status_id">{{ $t('contracts.status') }}</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-xl-3 mb-3">
                <div class="form-floating">
                  <Field type="date" id="contract.contract_date"
                         name="contract.contract_date"
                         class="form-control"
                         :class="{'is-invalid': errors['contract.contract_date']}"/>
                  <label for="contract.contract_date">{{ $t('contracts.contract_date') }}</label>
                  <div v-if="errors['contract.contract_date']" class="invalid-feedback">
                    {{ errors['contract.contract_date'] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="form-floating">
                  <Field as="textarea" id="contract.info"
                         name="contract.info"
                         class="form-control"
                         :class="{'is-invalid': errors['contract.info']}"/>
                  <label for="contract.info">{{ $t('contracts.info') }}</label>
                  <div v-if="errors['contract.info']" class="invalid-feedback">
                    {{ errors['contract.info'] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">
            {{ $t('contracts.create.products') }}
          </div>
          <div class="card-body">
            <ProductsFormFields :set-field-value="setFieldValue" :create-mode="isCreatePage"
                               :errors="errors" :products="contract.products"
                               :contractMode="true"
            />
          </div>
        </div>

        <template v-if="isCreatePage">
          <div class="card mt-3">
            <div class="card-header">
              {{ $t('contracts.create.documents') }}
            </div>
            <div class="card-body row">
              <div class="col-12">
                {{ $t('contracts.create.documents_hint') }}
              </div>
            </div>
          </div>
        </template>
<!--        <template v-else>-->
<!--          <div class="card mt-3">-->
<!--            <div class="card-header">-->
<!--              {{ $t('contracts.create.termination_data') }}-->
<!--            </div>-->
<!--            <div class="card-body row">-->
<!--              <div class="col-12 col-md-6 col-lg-3">-->
<!--                <div class="form-floating">-->
<!--                  <Field type="date" id="contract.termination_received"-->
<!--                         name="contract.termination_received"-->
<!--                         class="form-control"-->
<!--                         :class="{'is-invalid': errors['contract.termination_received']}"/>-->
<!--                  <label for="contract.termination_received">{{ $t('contracts.termination_received') }}</label>-->
<!--                  <div v-if="errors['contract.termination_received']" class="invalid-feedback">-->
<!--                    {{ errors['contract.termination_received'] }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-12 col-lg-6">-->
<!--                <Multiselect-->
<!--                    :options=terminationReasons-->
<!--                    label="name"-->
<!--                    valueProp="id"-->
<!--                    :searchable="true"-->
<!--                    :placeholder="$t('contracts.termination_reason')"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </template>-->

        <div class="col-12 mt-3">
          <button type="submit" class="btn btn-primary">{{ $t('save') }}</button>
        </div>
      </Form>
    </template>

  </div>
</template>

<script>
import {Field, Form} from "vee-validate";
import * as Yup from "yup";
import {useSetupStore} from "@/stores/setup";
import {useClientStore} from "@/stores/client";
// import TextEditor from "@/components/TextEditor";
import ProductsFormFields from "@/pages/products/ProductsFormFields.vue"
import {PRODUCT_FORM_SCHEMA_FUNC} from "@/scripts/schemas";
import i18n from "@/i18n";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContractsForm',
  setup() {
    return {
      setupStore: useSetupStore(),
      clientStore: useClientStore(),
    }
  },
  components: {
    Field,
    Form,
    ProductsFormFields,
    // TextEditor
  },
  props: {
    contract: {
      type: Object,
    }
  },
  data() {
    return {
      schema: Yup.object().shape({
        contract: Yup.object().shape({
          title: Yup.string().required(this.$t('validation.required')),
          contract_status_id: Yup.number().required(this.$t('validation.required')).nullable(),
          contract_date: Yup.date().when('contract_status_id', (contract_status_id, schema) => {
                if (this.setupStore.activeContractStatusIds.includes(contract_status_id)) {
                  return schema.typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required'))
                }
                return schema.nullable()
              }
          ),
        }),
        products: PRODUCT_FORM_SCHEMA_FUNC(this.setupStore)
      })
    }
  },
  created() {
    this.setupStore.getProductTerminationReasons()
    this.setupStore.getProductDurations()
    this.setupStore.getPaymentMethods()
    this.setupStore.getContractStatuses()
    this.setupStore.getProductStatuses()
    this.setupStore.getProductPaymentCycles()
    this.setupStore.getProductTypes()
  },
  computed: {
    isCreatePage() {
      return this.$route.name === ROUTES.CONTRACTS.CREATE
    },
    terminationReasons() {
      return this.setupStore.terminationReasons
    },
    contractStatuses() {
      return this.setupStore.contractStatuses
    },
    client() {
      return this.clientStore.client
    },
  },
  methods: {
    submit(values) {
      // console.log(values)
      this.$emit('submitted', values);
    },
    addToTitle(e, values, setFieldValue) {
      const value = e.target.innerHTML
      // const value = e
      const t = values.contract.title || ''
      const sep = t.length ? ', ' : ''
      if (!t.includes(value)) {
        const newValue = `${t}${sep}${value}`
        setFieldValue('contract.title', newValue)
      }
    },
  },
}
</script>
<style lang="scss">
</style>
<template>
  <div>
    <template v-if="item.client_event_id">
      <router-link class="operation-item color-inherit"
                   :to="{name: ROUTES.CLIENT_EVENTS.SHOW, params: {id: item.client_event_id}}">
        <i class="fas fa-fw" :class="setupStore.getClientEventTypeById(item.client_event_type_id)?.icon"/>
      </router-link>
    </template>
    <template v-else>
      <i class="fas fa-fw" />
    </template>
    {{ status?.name }}

    <template v-if="renewalActions && needsRenewal">
      <button class="btn btn-primary btn-sm" @click.prevent="setBilling(item.id, billingStatus?.id)">
        {{ billingStatus?.name }}
      </button>
    </template>
  </div>
</template>

<script>
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import {useProductBillingPeriodStore} from "@/stores/product_billing_period";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ProductBillingPeriodsStatus',
  setup() {
    return {
      productStore: useProductStore(),
      productBillingPeriodStore: useProductBillingPeriodStore(),
      setupStore: useSetupStore(),
    }
  },
  components: {},
  props: {
    item: {
      type: Object,
    },
    renewalActions: {
      type: Boolean,
      default: false,
    },
    reloadProductBillingPeriods: {
      type: Function,
    },
  },
  data() {
    return {
    }
  },
  created() {
    this.setupStore.getClientEventTypes()
    this.setupStore.getProductBillingPeriodStatuses()
    this.setupStore.getProductStatuses()
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    billingStatus() {
      return this.setupStore.getProductBillingPeriodStatusByKey('billing')
    },
    status() {
      return this.setupStore.getProductBillingPeriodStatusById(this.item.product_billing_period_status_id)
    },
    needsRenewal(){
      return this.item.needs_renewal
    },
  },
  methods: {
    setBilling(id, new_status_id) {
      const product_billing_period = {
        product_billing_period_status_id: new_status_id,
      }
      this.productBillingPeriodStore.update(id, {product_billing_period}).then(() => {
        // this.newStatusId = data.product_billing_period_status_id
        this.reloadProductBillingPeriods()
      })
    },
  }
}
</script>

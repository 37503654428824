<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('contracts.edit.headline') }}</h2>
    </div>
    <ContractsForm @submitted="update" :contract="contract"/>
  </div>
</template>

<script>
import {useContractStore} from "@/stores/contract";
import ContractsForm from "./Form";
import {Helpers} from "@/helpers";
import {useClientStore} from "@/stores/client";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContractsEdit',
  setup() {
    return {
      contractStore: useContractStore(),
      clientStore: useClientStore(),
    }
  },
  components: {
    ContractsForm
  },
  data() {
    return {
      contract: {},
    }
  },
  created() {
    this.contractStore.get(this.$route.params.id).then(({data}) => {
      this.contract = data.contract
      this.client = data.client
      this.setCurrentClient(this.client)
      this.setTitle(this.$t('contracts.edit.headline'))
    })
  },
  methods: {
    update(values) {
      values.contract.client_id = this.clientStore.client.id
      if (values.products) {
        values.contract.products_attributes = values.products
        delete values.products
        delete values.contract.products
      }
      this.contractStore.update(this.$route.params.id, values).then((data) => {
        Helpers.emitter.emit('success', this.$t('contracts.edit.success'))
        this.$router.push({name: ROUTES.CONTRACTS.SHOW, params: {id: data.id}})
      })
    }
  }
}
</script>

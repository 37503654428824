<template>
  <Form @submit="submit" v-slot="{ errors }" ref="form" :validation-schema="schema" class="row"
        :initial-values="{ contacts }">
    <div class="col-12 col-lg-6 mt-3">
      <div class="card">
        <div class="card-header">
          {{ $t('clients.create.base_data') }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="form-floating mb-3">
                <Field v-model="client.kdnr" id="client.kdnr" name="client.kdnr"
                       class="form-control"
                       :class="{'is-invalid': errors['client.kdnr']}"/>
                <label for="client.kdnr">{{ $t('clients.kdnr') }}*</label>
                <div v-if="errors['client.kdnr']" class="invalid-feedback">
                  {{ errors['client.kdnr'] }}
                </div>
              </div>
            </div>
            <Field v-if="client.parent" v-model="client.parent.id" id="client.parent_id" name="client.parent_id"
                   type="hidden"
                   class="form-control"
                   :class="{'is-invalid': errors['client.parent_id']}"/>
            <div class="col-12 col-lg-6">
              <div class="form-floating mb-3 position-relative">
                <input @input="searchClient" v-model="parentClient" class="form-control" id="paren_client.search"
                       autocomplete="off"/>
                <label for="paren_client.search">{{ $t('clients.parent_search') }}</label>
                <div class="search-results" v-if="clientSearchResults.length > 0">
                  <ul class="search-dropdown">
                    <li @click="selectParent(client)" v-for="client in clientSearchResults" :key="client.id">
                      {{ client.kdnr }} - {{ client.name }} - ({{ client.id }})
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-floating">
                <Field v-model="client.user_id" class="form-select" name="client.user_id" as="select"
                       id="client.user_id">
                  <option :value="user.id" v-for="user in activeUsers" :key="user.id">
                    {{ user.name }}
                  </option>
                </Field>
                <label for="client.user_id">{{ $t('clients.user_id') }}</label>
              </div>
            </div>
            <div class="col-12 col-lg-6" v-if="!hasKdnr">
              <a class="btn btn-primary btn-sm me-3" @click.prevent="setLead">Interessent !</a>
              <a class="btn btn-primary btn-sm" @click.prevent="useKdnr">Kundennummer {{
                  clientStore.nextAvailableKdnr
                }}</a>
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.name" id="client.name" name="client.name"
                   class="form-control"
                   :class="{'is-invalid': errors['client.name']}"/>
            <label for="client.name">{{ $t('clients.name') }}*</label>
            <div v-if="errors['client.name']" class="invalid-feedback">
              {{ errors['client.name'] }}
            </div>
          </div>

          <div class="col-12 mb-3">
            <div class="form-floating">
              <Field v-model="client.client_type_id" name="client.client_type_id" id="client.client_type_id">
                <Multiselect
                    v-model="client.client_type_id"
                    :options=setupStore.clientTypes
                    label="name"
                    valueProp="id"
                    class="form-control"
                    :class="{'is-invalid': errors['client.client_type_id']}"
                    :searchable="true"
                    :placeholder="$t('clients.client_type')+'*'"
                />
              </Field>
              <label for="client.client_type_id">{{ $t('clients.client_type') }}*</label>
            </div>
          </div>

          <div class="col-12 mb-3">
            <div class="form-floating">
              <Field v-model="client.client_status_id" name="client.client_status_id" id="client.client_status_id">
                <Multiselect
                    v-model="client.client_status_id"
                    :options=setupStore.clientStatuses
                    label="name"
                    valueProp="id"
                    class="form-control"
                    :class="{'is-invalid': errors['client.client_status_id']}"
                    :searchable="true"
                    :placeholder="$t('clients.client_status')+'*'"
                />
              </Field>
              <label for="client.client_status_id">{{ $t('clients.client_status') }}*</label>
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="address.street" id="address.street" name="address.street"
                   class="form-control"
                   :class="{'is-invalid': errors['address.street']}"/>
            <label for="address.street">{{ $t('addresses.street') }}</label>
            <div v-if="errors['address.street']" class="invalid-feedback">
              {{ errors['address.street'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="address.postcode" id="address.postcode" name="address.postcode"
                   class="form-control"
                   :class="{'is-invalid': errors['address.postcode']}"/>
            <label for="address.postcode">{{ $t('addresses.postcode') }}*</label>
            <div v-if="errors['address.postcode']" class="invalid-feedback">
              {{ errors['address.postcode'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="address.city" id="address.city" name="address.city"
                   class="form-control"
                   :class="{'is-invalid': errors['address.city']}"/>
            <label for="address.city">{{ $t('addresses.city') }}*</label>
            <div v-if="errors['address.city']" class="invalid-feedback">
              {{ errors['address.city'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field class="form-select" name="address.country_code" as="select" id="address.country_code"
                   v-model="address.country_code" :class="{'is-invalid': errors['address.country_code']}">
              <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
            </Field>
            <label for="address.country_code">{{ $t('addresses.country') }}*</label>
            <div v-if="errors['address.country_code']" class="invalid-feedback">
              {{ errors['address.country_code'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.address_label" id="client.address_label" name="client.address_label"
                   class="form-control"
                   :class="{'is-invalid': errors['client.address_label']}"/>
            <label for="client.address_label">{{ $t('clients.address_label') }}</label>
            <div v-if="errors['client.address_label']" class="invalid-feedback">
              {{ errors['client.address_label'] }}
            </div>
          </div>

          <div class="col-12 col-lg-9 mb-3">
            <div class="form-floating">
              <Field v-model="client.manet_url" id="client.manet_url" name="client.manet_url"
                     type="url"
                     class="form-control"
                     :class="{'is-invalid': errors['client.manet_url']}"/>
              <label for="client.manet_url">{{ $t('clients.manet_url') }} - https://...</label>
              <div v-if="errors['client.manet_url']" class="invalid-feedback">
                {{ errors['client.manet_url'] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-9 mb-3">
            <div class="form-floating">
              <Field v-model="client.url" id="client.url" name="client.url"
                     type="url"
                     class="form-control"
                     :class="{'is-invalid': errors['client.url']}"/>
              <label for="client.url">{{ $t('clients.url') }} - https://example.com</label>
              <div v-if="errors['client.url']" class="invalid-feedback">
                {{ errors['client.url'] }}
              </div>
            </div>
          </div>

          <div class="col-12">
            <h5>{{ $tc('clients.subdomain', 2) }}</h5>
            <div class="row my-2" :key="index" v-for="(subdomain, index) in client.subdomains">
              <div class="col-10">
                <div class="form-floating">
                  <Field v-model="client.subdomains[index]"
                         :id="'client.subdomains'+index"
                         :name="'client.subdomains'+[index]"
                         class="form-control"
                  />
                  <label :for="'client.subdomains'+index">{{ $t('clients.subdomain') }}</label>
                </div>
              </div>
              <div class="col-2">
                <div class="btn-secondary btn btn-sm" @click="removeSubdomain(subdomain)"
                     :title="$t('clients.create.remove_subdomain')">
                  <i class="fa-solid fa-trash"></i>
                </div>
              </div>
            </div>
            <div class="btn-primary btn btn-sm" @click="addSubdomain">
              <i class="fa-solid fa-plus"></i> {{ $t('clients.create.add_subdomain') }}
            </div>
          </div>

          <!--          <div class="mb-3">
                      <button @click.prevent="useAddressForInvoice" class="btn btn-sm btn-secondary">Rechnungsanschrift! (i18n)</button>
                      <button @click.prevent="useAddressForLegalInfo" class="btn btn-sm btn-secondary">Impressumsanschrift! (i18n)</button>
                    </div>-->

          <h5 class="mt-3">{{ $tc('clients.invoice_address', 2) }}</h5>

          <div class="form-floating mb-3">
            <Field v-model="client.billing_company" id="client.billing_company"
                   name="client.billing_company"
                   class="form-control"
                   :class="{'is-invalid': errors['client.billing_company']}"/>
            <label for="client.billing_company">{{
                $t('clients.billing_company')
              }}</label>
            <div v-if="errors['client.billing_company']" class="invalid-feedback">
              {{ errors['client.billing_company'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.billing_contact" id="client.billing_contact"
                   name="client.billing_contact"
                   class="form-control"
                   :class="{'is-invalid': errors['client.billing_contact']}"/>
            <label for="client.billing_contact">{{
                $t('clients.billing_contact')
              }}</label>
            <div v-if="errors['client.billing_contact']" class="invalid-feedback">
              {{ errors['client.billing_contact'] }}
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="row">
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field v-model="invoiceAddress.street" id="invoiceAddress.street" name="invoiceAddress.street"
                         class="form-control"
                         :class="{'is-invalid': errors['invoiceAddress.street']}"/>
                  <label for="invoiceAddress.street">{{ $t('addresses.street') }}</label>
                  <div v-if="errors['invoiceAddress.street']" class="invalid-feedback">
                    {{ errors['invoiceAddress.street'] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field v-model="invoiceAddress.postcode" id="invoiceAddress.postcode" name="invoiceAddress.postcode"
                         class="form-control"
                         :class="{'is-invalid': errors['invoiceAddress.postcode']}"/>
                  <label for="invoiceAddress.postcode">{{ $t('addresses.postcode') }}</label>
                  <div v-if="errors['invoiceAddress.postcode']" class="invalid-feedback">
                    {{ errors['invoiceAddress.postcode'] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field v-model="invoiceAddress.city" id="invoiceAddress.city" name="invoiceAddress.city"
                         class="form-control"
                         :class="{'is-invalid': errors['invoiceAddress.city']}"/>
                  <label for="invoiceAddress.city">{{ $t('addresses.city') }}</label>
                  <div v-if="errors['invoiceAddress.city']" class="invalid-feedback">
                    {{ errors['invoiceAddress.city'] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field class="form-select" name="invoiceAddress.country_code" as="select"
                         id="invoiceAddress.country_code"
                         v-model="invoiceAddress.country_code"
                         :class="{'is-invalid': errors['invoiceAddress.country_code']}">
                    <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
                  </Field>
                  <label for="invoiceAddress.country_code">{{ $t('addresses.country') }}</label>
                  <div v-if="errors['invoiceAddress.country_code']" class="invalid-feedback">
                    {{ errors['invoiceAddress.country_code'] }}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="form-floating mb-3">
            <Field v-model="client.billing_info" id="client.billing_info"
                   name="client.billing_info"
                   class="form-control"
                   :class="{'is-invalid': errors['client.billing_info']}"/>
            <label for="client.billing_info">{{
                $t('clients.billing_info')
              }}</label>
            <div v-if="errors['client.billing_info']" class="invalid-feedback">
              {{ errors['client.billing_info'] }}
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-floating">
                <Field v-model="client.invoice_delivery_method_id" name="client.invoice_delivery_method_id">
                  <Multiselect
                      v-model="client.invoice_delivery_method_id"
                      :options="this.setupStore.invoiceDeliveryMethods"
                      class="form-control"
                      label="name"
                      valueProp="id"
                      :class="{'is-invalid': errors['client.invoice_delivery_method_id']}"
                  />
                </Field>
                <label :for="`client-invoice_delivery_method`">{{ $t('clients.invoice_delivery_method') }}</label>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="form-floating mb-3">
                <Field v-model="client.invoice_mail_delivery_price" id="client.invoice_mail_delivery_price"
                       type="number"
                       name="client.invoice_mail_delivery_price"
                       class="form-control hide-number-specific-btns"
                       :class="{'is-invalid': errors['client.invoice_mail_delivery_price']}"/>
                <label for="client.invoice_mail_delivery_price">{{
                    $t('clients.invoice_mail_delivery_price')
                  }}</label>
                <div v-if="errors['client.invoice_mail_delivery_price']" class="invalid-feedback">
                  {{ errors['client.invoice_mail_delivery_price'] }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.billing_email" id="client.billing_email"
                   type="email"
                   name="client.billing_email"
                   class="form-control"
                   :class="{'is-invalid': errors['client.billing_email']}"/>
            <label for="client.billing_email">{{
                $t('clients.billing_email')
              }}</label>
            <div v-if="errors['client.billing_email']" class="invalid-feedback">
              {{ errors['client.billing_email'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.debtor" id="client.debtor"
                   type="number"
                   name="client.debtor"
                   class="form-control"
                   :class="{'is-invalid': errors['client.debtor']}"/>
            <label for="client.debtor">{{
                $t('clients.debtor')
              }}</label>
            <div v-if="errors['client.debtor']" class="invalid-feedback">
              {{ errors['client.debtor'] }}
            </div>
          </div>

          <div class="col-12 mb-3">
            <div class="mb-3">
              <label for="client-payment_performance">{{ $t('clients.payment_performance') }}</label>
              <PaymentPerformance v-model="client.payment_performance"/>
              <div v-if="errors['client.payment_performance']" class="invalid-feedback">
                {{ errors['client.payment_performance'] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-3 mt-3">
        <button type="submit" class="btn btn-primary">{{ $t("clients.save") }}</button>
      </div>
    </div>

    <div class="col-12 col-lg-6 mt-3">

      <!-- contacts -->
      <div class="card mb-3">
        <div class="card-header">
          {{ $t('clients.create.contacts') }}
          <router-link class="btn btn-primary btn-sm" target="_blank"
                       :to="{name: ROUTES.CLIENTS.SHOW, params: {id: this.clientStore.client?.id || 0}, query: { tab: CLIENT_TAB_TYPES.CONTACTS}}">
            <i class="fa-solid fa-arrow-up-right-from-square"></i> {{ $tc('contacts.contact', 2) }}
          </router-link>
        </div>
        <div class="card-body row">
          <FieldArray name="contacts" ref="fieldArray" v-slot="{ fields, remove }">
            <div class="col-12">
              <div class="row my-2 d-flex align-items-center py-2" :key="index" v-for="(contact, index) in fields"
                   :class="{'odd': index % 2 === 0, 'even': index % 2 !== 0 }">
                <div class="col-12">
                  <Field :id="`contacts_${index}_data_id`"
                         :name="`contacts[${index}].data.id`"
                         type="hidden"
                  />
                  <Field :id="`contacts_${index}_new`"
                         :name="`contacts[${index}].new`"
                         type="hidden"
                  />
                </div>
                <div class="col-10">
                  <div class="row">
                    <div class="col-12" v-if="contact.value.new">
                      <div class="row">

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.title'+index"
                                   :name="`contacts[${index}].data.title`"
                                   class="form-control"
                            />
                            <label :for="'contacts.title'+index">{{ $t('contacts.title') }}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.pronouns'+index"
                                   as="select"
                                   :name="`contacts[${index}].data.pronouns`"
                                   class="form-select"
                                   v-slot="{ value }"
                            >
                              <option value="">-</option>
                              <template v-for="(option, optionIndex) in pronounOptions" :key="optionIndex">
                                <option :value="option" :selected="value && value === option">
                                  {{ $t(`contacts.pronoun_options.${option}`) }}
                                </option>
                              </template>
                            </Field>
                            <label :for="'contacts.last_name'+index">{{ $t('contacts.pronouns') }}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.first_name'+index"
                                   :name="`contacts[${index}].data.first_name`"
                                   class="form-control"
                            />
                            <label :for="'contacts.first_name'+index">{{ $t('contacts.first_name') }}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.last_name'+index"
                                   :name="`contacts[${index}].data.last_name`"
                                   class="form-control"
                            />
                            <label :for="'contacts.last_name'+index">{{ $t('contacts.last_name') }}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.phone'+index"
                                   :name="`contacts[${index}].data.phone`"
                                   class="form-control"
                            />
                            <label :for="'contacts.phone'+index">{{ $t('contacts.phone') }}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.phone_mobile'+index"
                                   :name="`contacts[${index}].data.phone_mobile`"
                                   class="form-control"
                            />
                            <label :for="'contacts.phone_mobile'+index">{{ $t('contacts.phone_mobile') }}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.email'+index"
                                   :name="`contacts[${index}].data.email`"
                                   class="form-control"
                            />
                            <label :for="'contacts.email'+index">{{ $t('contacts.email') }}</label>
                          </div>
                        </div>

                        <div class="col-12 mb-2">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.info'+index"
                                   :name="`contacts[${index}].data.info`"
                                   class="form-control"
                            />
                            <label :for="'contacts.info'+index">{{ $t('contacts.info') }}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-2">
                          <div class="form-floating">
                            <Field :id="'contacts.position'+index"
                                   :name="`contacts[${index}].position`"
                                   class="form-control"
                            />
                            <label :for="'contacts.position'+index">{{
                                $t('clients.create.contact_position')
                              }}</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-6 ">
                          <Field :name="`contacts[${index}].active`" v-slot="{ field, handleChange }">
                            {{ $t('clients.create.contact_active') }}
                            <Toggle v-model="field.value" @change="handleChange"/>
                          </Field>
                        </div>

                        <div class="col-12 col-lg-6 mt-2">
                          <Field
                              :id="'contacts.send_newsletter'+index"
                              class="form-check-input"
                              :name="`contacts[${index}].send_newsletter`"
                              type="checkbox"
                              value="1"
                          />
                          <label class="form-check-label ms-2" :for="'contacts.send_newsletter'+index">
                            {{ $t('contacts.send_newsletter') }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12" v-if="contact.value.new === false || contact.value.data?.id">
                      <div class="row">
                        <div class="col-12 col-lg-4" v-if="contact.value.new === false">
                          <div class="form-floating search-wrapper">
                            <input id="search_client" class="form-control w-100" @input="searchContact($event, index)"
                                   placeholder="">
                            <label for="search_client">
                              {{ $t('clients.create.search_contact') }}
                            </label>
                            <div class="dropdown" v-if="showDropdown === index">
                              <ul>
                                <template :key="contactSearchIndex"
                                          v-for="(contact, contactSearchIndex) in contactsSearchData">
                                  <li @click="setContact(index, contactSearchIndex)">
                                    {{ contact.first_name }} {{ contact.last_name }} (ID: {{ contact.id }})
                                  </li>
                                </template>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-4 d-flex flex-column justify-content-center"
                             v-if="contact.value.data?.id">
                          <span>{{ $t('clients.create.dummy_name') }}:</span>
                          <div>
                            <b>{{ contact.value.data.first_name }} {{ contact.value.data.last_name }}</b> (ID: {{
                              contact.value.data.id
                            }})
                            <router-link class="operation-item"
                                         :to="{name: ROUTES.CONTACTS.EDIT, params: {id: contact.value.data.id}}"
                                         :title="$t('contacts.edit.headline')" target="_blank">
                              <i class="fas fa-edit"/>
                            </router-link>
                          </div>
                        </div>
                        <div class="col-12 col-lg-4 d-flex align-items-center">
                          <div class="form-floating w-100">
                            <Field :id="'contacts.position'+index"
                                   :name="`contacts[${index}].position`"
                                   class="form-control"/>
                            <label :for="'contacts.position'+index">{{
                                $t('clients.create.contact_position')
                              }}</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-4 d-flex align-items-center">
                          <div class="form-floating w-100">
                            <Field v-slot="{field, handleChange }" :name="`contacts[${index}].active`">
                              {{ $t('clients.create.contact_active') }}
                              <Toggle v-model="field.value" @change="handleChange"/>
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="btn-secondary btn btn-sm" @click="remove(index)"
                       :title="$t('clients.create.remove_contact')">
                    <i class="fa-solid fa-trash"></i>
                  </div>
                </div>
              </div>

              <div class="btn-primary btn btn-sm me-3" @click="addContact()">
                <i class="fas fa-search fa-fw"></i>
                {{ $t('clients.create.add_existing_contact') }}
              </div>
              <div class="btn-primary btn btn-sm me-3" @click="addContact(true)">
                <i class="fas fa-plus fa-fw"></i>
                {{ $t('clients.create.create_new_contact') }}
              </div>
            </div>
          </FieldArray>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-header">
          {{ $t('clients.create.additional_data') }}
        </div>


        <div class="card-body row">
          <div class="col-12 mb-3">
            <div class="form-floating mb-3">
              <Field v-model="client.email" id="client.email" name="client.email"
                     class="form-control"
                     :class="{'is-invalid': errors['client.email']}"/>
              <label for="client.email">{{ $t('clients.email') }}</label>
              <div v-if="errors['client.email']" class="invalid-feedback">
                {{ errors['client.email'] }}
              </div>
            </div>
          </div>


          <div class="col-12 mb-3">
            <h5>{{ $tc('clients.phone_number', 2) }}</h5>
            <div class="row mb-4" :key="index" v-for="(phone, index) in client.phones">
              <div class="col-12 col-lg-5 mb-1 ">
                <div class="form-floating">
                  <Field v-model="phone.number"
                         :id="`client-phones-${index}-number`"
                         :name="`client-phones-${index}-number`"
                         class="form-control"
                  />
                  <label :for="`client-phones-${index}-number`">{{ $t('clients.phone_number') }}</label>
                </div>
              </div>
              <div class="col-12 col-lg-4 mb-1">
                <div class="form-floating mb-1">
                  <Field v-model="phone.label"
                         :id="`client-phones-${index}-label`"
                         :name="`client-phones-${index}-label`"
                         class="form-control"
                  />
                  <label :for="`client-phones-${index}-label`">{{ $t('clients.phone_label') }}</label>
                </div>
              </div>
              <div class="col-12 col-lg-3">
                <Field v-model="phone.publish"
                       type="checkbox"
                       :value="true"
                       :id="`client-phones-${index}-publish`"
                       :name="`client-phones-${index}-publish`"
                />
                <label :for="`client-phones-${index}-publish`">{{
                    $t('clients.phone_publish')
                  }}</label>
              </div>
              <div class="col-9">
                <div class="form-floating">
                  <Field v-model="phone.note"
                         :id="`client-phones-${index}-note`"
                         :name="`client-phones-${index}-note`"
                         class="form-control"
                  />
                  <label :for="`client-phones-${index}-note`">{{ $t('clients.phone_note') }}</label>
                </div>
              </div>
              <div class="col-3 text-end">
                <div class="btn-secondary btn btn-sm" @click="removePhone(index)"
                     :title="$t('clients.create.remove_phone')">
                  <i class="fa-solid fa-trash"></i>
                </div>
              </div>
            </div>
            <div class="btn-primary btn btn-sm" @click="addPhone">
              <i class="fa-solid fa-plus"></i> {{ $t('clients.create.add_phone') }}
            </div>
          </div>

          <div class="col-12 col-lg-9 mb-3">
            <div class="form-floating">
              <Field v-model="client.fax" id="client.fax" name="client.fax"
                     class="form-control"
                     :class="{'is-invalid': errors['client.fax']}"/>
              <label for="client.fax">{{ $t('clients.fax') }}</label>
              <div v-if="errors['client.fax']" class="invalid-feedback">
                {{ errors['client.fax'] }}
              </div>
            </div>
          </div>

        </div>
      </div>


      <!-- social media profiles -->
      <div class="card mb-3">
        <div class="card-header">
          {{ $t('clients.social_media_profiles') }}
        </div>
        <div class="card-body row">
          <div class="col-12 mb-3">
            <div class="row my-3" :key="index" v-for="(social_media_profiles, index) in client.social_media_profiles">
              <div class="col-12 col-lg-9 mb-1 ">
                <div class="form-floating">
                  <Multiselect
                      v-model="social_media_profiles.type"
                      :options="setupStore.socialMediaTypes"
                      class="form-control"
                  />

                  <label :for="`client-social_media_profiles-${index}-type`">{{
                      $t('clients.social_media_profiles_type')
                    }}</label>
                </div>
              </div>

              <div class="col-12 col-lg-9 mb-1 ">
                <div class="form-floating">
                  <Field v-model="social_media_profiles.url"
                         :id="`client-social_media_profiles-${index}-url`"
                         :name="`client-social_media_profiles-${index}-url`"
                         class="form-control"
                  />
                  <label :for="`client-social_media_profiles-${index}-url`">{{
                      $t('clients.social_media_profiles_url')
                    }}</label>
                </div>
              </div>
              <div class="col-9">
                <div class="form-floating">
                  <Field v-model="social_media_profiles.info"
                         :id="`client-social_media_profiles-${index}-info`"
                         :name="`client-social_media_profiles-${index}-info`"
                         class="form-control"
                  />
                  <label :for="`client-social_media_profiles-${index}-info`">{{
                      $t('clients.social_media_profiles_info')
                    }}</label>
                </div>
              </div>
              <div class="col-3 text-end">
                <div class="btn-secondary btn btn-sm" @click="removeSocialMediaProfile(index)"
                     :title="$t('clients.create.remove_social_media_profile')">
                  <i class="fa-solid fa-trash"></i>
                </div>
              </div>
            </div>
            <div class="btn-primary btn btn-sm" @click="addSocialMediaProfile">
              <i class="fa-solid fa-plus"></i> {{ $t('clients.create.add_social_media_profile') }}
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">

          <div class="col-12 mb-3">


            <div class="form-floating">
              <Field v-model="client.info" type="textarea" as="textarea" name="client.info"
                     class="form-control w-100"
                     style="height: 7em">
              </Field>
              <label for="client.info">{{ $t('clients.info') }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">
          {{ $t('clients.legal_info_and_privacy_policy') }}
        </div>
        <div class="card-body">
          <div class="form-floating mb-3">
            <Field v-model="client.legal_info_type" id="client.legal_info_type" name="client.legal_info_type"
                   as="select"
                   class="form-select"
                   :class="{'is-invalid': errors['client.legal_info_type']}">
              <template v-for="(label, key) in setupStore.legalInfoTypes" :key="key">
                <option :value="key">{{ label }}</option>
              </template>
            </Field>
            <label for="client.legal_info_type">{{ $t('clients.legal_info_type') }}</label>
            <div v-if="errors['client.legal_info_type']" class="invalid-feedback">
              {{ errors['client.legal_info_type'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.legal_info_name" id="client.legal_info_name" name="client.legal_info_name"
                   class="form-control"
                   :class="{'is-invalid': errors['client.legal_info_name']}"/>
            <label for="client.legal_info_name">{{ $t('clients.legal_info_name') }}</label>
            <div v-if="errors['client.legal_info_name']" class="invalid-feedback">
              {{ errors['client.legal_info_name'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.legal_form" id="client.legal_form" name="client.legal_form" class="form-control"
                   :class="{'is-invalid': errors['client.legal_form']}" list="client.legal_form_datalist">
            </Field>
            <datalist id="client.legal_form_datalist">
              <template v-for="label in setupStore.legalForms" :key="label">
                <option :value="label">{{ label }}</option>
              </template>
            </datalist>
            <label for="client.legal_form">{{ $t('clients.legal_form') }}</label>
            <div v-if="errors['client.legal_form']" class="invalid-feedback">
              {{ errors['client.legal_form'] }}
            </div>
          </div>

          <div class="col-12 mt-3" v-if="showLegalAddress">
            <div class="row">
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field v-model="legalAddress.street" id="legalAddress.street" name="legalAddress.street"
                         class="form-control"
                         :class="{'is-invalid': errors['legalAddress.street']}"/>
                  <label for="legalAddress.street">{{ $t('addresses.street') }}</label>
                  <div v-if="errors['legalAddress.street']" class="invalid-feedback">
                    {{ errors['legalAddress.street'] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field v-model="legalAddress.postcode" id="legalAddress.postcode" name="legalAddress.postcode"
                         class="form-control"
                         :class="{'is-invalid': errors['legalAddress.postcode']}"/>
                  <label for="legalAddress.postcode">{{ $t('addresses.postcode') }}</label>
                  <div v-if="errors['legalAddress.postcode']" class="invalid-feedback">
                    {{ errors['legalAddress.postcode'] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field v-model="legalAddress.city" id="legalAddress.city" name="legalAddress.city"
                         class="form-control"
                         :class="{'is-invalid': errors['legalAddress.city']}"/>
                  <label for="legalAddress.city">{{ $t('addresses.city') }}</label>
                  <div v-if="errors['legalAddress.city']" class="invalid-feedback">
                    {{ errors['legalAddress.city'] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <Field class="form-select" name="legalAddress.country_code" as="select"
                         id="legalAddress.country_code"
                         v-model="legalAddress.country_code"
                         :class="{'is-invalid': errors['legalAddress.country_code']}">
                    <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
                  </Field>
                  <label for="legalAddress.country_code">{{ $t('addresses.country') }}</label>
                  <div v-if="errors['legalAddress.country_code']" class="invalid-feedback">
                    {{ errors['legalAddress.country_code'] }}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="form-floating mb-3">
            <Field v-model="client.legal_info_phone" id="client.legal_info_phone" name="client.legal_info_phone"
                   class="form-control"
                   :class="{'is-invalid': errors['client.legal_info_phone']}"/>
            <label for="client.legal_info_phone">{{ $t('clients.legal_info_phone') }}</label>
            <div v-if="errors['client.legal_info_phone']" class="invalid-feedback">
              {{ errors['client.legal_info_phone'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.legal_info_fax" id="client.legal_info_fax" name="client.legal_info_fax"
                   class="form-control"
                   :class="{'is-invalid': errors['client.legal_info_fax']}"/>
            <label for="client.legal_info_fax">{{ $t('clients.legal_info_fax') }}</label>
            <div v-if="errors['client.legal_info_fax']" class="invalid-feedback">
              {{ errors['client.legal_info_fax'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.legal_info_email" id="client.legal_info_email" name="client.legal_info_email"
                   class="form-control"
                   :class="{'is-invalid': errors['client.legal_info_email']}"/>
            <label for="client.legal_info_email">{{ $t('clients.legal_info_email') }}</label>
            <div v-if="errors['client.legal_info_email']" class="invalid-feedback">
              {{ errors['client.legal_info_email'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.representative" id="client.representative" name="client.representative"
                   class="form-control"
                   :class="{'is-invalid': errors['client.representative']}"/>
            <label for="client.representative">{{ $t('clients.representative') }}</label>
            <div v-if="errors['client.representative']" class="invalid-feedback">
              {{ errors['client.representative'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.representative_function" id="client.representative_function"
                   name="client.representative_function"
                   class="form-control"
                   :class="{'is-invalid': errors['client.representative_function']}"/>
            <label for="client.representative_function">{{ $t('clients.representative_function') }}</label>
            <div v-if="errors['client.representative_function']" class="invalid-feedback">
              {{ errors['client.representative_function'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.register_court" id="client.register_court" name="client.register_court"
                   class="form-control" list="client.register_court_datalist"
                   :class="{'is-invalid': errors['client.register_court']}">
            </Field>
            <datalist id="client.register_court_datalist">
              <template v-for="label in setupStore.registerCourts" :key="label">
                <option :value="label">{{ label }}</option>
              </template>
            </datalist>
            <label for="client.register_court">{{ $t('clients.register_court') }}</label>
            <div v-if="errors['client.register_court']" class="invalid-feedback">
              {{ errors['client.register_court'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.register_number" id="client.register_number" name="client.register_number"
                   class="form-control"
                   :class="{'is-invalid': errors['client.register_number']}"/>
            <label for="client.register_number">{{ $t('organizations.register_number') }}</label>
            <div v-if="errors['client.register_number']" class="invalid-feedback">
              {{ errors['client.register_number'] }}
            </div>
          </div>
          <div class="form-floating mb-3">
            <Field v-model="client.vat_id" id="client.vat_id" name="client.vat_id"
                   class="form-control"
                   :class="{'is-invalid': errors['client.vat_id']}"/>
            <label for="client.vat_id">{{ $t('organizations.vat_id') }}</label>
            <div v-if="errors['client.vat_id']" class="invalid-feedback">
              {{ errors['client.vat_id'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.legal_info_text" id="client.legal_info_text" name="client.legal_info_text"
                   as="textarea"
                   class="form-control"
                   :class="{'is-invalid': errors['client.legal_info_text']}"
                   style="height: 8em"
            />
            <label for="client.legal_info_text">{{ $t('clients.legal_info_text') }}</label>
            <div v-if="errors['client.legal_info_text']" class="invalid-feedback">
              {{ errors['client.legal_info_text'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.data_protection_officer" id="client.data_protection_officer"
                   type="textarea" as="textarea"
                   name="client.data_protection_officer"
                   class="form-control"
                   style="height: 8em"
                   :class="{'is-invalid': errors['client.data_protection_officer']}"/>
            <label for="client.data_protection_officer">{{ $t('clients.data_protection_officer') }}</label>
            <div v-if="errors['client.data_protection_officer']" class="invalid-feedback">
              {{ errors['client.data_protection_officer'] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <Field v-model="client.privacy_policy_version" id="client.privacy_policy_version"
                   name="client.privacy_policy_version" as="select"
                   class="form-select"
                   :class="{'is-invalid': errors['client.privacy_policy_version']}">
              <template v-for="label in setupStore.privacyPolicyVersions" :key="label">
                <option :value="label">{{ label }}</option>
              </template>
            </Field>
            <label for="client.privacy_policy_version">{{ $t('clients.privacy_policy_version') }}</label>
            <div v-if="errors['client.privacy_policy_version']" class="invalid-feedback">
              {{ errors['client.privacy_policy_version'] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import {Field, FieldArray, Form} from "vee-validate";
import * as Yup from "yup";
import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import {useUserStore} from "@/stores/user";
import {useContactStore} from "@/stores/contact";
import Multiselect from '@vueform/multiselect'
import Toggle from '@vueform/toggle'
import {CLIENT_TAB_TYPES, ROUTES} from "@/scripts/constants";
import PaymentPerformance from "@/pages/clients/components/PaymentPerformance.vue";

export default {
  name: 'ClientsForm',
  setup() {
    return {
      clientStore: useClientStore(),
      setupStore: useSetupStore(),
      contactStore: useContactStore(),
      userStore: useUserStore(),
    }
  },
  components: {
    FieldArray,
    Toggle,
    Field,
    Form,
    Multiselect,
    PaymentPerformance,
  },
  props: {
    clientModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    addressModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    invoiceAddressModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    legalAddressModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    contacts: {
      type: Array,
    },
    serverErrors: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showLegalAddress: true,
      contactSearch: '',
      showDropdown: null,
      clientSearchResults: [],
      timer: null,
      parentClient: null,
      contactsSearchData: [],
      schema: Yup.object().shape({
        // address is often missing for leads => ignore address for now
        // address: Yup.object().shape({
        //   // street: Yup.string().required(this.$t('validation.required')),
        //   postcode: Yup.string().required(this.$t('validation.required')),
        //   country_code: Yup.string().required(this.$t('validation.required')),
        //   city: Yup.string().required(this.$t('validation.required')),
        // }),
        client: Yup.object().shape({
          name: Yup.string().required(this.$t('validation.required')),
          // email: Yup.string().email().required(this.$t('validation.required')),
          kdnr: Yup.string().required(this.$t('validation.required')),
          // phone: Yup.string().required(this.$t('validation.required')),
          client_type_id: Yup.string().required(this.$t('validation.required')),
          client_status_id: Yup.string().required(this.$t('validation.required')),

          manet_url: Yup.string().url(),
          url: Yup.string().url(),
          parent_id: Yup.string(),

          // TODO: enable if active_client
          // invoice_delivery_method_id: Yup.string().required(this.$t('validation.required')),
          // invoice_delivery_method_id: Yup.number().typeError(this.$t('validation.required')).required(this.$t('validation.required')),
        }),
        // invoiceAddress: Yup.object().when("invoice_address", {
        //   is: (invoice_address) => invoice_address.city,
        //   then: Yup.object().shape({
        //     street: Yup.string().required(this.$t('validation.required')),
        //     postcode: Yup.string().required(this.$t('validation.required')),
        //     city: Yup.string().required(this.$t('validation.required')),
        //     country_code: Yup.string().required(this.$t('validation.required')),
        //   }),
        // }),
      })
    }
  },
  created() {
    this.clientStore.getNextAvailableKdnr()
    this.setupStore.getCountries()
    this.setupStore.getClientTypes()
    this.setupStore.getClientStatuses()
    this.setupStore.getInvoiceDeliveryMethods()
    this.setupStore.getLegalForms()
    this.setupStore.getPrivacyPolicyVersions()
    this.setupStore.getRegisterCourts()
    if (this.userStore.users.length === 0) {
      this.userStore.index()
    }
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    CLIENT_TAB_TYPES() {
      return CLIENT_TAB_TYPES
    },
    activeUsers() {
      return this.userStore.users.filter((u) => {
        return !!u.active || u.id === this.client.user_id
      })
    },
    hasKdnr() {
      return this.client.kdnr && !this.client.kdnr.match(/^INT/)
    },
    countries() {
      return {'': this.$t('addresses.country'), ...this.setupStore.countries}
    },
    pronounOptions() {
      return Object.keys(this.$i18n.messages[this.$i18n.locale].contacts.pronoun_options)
    },
    client: {
      get() {
        if (this.clientModel.parent) {
          this.selectParent(this.clientModel.parent, false)
        }
        return this.clientModel
      },
      set(newValue) {
        this.$emit('update:clientModel', newValue)
      }
    },
    address: {
      get() {
        return this.addressModel
      },
      set(newValue) {
        this.$emit('update:clientModel', newValue)
      }
    },
    invoiceAddress: {
      get() {
        return this.invoiceAddressModel
      },
      set(newValue) {
        this.$emit('update:invoiceAddressModel', newValue)
      }
    },
    legalAddress: {
      get() {
        return this.legalAddressModel
      },
      set(newValue) {
        this.$emit('update:legalAddressModel', newValue)
      }
    },
  },
  methods: {
    resetParent() {
      this.client.parent = null
      this.client.parent_id = null
      this.parentClient = null
    },
    selectParent(client, change = true) {
      if (change) {
        this.client.parent = client
        this.client.parent_id = client.id
      }
      this.clientSearchResults = []
      this.parentClient = `${client.kdnr} ${client.name}`
    },
    searchClient(e) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      const query = e.target.value
      if (query) {
        this.timer = setTimeout(() => {
          this.clientStore.search(query).then((result) => {
            this.clientSearchResults = result.data.clients
          })
        }, 500);
      } else {
        this.resetParent()
      }
    },
    setLead() {
      this.client.kdnr = 'INT'
      this.client.client_type_id = this.setupStore.clientTypes.find((ct) => ct.key === 'lead')?.id
      this.client.client_status_id = this.setupStore.clientStatuses.find((ct) => ct.key === 'active')?.id
    },
    useKdnr() {
      this.client.kdnr = this.clientStore.nextAvailableKdnr
      this.client.client_type_id = null
    },
    submit(values) {
      // nice to know: values are NOT getting submitted but the models are
      this.$emit('submitted', values);
    },
    addSubdomain() {
      if (!this.client.subdomains) {
        this.client.subdomains = []
      }
      this.client.subdomains.push('')
    },
    removeSubdomain(subdomain) {
      this.client.subdomains = this.client.subdomains.filter(item => item !== subdomain)
    },
    addPhone() {
      if (!this.client.phones) {
        this.client.phones = []
      }
      this.client.phones.push({number: '', label: '', publish: true})
    },
    removePhone(index) {
      this.client.phones.splice(index, 1)
    },
    addSocialMediaProfile() {
      if (!this.client.social_media_profiles) {
        this.client.social_media_profiles = []
      }
      this.client.social_media_profiles.push({url: '', info: ''})
    },
    removeSocialMediaProfile(index) {
      this.client.social_media_profiles.splice(index, 1)
    },
    addContact(newContact = false) {
      this.$refs.fieldArray.push({
        new: newContact,
        active: true,
      })
    },
    searchContact(e, index) {
      this.contactStore.search(e.target.value).then(({data}) => {
        this.contactsSearchData = data.contacts
        this.showDropdown = index
      })
    },
    setContact(contactIndex, contactSearchIndex) {
      this.$refs.fieldArray.update(contactIndex, {
        // new: false, // leave it null to omit search field
        active: true,
        data: this.contactsSearchData[contactSearchIndex],
      })
      this.showDropdown = null
    },
    /*    useAddressForInvoice() {
          this.invoiceAddress.street = this.address.street
          this.invoiceAddress.postcode = this.address.postcode
          this.invoiceAddress.city = this.address.city
          this.invoiceAddress.country = this.address.country
        },
        useAddressForLegalInfo() {
          this.legalAddress.street = this.address.street
          this.legalAddress.postcode = this.address.postcode
          this.legalAddress.city = this.address.city
          this.legalAddress.country = this.address.country
        }*/
  },
  watch: {
    serverErrors(newValue) {
      this.$refs.form.setErrors(newValue)
    },
  }
}
</script>
<style lang="scss">
.odd {
  background: var(--bs-card-cap-bg);
}

.search-dropdown {
  background: white;
  list-style-type: none;

  li {
    &:hover {
      background: grey;
      cursor: pointer;
    }
  }
}
</style>
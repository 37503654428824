import * as Yup from "yup";
import i18n from '../i18n'

export const PRODUCT_FORM_SCHEMA_FUNC = (setupStore) => {
    return Yup.array().of(
        Yup.object().shape({
            product_type_id: Yup.number().typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required')),
            product_status_id: Yup.number().typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required')),
            name: Yup.string().required(i18n.global.t('validation.required')),
            contract_id: Yup.string().nullable(),
            client_id: Yup.number().required(i18n.global.t('validation.required')).nullable(),
            start_at: Yup.string().when('product_status_id', (product_status_id, schema) => {
                    if (setupStore.activeProductStatusIds.includes(product_status_id)) {
                        return schema.typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required'))
                    }
                    return schema.nullable()
                }
            ),
            end_at: Yup.string(),
            description: Yup.string().nullable(),
            product_termination_reason_id: Yup.number().nullable(),
            disable_renewal: Yup.boolean().nullable(),
            product_duration_id: Yup.number().typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required')),
            product_renewal_setting_id: Yup.number().typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required')),
            product_payment_cycle_id: Yup.number().when(['price', 'parent_id'], (price, parent_id, schema) => {
                    if (parseFloat(price) !== 0 && !parent_id) {
                        return schema.oneOf(setupStore.activeProductPaymentCycleIds, i18n.global.t('validation.required')).required(i18n.global.t('validation.required'))
                    }
                    return schema.nullable()
                }
            ),
            // product_payment_cycle_id: Yup.number().oneOf(setupStore.activeProductPaymentCycleIds).required(i18n.global.t('validation.required')),
            vat: Yup.string().required(i18n.global.t('validation.required')),
            price: Yup.string().required(i18n.global.t('validation.required')),
        })
    ).strict()
}

export const INVOICE_FORM_SCHEMA = Yup.object().shape({
    invoice: Yup.object().shape({
        payment_method_id: Yup.number().typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required')),
        // invoice_date: Yup.string().required(i18n.global.t('validation.required')),
        positions: Yup.array().of(
            Yup.object().shape({
                price: Yup.number().required(i18n.global.t('validation.required')),
                type: Yup.string().required(i18n.global.t('validation.required')),
                content: Yup.string().required(i18n.global.t('validation.required')),
                count: Yup.number().typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required')),
                vat: Yup.number().typeError(i18n.global.t('validation.required')).required(i18n.global.t('validation.required')),
            })
        )
    })
}).strict()

export const CONTACT_FORM_SCHEMA = Yup.object().shape({
    contact: Yup.object().shape({
        first_name: Yup.string().required(i18n.global.t('validation.required')),
        last_name: Yup.string().required(i18n.global.t('validation.required')),
        pronouns: Yup.string().required(i18n.global.t('validation.required')),
    })
}).strict()

export const DIRECT_DEBIT_MANDATE_FORM_SCHEMA_CREATE = Yup.object().shape({
    mandate_date: Yup.string().required(i18n.global.t('validation.required')),
    end_date: Yup.string(),
    account_owner: Yup.string().required(i18n.global.t('validation.required')),
    bank_name: Yup.string().required(i18n.global.t('validation.required')),
    iban: Yup.string().required(i18n.global.t('validation.required')),
    bic: Yup.string(),
    file: Yup.mixed().when('file_url', (file_url, schema) => {
            if (file_url === undefined) {
                return schema.required(i18n.global.t('validation.required'))
            }
            return schema.nullable()
        }
    ),
    // reference: Yup.mixed().required(i18n.global.t('validation.required')),
}).strict()

export const DIRECT_DEBIT_MANDATE_FORM_SCHEMA_EDIT = Yup.object().shape({
    mandate_date: Yup.string(),
    end_date: Yup.string(),
    account_owner: Yup.string(),
    bank_name: Yup.string(),
    iban: Yup.string(),
    bic: Yup.string(),
    file: Yup.mixed(),
    reference: Yup.string(),
}).strict()
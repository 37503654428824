<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4">
          <template #start>
            <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
          </template>
        </Toolbar>

        <DataTable stripedRows ref="dt" :value="tags" dataKey="id"
                   :paginator="true"
                   :lazy="true"
                   :rowsPerPageOptions="[10,20,50]"
                   :rows="accountTransactionTagStore.serverOptions.rows"
                   :totalRecords="accountTransactionTagStore.itemLength"
                   @page="onPage($event)"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                   responsiveLayout="scroll">
          <Column field="name" header="Name" :sortable="true" style="min-width:16rem"></Column>
          <Column field="include_positive_amounts" :header="$t('account_transaction_tags.include_positive_amounts')">
            <template #body="{data}">
              <i v-if="data.include_positive_amounts" class="fa fa-check-circle"></i>
              <i v-else class="fa fa-times-circle"></i>
            </template>
          </Column>
          <Column field="include_negative_amounts" :header="$t('account_transaction_tags.include_negative_amounts')">
            <template #body="{data}">
              <i v-if="data.include_negative_amounts" class="fa fa-check-circle"></i>
              <i v-else class="fa fa-times-circle"></i>
            </template>
          </Column>
          <Column field="match_filters" header="Regex Matcher" :sortable="true" style="min-width:8rem">
            <template #body="{data}">
              {{data.match_list}}
            </template>
          </Column>
          <Column field="operations" header="Operations" :sortable="true" style="min-width:8rem">
            <template #body="{data}">
              <span class="operation-item me-2" @click.prevent="deleteTag(data.id)">
                <i class="fas fa-trash"/>
              </span>
              <span class="operation-item" @click.prevent="editTag(data)">
                <i class="fas fa-edit"/>
              </span>
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog v-model:visible="tagDialog" :style="{width: '500px'}" header="Tag Details" :modal="true" class="p-fluid">
        <div class="field">
          <label for="name">Name</label>
          <InputText id="name" v-model.trim="tag.name" required="true" autofocus :class="{'p-invalid': submitted && !tag.name}" />
          <small class="p-error" v-if="submitted && !tag.name">Name is required.</small>
        </div>
        <div class="field">
            <Checkbox id="manual_tag" v-model="tag.manual" :binary="true" />
            <label for="manual_tag">{{$t('account_transaction_tags.manual_tag')}}</label>
        </div>
        <div class="field mt-2" v-if="!tag.manual">
          <h4>{{$t('account_transaction_tags.autotagging_settings')}}</h4>
          Regex-List
          <div class="d-flex mt-2" :key="index" v-for="(tag_match, index) in tag.match_list" :class="{'p-invalid': submitted && tag.match_list.length === 0}">
            <InputText v-model="tag.match_list[index]" class="me-2 w-75"/>
            <Button label="" icon="pi pi-trash" class="p-button-info mr-2 w-25" @click.prevent="tag.match_list.splice(index, 1);" />
          </div>
          <br>
          <Button label="Add" icon="pi pi-plus" class="p-button-success mr-2 w-25" @click.prevent="addToMatchList(tag)" />
          <small class="p-error" v-if="submitted && tag.match_list.length === 0">Match list is required.</small>
          <br>
          <br>
          <Checkbox id="tag_include_positive_amounts" v-model="tag.include_positive_amounts" :binary="true" />
          <label for="tag_include_positive_amounts">{{$t('account_transaction_tags.include_positive_amounts')}}</label>
          <br>
          <Checkbox id="tag_include_negative_amounts" v-model="tag.include_negative_amounts" :binary="true" />
          <label for="tag_include_negative_amounts">{{$t('account_transaction_tags.include_negative_amounts')}}</label>
        </div>
        <template #footer>
          <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
          <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveTag" />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import {useAccountTransactionTagStore} from "@/stores/account_transaction_tag";
import DataTable from "primevue/datatable";
import Toolbar from "primevue/toolbar";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Button from "primevue/button";
import Checkbox from 'primevue/checkbox';
import {Helpers} from "@/helpers";
export default {
  name: 'AccountTransactionTags',
  components: {
    DataTable,
    Toolbar,
    Dialog,
    InputText,
    Column,
    Button,
    Checkbox
  },
  setup() {
    return {
      accountTransactionTagStore: useAccountTransactionTagStore(),
    }
  },
  data() {
    return {
      tagDialog: false,
      tag: {
        match_list: []
      },
      submitted: false,
    }
  },
  created() {
    this.accountTransactionTagStore.index()
    this.setTitle(this.$tc('accounts.tag', 2).capitalize())
  },
  computed: {
    tags() {
      return this.accountTransactionTagStore.tags
    },
  },
  methods: {
    addToMatchList(tag) {
      if(!tag.match_list) {
          tag.match_list = []
      }
      tag.match_list.push('')
    },
    openNew() {
      this.tag = {
        match_list: [],
        manual: false
      };
      this.submitted = false;
      this.tagDialog = true;
    },
    hideDialog() {
      this.tagDialog = false;
      this.submitted = false;
    },
    onPage(event) {
        this.accountTransactionTagStore.serverOptions = event
        this.accountTransactionTagStore.index()
    },
    deleteTag(id) {
      this.accountTransactionTagStore.delete(id)
    },
    editTag(tag) {
      this.tag = {...tag};
      this.tagDialog = true;
    },
    saveTag() {
      this.submitted = true
      if(this.tag.name && (this.tag.match_list.length > 0 || this.tag.manual)) {
        if(this.tag.id) {
          this.accountTransactionTagStore.update(this.tag).then((data) => {
            Helpers.emitter.emit('success', data.message)
            this.tagDialog = false;
          })
        } else {
          this.accountTransactionTagStore.store(this.tag).then(() => {
            this.tagDialog = false;
          })
        }
        this.accountTransactionTagStore.index()
      }
    },
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('products.edit.headline') }}</h2>

      <button @click="clone" type="button"
              :title="$t('actions.clone')"
              class="btn btn-secondary btn-sm ms-1">
        <i class="fa-solid fa-clone fa-fw"/>
      </button>
    </div>
    <div class="col-12 mt-3" v-if="product">
      <Form autocomplete="off" @submit="updateProduct" v-model:contractIdModel="product.contract_id"
            v-slot="{ errors, setFieldValue }" :validation-schema="schema"
            :initial-values="initialProducts">
        <template v-if="client?.id">
          <ProductsFormFields :loadAllClientProducts="false" :showAddRemoveButtons="false" :errors="errors" :set-field-value="setFieldValue" :client="client"/>
        </template>
        <template v-else>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </template>
        <div class="mt-3">
          <button class="btn btn-success" type="submit">
            {{ $t('save') }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import ProductsFormFields from "./ProductsFormFields.vue"
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import {Form} from "vee-validate";
import * as Yup from "yup";
import {PRODUCT_FORM_SCHEMA_FUNC} from "@/scripts/schemas";
import {Helpers} from "@/helpers";
import {useClientStore} from "@/stores/client";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ProductEdit',
  setup() {
    return {
      clientStore: useClientStore(),
      productStore: useProductStore(),
      setupStore: useSetupStore(),
    }
  },
  components: {
    ProductsFormFields,
    Form
  },
  data() {
    return {
      schema: Yup.object().shape({
        products: PRODUCT_FORM_SCHEMA_FUNC(this.setupStore)
      }),
      disabled: {

      }
    }
  },
  created() {
    this.load(this.$route.params.id)
    this.setupStore.getProductTypes()
    this.setupStore.getProductStatuses()
    this.setupStore.getProductTerminationReasons()
    this.setupStore.getProductPaymentCycles()
    this.setupStore.getProductDurations()
  },
  computed: {
    initialProducts() {
      return {
        products: [
          this.product
        ]
      }
    },
    client() {
      return this.clientStore.client
    },
    product() {
      return this.productStore.product
    },
  },
  methods: {
    load(id) {
      this.productStore.getProduct(id, () => {}, this.$t('products.edit.headline'))
    },
    clone() {
      const id = this.product.id
      this.productStore.clone(id).then(({ data }) => {
        Helpers.emitter.emit('success', this.$t('products.clone.success'))
        this.$router.push({name: ROUTES.PRODUCTS.EDIT, params: {id: data.id}})
        this.load(data.id)
      })
    },
    updateProduct(data) {
      this.productStore.update(this.$route.params.id, data.products[0]).then(({ data }) => {
        Helpers.emitter.emit('success', this.$t('products.edit.success'))
        this.$router.push({
          name: ROUTES.PRODUCTS.SHOW,
          params: {
            product_id: data.id
          }
        })
      })
    },
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">{{ $t('users.edit.headline') }}</h5>
          <Form @submit="update" v-slot="{ errors }" :validation-schema="schema" class="row" autocomplete="off">
            <div class="col-12 col-lg-6 mb-3">
              <div class="form-floating">
                <Field v-model="user.name" id="users.name" name="users.name"
                       placeholder="{{$t('users.name')}}" class="form-control"
                       :class="{'is-invalid': errors['users.name']}"/>
                <label for="users.name">{{ $t('users.name') }}</label>
                <div v-if="errors['users.name']" class="invalid-feedback">
                  {{ errors['users.name'] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <div class="form-floating">
                <Field v-model="user.sip_uid" id="users.sip_uid" name="users.sip_uid"
                       placeholder="{{$t('users.sip_uid')}}" class="form-control"
                       :class="{'is-invalid': errors['users.sip_uid']}"/>
                <label for="users.sip_uid">{{ $t('users.sip_uid') }}</label>
                <div v-if="errors['users.sip_uid']" class="invalid-feedback">
                  {{ errors['users.sip_uid'] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <div class="form-floating">
                <Field v-model="user.phone" id="users.phone" name="users.phone"
                       placeholder="{{$t('users.phone')}}" class="form-control"
                       :class="{'is-invalid': errors['users.phone']}"/>
                <label for="users.phone">{{ $t('users.phone') }}</label>
                <div v-if="errors['users.phone']" class="invalid-feedback">
                  {{ errors['users.phone'] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <div class="form-floating">
                <Field v-model="user.phone_mobile" id="users.phone_mobile" name="users.phone_mobile"
                       placeholder="{{$t('users.phone_mobile')}}" class="form-control"
                       :class="{'is-invalid': errors['users.phone_mobile']}"/>
                <label for="users.phone_mobile">{{ $t('users.phone_mobile') }}</label>
                <div v-if="errors['users.phone_mobile']" class="invalid-feedback">
                  {{ errors['users.phone_mobile'] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <div class="form-floating">
                <Field v-model="user.email" disabled="" id="users.email" name="users.email"
                       placeholder="{{$t('users.email')}}" class="form-control"
                       :class="{'is-invalid': errors['users.email']}"/>
                <label for="users.email">{{ $t('users.email') }}</label>
                <div v-if="errors['users.email']" class="invalid-feedback">
                  {{ errors['users.email'] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
                <div class="form-floating">
                    <Field v-model="user.special_role" id="users.special_role" name="users.special_role"
                           placeholder="{{$t('users.special_role')}}" class="form-control"
                           :class="{'is-invalid': errors['users.special_role']}"/>
                    <label for="users.special_role">{{ $t('users.special_role') }}</label>
                    <div v-if="errors['users.special_role']" class="invalid-feedback">
                        {{ errors['users.special_role'] }}
                    </div>
                </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <h5 class="card-title mb-3">{{ $t('users.edit.permissions') }}</h5>
                </div>

                <div class="col-12 mb-4">
                  <select class="form-select" @change="overridePermissions">
                    <option>{{ $t('users.edit.override_permissions') }}</option>
                    <option v-bind:key="key" v-for="(preset, key) in PERMISSIONS_PRESETS">{{ key }}</option>
                  </select>
                </div>

                <div v-bind:key="groupkey" v-for="(permissiongroup, groupkey) in PERMISSIONS"
                     class="col-12 col-lg-3 mb-3">
                  <h5>{{ groupkey }}</h5>
                  <div v-bind:key="key" v-for="(permission, key) in permissiongroup" class="form-check">
                    <Field v-model="permissions" :id="groupkey+key" class="form-check-input" name="permissions"
                           type="checkbox" :value="permission"/>
                    <label class="form-check-label" :for="groupkey+key">
                      {{ key }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-2">
              <button type="submit" class="btn btn-primary">Update</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/user";
import * as Yup from "yup";
import {Field, Form} from "vee-validate";
import {PERMISSIONS, PERMISSIONS_PRESETS} from "@/scripts/constants";
import {Helpers} from "@/helpers";

export default {
  name: 'UsersEdit',
  components: {
    Field,
    Form,
  },
  setup() {
    return {
      userStore: useUserStore(),
      PERMISSIONS,
      PERMISSIONS_PRESETS
    }
  },
  data() {
    return {
      user: {},
      permissions: [],
      schema: Yup.object().shape({
        users: Yup.object().shape({
          name: Yup.string().required(this.$t('validation.required')),
          special_role: Yup.string().nullable(),
          // email: Yup.string().required(this.$t('validation.required')),
        })
      })
    }
  },
  created() {
    this.userStore.show(this.$route.params.id).then(({data}) => {
      this.setUserPermissions(data.user.permissions)
      this.user = data.user
    })

  },

  methods: {
    setUserPermissions(permissions) {
      permissions.forEach((item) => {
        this.permissions.push(item.name)
      });
    },
    overridePermissions(e) {
      this.permissions = PERMISSIONS_PRESETS[e.target.value]
    },
    update() {
      this.userStore.update(this.user, this.$route.params.id, this.permissions).then(() => {
        Helpers.emitter.emit('success', this.$t('users.edit.success'))
      })
    }
  }
}
</script>

<template>
  <div class="container-fluid h-100 register">
    <div class="row h-100">
      <div class="col-lg-6 d-none d-lg-flex tantum-bg">
        <img class="img-fluid" :src="require('@/assets/auth/register.svg')"/>
      </div>
      <div v-if="userCreated" class="col-12 col-lg-6">
        <div class="w-75 mx-auto mt-5 text-center">
          <h3>{{ $i18n.t('auth.success.heading') }}</h3>
          <p class="mt-4">{{ $i18n.t('auth.success.text') }}</p>
        </div>
      </div>
      <div v-else class="col-12 col-lg-6">
        <Form @submit="register" v-slot="{ errors }" :validation-schema="schema"
              class="register-form d-flex flex-column h-100">
          <div class="w-75 mx-auto mt-5">
            <div class="h2">{{ $i18n.t('auth.sign_up') }}</div>
            <div class="mb-3 mt-5">
              <label for="name" class="form-label">{{ $i18n.t('auth.name') }}</label>
              <Field v-model="name" id="name" name="name" class="form-control" :class="{'is-invalid': errors.name}"/>
              <div v-if="errors.name" class="invalid-feedback">
                {{ errors.name }}
              </div>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">{{ $i18n.t('auth.email') }}</label>
              <Field v-model="email" id="email" name="email" class="form-control"
                     :class="{'is-invalid': errors.email}"/>
              <div v-if="errors.email" class="invalid-feedback">
                {{ errors.email }}
              </div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">{{ $i18n.t('auth.password') }}</label>
              <Field type="password" v-model="password" id="password" name="password" class="form-control"
                     :class="{'is-invalid': errors.password}"/>
              <div v-if="errors.password" class="invalid-feedback">
                {{ errors.password }}
              </div>
            </div>
            <div class="mb-3">
              <label for="password_confirmation" class="form-label">{{ $i18n.t('auth.password_confirmation') }}</label>
              <Field type="password" v-model="password_confirmation" id="password_confirmation"
                     name="password_confirmation" class="form-control"
                     :class="{'is-invalid': errors.password_confirmation}"/>
              <div v-if="errors.password_confirmation" class="invalid-feedback">
                {{ errors.password_confirmation }}
              </div>
            </div>
            <div class="mb3">
              <button type="submit" class="btn btn-primary">{{ $i18n.t('auth.sign_up') }}</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "../stores/user";
import {Field, Form} from 'vee-validate';
import * as Yup from "yup";

export default {
  name: "RegisterPage",
  components: {
    Field,
    Form,
  },
  setup() {
    const userStore = useUserStore()
    return {
      userStore,
    }
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      schema: Yup.object().shape({
        name: Yup.string().required(this.$t('validation.required')),
        email: Yup.string().email(this.$t('validation.email')).required(this.$t('validation.required')),
        password: Yup.string().min(6).required(this.$t('validation.required')),
        password_confirmation: Yup.string()
            .test('passwords-match', this.$t('validation.match'), function (value) {
              return this.parent.password === value
            }).required(this.$t('validation.required'))
      })
    }
  },
  methods: {
    register() {
      this.userStore.register({
        lang: this.$i18n.locale,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        name: this.name
      })
    },
  },
  computed: {
    userCreated() {
      return this.userStore.created;
    },
  },
  created() {
    // console.log(i18n.t('validation.required'))
    // this.organizationStore.get();
    // if (window.tantum.user) {
    //   this.name = window.tantum.user.name;
    // }
  },
  // beforeRouteEnter(to, from, next) {
  // }
}
</script>

<template>
  <div>
    <label :for="fieldId">{{ $t(`client_events.filter_labels.${this.mode}`) }}</label>
    <input type="date" v-model="filterValue" :id="fieldId" :name="fieldId"
           class="form-control" @change="change"/>
  </div>
</template>

<script>


export default {
  name: 'ClientEventTimelineFilterDate',
  components: {
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {}
  },
  data() {
    return {
      filterValue: null,
    }
  },
  created() {
    this.initFromParams()
  },
  computed: {
    fieldId() {
      return `ce_filter_date_${this.mode}`
    }
  },
  methods: {
    initFromParams() {
      const filters = JSON.parse(this.$route.query.filters || 'null') || {}
      if (filters[this.mode]) {
      this.filterValue = (filters[this.mode])
      this.$emit('valueChanged', this.filterValue)
      }
    },
    change() {
      this.$emit('valueChanged', this.filterValue)
    },
  },
  watch: {}
}
</script>

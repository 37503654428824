<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $tc('organizations.organization', 2) }}</h2>
    </div>
  </div>
</template>

<script>
import {useOrganizationStore} from "@/stores/organization";

export default {
  name: 'OrganizationsIndex',
  setup() {
    return {
      organizationStore: useOrganizationStore(),
    }
  },
  data() {
        return {

        }
  },
  created() {
  },
  methods: {
    store() {

    }
  }
}
</script>

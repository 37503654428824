<template>
  <div>
    <template v-for="(word, index) in splittedText" :key="index">
      <span class="text-nowrap">{{ word }}</span>
      <template v-if="index < lastIndex">&#8196;</template>
      <!-- use special white space bc normal whitespace is removed by compilerOptions.whitespace = 'condense'; -->
    </template>
  </div>
</template>

<script>

export default {
  name: 'TextNoWordBreak',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    splittedText(){
      const givenString = this.$slots.default()[0].children
      return givenString.split(/\s+/)
    },
    lastIndex() {
      return this.splittedText.length - 1
    },
  }
}

</script>
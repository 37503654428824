<template>
  <FieldArray name="products" v-slot="{ fields, push, remove }">
    <fieldset
        v-for="(field, index) in fields"
        :key="field.key">
      <div class="row">
        <div class="col-12 d-flex" v-if="showAddRemoveButtons">
          <h5>{{ $t('products.product') }} {{ index + 1 }}</h5>

          <div class="ms-2 mb-2 btn btn-sm btn-secondary" @click="remove(index)">
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>

      <ProductFormFields :current-fields="fields.find((field) => field.key === index)"
                         :index="index"
                         :errors="errors"
                         :setFieldValue="setFieldValue"
                         :contract-mode="contractMode"
                         class="row"
      />

      <hr class="my-3">
    </fieldset>
    <button v-if="showAddRemoveButtons" class="btn btn-secondary btn-sm me-3" type="button"
            @click="push({ client_id: clientId })">
      <i class="fa-solid fa-plus"></i> {{ $t('products.add') }}
    </button>
  </FieldArray>
</template>

<script>
import {FieldArray} from "vee-validate";
import {useProductStore} from "@/stores/product";
import {useClientStore} from "@/stores/client";
import ProductFormFields from "@/pages/products/ProductFormFields.vue";

export default {
  name: 'ProductsFormFields',
  components: {
    FieldArray,
    ProductFormFields,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      productStore: useProductStore(),
    }
  },
  props: {
    errors: {
      type: Object,
    },
    setFieldValue: {
      type: Function,
    },
    createMode: {
      type: Boolean,
      default: false
    },
    showAddRemoveButtons: {
      type: Boolean,
      default: true
    },
    contractMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    clientId() {
      const clientId = this.clientStore.client?.id
      if (!clientId) {
        throw new Error("missing clientId")
      }
      return clientId
    },
  },
  data() {
    return {
    }
  },
  created() {
    this.productStore.getProducts({client_id: this.clientId})
  },
  methods: {
  },
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('direct_debit_mandates.create') }}</h2>
    </div>
    <div v-if="Object.keys(client).length === 0" class="col-12">
      <p class="alert alert-danger">
        Client ID missing in url query
      </p>
    </div>
    <div v-else class="col-12 mt-5">
      <Form @submit="createDdm" v-slot="{ errors, setFieldValue }" :validation-schema="schema" class="row"  :initial-values="initialDdm">
        <DirectDebitMandateFormFields :errors="errors" :setFieldValue="setFieldValue" :client="client" :create-mode="true" />
        <div class="col-12 col-lg-3">
          <button class="btn btn-success mt-3" type="submit">
            {{ $t('save') }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {useDirectDebitMandateStore} from "@/stores/direct_debit_mandates";
import {useClientStore} from "@/stores/client";
import {Form} from "vee-validate";
import DirectDebitMandateFormFields from "./components/FormFields.vue"
import {DIRECT_DEBIT_MANDATE_FORM_SCHEMA_CREATE} from "@/scripts/schemas";
import {Helpers} from "@/helpers";
import {CLIENT_TAB_TYPES, ROUTES} from "@/scripts/constants";

export default {
  name: 'DirectDebitMandateCreate',
  components: {
    Form,
    DirectDebitMandateFormFields
  },
  setup() {
    return {
      directDebitMandateStore: useDirectDebitMandateStore(),
      clientStore: useClientStore(),
    }
  },
  props: {
  },
  data() {
    return {
      schema: DIRECT_DEBIT_MANDATE_FORM_SCHEMA_CREATE,
      initialDdm: {
        // init optional fields with strings to prevent "undefined" getting POSTed
        reference: '',
        bic: '',
      }
    }
  },
  computed: {
    client() {
      return this.clientStore.client
    },
  },

  created() {
    if (this.$route.query.client_id) {
      this.setCurrentClientById(this.$route.query.client_id)
    }
  },
  methods: {
    createDdm(data) {
      this.directDebitMandateStore.create(data).then(() => {
        Helpers.emitter.emit('success', this.$t('direct_debit_mandates.created'))
        this.$router.push({
          name: ROUTES.CLIENTS.SHOW,
          params: {id: this.client.id},
          query: {tab: CLIENT_TAB_TYPES.DIRECT_DEBIT_MANDATES}
        })
      })
    }
  },
  watch: {
  }
}
</script>

import {defineStore} from 'pinia'

export const useConversationStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'conversation',
    // a function that returns a fresh state
    state: () => ({
        loading: false,
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        index() {
            return this.apiClient.get(`/conversations`)
        },
        show(id) {
            return this.apiClient.get(`/conversations/${id}`)
        },
        update(id, conversation) {
            return this.apiClient.put(`/conversations/${id}`, {conversation})
        },
        unread() {
            return this.apiClient.get(`/conversations/unread`)
        }
    },
})

<template>
  <div class="p-4">
    <h2 class="mt-3">{{ $t('invoices.create') }}</h2>
    <InvoiceForm @updated="saveInvoice"/>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";
import {useInvoiceStore} from "@/stores/invoice";
import InvoiceForm from "./components/Form.vue";
import {useDirectDebitMandateStore} from "@/stores/direct_debit_mandates";

export default {
  name: 'InvoicesCreate',
  components: {
    InvoiceForm,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      invoiceStore: useInvoiceStore(),
      directDebitMandateStore: useDirectDebitMandateStore(),
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    saveInvoice(values) {
      this.invoiceStore.store(values.invoice)
    }
  },
}
</script>

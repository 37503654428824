import {defineStore} from 'pinia'

export const useEventLogStore = defineStore({
    id: 'event_log',
    state: () => ({
        event_log: null,
        event_logs: [],
    }),
    getters: {},
    actions: {
        index(service) {
            const params = {}
            if (service) {
                params.service_eq = service
            }
            return this.apiClient.get(`/event_logs`, {params})
        },
    },
})

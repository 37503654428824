<template>
  <div class="mb-3 col-12 col-lg-4">
    <div class="form-floating">
      <Field id="mandate_date" name="mandate_date" class="form-control" :class="{'is-invalid': errors.mandate_date}" type="date" />
      <label for="mandate_date" class="form-label">{{ $t('direct_debit_mandates.mandate_date') }}</label>
      <div v-if="errors.mandate_date" class="invalid-feedback">
        {{ errors.mandate_date }}
      </div>
    </div>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <div class="form-floating">
      <Field id="account_owner" name="account_owner" class="form-control" :class="{'is-invalid': errors.account_owner}"/>
      <label for="account_owner" class="form-label">{{ $t('direct_debit_mandates.account_owner') }}</label>
      <div v-if="errors.account_owner" class="invalid-feedback">
        {{ errors.account_owner }}
      </div>
    </div>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <div class="form-floating">
      <Field id="reference" name="reference" class="form-control" :class="{'is-invalid': errors.reference}" :disabled="createMode"/>
      <label for="reference" class="form-label">{{ $t('direct_debit_mandates.reference') }}</label>
      <div v-if="errors.reference" class="invalid-feedback">
        {{ errors.reference }}
      </div>
    </div>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <div class="form-floating">
      <Field id="iban" name="iban" class="form-control" :class="{'is-invalid': errors.iban}"/>
      <label for="iban" class="form-label">{{ $t('direct_debit_mandates.iban') }}</label>
      <div v-if="errors.iban" class="invalid-feedback">
        {{ errors.iban }}
      </div>
    </div>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <div class="form-floating">
      <Field id="bic" name="bic" class="form-control" :class="{'is-invalid': errors.bic}"/>
      <label for="bic" class="form-label">{{ $t('direct_debit_mandates.bic') }}</label>
      <div v-if="errors.bic" class="invalid-feedback">
        {{ errors.bic }}
      </div>
    </div>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <div class="form-floating">
      <Field id="bank_name" name="bank_name" class="form-control" :class="{'is-invalid': errors.bank_name}"/>
      <label for="bank_name" class="form-label">{{ $t('direct_debit_mandates.bank_name') }}</label>
      <div v-if="errors.bank_name" class="invalid-feedback">
        {{ errors.bank_name }}
      </div>
    </div>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <Field v-slot="{ field }" name="recurring_payments" >
      <label>
        <input type="checkbox" name="recurring_payments"  @change="setFieldValue('recurring_payments', field.value)" v-model="field.value" :value="true" />
        {{ $t('direct_debit_mandates.recurring_payments') }}
      </label>
    </Field>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <Field v-slot="{ field }" name="one_time_payments" >
      <label>
        <input type="checkbox" name="one_time_payments"  @change="setFieldValue('one_time_payments', field.value)" v-model="field.value" :value="true" />
        {{ $t('direct_debit_mandates.one_time_payments') }}
      </label>
    </Field>
  </div>
  <div class="mb-3 col-12 col-lg-4">
    <div class="form-floating">
      <Field id="end_date" name="end_date" class="form-control" :class="{'is-invalid': errors.end_date}" type="date"/>
      <label for="end_date" class="form-label">{{ $t('direct_debit_mandates.end_date') }}</label>
      <div v-if="errors.end_date" class="invalid-feedback">
        {{ errors.end_date }}
      </div>
    </div>
  </div>
  <div class="mb3 col-12" v-if="ddm">
    <div class="d-flex flex-column w-25 mt-3">
      <vue-pdf-embed :page="1" :source="ddm.file_url" v-if="ddm.file_type === 'application/pdf'"/>
      <img class="w-100" :src="ddm.file_url" v-if="ddm.file_type === 'image/jpeg'" />
    </div>
    <div class="alert alert-warning">
      {{ $t('direct_debit_mandates.upload_warning') }}
    </div>
  </div>
  <div class="mb-3 col-12">
    <Field name="file" v-slot="{ handleChange, handleBlur }">
      <DropZone class="col-12" :class="{'is-invalid': errors.file}" :show-buttons="false" :accept-files="['.pdf', '.jpg']" @change="handleChange" @blur="handleBlur"/>
      <div v-if="errors.file" class="invalid-feedback">
        {{ errors.file }}
      </div>
    </Field>
  </div>
  <Field type="hidden" id="client_id" name="client_id" :value="client.id" />
  <Field type="hidden" id="file_url" name="file_url" />
</template>

<script>
import {Field} from "vee-validate";
import DropZone from "@/components/DropZone.vue";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: 'DirectDebitMandateFormFields',
  components: {
    Field,
    DropZone,
    VuePdfEmbed
  },
  props: {
    errors: {
      type: Object,
      default: () => {
      },
    },
    createMode: {
      type: Boolean,
      default: false
    },
    ddm: {
      type: Object,
      default: () => null,
    },
    client: {
      type: Object
    },
    setFieldValue: {
      type: Function,
      default: () => {
      },
    },
  },
  setup() {
  },
  data() {
    return {
    }
  },
  created() {

  },
  computed: {
  }
}
</script>

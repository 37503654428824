<template>
  <div class="row">
    <div :class="`col-12 col-lg-${labelColumns} me-2 text-nowrap` ">
      {{ label }}
    </div>
    <div :class="`col-12 col-lg-${valueColumns} fw-bold ${valueClass}`">
      <slot></slot>
      <template v-if="rawHtml">
        <span v-html="value"></span>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShowRow',
  components: {},
  props: {
    labelColumns: {
      type: Number,
      default: 3
    },
    label: String,
    value: String,
    rawHtml: {
      type: Boolean,
      default: false
    },
    valueClass: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {}
  },
  data() {
    return {}
  },
  created() {
  },
  computed: {
    valueColumns() {
      return 11 - this.labelColumns
    }
  },
  methods: {},
}
</script>

<style lang="scss">
</style>
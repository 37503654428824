<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb m-0 d-flex align-items-center">
      <template v-if="axiosIsLoading > 0">
        <li class="me-3">
          <div class="loading">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </li>
      </template>
      <li class="breadcrumb-item" aria-current="page">
        {{ userStore.user.organization.name }}
      </li>
      <li class="breadcrumb-item" aria-current="page" v-if="clientIsSet && parentClient?.id">
        <router-link @click.prevent="clickClient(parentClient.id)" class="operation-item"
                     :to="{name: ROUTES.CLIENTS.SHOW, params: {id: parentClient.id}}">
          {{ parentLabel }}
        </router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page" v-if="clientIsSet && currentClient?.id">
        <!--                             :to="{name: ROUTES.CLIENTS.SHOW, params: {id: currentClient.id}}"-->
        <!-- FIXME: make .font-weight-bold work -->
        <router-link @click.prevent="clickClient(currentClient.id)" class="operation-item"
                     style="font-weight: bold;"
                     :to="{name: ROUTES.CLIENTS.SHOW, params: {id: 'auto'}, query: {kdnr: currentClient.kdnr}}"
                     title="NEW TAB: mittels kdnr laden, damit reimportierte neue id verwendet wird."
        >
          {{ currentLabel }}
        </router-link>
        <UserAvatar :id="currentClient.user_id" class="user"/>
        <div class="dropdown d-inline-block">
          <a class="fas fa-tools py-1 px-2 dropdown-toggle operation-item" data-bs-toggle="dropdown"></a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item text-nowrap"
                 :href="`https://go.m-vp.de/${currentClient.kdnr}`">
                <img style="width:16px;height:16px" class="me-2" src="//www.mvp.de/favicon.ico">www</a>
            </li>
            <li><a class="dropdown-item text-nowrap" target="_blank"
                   :href="`https://admin2.manet-marketing.de/platform/clients/auto/detail_page?kdnr=${currentClient.kdnr}`">
              <i class="fas fa-newspaper fa-fw me-1"></i>Detailseite</a>
            </li>
            <li>
              <a class="dropdown-item me-2 " :href="`https://admin2.manet-marketing.de/manet/crm/${currentClient.kdnr}?crm=zohocrm`"
                 title="ZohoCRM" target="_blank">
                <i class="fas fa-handshake fa-fw me-1"/>ZohoCRM
              </a>
            </li>
            <li>
              <a class="dropdown-item text-nowrap" target="_blank"
                 :href="`https://admin2.manet-marketing.de/?kdnr=${currentClient.kdnr}`">
                <i class="fas fa-sparkles fa-fw me-1"></i>Admin2</a>
            </li>
            <li>
              <a show_label="true" class="dropdown-item text-nowrap"
                 :href="`https://admin2.manet-marketing.de/ms/customers/${currentClient.kdnr}/stats`">
                <i class="fas fa-analytics fa-fw me-1"></i>Statistik {{ currentClient.kdnr }}</a>
            </li>
            <li>
              <a target="_blank"
                 class="dropdown-item text-nowrap"
                 :href="`https://admin2.manet-marketing.de/issues/new?kdnr=${currentClient.kdnr}&amp;product_config_id=&amp;task=`"><img
                  alt="Mantis-Vorlagen"
                  title="Mantis-Vorlagen"
                  style="width:1em"
                  src="https://www.manetservice.de/mantis/images/favicon.ico"
                  class="me-2"
              >Mantis-Job erstellen <small>(Vorlagen)</small></a>
            </li>
            <li>
              <a target="_blank"
                 class="dropdown-item text-nowrap"
                 :href="`https://www.manetservice.de/mantis/search.php?project_id=0&amp;sticky_issues=off&amp;sortby=last_updated&amp;dir=DESC&amp;status[]=0&amp;hide_status=&amp;custom_field_4[]=${currentClient.kdnr}`">
                <img :alt="`Mantis-Jobs ${currentClient.kdnr}`" :title="`Mantis-Jobs ${currentClient.kdnr}`"
                     style="width:1em" class="me-2"
                     src="https://www.manetservice.de/mantis/images/favicon.ico">Mantis-Jobs {{
                  currentClient.kdnr
                }}</a>
            </li>
            <li>
              <a class="dropdown-item text-nowrap"
                 :href="`https://www.manetservice.de/mantis/search.php?project_id=0&&sticky_issues=off&sortby=status&dir=ASC&hide_status=-2&custom_field_6%5B%5D=Rechnung%20f%C3%BCr%20VL%20(inkl.%20%C3%84nderungen)&custom_field_6%5B%5D=Rechnung%20f%C3%BCr%20Auftrag&custom_field_4%5B%5D=${currentClient.kdnr}`"><img
                  alt="Mantis-Vorlagen"
                  title="Mantis-Vorlagen"
                  style="width:1em"
                  class="me-2"
                  src="https://www.manetservice.de/mantis/images/favicon.ico">Mantis-Jobs
                <small>(rechnungsrelevant)</small></a>
            </li>
            <li>
              <a class="dropdown-item text-nowrap"
                 :href="`https://anfrage.mvp.de/v3/backend/customers/search?search[string]=${currentClient.kdnr}`">
                <i class="fas fa-envelope fa-fw me-1"></i>Ulmo-Konfigurationen {{ currentClient.kdnr }}</a>
            </li>
            <li>
              <a class="dropdown-item text-nowrap" target="_blank"
                 :href="`https://www.manetservice.de/domain-tool/domains/?search[kdnr_contains]=${currentClient.kdnr}`">
                <i class="fas fa-network-wired fa-fw me-1"></i>Domain-Tool
              </a>
            </li>
            <li>
              <a class="dropdown-item   text-nowrap"
                 :href="`https://admin2.manet-marketing.de/click_counter/groups?kdnr=${currentClient.kdnr}`">
                <i class="fas fa-external-link fa-fw me-1"></i>ClickCounter-Verträge/Gruppen
              </a>
            </li>
            <li>
              <a class="dropdown-item   text-nowrap" target="_blank"
                 :href="`https://console.manet-marketing.de/account?account_search=${currentClient.kdnr}`">
                <i class="fas fa-user-secret fa-fw me-1"></i>Accounts (Passwörter)
              </a>
            </li>
            <li><a class="dropdown-item   text-nowrap"
                   :href="`https://admin2.manet-marketing.de/manager/logins?kdnr=${currentClient.kdnr}`">
              <i class="fas fa-key fa-fw me-1"></i>Logins für login.manet.info </a>
            </li>
          </ul>
        </div>
        <a class="operation-item pe-2" title="Admin2" target="_blank"
           :href="`https://admin2.manet-marketing.de/?kdnr=${currentClient.kdnr}`">
          <i class="fas fa-sparkles fa-fw me-1"></i></a>
        <a class="operation-item" title="Zulip" target="_blank"
           :href="`https://chat.manet-marketing.de/#narrow/stream/67-Kundenspezifisch/topic/${zulipClientTopic}`">
          <img src="https://chat.manet-marketing.de/static/images/favicon.svg?v=4" style="width:1em"/>
        </a>
      </li>
    </ol>
    <ClientTabNav ref="clientTabNav" v-if="clientIsSet"/>
  </nav>
</template>

<script>
import {useClientStore} from "@/stores/client";
import {useUserStore} from "@/stores/user";
import ClientTabNav from "./ClientTabNav";
import {ROUTES} from "@/scripts/constants";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: 'BreadcrumbNav',
  components: {
      UserAvatar,
    ClientTabNav
  },
  props: {},
  setup() {
    return {
      clientStore: useClientStore(),
      userStore: useUserStore(),
    }
  },
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {
    ROUTES() {
      return ROUTES
    },
    currentClient() {
      return this.clientStore.client;
    },
    currentLabel() {
      return this.currentClient?.id ? `${this.currentClient.kdnr} ${this.currentClient.name}` : null;
    },
    parentClient() {
      return this.clientStore?.client?.parent;
    },
    parentLabel() {
      return this.parentClient ? `${this.parentClient.kdnr} ${this.parentClient.name}` : null;
    },
    zulipClientTopic() {
      let topic = `${this.currentClient.kdnr} ${this.currentClient.name}`
      // 10142 Zweckverband "Wasser/Abwasser Mecklenburgische Schweiz"
      if (topic.length > 60) {
        // => 10142 Zweckverband "Wasser/Abwasser Mecklenburgische Schw...
        topic = topic.substring(0, 57) + '...';
      }
      // 2026 Friesenhof Reit- u. Freizeithotel
      // https://chat.manet-marketing.de/#narrow/stream/67-Kundenspezifisch/topic/2026.20Friesenhof.20Reit-.20u.2E.20Freizeithotel
      return encodeURIComponent(topic).replaceAll('.', '%2E').replaceAll('%', '.')
    },
  },
  methods: {
    clickClient(parentId) {
      //dirty hack to force correct navigation/load of data
      if (parentId !== this.clientStore.client.id) {
        this.clientStore.getClient(parentId, '').then(() => {
          this.$refs.clientTabNav.switchTab(this.$refs.clientTabNav.tabs[0])
        })
      } else {
        this.$refs.clientTabNav.switchTab(this.$refs.clientTabNav.tabs[0])
      }
    }
  },
}
</script>
<style scoped lang="scss">
.avatar {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
</style>
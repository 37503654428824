<template>
  <div>
    <a class="btn btn-info btn-sm my-2" data-bs-toggle="collapse" :href="`#${id}`" role="button"
       aria-expanded="false">
      <slot name="action">
        {{ label }}
      </slot>
    </a>
    <div :id="id" class="collapse">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CollapsedContent',
  components: {},
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  methods: {}
}
</script>
<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $tc('invoices.invoice', 2).capitalize() }}</h2>
    </div>
    <div class="col-12">


      <DataTable stripedRows :lazy="true"
                 :paginator="true"
                 :rows="dunningStore.serverOptions.rows"
                 :totalRecords="dunningStore.itemLength"
                 dataKey="id"
                 :value="dunningStore.invoices"
                 :loading="dunningStore.loading"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 @page="onPage($event)"
                 responsiveLayout="scroll">
        <TableColumn field="id" :header="this.$t('invoices.id')"></TableColumn>
        <TableColumn field="client_kdnr" :header="this.$t('invoices.client_kdnr')"></TableColumn>
        <TableColumn field="client_name" :header="this.$t('invoices.client_name')"></TableColumn>
        <TableColumn :header="this.$t('invoices.number')">
          <template #body="{data}">
            {{ data.number || `-- ${$t('draft')} ${data.id} --` }}
          </template>
        </TableColumn>
        <TableColumn :header="this.$t('invoices.date')">
          <template #body="{data}">
            {{ formatDate(data.invoice_date, 'short2') || '-' }}
          </template>
        </TableColumn>
        <TableColumn :header="this.$t('invoices.due_date')">
          <template #body="{data}">
            <div class="text-center" :class="{ 'bg-warning': data.overdue }">
              {{ data.due_date ? $d(data.due_date, 'short2') : '-' }}
            </div>
          </template>
        </TableColumn>
        <TableColumn :header="this.$t('invoices.status')">
          <template #body="slotProps">
            {{ setupStore.getInvoiceStatusById(slotProps.data.invoice_status_id)?.name }}
          </template>
        </TableColumn>
        <TableColumn :header="this.$t('invoices.resubmission_date')">
          <template #body="{data}">
            <div class="text-center" :class="{ 'bg-warning': exceeded(data.resubmission_date) }">
              {{ formatDate(data.resubmission_date, 'short2') || '-' }}
            </div>
          </template>
        </TableColumn>
        <TableColumn :header="this.$t('invoices.total')">
          <template #body="{data}">
            <div class="currency">
              {{ formatCurrency(data.net_total) }}
            </div>
          </template>
        </TableColumn>
        <TableColumn :header="this.$t('accounts.operations')">
          <template #body="slotProps">
            <div class="operations-wrapper">
              <span class="operation-item" @click="openInvoice(slotProps.data)">
              <template v-if="slotProps.data.number">
                <i class="fa-fw fa-solid fa-file-pdf"></i>
              </template>
              <template v-else>
                <i class="fa-fw fas fa-eye"/>
              </template>
            </span>
              <router-link class="operation-item" :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: slotProps.data.id}}"
                           :title="$t('invoices.dunning_edit')"
              >
                <i class="fas fa-file-invoice-dollar"/>
              </router-link>
            </div>
          </template>
        </TableColumn>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {useDunningStore} from "@/stores/dunning";
import dayjs from "dayjs";
import {useSetupStore} from "@/stores/setup";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'DunningIndex',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  components: {
  },
  setup() {
    return {
      dunningStore: useDunningStore(),
      setupStore: useSetupStore(),
    }
  },
  data() {
    return {
    }
  },
  created() {
    this.getInvoices()
    this.setupStore.getInvoiceStatuses();
    this.setTitle(this.$t('dunning.headline'))
  },
  methods: {
    openInvoice(invoice) {
      this.dunningStore.pdf(invoice)
    },
    sendInvoice(invoice, deliveryMethod = null) {
      this.dunningStore.send(invoice, deliveryMethod)
    },
    exceeded(date) {
      return dayjs(date).isBefore(dayjs(), 'day') // compares month and year
    },
    getInvoices() {
      this.dunningStore.getInvoices()
    },
    onPage(event) {
      this.dunningStore.serverOptions = event
      this.getInvoices()
    },
  },
  watch: {
  }
}
</script>

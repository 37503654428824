<template>
  <div class="d-flex flex-column">
    <template v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.VIEW)">
      <!-- FIXME: PERMISSIONS.CLIENTS.EXPORT -->
      <button class="btn btn-primary btn-sm" type="button" @click="clientStore.index(true)">{{
          $t('actions.export_csv')
        }}
        Kunden
      </button>
    </template>
    <template v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.VIEW)">
      <!-- FIXME: PERMISSIONS.INVOICES.EXPORT -->
      <button class="btn btn-primary btn-sm" type="button" @click="invoiceStore.index([], 1)">
        {{ $t('actions.export_csv') }} Rechnungen - letzter Monat
      </button>
      <button class="btn btn-primary btn-sm" type="button" @click="invoiceStore.index([], 2)">
        {{ $t('actions.export_csv') }} Rechnungen - vorletzter Monat
      </button>
    </template>
  </div>
</template>

<script>
import {useInvoiceStore} from "@/stores/invoice";
import {useClientStore} from "@/stores/client";
import {useUserStore} from "@/stores/user";
import {PERMISSIONS} from "@/scripts/constants";

export default {
  name: "CsvActions",
  components: {
  },
  setup() {
    return {
      clientStore: useClientStore(),
      invoiceStore: useInvoiceStore(),
      userStore: useUserStore(),
    }
  },
  data() {
    return {
    }
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
  },
  created() {
  },
  methods: {},
}
</script>

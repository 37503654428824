<template>
  <template v-if="value">
    <i class="fas fa-fw fa-xl fa-circle-check" :class="trueClass"></i>
  </template>
  <template v-else>
    <i class="fas fa-fw fa-xl fa-circle-xmark" :class="falseClass"></i>
  </template>
</template>

<script>
export default {
  name: "ShowBool",
  props: {
    value: {
      type: Boolean,
    },
    trueClass: {
      type: String,
      default: 'text-success'
    },
    falseClass: {
      type: String,
      default: 'text-muted'
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 d-flex">
      <h2>{{ $tc('contacts.contact', 2).capitalize() }}</h2>
    </div>
    <div class="col-12">
      <template v-if="mode === 'contact'">
        <ContactsTable/>
      </template>
      <template v-else>
        <ContactAssignmentsTable :client_id="client_id" />
      </template>
    </div>
  </div>
</template>

<script>

import {useContactStore} from "@/stores/contact";
import {useSetupStore} from "@/stores/setup";
import ContactAssignmentsTable from "@/pages/contacts/components/ContactAssignmentsTable.vue";
import ContactsTable from "@/pages/contacts/components/ContactsTable.vue";

export default {
  name: 'ContactsIndex',
  components: {
    ContactsTable,
    ContactAssignmentsTable,
  },
  setup() {
    return {
      contactStore: useContactStore(),
      setupStore: useSetupStore(),
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: "contact",
    },
  },
  data() {
    return {
    }
  },
  created() {
    this.setTitle(this.$tc('contacts.contact', 2).capitalize())
  },
  computed: {
  },
  methods: {
  },
  watch: {}
}
</script>

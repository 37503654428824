<template>
  <div class="p-4">
    <h2>{{ $t('invoices.edit.edit') }}: {{ invoiceStore.invoiceNumber }}</h2>
    <div class="row">
      <InvoiceForm @updated="updateInvoice">
        <input type="checkbox" class="btn-check" id="btn-check" autocomplete="off" v-model="showPreview">
        <label class="btn btn-primary me-3" for="btn-check">
          <i class="fa-fw fa-solid" :class="showPreview ? 'fa-eye' : 'fa-eye-slash'"></i>
          {{ $t('invoices.preview') }}
        </label>

        <router-link :to="{name: ROUTES.DUNNING.EDIT, params: { invoice_id: invoiceId}}" class="btn btn-primary">
          {{ $t('invoices.proceed') }}
        </router-link>
      </InvoiceForm>
      <div class="col-12 col-lg-6" v-if="showPreview">
        <InvoicePreview :id="invoiceId" :version="invoiceStore.invoiceVersion"/>
      </div>
    </div>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";
import {useInvoiceStore} from "@/stores/invoice";
import InvoicePreview from "./components/InvoicePreview.vue";
import InvoiceForm from "./components/Form.vue";
import {Helpers} from "@/helpers";
import {useSetupStore} from "@/stores/setup";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'InvoicesEdit',
  components: {
    InvoiceForm,
    InvoicePreview,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      invoiceStore: useInvoiceStore(),
      setupStore: useSetupStore(),
    }
  },
  data() {
    return {
      showPreview: true,
    }
  },
  created() {
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    invoiceId() {
      return Number(this.$route.params.id);
    },
  },
  mounted() {
  },
  methods: {
    updateInvoice(values) {
      this.invoiceStore.update(values.invoice, this.invoiceId).then(() => {
        Helpers.emitter.emit('success', this.$t('invoices.edit.success'))
        this.invoiceStore.invoiceVersion++
      })
    }
  },
}
</script>

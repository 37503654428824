<template>
  <div>
    <div class="btn-group" role="group" aria-label="Select event type">
      <template v-for="type in eventTypes" :key="type.key">
        <Field v-slot="{ field }" name="client_event_type_id" type="radio" :value="type.id">
          <input type="radio" name="client_event_type_id" class="btn-check" :value="type.id"
                 :id="`${itemKey}_${type.key}_client_event_type_id`"
                 v-bind="field"
                 @input="$emit('update:modelValue', $event.target.value)">
          <label class="btn btn-outline-primary" :for="`${itemKey}_${type.key}_client_event_type_id`">
            <i class="fas" :class="type.icon" :title="type.name"/>
            {{ type.name }}
          </label>
        </Field>
      </template>
    </div>
  </div>
</template>

<script>

import {Field} from "vee-validate";

export default {
  name: 'ClientEventTypeSelect',
  components: {
    Field
  },
  props: {
    eventTypes: {
      type: Array,
    },
    itemKey: {
      type: Number,
    },
  },
  setup() {
    return {
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
</style>
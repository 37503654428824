<template>
  <div v-if="show">
      <span class="badge interactive me-2 prevent-select" @click="clicked"
            :class="{'bg-dark': !active, 'bg-success': active}">
        <UserAvatar :id="user.id"/>
        {{ user.name }}
        </span>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: 'UserButton',
  components: {
    UserAvatar,
  },
  props: {
    user: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: true,
    }
  },
  setup() {
    return {}
  },
  created() {
  },
  watch: {},
  data() {
    return {}
  },
  methods: {
    clicked() {
      this.$emit('userChanged', this.user.id)
    },
  },
  computed: {},
}
</script>
<template>
  <div class="row">
    <ClientEventTimelineHeader/>
    <ClientEventTimelineFilters/>
    <ClientEventTimeline :items="clientEventStore.client_events" :allow-comment="false" :show-client="true"/>
  </div>
</template>

<script>
import ClientEventTimeline from "@/components/ClientEventTimeline";
import ClientEventTimelineFilters from "@/components/ClientEventTimelineFilters.vue";
import {useClientEventStore} from "@/stores/client_event";
import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import {useAllUsersStore} from "@/stores/all_users";
import ClientEventTimelineHeader from "@/components/ClientEventTimelineHeader.vue";

export default {
  name: 'ClientsShow',
  components: {
    ClientEventTimeline,
    ClientEventTimelineHeader,
    ClientEventTimelineFilters,
  },
  setup() {
    return {
      clientEventStore: useClientEventStore(),
      clientStore: useClientStore(),
      setupStore: useSetupStore(),
      allUserStore: useAllUsersStore(),
    }
  },
  data() {
    return {}
  },
  created() {
    // events loaded by ClientEventTimelineFilters
    this.setTitle(this.$tc('client_events.client_events', this.clientEventStore.client_events.length))
  },
  computed: {},
  methods: {},
  watch: {}

}
</script>

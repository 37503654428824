<template>
  <div>
    <!-- Kunde -->
    <Field type="hidden" :name="fieldName('client_id')" />

    <!-- parent_id -->
    <div class="col-12 col-lg-6 mb-3">
      <div class="form-floating">
        <Field class="form-select"
               as="select"
               v-slot="{  }"
               :id="`parent_id_${index}`"
               :name="fieldName('parent_id')"
               :class="{'is-invalid': errors[`products[${index}].parent_id`]}"
               @change="delayedUpdateDisabledAndSetValues"
        >
          <option :value="null" key="nil">{{ $t('products.parent_product_select_blank') }}</option>
          <option v-for="(product, key) in packageProducts" :key="key" :value="product.id">
            [{{ setupStore.getProductTypeById(product.product_type_id).name }}] {{ product.auto_name }}
          </option>
        </Field>
        <label :for="`parent_id_${index}`">{{ $t('products.parent_product_select_prompt') }}</label>
        <div v-if="errors[`products[${index}].parent_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].parent_id`] }}
        </div>
      </div>
    </div>

    <div>
    </div>

    <!-- Typ -->
    <div class="col-12 col-lg-4 mb-3">
      <div class="form-floating">
        <Field class="form-select"
               as="select"
               :id="`product_type_id_${index}`"
               :name="fieldName('product_type_id')"
               :class="{'is-invalid': errors[`products[${index}].product_type_id`]}"
        >
          <option v-for="(productType, key) in productTypes" :key="`${key}-${index}`"
                  :value="productType.id"
          >
            {{ productType.name }}
          </option>
        </Field>
        <label :for="`product_type_id_${index}`">{{ $t('products.product_type') }}</label>
        <div v-if="errors[`products[${index}].product_type_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].product_type_id`] }}
        </div>
      </div>
    </div>

    <!-- Name -->
    <div class="col-12 col-lg-4 mb-3">
      <div class="form-floating">
        <Field
            :id="`name_${index}`"
            :name="fieldName('name')"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].name`]}"/>
        <label :for="`name_${index}`">{{ $t('products.name') }}</label>
        <div v-if="errors[`products[${index}].name`]" class="invalid-feedback">
          {{ errors[`products[${index}].name`] }}
        </div>
      </div>
    </div>

    <!-- Status -->
    <div class="col-12 col-lg-4 mb-3">
      <div class="form-floating">
        <Field class="form-select"
               as="select"
               :id="`product_status_id_${index}`"
               :name="fieldName('product_status_id')"
               :class="{'is-invalid': errors[`products[${index}].product_status_id`]}"
        >
          <option v-for="(productStatus, key) in setupStore.productStatuses" :key="key" :value="productStatus.id">
            {{ productStatus.name }}
          </option>
        </Field>
        <label :for="`product_status_id_${index}`">{{ $t('products.product_status') }}</label>
        <div v-if="errors[`products[${index}].product_status_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].product_status_id`] }}
        </div>
      </div>
    </div>

    <!-- Description -->
    <div class="col-12 col-lg-8 mb-3">
      <div class="form-floating">
        <Field
            :id="`description_${index}`"
            :name="fieldName('description')"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].description`]}"/>
        <label :for="`description_${index}`">{{ $t('products.description') }}</label>
        <div v-if="errors[`products[${index}].description`]" class="invalid-feedback">
          {{ errors[`products[${index}].description`] }}
        </div>
      </div>
    </div>

    <!-- invoice_label_intro_text -->
    <div class="col-12 col-lg-6 mb-3">
      <div class="form-floating">
        <Field
            :id="`invoice_label_intro_text_${index}`"
            :name="fieldName('invoice_label_intro_text')"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].invoice_label_intro_text`]}"/>
        <label :for="`invoice_label_intro_text_${index}`">{{ $t('products.invoice_label_intro_text') }}</label>
        <div v-if="errors[`products[${index}].invoice_label_intro_text`]" class="invalid-feedback">
          {{ errors[`products[${index}].invoice_label_intro_text`] }}
        </div>
      </div>
    </div>

    <div class="w-100"></div>

    <!-- invoice_label_outro_text -->
    <div class="col-12 col-lg-6 mb-3">
      <div class="form-floating">
        <Field
            :id="`invoice_label_outro_text_${index}`"
            :name="fieldName('invoice_label_outro_text')"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].invoice_label_outro_text`]}"/>
        <label :for="`invoice_label_outro_text_${index}`">{{ $t('products.invoice_label_outro_text') }}</label>
        <div v-if="errors[`products[${index}].invoice_label_outro_text`]" class="invalid-feedback">
          {{ errors[`products[${index}].invoice_label_outro_text`] }}
        </div>
      </div>
    </div>

    <div class="w-100"></div>

    <!-- Preis -->
    <div class="col-6 col-lg-2 mb-3">
      <div class="form-floating">
        <Field
            :id="`setup_price_${index}`"
            :name="fieldName('setup_price')"
            type="text"
            class="form-control text-end" :class="{'is-invalid': errors[`products[${index}].setup_price`]}"/>
        <label :for="`setup_price_${index}`">{{ $t('products.setup_price') }}</label>
        <div v-if="errors[`products[${index}].setup_price`]" class="invalid-feedback">
          {{ errors[`products[${index}].setup_price`] }}
        </div>
      </div>
    </div>

    <!-- Steuer -->
    <div class="col-6 col-lg-1 mb-3">
      <div class="form-floating">
        <Field
            :id="`vat_${index}`"
            :name="fieldName('vat')"
            type="number"
            step="0.1"
            value="19"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].vat`]}"/>
        <label :for="`vat_${index}`">{{ $t('products.vat') }}</label>
        <div v-if="errors[`products[${index}].vat`]" class="invalid-feedback">
          {{ errors[`products[${index}].vat`] }}
        </div>
      </div>
    </div>

    <div class="w-100"></div>

    <!-- Duration -->
    <div class="col-6 col-lg-4 col-xl-2 mb-3">
      <div class="form-floating">
        <Field class="form-select"
               as="select"
               :id="`product_duration_id_${index}`"
               :name="fieldName('product_duration_id')"
               :class="{'is-invalid': errors[`products[${index}].product_duration_id`]}"
               :disabled="disabled.productDuration"
               @change="delayedUpdateDisabledAndSetValues"
        >
          <option v-for="(productDuration, key) in setupStore.productDurations" :key="key"
                  :value="productDuration.id">
            {{ productDuration.name }}
          </option>
        </Field>
        <label :for="`product_duration_id_${index}`">{{ $t('products.product_duration') }}</label>
        <div v-if="errors[`products[${index}].product_duration_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].product_duration_id`] }}
        </div>
      </div>
    </div>

    <!-- product_renewal_setting -->
    <div class="col-12 col-lg-4 mb-3">
      <div class="form-floating">
        <Field class="form-select"
               as="select"
               :id="`product_renewal_setting_${index}`"
               :name="fieldName('product_renewal_setting_id')"
               :disabled="disabled.productRenewalSettings"
               :class="{'is-invalid': errors[`products[${index}].product_renewal_setting_id`]}"
        >
          <template v-for="(productRenewalSettings, key) in setupStore.productRenewalSettings" :key="key">
            <option :value="productRenewalSettings.id">
              {{ productRenewalSettings.name }}
            </option>
          </template>
        </Field>
        <label :for="`product_renewal_setting_id_${index}`">{{ $t('products.product_renewal_setting') }}</label>
        <div v-if="errors[`products[${index}].product_renewal_setting_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].product_renewal_setting_id`] }}
        </div>
      </div>
    </div>

    <div class="w-100"></div>

    <!-- Preis -->
    <div class="col-6 col-lg-2 mb-3">
      <div class="form-floating">
        <Field
            :id="`price_${index}`"
            :name="fieldName('price')"
            type="text"
            :disabled="disabled.price"
            class="form-control text-end" :class="{'is-invalid': errors[`products[${index}].price`]}"/>
        <label :for="`price_${index}`">{{ $t('products.price') }}</label>
        <div v-if="errors[`products[${index}].price`]" class="invalid-feedback">
          {{ errors[`products[${index}].price`] }}
        </div>
      </div>
    </div>

    <!-- PaymentCycle -->
    <div class="col-12 col-lg-2 mb-3">
      <div class="form-floating">
        <Field class="form-select"
               as="select"
               :id="`product_payment_cycle_id_${index}`"
               :name="fieldName('product_payment_cycle_id')"
               :disabled="disabled.productPaymentCycle"
               :class="{'is-invalid': errors[`products[${index}].product_payment_cycle_id`]}"
        >
          <option v-for="(productPaymentCycle, key) in setupStore.productPaymentCycles" :key="key"
                  :value="productPaymentCycle.id">
            {{ productPaymentCycle.name }}
          </option>
        </Field>
        <label :for="`product_payment_cycle_id_${index}`">{{ $t('products.product_payment_cycle') }}</label>
        <div v-if="errors[`products[${index}].product_payment_cycle_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].product_payment_cycle_id`] }}
        </div>
      </div>
    </div>


    <div class="w-100"></div>

    <!-- Begin -->
    <div class="col-6 col-lg-4 col-xl-2 mb-3">
      <div class="form-floating">
        <Field
            :id="`start_at_${index}`"
            type="datetime-local"
            :name="fieldName('start_at')"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].start_at`]}"/>
        <label :for="`start_at_${index}`">{{ $t('products.start_at') }}</label>
        <div v-if="errors[`products[${index}].start_at`]" class="invalid-feedback">
          {{ errors[`products[${index}].start_at`] }}
        </div>
      </div>
    </div>

    <!-- Admin2 product id  -->
    <div class="col-6 col-lg-2 mb-3">
      <div class="form-floating">
        <Field
            :id="`admin2_product_config_id_${index}`"
            type="number"
            :name="fieldName('admin2_product_config_id')"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].admin2_product_config_id`]}"/>
        <label :for="`admin2_product_config_id_${index}`">{{ $t('products.admin2_product_config_id') }}</label>
        <div v-if="errors[`products[${index}].admin2_product_config_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].admin2_product_config_id`] }}
        </div>
      </div>
    </div>

    <div class="w-100"></div>

    <!-- Ende -->
    <div class="col-6 col-lg-4 col-xl-2 mb-3">
      <div class="form-floating">
        <Field
            :id="`end_at_${index}`"
            :name="fieldName('end_at')"
            type="datetime-local"
            class="form-control" :class="{'is-invalid': errors[`products[${index}].end_at`]}"/>
        <label :for="`end_at_${index}`">{{ $t('products.end_at') }}</label>
        <div v-if="errors[`products[${index}].end_at`]" class="invalid-feedback">
          {{ errors[`products[${index}].end_at`] }}
        </div>
      </div>
    </div>

    <!-- Kündigungsgrund -->
    <div class="col-12 col-lg-4 mb-3">
      <div class="form-floating">
        <Field class="form-select"
               as="select"
               :id="`product_termination_reason_id_${index}`"
               :name="fieldName('product_termination_reason_id')"
               :class="{'is-invalid': errors[`products[${index}].product_termination_reason_id`]}"
        >
          <option :value="null">-</option>
          <option v-for="(terminationReason, key) in setupStore.terminationReasons" :key="key"
                  :value="terminationReason.id">
            {{ terminationReason.name }}
          </option>
        </Field>
        <label :for="`product_termination_reason_id_${index}`">{{
            $t('products.product_termination_reason_id')
          }}</label>
        <div v-if="errors[`products[${index}].product_termination_reason_id`]" class="invalid-feedback">
          {{ errors[`products[${index}].product_termination_reason_id`] }}
        </div>
      </div>
    </div>

    <template v-if="!contractMode">
      <div class="col-12"></div>
      <div class="col-12 col-lg-6">
        <h4>{{ $t('products.assign_contracts') }}</h4>
        <ul class="list-group">
          <template v-for="(contract, contractIdx) in contractStore.contracts" :key="contractIdx">
            <li class="list-group-item">
              <Field v-slot="{ field }" :name="fieldName('contract_ids')" type="checkbox" :value="contract.id">
                <label class="d-block">
                  <input type="checkbox" :name="fieldName('contract_ids')"
                         v-bind="field"
                         :value="contract.id"
                         :checked="fieldValue?.contract_ids?.includes(contract.id)"
                  >
                  #{{ contract.id }} {{ contract.title }} - {{ formatDate(contract.contract_date, 'short2') }}
                </label>
              </Field>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import {Field} from "vee-validate";
import {useSetupStore} from "@/stores/setup";
import {useProductStore} from "@/stores/product";
import {
  PRODUCT_BILLING_DURATIONS as PRODUCT_DURATIONS,
  PRODUCT_BILLING_RENEWAL_SETTINGS,
  PRODUCT_PAYMENT_CYCLE
} from "@/scripts/constants";
import {useClientStore} from "@/stores/client";
import {useContractStore} from "@/stores/contract";

export default {
  name: 'ProductFormFields',
  components: {
    Field,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      productStore: useProductStore(),
      setupStore: useSetupStore(),
      contractStore: useContractStore(),
    }
  },
  props: {
    currentFields: {
      type: Object,
    },
    index: {
      type: Number,
    },
    setFieldValue: {
      type: Function,
    },
    errors: {
      type: Object,
    },
    createMode: {
      type: Boolean,
      default: false
    },
    contractMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contractIds: [],
      disabled: {
        productDuration: false, // partOfPackage(fields, index)
        productRenewalSettings: false, // durationOnce(fields, index) || partOfPackage(fields, index)
        productPaymentCycle: false, // durationOnce(fields, index) || partOfPackage(fields, index)
        price: false, // durationOnce(fields, index)
      }
    }
  },
  created() {
    this.setupStore.getProductBillingPeriodStatuses()
    this.setupStore.getProductRenewalSettings()
    this.delayedUpdateDisabledAndSetValues(250)
    if (!this.contractMode) {
      this.contractStore.index(this.clientId)
    }
  },
  computed: {
    clientId() {
      const clientId = this.clientStore.client?.id
      if (!clientId) {
        throw new Error("missing clientId")
      }
      return clientId
    },
    fieldValue() {
      return this.currentFields.value
    },
    packageProducts() {
      return this.productStore.products.filter((p) => this.setupStore.getProductTypeById(p.product_type_id).package)
    },
    productTypes() {
      const currentlyIsProduct = this.isPartOfPackage()
      const allProductTypes = this.setupStore.productTypes
      // prevent type package inside package:
      const filteredProductTypes = allProductTypes.filter((pt) => !currentlyIsProduct || (currentlyIsProduct && !pt.package))
      return filteredProductTypes
    }
  },
  methods: {
    // moved from computed to method while trying to fix
    // [Vue warn]: Maximum recursive updates exceeded in component <FieldArray>. This means you have a reactive effect that is mutating its own dependencies and thus recursively triggering itself. Possible sources include component template, render function, updated hook or watcher source function.
    isPartOfPackage() {
      const parent_id = this.fieldValue.parent_id
      return !!parent_id
    },
    isDurationOnce() {
      let productDuration;
      const id = this.fieldValue.product_duration_id
      // console.log(id)
      if (id && (productDuration = this.setupStore.getProductDurationById(id))) {
        // console.log(productDuration.key)
        return productDuration.key === 'once'
      }
      return false;
    },
    delayedUpdateDisabledAndSetValues(delay = 100) {
      // console.log("delaying...")
      setTimeout(this.updateDisabledAndSetValues, delay)
    },
    updateDisabledAndSetValues() {
      const partOfPackage = this.isPartOfPackage()
      const durationOnce = this.isDurationOnce()
      // console.log("updateDisabledAndSetValues()  partOfPacke", partOfPackage, "durationOnce", durationOnce)

      this.disabled.productDuration = partOfPackage
      this.disabled.productRenewalSettings = durationOnce || partOfPackage
      this.disabled.productPaymentCycle = durationOnce || partOfPackage
      this.disabled.price = durationOnce

      if (partOfPackage) {
        this.autoSetFieldValue('product_duration_id', this.setupStore.productDurations.find((pd) => pd.key === PRODUCT_DURATIONS.NONE)?.id)
        this.autoSetFieldValue('product_renewal_setting_id', this.setupStore.productRenewalSettings.find((rs) => rs.key === PRODUCT_BILLING_RENEWAL_SETTINGS.NO_RENEWAL)?.id)
        this.autoSetFieldValue('product_payment_cycle_id', this.setupStore.productPaymentCycles.find((pc) => pc.key === PRODUCT_PAYMENT_CYCLE.NONE)?.id)
      }
      if (durationOnce) {
        this.autoSetFieldValue('price', "0")
        this.autoSetFieldValue('product_renewal_setting_id', this.setupStore.productRenewalSettings.find((rs) => rs.key === PRODUCT_BILLING_RENEWAL_SETTINGS.NO_RENEWAL)?.id)
        this.autoSetFieldValue('product_payment_cycle_id', this.setupStore.productPaymentCycles.find((pc) => pc.key === PRODUCT_PAYMENT_CYCLE.ONCE)?.id)
      }
    },
    fieldName(attribute) {
      return `products[${this.index}].${attribute}`
    },
    autoSetFieldValue(attribute, newValue) {
      const fieldName = this.fieldName(attribute)
      this.setFieldValue(fieldName, newValue)
      // const currentValue = this.fieldValue
      // if (currentValue != newValue) {
      //   this.setFieldValue(fieldName, newValue)
      // }
    },
  },
}
</script>

import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
import {useGlobalStore} from "@/stores/global";
import {saveAs} from 'file-saver';

export const useClientStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'client',
    // a function that returns a fresh state
    state: () => ({
        nextAvailableKdnr: null,
        activeTab: {},
        address: {},
        clientAddresses: {
            address: null,
            invoice_address: null,
            legal_address: null,
        },
        client: {},
        clients: [],
        clientsById: {},
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 20,
            filters: {},
            sortField: null,
            sortOrder: null,
        },
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        setCurrentClientById(newId, pageTitle) {
            // ignore if new id is same as current
            if (newId.toString() !== this.client?.id?.toString()) {
                // console.log(`${newId.toString()} vs ${this.client?.id?.toString()}`)
                this.getClient(newId, pageTitle)
            } else {
                //     console.log("SKIPPING LOAD OF CLIENT")
                useGlobalStore().setTitle(pageTitle)
            }
        },
        removeClientById(id) {
            this.clients = this.clients.filter(client => client.id !== id)
            this.itemLength--
        },
        mergeClientData(contacts, client, address, invoice_address, legal_address) {
            const data = {contacts, client, address}
            if (invoice_address && Object.keys(invoice_address).length > 0) {
                data.invoice_address = invoice_address
            }
            if (legal_address && Object.keys(legal_address).length > 0) {
                data.legal_address = legal_address
            }
            return data
        },
        create(contacts, client, address, invoice_address, legal_address) {
            return this.apiClient.post(`/clients`, this.mergeClientData(contacts, client, address, invoice_address, legal_address))
        },
        update(id, contacts, client, address, invoice_address, legal_address) {
            return this.apiClient.put(`/clients/${id}`, this.mergeClientData(contacts, client, address, invoice_address, legal_address))
        },
        index(csv = false) {
            this.loading = true
            let params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }
            let filters = {}
            if (this.serverOptions.filters.name?.value) {
                filters['name'] = this.serverOptions.filters.name.value
            }
            if (this.serverOptions.filters.kdnr?.value) {
                filters['kdnr'] = this.serverOptions.filters.kdnr.value
            }
            if (this.serverOptions.filters.client_type_id?.value) {
                filters['client_type_id'] = this.serverOptions.filters.client_type_id.value
            }
            if (this.serverOptions.filters.client_status_id?.value) {
                filters['client_status_id'] = this.serverOptions.filters.client_status_id.value
            }
            if (this.serverOptions.filters.contact_id?.value) {
                filters['contact_id'] = this.serverOptions.filters.contact_id.value
            }
            Helpers.addQueryToParams(filters, params)
            Helpers.setUrlQuery(filters)
            if (this.serverOptions.sortField) {
                Helpers.addQueryToParams({
                    sort_by: this.serverOptions.sortField,
                    sort_order: this.serverOptions.sortOrder
                }, params)
            }
            if (csv) {
                params["csv"] = true
            }
            return this.apiClient.get(`/clients`, {
                params
            }).then(({headers, data}) => {
                if (headers['content-type'].split(';')[0] === 'text/csv') {
                    const blob = new Blob([ data ], {
                        type: headers['content-type']
                    })
                    window.location.href = URL.createObjectURL(blob);
                } else if (data.filename) {
                    saveAs(Helpers.textToBlob(data), data.filename);
                } else {
                    this.clients = data.clients
                    this.itemLength = data.itemLength
                    this.loading = false
                }
            })
        },
        search(search) {
            return this.apiClient.get(`/clients/search`, {params: {search}})
        },
        delete(id) {
            return this.apiClient.delete(`/clients/${id}`).then(() => {
                this.removeClientById(id)
            })
        },
        getNextAvailableKdnr() {
            return this.apiClient.get(`/clients/next_available_kdnr`).then(({data}) => {
                this.nextAvailableKdnr = data.kdnr
            })
        },
        get(id, kdnr = null) {
            return this.apiClient.get(`/clients/${id}`, {
                params: {
                    kdnr
                }
            })
        },
        getClient(id, pageTitle = 'no page title passed to getClient in clientStore') {
            return this.get(id).then(({data}) => {
                this.client = data.client;
                this.address = data.auto_invoice_address
                this.clientAddresses.address = data.address
                this.clientAddresses.invoice_address = data.invoice_address
                this.clientAddresses.legal_address = data.legal_address
                useGlobalStore().setTitle(pageTitle)
            });
        },
        loadClient(id) {
            const c = this.clientsById[id]
            if (c === false) {
                // already loading ...
                return
            }
            this.clientsById[id] = false;
            this.get(id).then(({data}) => {
                this.clientsById[id] = data.client
            })
        },
        loadClients(clientIds) {
            const uniqueClientIds = [...new Set(clientIds)];
            // TODO: replace with single call fetching all clients
            uniqueClientIds.forEach((id) => this.loadClient(id))
        },
        findClient(id) {
            const c = this.clientsById[id];
            if (typeof c === 'object') {
                return c
            }
        },
    },
})

import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
import dayjs from "dayjs";
import {NANO_EVENT_TYPES} from "@/scripts/constants";

export const useContractStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'contract',
    // a function that returns a fresh state
    state: () => ({
        contracts: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 25,
            sortBy: 'age',
            sortType: 'desc',
        }
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        store(data) {
            return this.apiClient.post(`/contracts`, data)
        },
        index(clientId = null) {
            this.loading = true
            const params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }
            if (clientId) {
                Helpers.addQueryToParams({client_id: clientId}, params)
            }
            return this.apiClient.get(`/contracts`, {
                params
            }).then(({data}) => {
                this.contracts = data.contracts
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        get(id) {
            return this.apiClient.get(`/contracts/${id}`)
        },
        update(id, data) {
            return this.apiClient.put(`/contracts/${id}`, data)
        },
        setOnline(id, contractStatusId) {
            return this.apiClient.put(`/contracts/${id}`, {contract: {
                    start_at: dayjs(),
                    contract_status_id: contractStatusId,
                }}).then(({data}) => {
                console.log(JSON.stringify(data))
                Helpers.emitter.emit(NANO_EVENT_TYPES.CONTRACT_CHANGED)
            }).catch( (error) => {
                if (error.response) {
                    Helpers.emitter.emit('error', error.response.data.messages)
                    // console.log(error.response.status);
                }else {
                    Helpers.emitter.emit('error', "unknown error - check console!")
                    console.error(JSON.stringify(error))
                }
            })
        }
    },
})

<template>
  <div>
    <div class="alert alert-warning mx-2 py-1">
      Bisher noch manuelle Einstellung / keine Bedeutung
    </div>
    <div class="d-flex align-items-center">
<!--      <span>{{ $t('clients.payment_performance_bad') }}</span>-->
      <Field type="range" v-model="inputVal" id="client-payment_performance"
             name="client.payment_performance"
             class="form-control mx-2 payment-performance-output"
             :class="{'is-invalid': errors['client.payment_performance']}"
             min="1"
             max="99"
             :readonly="readonly"
             :disabled="readonly"
      />
<!--      <span>{{ $t('clients.payment_performance_good') }}</span>-->
    </div>
  </div>

</template>

<script>
import {Field} from "vee-validate";

export default {
  name: 'PaymentPerformance',
  setup() {
    return {
    }
  },
  components: {
    Field,
  },
  props: {
    modelValue: {
      type: Number,
    },
    errors: {
      type: Object,
      default: () => {
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.payment-performance-output {
  background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(9, 121, 23, 1) 100%);
}
</style>
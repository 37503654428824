<template>
  <div>
    <DataTable stripedRows
               v-bind="$props"
               @page="onPage($event)"
               @filter="onFilter($event)"
               @sort="onSort($event)"
               v-model:filters="filters"
               :filterDisplay="filterDisplay"
               :rowClass="rowClass"
    >
      <TableColumn field="id" :header="this.$t('products.id')" :sortable="true">
        <template #body="{ data }">
          <template v-if="data.parent_id">{{ data.parent_id }}/</template>
          {{ data.id }}
        </template>
      </TableColumn>
      <TableColumn field="client_id" style="min-width: 220px" :header="this.$t('clients.client')" :sortable="true">
        <template #filter="{filterModel, filterCallback}">
          <Multiselect class="p-0"
                       :searchable="true"
                       :options="kdnrOptions"
                       v-model="filterModel.value"
                       @search-change="fetchClients"
                       @select="filterCallback"
          />
        </template>
        <template #body="{ data }">
          {{ data.client.kdnr }}
          {{ data.client.name }}
        </template>
      </TableColumn>
      <TableColumn field="product_type_key" :header="this.$t('products.product_type')" :sortable="true">
        <template #filter="{filterModel,filterCallback}">
          <select v-model="filterModel.value" @change="filterCallback()" class="form-select">
            <template v-for="(productType, key) in setupStore.productTypes" v-bind:key="key">
              <option :value="productType.key">{{ productType.name }}</option>
            </template>
          </select>
        </template>
        <template #body="slotProps">
          {{ setupStore.getProductTypeById(slotProps.data.product_type_id)?.name }}
        </template>
      </TableColumn>
      <TableColumn field="name" :header="this.$t('products.name')" :sortable="true">
        <template #filter="{filterModel,filterCallback}">
          <input type="text" v-model="filterModel.value" @input="filterCallback()" class="form-control"/>
        </template>
        <template #body="{ data }">
          <div class="d-flex">
            <router-link :to="{name: ROUTES.PRODUCTS.SHOW, params: {product_id: data.id }}"
                         style="text-decoration:none">
              <i class="fa-fw fa-regular d-inline-block me-2"
                 :class="data.parent_id ? 'fa-folder-tree' : 'fa-folder'"></i>
            </router-link>
            <router-link :to="{name: ROUTES.PRODUCTS.SHOW, params: {product_id: data.id }}">
              <textNoWordBreak>{{ data.name }}</textNoWordBreak>
            </router-link>
          </div>
        </template>
      </TableColumn>
      <TableColumn field="product_status_key" :header="this.$t('products.product_status')">
        <template #filter="{filterModel,filterCallback}">
          <select v-model="filterModel.value" @change="filterCallback()" class="form-select">
            <option :value="status.key" v-bind:key="key"
                    v-for="(status, key) in setupStore.productStatuses">{{ status.name }}
            </option>
          </select>
        </template>
        <template #body="slotProps">
          {{ setupStore.getProductStatusById(slotProps.data.product_status_id)?.name }}
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('products.product_duration')">
        <template #body="{ data }">
          <div>
            {{ setupStore.getProductDurationById(data.product_duration_id)?.name }}
          </div>
        </template>
      </TableColumn>
      <TableColumn field="setup_price" :sortable="true">
        <template #header>
          <div class="currency ms-auto">
            {{ $t('products.setup_price') }}
          </div>
        </template>
        <template #body="slotProps">
          <div class="currency">
            {{ formatCurrency(slotProps.data.setup_price) }}
          </div>
        </template>
      </TableColumn>
      <TableColumn field="price" :sortable="true">
        <template #header>
          <div class="currency ms-auto">
            {{ $t('products.price') }}
          </div>
        </template>
        <template #body="slotProps">
          <div class="currency">
            {{ formatCurrency(slotProps.data.price) }}
          </div>
        </template>
      </TableColumn>
      <TableColumn>
        <template #header>
          <div class="mx-auto">
            {{ $t('products.product_payment_cycle') }}
          </div>
        </template>
        <template #body="{ data }">
          <div class="text-center">
            {{ setupStore.getProductPaymentCycleById(data.product_payment_cycle_id)?.name }}
          </div>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('products.operations')">
        <template #body="slotProps">
          <div class="operations-wrapper">
            <router-link class="operation-item"
                         :to="{name: ROUTES.PRODUCTS.SHOW, params: {product_id: slotProps.data.id}}"
                         :title="$t('products.show')">
              <i class="fas fa-eye"/>
            </router-link>
            <router-link class="operation-item"
                         :to="{name: ROUTES.PRODUCTS.EDIT, params: {id: slotProps.data.id}}"
                         :title="$t('products.edit.headline')">
              <i class="fas fa-edit"/>
            </router-link>
          </div>
        </template>
      </TableColumn>
      <template #empty>
        <span class="d-flex" style="min-height: 75px;">
            -
        </span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import {useClientStore} from "@/stores/client";
import {ROUTES} from "@/scripts/constants";
import {FilterMatchMode} from "primevue/api";
import Multiselect from "@vueform/multiselect/src/Multiselect.vue";
import TextNoWordBreak from "@/components/TextNoWordBreak.vue";

export default {
  name: 'ProductsTable',
  setup() {
    return {
      clientStore: useClientStore(),
      productStore: useProductStore(),
      setupStore: useSetupStore(),
    }
  },
  components: {TextNoWordBreak, Multiselect},
  props: {
    showFilters: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => [],
    },
    client_id: {
      type: Number,
      default: null,
    },
    parent_id: {
      type: Number,
      default: null,
    },
    rowsPerPageOptions: {
      type: Array,
      default: () => useSetupStore().dataTableDefaults.rowsPerPageOptions,
    },
    paginator: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: null,
    },
    totalRecords: {
      type: Number,
      default: null,
    },
    paginatorTemplate: {
      type: String,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
    currentPageReportTemplate: {
      type: String,
      default: null,
    },
    responsiveLayout: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filters: {
        'product_status_key': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'product_type_key': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'client_id': {value: null, matchMode: FilterMatchMode.EQUALS},
        'parent_id': {value: null, matchMode: FilterMatchMode.EQUALS},
        'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      },
      clientSearchResults: []
    }
  },
  created() {
    this.productStore.serverOptions.filters = {}
    this.setupStore.getProductDurations()
    this.setupStore.getProductStatuses()
    this.setupStore.getProductTypes()
    this.setupStore.getProductPaymentCycles()
    if (this.$route.query.name || this.$route.query.product_status_key || this.$route.query.product_type_key || this.$route.query.client_id || this.client_id || this.parent_id) {
      if (this.$route.query.product_status_key) {
        this.filters.product_status_key.value = this.$route.query.product_status_key
      }
      if (this.$route.query.product_type_key) {
        this.filters.product_type_key.value = this.$route.query.product_type_key
      }
      if (this.$route.query.name) {
        this.filters.name.value = this.$route.query.name
      }
      if (this.$route.query.client_id || this.client_id) {
        this.filters.client_id.value = this.$route.query.client_id || this.client_id
        this.clientStore.get(this.filters.client_id.value).then((result) => {
          this.clientSearchResults = [result.data.client]
        })
      }
      if (this.parent_id) {
        this.filters.parent_id.value = this.parent_id
      }
      this.onFilter({filters: this.filters})
    } else {
      this.productStore.index()
    }
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    kdnrOptions() {
      let clients = []
      for (let i = 0; i < this.clientSearchResults.length; i++) {
        const client = this.clientSearchResults[i]
        const msClient = {
          label: `${client.kdnr} - ${client.name}`,
          value: client.id
        }
        clients.push(msClient)
      }
      return clients
    },
    filterDisplay() {
      return this.showFilters ? 'row' : null;
    },
  },
  methods: {
    rowClass(data) {
      return this.setupStore.getProductStatusById(data.product_status_id)?.is_over ? 'opacity-50' : ''
    },
    fetchClients(query) {
      if (query) {
        this.clientStore.search(query).then((result) => {
          this.clientSearchResults = result.data.clients
        })
      }
    },
    onPage(event) {
      this.productStore.serverOptions = event
      this.productStore.index()
    },
    onSort(event) {
      this.productStore.serverOptions.sortField = event.sortField
      this.productStore.serverOptions.sortOrder = event.sortOrder
      this.productStore.index()
    },
    onFilter(data) {
      this.$nextTick(() => {
        this.productStore.serverOptions.filters = data.filters
        this.productStore.index()
      })
    },
  },
  watch: {
    // 'filters.client_id.value'(val) {
    //     this.clientMultiselectValue = val
    // },
  },
}
</script>

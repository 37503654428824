<template>
  <div class="row">
    <div class="col-12 d-flex">
      <h2>{{ $tc('contracts.contract', 2).capitalize() }}</h2>
      <div class="ms-3" v-if="client_id">
        <router-link class="btn btn-primary btn-sm" :to="{name: ROUTES.CONTRACTS.CREATE, query:{ client_id }}">
          <i class="fa-solid fa-circle-plus"></i> {{ $t('contracts.create.headline') }}
        </router-link>
      </div>
    </div>
    <div class="col-12">
      <ContractsTable :paginator="true"
                      :rows="contractStore.serverOptions.rows"
                      :totalRecords="contractStore.itemLength"
                      dataKey="id"
                      :value="contractStore.contracts"
                      :loading="contractStore.loading"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      :rowsPerPageOptions="setupStore.dataTableDefaults.rowsPerPageOptions"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      @page="onPage($event)"
                      responsiveLayout="scroll">
      </ContractsTable>
    </div>
  </div>
</template>

<script>

import {useContractStore} from "@/stores/contract";
import {useSetupStore} from "@/stores/setup";
import ContractsTable from "@/components/tables/ContractsTable.vue";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContractsIndex',
  components: {
    ContractsTable,
  },
  setup() {
    return {
      contractStore: useContractStore(),
      setupStore: useSetupStore(),
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      expandAll: false,
    }
  },
  created() {
    this.getContracts()
    this.setTitle(this.$tc('contracts.contract', 2).capitalize())
  },
  computed: {
    ROUTES() {
      return ROUTES
    }
    // autoExpandedRows() {
    //   if (this.expandAll) {
    //     return this.contractStore.contracts;
    //   } else {
    //     return this.expandedRows
    //   }
    // }
  },
  methods: {
    onPage(event) {
      this.contractStore.serverOptions = event
      this.getContracts()
    },
    getContracts() {
      if (this.$route.query.client_id || this.client_id) {
        const clientId = this.$route.query.client_id || this.client_id
        this.contractStore.index(clientId).then(() => {
          this.expandedRows = this.contractStore.contracts.filter(c => c.id);
        })
      } else {
        this.contractStore.index()
      }
    },
  },
  watch: {
  }
}
</script>

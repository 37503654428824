import {defineStore} from 'pinia'

export const useDownloadStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'download',
    // a function that returns a fresh state
    state: () => ({
        downloads: [],
        serverOptions: {
            // page: 0,
            // rows: 20,
            // sortBy: 'created_at',
            // sortType: 'desc',
        },
    }),
    // optional getters
    getters: {
    },
    // optional actions
    actions: {
        index() {
            this.loading = true
            return this.apiClient.get(`/downloads`, {
                params: {
                    // page: this.serverOptions.page + 1,
                    // size: this.serverOptions.rows
                }
            }).then(({data}) => {
                this.downloads =  data.downloads
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
    },
})

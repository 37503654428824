<template>
  <div class="row">
    <div class="modal fade p-0" id="invoicesModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title me-2" id="exampleModalLabel"
                v-html="$t('accounts.search_invoices', { amount: transaction ? formatCurrency(transaction.raw_amount) : 0 })"></h5>
            <span class="alert alert-warning m-0"
                  v-if="invoiceStore.serverOptions.filters.number?.value">Autofilter: {{ invoiceStore.serverOptions.filters.number.value }}</span>
            <span>Filter: <input type="text" :disabled="searching" @input="searchInvoices"></span>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <DataView :value="invoices" layout="grid" :paginator="true" :rows="20" :lazy="true" @page="onPage($event)">
              <template #grid="{data}">
                <DataViewContent :transaction="transaction" :data="data" :matchable="true"
                                 @entitySelected="invoiceSelected"/>
              </template>
            </DataView>
          </div>
          <div class="modal-footer">
            <button @click="assignInvoice" type="button" class="btn btn-primary"
                    :class="{disabled: currentSelectedInvoice === null}" data-bs-dismiss="modal">
              {{ $t('receipts.assign') }}
            </button>
            <button @click="assignPaidInvoice" type="button" class="btn btn-primary"
                    :class="{disabled: currentSelectedInvoice === null}" data-bs-dismiss="modal">
              {{ $t('receipts.assign_and_paid') }}
            </button>
            <button @click="closeModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useInvoiceStore} from "@/stores/invoice";
import {useTransactionStore} from "@/stores/transactions";
import {useDunningStore} from "@/stores/dunning";
import {Helpers} from "@/helpers";
import DataView from "primevue/dataview";
import DataViewContent from "@/pages/invoices/components/DataViewContent.vue";

export default {
  name: 'ModalInvoices',
  components: {
    DataView,
    DataViewContent
  },
  setup() {
    return {
      invoiceStore: useInvoiceStore(),
      transactionStore: useTransactionStore(),
      dunningStore: useDunningStore(),
    }
  },
  data() {
    return {
      transaction: null,
      currentSelectedInvoice: null,
      timer: null,
      searching: false
    }
  },
  computed: {
    invoices() {
      let parsedInvoices = []
      this.invoiceStore.invoices.forEach((invoice) => {
        if (this.currentSelectedInvoice === invoice.id) {
          invoice.selected = true
        }
        parsedInvoices.push(invoice)
      })
      return parsedInvoices
    }
  },

  created() {
    Helpers.emitter.on('openInvoicesModal', (transaction) => {
      this.transaction = transaction
      this.getInvoices()
    })
  },
  methods: {
    searchInvoices(e) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (e.target.value) {
        this.timer = setTimeout(() => {
          this.searching = true
          this.invoiceStore.search(e.target.value).then(({data}) => {
            this.invoiceStore.invoices = data.invoices
            this.invoiceStore.itemLength = data.itemLength
            this.searching = false
          })
        }, 500);
      } else {
        this.getInvoices()
      }
    },
    matchInvoiceNumber() {
      const re = /(?:19|20)[\d /|-]{6,}/
      const matches = re.exec(this.transaction.remittance_info)
      if (matches && matches.length > 0) {
        // return non-numeric characters from string
        return matches[0].replace(/\D/g, '');
      }
      // implicit return null -> is used as filters.numbers.value
    },
    getInvoices() {
      const matchedNumber = this.matchInvoiceNumber()
      this.invoiceStore.serverOptions.filters.number = {value: matchedNumber}
      // ignore "not assigned" filter on search by invoice number
      let filters = matchedNumber ? [] : [{key: 'not_assigned'}]
      this.invoiceStore.index(filters)
    },
    onPage(event) {
      this.invoiceStore.serverOptions = event
      this.getInvoices()
    },
    invoiceSelected(id) {
      this.invoiceStore.invoices.forEach((invoice) => {
        invoice.selected = false
      })
      this.currentSelectedInvoice = id
    },
    assignPaidInvoice() {
      const payload = {
        invoice: {
          date_of_payment: this.transaction.booking_date,
        }
      }
      this.dunningStore.setInvoicePaid(this.currentSelectedInvoice, payload).then(() => {
        this.$emit('updated')
        this.assignInvoice()
      })
    },
    assignInvoice() {
      this.invoiceStore.update({}, this.currentSelectedInvoice, this.transaction.id).then(({data}) => {
        this.transactionStore.getForAccount(data.account_id)
        Helpers.emitter.emit('success', data.message)
      }).catch(({data}) => {
        this.transactionStore.getForAccount(data.account_id)
        Helpers.emitter.emit('error', data.message)
      })
      this.currentSelectedInvoice = null
    },
    closeModal() {
      this.currentSelectedInvoice = null
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.amount {
  padding: 0.25em 0.5em;
  background-color: var(--tantum-color-bg1);
  color: white;
}
</style>
<template>
  <div class="row">
    <h2>{{ $t('client_events.client_events') }}</h2>

    <template v-if="clientEventStore.client_event">
      <div class="col-12" :class="{'col-xl-6': $route.query.preview}">
        <ClientEventTimeline :items="[ clientEventStore.client_event ]" :show-client="true"/>
      </div>
    </template>

    <template v-if="$route.query.preview">
      <div class="col-12 col-xl-6">
        <FilePreview :src="clientEventFileStore.previewData"/>
      </div>
    </template>
  </div>
</template>

<script>
import {useClientEventStore} from "@/stores/client_event";
import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import {useAllUsersStore} from "@/stores/all_users";
// import {Helpers} from "@/helpers";
import ClientEventTimeline from "@/components/ClientEventTimeline";
import {CLIENT_EVENT_TYPES, NANO_EVENT_TYPES} from "@/scripts/constants";
import {Helpers} from "@/helpers";
import {useClientEventFileStore} from "@/stores/client_event_file";
import FilePreview from "@/components/FilePreview.vue";


export default {
  name: 'ClientEventsShow',
  components: {
    FilePreview,
    ClientEventTimeline,
  },
  setup() {
    return {
      clientEventFileStore: useClientEventFileStore(),
      clientEventStore: useClientEventStore(),
      clientStore: useClientStore(),
      setupStore: useSetupStore(),
      allUserStore: useAllUsersStore(),
      CLIENT_EVENT_TYPES,
    }
  },
  data() {
    return {
      emitListenerUnbind: null,
    }
  },
  created() {
    this.setupStore.getClientEventTypes()
    this.fetchEvent()
    this.emitListenerUnbind = Helpers.emitter.on(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED, this.fetchEvent)
    if (this.$route.query.preview) {
      this.clientEventFileStore.preview(this.clientEventId, 1, this.$route.query.attachment_name)
    }
  },
  beforeUnmount() {
    this.emitListenerUnbind()
  },
  computed: {
    clientEventType() {
      if (this.clientEventStore.client_event) {
        return this.setupStore.getClientEventTypeById(this.clientEventStore.client_event.client_event_type_id)
      }
      return null;
    },
    clientEventId() {
      return this.$route.params.id
    },
  },
  methods: {
    fetchEvent() {
      this.clientEventStore.getClientEvent(this.$route.params.id, this.$t('client_events.headline'))
    },
  },
}
</script>

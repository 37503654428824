<template>
  <div class="row">
    <div class="col-12 mt-3">
      <h2>{{ $t('clients.create.headline') }}</h2>
    </div>
    <ClientsForm @submitted="store"
                 v-model:clientModel="client"
                 v-model:addressModel="address"
                 v-model:invoiceAddressModel="invoice_address"
                 v-model:contactsModel="contacts"
    />
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";

import ClientsForm from "./Form";
import {Helpers} from "@/helpers";
import {DEFAULT_VALUES, ROUTES} from "@/scripts/constants";

export default {
  name: 'ClientsCreate',
  setup() {
    return {
      clientStore: useClientStore(),
    }
  },
  components: {
    ClientsForm
  },
  data() {
    return {
      client: {
        name: '',
        email: '',
        kdnr: '',
        phones: [],
        fax: '',
        url: '',
        manet_url: '',
        parent_id: null,
        client_type_id: null,
        client_status_id: null,
        invoice_delivery_method: '',
        invoice_mail_delivery_price: null,
        billing_company: '',
        billing_contact: '',
        billing_email: '',
        payment_info: '',
        payment_performance: null,
        subdomains: [],
        social_media_profiles: [],
      },
      address: {
        street: '',
        postcode: '',
        country_code: DEFAULT_VALUES.COUNTRY_CODE,
        city: '',
      },
      invoice_address: {
        country_code: DEFAULT_VALUES.COUNTRY_CODE,
      },
      legal_address: {
        country_code: DEFAULT_VALUES.COUNTRY_CODE,
      },
      contacts: []
    }
  },
  created() {

  },
  computed: {
  },
  methods: {
    store() {
      this.clientStore.create(this.contacts, this.client, this.address, this.invoice_address, this.legal_address).then(({data}) => {
        Helpers.emitter.emit('success', this.$t('clients.create.success'))
        this.$router.push({
          name: ROUTES.CLIENTS.SHOW,
          params: {
            id: data.id
          },
          query: {
            tab: 'overview'
          },
        })
      })
    }
  }
}
</script>

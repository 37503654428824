<template>
  <div>
    <DataTable stripedRows v-bind="$props" @page="$emit('page', $event)" v-model:expandedRows="expandedRows">
        <TableColumn :expander="true" headerStyle="width: 3rem"/>
        <TableColumn :header="this.$t('contracts.contract_date')">
            <template #body="slotProps">
                {{ formatDate(slotProps.data.contract_date, 'short2') }}
            </template>
        </TableColumn>
        <TableColumn :header="this.$t('clients.client')">
            <template #body="{ data }">
                {{ client(data.client_id)?.kdnr }}
                {{ client(data.client_id)?.name }}
            </template>
        </TableColumn>
        <TableColumn :header="this.$t('contracts.title')">
            <template #body="{ data }">
                <router-link :to="{name: ROUTES.CONTRACTS.SHOW, params: {id: data.id }}">
                    {{ data.title }}
                </router-link>
            </template>
        </TableColumn>
        <TableColumn :header="this.$t('contracts.contract_status')">
            <template #body="slotProps">
                {{ setupStore.getContractStatusById(slotProps.data.contract_status_id)?.name }}
            </template>
        </TableColumn>
        <TableColumn :header="this.$t('contracts.info')">
            <template #body="slotProps">
                {{ slotProps.data.info }}
            </template>
        </TableColumn>
        <TableColumn :header="this.$t('clients.operations')">
            <template #body="slotProps">
                <div class="operations-wrapper">
                    <router-link class="operation-item" :to="{name: ROUTES.CONTRACTS.SHOW, params: {id: slotProps.data.id}}"
                                 :title="$t('contracts.show')">
                        <i class="fas fa-eye"/>
                    </router-link>
                    <router-link class="operation-item" :to="{name: ROUTES.CONTRACTS.EDIT, params: {id: slotProps.data.id}}"
                                 :title="$t('contracts.edit.headline')">
                        <i class="fas fa-edit"/>
                    </router-link>
                </div>
        </template>
      </TableColumn>
      <template #expansion="slotProps">
        <div class="ms-5">
          <ProductsTable :value="slotProps.data.products"></ProductsTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import ProductsTable from "@/components/tables/ProductsTable.vue";
import {useClientStore} from "@/stores/client";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContractsTable',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  setup() {
    return {
        clientStore: useClientStore(),
        productStore: useProductStore(),
        setupStore: useSetupStore(),
    }
  },
  components: {
    ProductsTable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    rowsPerPageOptions: {
      type: Array,
      default: () => useSetupStore().dataTableDefaults.rowsPerPageOptions,
    },
    paginator: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: null,
    },
    totalRecords: {
      type: Number,
      default: null,
    },
    paginatorTemplate: {
      type: String,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
    currentPageReportTemplate: {
      type: String,
      default: null,
    },
    responsiveLayout: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      expandedRows: [],
    }
  },
    computes: {},
    created() {
        this.setupStore.getContractStatuses()
        this.setupStore.getProductStatuses()
        this.setupStore.getProductTypes()
    },
    methods: {
        client(clientId) {
            return this.clientStore.findClient(clientId)
        },
        getProducts() {
            if (this.$route.query.client_id || this.client_id) {
                const clientId = this.$route.query.client_id || this.client_id
                this.productStore.index(clientId)
            } else {
                this.productStore.index()
            }
        },
    },
    watch: {
      value() {
          const clientIds = this.value.map((value) => value.client_id)
          this.clientStore.loadClients(clientIds)
      }
    },
}
</script>

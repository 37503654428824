import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
// import {useClientStore} from "@/stores/client";

export const useProductBillingPeriodStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'product_billing_period',
    // a function that returns a fresh state
    state: () => ({
        product: null,
        product_contracts: null,
        // pbps are handled by ProductBillingPeriodsTable now
        // product_billing_periods: [],
        // but are copied here to have it accessable by PositionEditor
        copy_of_product_billing_periods: [],
        client_event_needs_renewal: {
            // [clientEventId]: true, // if any of the related productBillingPeriods have "needs_renwal"
        },
        //used for edit form
        product_billing_period: null,
        products: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 25,
            // filters: {}, // moved to ProductBillingPeriodsTable
            sortField: null,
            sortOrder: null,
        }
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        async index(filters) {
            this.loading = true
            const newUrlQuery = {}
            const payload = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }

            if (filters.client_id?.value) {
                Helpers.addQueryToParams({client_id: filters.client_id.value}, payload)
            }

            if (filters.client_event_id?.value) {
                Helpers.addQueryToParams({client_event_id: filters.client_event_id?.value}, payload)
            }

            if (filters.product_id?.value) {
                Helpers.addQueryToParams({product_id: filters.product_id?.value}, payload)
            }
            if (filters.invoice_id?.value) {
                Helpers.addQueryToParams({invoice_id: filters.invoice_id?.value}, payload)
            }

            ['product_billing_status_key', 'product_type_key'].forEach(async (paramName) => {
                if (filters[paramName]?.value) {
                    Helpers.addQueryToParams({[paramName]: filters[paramName]?.value}, payload)
                    newUrlQuery[paramName] = filters[paramName]?.value
                } else {
                    await Helpers.removeQueryFromUrl(paramName)
                }
            })

            if (filters.scheduled_billing_date_today?.value) {
                Helpers.addQueryToParams({scheduled_billing_date_today: filters.scheduled_billing_date_today?.value}, payload)
                newUrlQuery['scheduled_billing_date_today'] = filters.scheduled_billing_date_today?.value
            } else {
                await Helpers.removeQueryFromUrl("scheduled_billing_date_today")
            }

            if (filters.scheduled_billing_date_lte?.value) {
                Helpers.addQueryToParams({scheduled_billing_date_lte: filters.scheduled_billing_date_lte?.value}, payload)
                newUrlQuery['scheduled_billing_date_lte'] = filters.scheduled_billing_date_lte?.value
            } else {
                await Helpers.removeQueryFromUrl("scheduled_billing_date_lte")
            }

            if (this.serverOptions.sortField) {
                Helpers.addQueryToParams({
                    sort_by: this.serverOptions.sortField,
                    sort_order: this.serverOptions.sortOrder
                }, payload)
            }
            await Helpers.setUrlQuery(newUrlQuery)

            return this.apiClient.get(`/product_billing_periods`, {
                params: payload
            })
        },
        multi_update(ids, status_key) {
            return this.apiClient.put(`/product_billing_periods/multi_update`, {ids, status_key})
        },
        get(id, callback = null) {
            return this.apiClient.get(`/product_billing_periods/${id}`).then(({data}) => {
                this.product_billing_period = data
                if (typeof callback === 'function') {
                    callback(data)
                }
            })
        },
        /*
        get(id) {
            return this.apiClient.get(`/products/${id}`)
        },
        getProduct(id) {
            this.get(id).then(({data}) => {
                this.product = data.product;
                this.product_billing_periods = data.product_billing_periods;
                this.product_contracts = data.contracts
                const clientId = data.product?.client_id
                if (clientId) {
                    useClientStore().getClient(clientId)
                }
            });
        },
        store(product_billing_periods) {
            product.contract_components_attributes = [{contract_id: product.contract_id}]
            return this.apiClient.post(`/product_billing_periods`, {product_billing_periods, client_id: product.client_id})
        },
        */
        clone(id) {
            return this.apiClient.post(`/product_billing_periods`, { from_id: id })
        },
        update(id, product_billing_period) {
            return this.apiClient.put(`/product_billing_periods/${id}`, product_billing_period)
        },
        delete(id) {
            return this.apiClient.delete(`/product_billing_periods/${id}`)
        },
    },
})

<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('contracts.contract') }} {{ contract?.title }}</h2>

      <div class="my-3" v-if="contract">
        <ShowRow :label="$t('clients.client')" :value="`${clientStore.client?.kdnr} ${clientStore.client?.name}`"/>
        <ShowRow :label="$t('contracts.contract_date')" :value="formatDate(contract.contract_date, 'short2')"/>
        <ShowRow :label="$t('contracts.description')" :value="contract.description"/>
        <ShowRow :label="$t('contracts.contract_status')"
                 :value="setupStore.getContractStatusById(contract.contract_status_id)?.name"/>
        <ShowRow :label="$t('contracts.operations')">
          <a :href="`https://crm.zoho.eu/crm/org20062499282/tab/SalesOrders/${contract?.zoho_sales_order_id}`"
             target="_blank" class="color-inherit">
            <i class="fas fa-handshake"/>
          </a>
        </ShowRow>
        <ShowRow :label="$t('contracts.info')" :value="contract.info"/>
        <ShowRow :label="$t('contracts.zoho_infos')">
          <CollapsedInfos :data="contract.zoho_infos" />
        </ShowRow>

        <router-link class="btn btn-primary me-3" :to="{name: ROUTES.CONTRACTS.EDIT, params: {id: contract.id}}"
                     :title="$t('contracts.edit.headline')">
          <i class="fas fa-edit"/>
        </router-link>

        <button class="btn btn-primary me-3" @click.prevent="setOnline" :disabled="!allowSetOnline">
          {{ $t('products.setOnline') }}
        </button>
      </div>
      <!--      {{ contract }}-->

      <template v-if="contract">
        <ProductsTable :value="contract.products"/>
      </template>

      <template v-if="clientEvents">
        <ClientEventTimeline :items="clientEvents" :allow-comment="true"/>
      </template>
      <template v-if="contract?.id">
        <ClientEventAppendButton eventable-type="Contract" :eventable-id="contract.id" />
      </template>
    </div>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";
import {useContractStore} from "@/stores/contract";
import ShowRow from "@/components/ShowRow.vue";
import {useSetupStore} from "@/stores/setup";
import ProductsTable from "@/components/tables/ProductsTable.vue";
import ClientEventTimeline from "@/components/ClientEventTimeline.vue";
import {Helpers} from "@/helpers";
import {NANO_EVENT_TYPES, ROUTES} from "@/scripts/constants";
import CollapsedInfos from "@/components/CollapsedInfos.vue";
import ClientEventAppendButton from "@/components/ClientEventAppendButton.vue";

export default {
  name: 'ClientsShow',
  components: {
    ClientEventAppendButton,
    CollapsedInfos,
    ClientEventTimeline,
    ShowRow,
    ProductsTable,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      contractStore: useContractStore(),
      setupStore: useSetupStore(),
    }
  },
  data() {
    return {
      contract: null,
      clientEvents: null,
      emitListenerUnbind: null,
    }
  },
  created() {
    this.setupStore.getContractStatuses()
    this.emitListenerUnbind = Helpers.emitter.on(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED, this.load)
    this.emitListenerUnbind2 = Helpers.emitter.on(NANO_EVENT_TYPES.CONTRACT_CHANGED, this.load)
    this.load()
  },
  beforeUnmount() {
    this.emitListenerUnbind()
    this.emitListenerUnbind2()
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    contractId() {
      return this.$route.params.id
    },
    contractStatus() {
      return this.contract?.contract_status_id ?
          this.setupStore.getContractStatusById(this.contract.contract_status_id) : null
    },
    allowSetOnline() {
      return this.contractStatus?.allow_set_online
    },
  },
  methods: {
    load() {
      this.contractStore.get(this.contractId).then(({data}) => {
        this.contract = data.contract
        this.client = data.client
        this.clientEvents = data.client_events
        this.setCurrentClient(this.client)
        this.setTitle(this.$t('contracts.contract'))
      })
    },
    setOnline() {
      const contractStatusId = this.setupStore.contractStatuses.find((ps) => ps.key === 'active').id
      this.contractStore.setOnline(this.contractId, contractStatusId)
    },
  }
}
</script>

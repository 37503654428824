import {defineStore} from 'pinia'
import {useUserStore} from "./user";
import {Helpers} from "@/helpers";
import dayjs from "dayjs";

export const useAccountStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'account',
    // a function that returns a fresh state
    state: () => ({
        accounts: [],
        analyticsData: {
            revenues: [],
            expenses: []
        },
        currentAccount: {
            transactions: []
        }
    }),
    // optional getters
    getters: {

    },
    // optional actions
    actions: {
        analytics(id, filters = null) {
            let params = {}
            if(filters) {
                let parsedFilters = {}
                if(filters.daterange) {
                    parsedFilters['start'] = (dayjs(filters.daterange[0]).format("YYYY-MM-DD"))
                    parsedFilters['end'] = (dayjs(filters.daterange[1]).format("YYYY-MM-DD"))
                }
                if(filters.timerange) {
                    parsedFilters['timerange'] = filters.timerange
                }
                if(filters.tags) {
                    parsedFilters['tags'] = filters.tags.join(',')
                }
                Helpers.addQueryToParams(parsedFilters, params)
                Helpers.setUrlQuery(parsedFilters)
            }

            return this.apiClient.get(`/accounts/${id}/analytics`, {
                params
            }).then(({data}) => {
                this.analyticsData = data
            })
        },
        getAccounts() {
            const userStore = useUserStore()
            return this.apiClient.get(`/accounts`, {params: {organization_id: userStore.user.current_organization_id}}).then(({data}) => {
                this.accounts = data
            })
        },
        get(id) {
            return this.apiClient.get(`/accounts/${id}`).then(({data}) => {
                this.currentAccount = data
            })
        },
        store(account) {
            return this.apiClient.post(`/accounts`, {account})
        },
        importTransactions(assetData, accountId) {
            const formData = new FormData(); // pass data as a form
            formData.append("data", assetData)
            const conf = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            return this.apiClient.post(`/accounts/${accountId}/import_transactions`, formData, conf)
        }
    },
})

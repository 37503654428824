import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";

export const useDirectDebitMandateStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'direct_debit_mandates',
    // a function that returns a fresh state
    state: () => ({
        direct_debit_mandates: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 25,
            sortBy: 'age',
            sortType: 'desc',
        }
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        setFormdata(data) {
            const formData = new FormData();
            formData.append("direct_debit_mandate[mandate_date]", data.mandate_date)
            formData.append("direct_debit_mandate[end_date]", data.end_date)
            formData.append("direct_debit_mandate[account_owner]", data.account_owner)
            formData.append("direct_debit_mandate[iban]", data.iban)
            formData.append("direct_debit_mandate[bic]", data.bic)
            formData.append("direct_debit_mandate[bank_name]", data.bank_name)
            formData.append("direct_debit_mandate[reference]", data.reference)
            formData.append("direct_debit_mandate[client_id]", data.client_id)
            if(data.recurring_payments !== undefined) {
                formData.append("direct_debit_mandate[recurring_payments]", data.recurring_payments)
            }
            if(data.one_time_payments !== undefined) {
                formData.append("direct_debit_mandate[one_time_payments]", data.one_time_payments)
            }
            if(data.file !== undefined) {
                formData.append("direct_debit_mandate[file]", data.file[0])
            }
            return formData
        },
        index(clientId = null) {
            this.loading = true
            const params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }
            if (clientId) {
                Helpers.addQueryToParams({client_id: clientId}, params)
            }
            return this.apiClient.get(`/direct_debit_mandates`, {
                params
            }).then(({data}) => {
                console.log(data)
                this.direct_debit_mandates = data.direct_debit_mandates
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        show(id) {
            return this.apiClient.get(`/direct_debit_mandates/${id}`)
        },
        update(id, data) {
            const formData = this.setFormdata(data)
            const conf = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            return this.apiClient.put(`/direct_debit_mandates/${id}`, formData, conf)
        },
        create(data) {
            const formData = this.setFormdata(data)
            const conf = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            return this.apiClient.post(`/direct_debit_mandates`, formData, conf)
        },
    },
})

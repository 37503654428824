<template>
  <DataTable stripedRows :lazy="true"
             :paginator="paginator"
             :rows="directDebitMandateStore.serverOptions.rows"
             :totalRecords="directDebitMandateStore.itemLength"
             dataKey="id"
             :value="directDebitMandateStore.direct_debit_mandates"
             :loading="directDebitMandateStore.loading"
             paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
             :rowsPerPageOptions="[10,20,50]"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
             @page="onPage($event)"
             responsiveLayout="scroll"
             :rowClass="rowClass"
  >
    <TableColumn field="mandate_date" :header="this.$t('direct_debit_mandates.mandate_date')">
      <template #body="{data}">
        {{ formatDate(data.mandate_date, 'short2') }}
      </template>
    </TableColumn>
    <TableColumn field="account_owner" :header="this.$t('direct_debit_mandates.account_owner')"></TableColumn>
    <TableColumn field="iban" :header="this.$t('direct_debit_mandates.iban')"></TableColumn>
    <TableColumn field="bic" :header="this.$t('direct_debit_mandates.bic')"></TableColumn>
    <TableColumn field="reference" :header="this.$t('direct_debit_mandates.reference')"></TableColumn>
    <TableColumn field="recurring_payments" :header="$t('direct_debit_mandates.recurring_payments_short')" class="text-center">
      <template #body="{data}">
        <i v-if="data.recurring_payments" class="fa fa-check-circle"></i>
        <i v-else class="fa fa-times-circle"></i>
      </template>
    </TableColumn>
    <TableColumn field="one_time_payments" :header="$t('direct_debit_mandates.one_time_payments_short')" class="text-center">
      <template #body="{data}">
        <i v-if="data.one_time_payments" class="fa fa-check-circle"></i>
        <i v-else class="fa fa-times-circle"></i>
      </template>
    </TableColumn>
    <TableColumn field="end_date" :header="this.$t('direct_debit_mandates.end_date')">
      <template #body="{data}">
        {{ formatDate(data.end_date, 'short2') }}
      </template>
    </TableColumn>
    <TableColumn :header="this.$t('products.operations')">
      <template #body="{data}">
        <div class="operations-wrapper">
          <router-link class="operation-item" :to="{name: ROUTES.DIRECT_DEBIT_MANDATES.EDIT, params: {id: data.id}}"
                       :title="$t('contacts.edit.headline')">
            <i class="fas fa-edit"/>
          </router-link>
        </div>
      </template>
    </TableColumn>
  </DataTable>
</template>

<script>

import {useDirectDebitMandateStore} from "@/stores/direct_debit_mandates";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'DirectDebitMandatesTable',
  components: {},
  setup() {
    return {
      directDebitMandateStore: useDirectDebitMandateStore(),
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
    paginator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() {
    this.index()
  },
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  methods: {
    onPage(event) {
      this.directDebitMandateStore.serverOptions = event
      this.index()
    },
    index() {
      this.directDebitMandateStore.index(this.client_id)
    },
    rowClass(data) {
      return data.end_date ? 'opacity-50' : null;
    }
  },
  watch: {}
}
</script>

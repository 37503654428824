<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $tc('product_billing_periods.product_billing_period', 2).capitalize() }}</h2>
    </div>
    <div class="col-12">
      <ProductBillingPeriodsTable
          :paginator="true"
          :rows="productBillingPeriodStore.serverOptions.rows"
          :totalRecords="productBillingPeriodStore.itemLength"
          dataKey="id"
          :client_id="client_id"
          :loading="productBillingPeriodStore.loading"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          :show-filters="true"
      />
    </div>
  </div>
</template>

<script>
import {useProductBillingPeriodStore} from "@/stores/product_billing_period";
import {useSetupStore} from "@/stores/setup";
import ProductBillingPeriodsTable from "@/components/tables/ProductBillingPeriodsTable.vue";

export default {
  name: 'ProductBillingPeriodsIndex',
  setup() {
    return {
      productBillingPeriodStore: useProductBillingPeriodStore(),
      setupStore: useSetupStore(),
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    ProductBillingPeriodsTable,
  },
  data() {
    return {
    }
  },
  created() {
    this.setupStore.getProductStatuses()
    this.setupStore.getProductTypes()
    this.setTitle(this.$tc('product_billing_periods.product_billing_period', 2).capitalize())
  },
  methods: {
  }
}
</script>

import {defineStore} from 'pinia'
import { serialize } from 'object-to-formdata';

export const useReceiptStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'receipt',
    // a function that returns a fresh state
    state: () => ({
        receipts: [],
        loading: false,
        itemLength: 0,
        serverOptions: {
            page: 0,
            rows: 9,
            sortBy: 'age',
            sortType: 'desc',
        }
    }),
    // optional getters
    getters: {
    },
    // optional actions
    actions: {
        index(filters = [], search = null) {
            let params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows,
            }
            if(filters.length > 0) {
                filters.forEach((val) => {
                    params[val.key] = 1
                })
            }
            if(search) {
                params['search'] = search
            }
            this.loading = true
            return this.apiClient.get(`/receipts`, {
                params
            }).then(({data}) => {
                this.receipts = data.receipts
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        update(id, transactionId) {
            return this.apiClient.put(`/receipts/${id}`, {
                receipt: {transaction_id: transactionId}
            })
        },
        create(data) {
            data.file = data.file[0]
            // const formData = new FormData();
            // formData.append("receipt[creditor_name]", data.creditor_name)
            // formData.append("receipt[file]", data.file[0])
            // if(data.transaction_id) {
            //     formData.append("receipt[transaction_id]", data.transaction_id)
            // }
            // if(data.amount !== undefined) {
            //     formData.append("receipt[amount]", data.amount)
            // }
            // if(data.intermediate_user_id !== undefined) {
            //     formData.append("receipt[intermediate_user_id]", data.intermediate_user_id)
            // }
            // if(data.paid_at !== undefined) {
            //     formData.append("receipt[paid_at]", data.paid_at)
            // }
            // if(data.resubmission_date !== undefined) {
            //     formData.append("receipt[resubmission_date]", data.resubmission_date)
            // }
            const formData = serialize({receipt: data}, {nullsAsUndefineds: true})

            const conf = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            return this.apiClient.post(`/receipts`, formData, conf)
        },
    },
})

<template>
  <div>
    <DataTable stripedRows
               v-bind="$props"
               :loading="loading"
               responsiveLayout="scroll"
               :filterDisplay="filterDisplay"
               v-model:filters="filters"
               @filter="onFilter"
               @sort="onSort($event)"
               @page="onPage($event)"
               :totalRecords="itemLength"
               :value="pbps"
               :rowClass="({id}) => usedProductBillingPeriodIds.includes(id) ? 'opacity-50' : ''"
    >
      <TableColumn field="id" :sortable="true" :header="this.$t('product_billing_periods.id')"></TableColumn>
      <template v-if="!hideClientColumn">
        <TableColumn field="client_id" style="min-width: 220px" :header="this.$t('clients.client')" :sortable="true">
          <template #body="{ data }">
            {{ data.client.kdnr }}
            {{ data.client.name }}
          </template>
        </TableColumn>
      </template>
      <TableColumn field="start_on" :sortable="true" :header="this.$t('product_billing_periods.period')">
        <template #body="{ data }">
          <div class="d-flex">
            <div class="text-nowrap text-start">
              <template v-if="data.start_on == data.end_on">
                {{ formatDate(data.start_on, 'short2') }}
              </template>
              <template v-else>
                {{ formatDate(data.start_on, 'short2') }} - {{ formatDate(data.end_on, 'short2') }}
              </template>
            </div>
            <div class="ms-1 text-nowrap text-end flex-grow-1">
              <sup>
                {{
                  data.number_of_installments != 1 ? `${data.installment} ${$t('common.installment_separator')} ${data.number_of_installments}` : null
                }}
              </sup>
            </div>
          </div>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('product_billing_periods.product_type')"
                   field="product_type_key"
      >
        <template #body="{ data }">
          <div class="">
            {{ setupStore.getProductTypeById(data.product_type_id)?.name }}
          </div>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <select v-model="filterModel.value" @change="filterCallback()" class="form-select">
            <template v-for="(product_type, key) in setupStore.productTypes" v-bind:key="key">
              <option :value="product_type.key">{{ product_type.name }}</option>
            </template>
          </select>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('product_billing_periods.product_name')">
        <template #body="{ data }">
          <div class="" style="max-width: 25vw;">
            <router-link :to="{name: ROUTES.PRODUCTS.SHOW, params: {product_id: data.product.id }}">
              <textNoWordBreak :key="data.product.id">{{ data.product.auto_name }}</textNoWordBreak>
            </router-link>
          </div>
        </template>
      </TableColumn>
      <TableColumn field="price" :sortable="true" :header="this.$t('product_billing_periods.price')">
        <template #body="{ data }">
          <div class="currency">
            {{ formatCurrency(data.price) }}
          </div>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('product_billing_periods.vat')">
        <template #body="{ data }">
          {{ formatPercentage(data.vat) }}
        </template>
      </TableColumn>
      <TableColumn field="product_billing_status_key"
                   :sortable="true"
                   :header="this.$t('product_billing_periods.product_billing_period_status')">
        <template #filter="{filterModel,filterCallback}">
          <select v-model="filterModel.value" @change="filterCallback()" class="form-select">
            <option :value="status.key" v-bind:key="key"
                    v-for="(status, key) in setupStore.productBillingPeriodStatuses">{{ status.name }}
            </option>
          </select>
          <label>
            <input type="checkbox" name="scheduled_billing_date_today"
                   v-model="filters.scheduled_billing_date_today.value"
                   @change="filterCallback()">
            Fällig (Abrechnungsdatum vergangen oder heute)  <!-- FIXME: I18n-->
          </label>
          <label>
            Gepl. Abrechnungsdatum vor ...
            <Field type="date" name="scheduled_billing_date_before_lte"
                   v-model="filters.scheduled_billing_date_lte.value"
                   @change="filterCallback()" />
          </label>
        </template>
        <template #body="{ data }">
          <div class="d-flex">
            <ProductBillingPeriodsStatus :item="data" :renewalActions="renewalActions" :reloadProductBillingPeriods="load" />
            <div class="ms-3">
              {{ formatDate(data.scheduled_billing_date, 'short2') }}
            </div>
          </div>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('product_billing_periods.invoices')">
        <template #body="{ data }">
          <template v-if="data.invoices.length">
            <template v-for="invoice in data.invoices" :key="invoice.id">
              <div class="text-nowrap">
                {{ formatDate(invoice.invoice_date, 'short2') }}
                <template
                    v-if="setupStore.getInvoiceStatusById(invoice.invoice_status_id)?.key == INVOICE_STATUS.CANCELED">
                  <del>{{ invoice.number }}</del>
                </template>
                <template v-else>
                  {{ invoice.number }}
                </template>

                <template v-if="invoiceActions">
                  <div class="operations-wrapper d-inline-block ms-2">
                    <template v-if="invoice.number">
                      <router-link class="operation-item"
                                   :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: invoice.id}}"
                                   :title="$t('dunning.edit')">
                        <i class="fas fa-file-invoice-dollar"/>
                      </router-link>
                    </template>
                    <!--                    <template v-else>-->
                    <!--                      <router-link class="me-2 operation-item"-->
                    <!--                                   :to="{name: ROUTES.INVOICES.CREATE, query: {-->
                    <!--                                 client_id: data.product.client_id, add_pbp_id: data.id}}"-->
                    <!--                                   :title="$t('invoices.create')">-->
                    <!--                        <i class="fas fa-square-plus fa-2x"></i>-->
                    <!--                      </router-link>-->
                    <!--                    </template>-->
                  </div>
                </template>
              </div>
            </template>
          </template>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('product_billing_periods.operations')">
        <template #body="{ data }">
          <router-link class="me-2"
                       :to="{name: ROUTES.PRODUCT_BILLING_PERIODS.EDIT, params: {id: data.id}}"
                       :title="$t('product_billing_periods.edit')">
            <i class="fas fa-edit"></i>
          </router-link>

          <template v-if="invoiceEditActions">
            <a class="me-2" href="#" @click.prevent="$emit('addProductBillingPeriod', data)"
               :title="$t('product_billing_periods.add_to_invoice')">
              <i class="fa-regular fa-square-plus"></i>
            </a>
          </template>

          <template v-if="data.client_event_id">
            <router-link class="text-nowrap"
                         :style="`color: ${setupStore.getClientEventTypeById(data.client_event_type_id)?.color}`"
                         :to="{name: ROUTES.CLIENT_EVENTS.SHOW, params: {id: data.client_event_id}}"
                         :title="$t('client_events.client_events')">
              <i class="fas" :class="setupStore.getClientEventTypeById(data.client_event_type_id)?.icon"/>
              {{ data.client_event_id }}
            </router-link>
          </template>
        </template>
      </TableColumn>
      <template #footer>
        {{ pbps.length }} {{ $tc('product_billing_periods.product_billing_period', pbps.length) }}:
        {{ formatCurrency(pbps.reduce((sum, pbp) => sum += Number(pbp.price), 0)) }}
      </template>
      <template #empty>
        <span class="d-flex" style="min-height: 75px;">
            -
        </span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import {useProductBillingPeriodStore} from "@/stores/product_billing_period";
import ProductBillingPeriodsStatus from "@/components/ProductBillingPeriodsStatus.vue";
import {FilterMatchMode} from "primevue/api";
import {INVOICE_STATUS, ROUTES} from "@/scripts/constants";
import TextNoWordBreak from "@/components/TextNoWordBreak.vue";
import {Field} from "vee-validate";

export default {
  name: 'ProductBillingPeriodsTable',
  setup() {
    return {
      productStore: useProductStore(),
      productBillingPeriodStore: useProductBillingPeriodStore(),
      setupStore: useSetupStore(),
    }
  },
  components: {
    Field,
    TextNoWordBreak,
    ProductBillingPeriodsStatus,
  },
  props: {
    renewalActions: {
      type: Boolean,
      default: false,
    },
    invoiceEditActions: {
      type: Boolean,
      default: false,
    },
    invoiceActions: {
      type: Boolean,
      default: true,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    rowsPerPageOptions: {
      type: Array,
      default: () => useSetupStore().dataTableDefaults.rowsPerPageOptions,
    },
    paginator: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: null,
    },
    totalRecords: {
      type: Number,
      default: null,
    },
    paginatorTemplate: {
      type: String,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
    currentPageReportTemplate: {
      type: String,
      default: null,
    },
    responsiveLayout: {
      type: String,
      default: null,
    },
    // productBillingPeriods: {
    //   type: Array,
    //   default: null
    // },
    usedProductBillingPeriodIds: {
      type: Array,
      default: () => [],
    },
    product_id: {
      type: Number,
      default: null,
    },
    invoice_id: {
      type: Number,
      default: null,
    },
    client_id: {
      type: Number,
      default: null,
    },
    client_event_id: {
      type: Number,
      default: null,
    },
    initialFilters: {
      type: Object,
      default: () => {
      },
    },
    forceHideClient: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filters: {
        product_billing_status_key: {value: null, matchMode: FilterMatchMode.EQUALS},
        product_type_key: {value: null, matchMode: FilterMatchMode.EQUALS},
        scheduled_billing_date_today: {value: null, matchMode: FilterMatchMode.EQUALS},
        scheduled_billing_date_lte: {value: null, matchMode: FilterMatchMode.EQUALS},
        product_id: {value: null, matchMode: FilterMatchMode.EQUALS},
        invoice_id: {value: null, matchMode: FilterMatchMode.EQUALS},
        // client_id: {value: null, matchMode: FilterMatchMode.EQUALS}, // set in getProductBillingPeriods
        client_event_id: {value: null, matchMode: FilterMatchMode.EQUALS},
        loading: false,
      },
      pbps: [],
      filterModelScheduledBillingDate: null,
      loading: false,
      itemLength: 0
    }
  },
  computed: {
    hideClientColumn() {
      if (this.forceHideClient) {
        return true
      }
      const clientId = this.$route.query.client_id || this.client_id
      return this.invoice_id || this.product_id || clientId
    },
    INVOICE_STATUS() {
      return INVOICE_STATUS
    },
    ROUTES() {
      return ROUTES
    },
    filterDisplay() {
      return this.showFilters ? 'row' : null;
    },
  },
  created() {
    this.productBillingPeriodStore.serverOptions.filters = {}
    this.setupStore.getInvoiceStatuses()
    this.setupStore.getProductBillingPeriodStatuses()
    this.setupStore.getProductStatuses()
    this.setupStore.getProductTypes()
    this.load();
  },
  methods: {
    load() {
      this.filters = {...this.filters, ...this.initialFilters}

      if (this.$route.query.product_billing_status_key ||
          this.$route.query.product_type_key ||
          this.$route.query.scheduled_billing_date_today ||
          this.$route.query.scheduled_billing_date_lte ||
          this.$route.query.product_id ||
          this.product_id ||
          this.client_event_id ||
          this.initialFilters?.isPresent ||
          this.invoice_id) {
        if (this.$route.query.product_billing_status_key) {
          this.filters.product_billing_status_key.value = this.$route.query.product_billing_status_key
        }
        if (this.$route.query.product_type_key) {
          this.filters.product_type_key.value = this.$route.query.product_type_key
        }
        if (this.$route.query.scheduled_billing_date_today) {
          this.filters.scheduled_billing_date_today.value = this.$route.query.scheduled_billing_date_today
        }
        if (this.$route.query.scheduled_billing_date_lte) {
          this.filters.scheduled_billing_date_lte.value = this.$route.query.scheduled_billing_date_lte
        }
        if (this.$route.query.product_id || this.product_id) {
          this.filters.product_id.value = this.$route.query.product_id || this.product_id
        }
        if (this.$route.params.product_id) {
          this.filters.product_id.value = this.$route.params.product_id
        }
        if (this.invoice_id) {
          this.filters.invoice_id.value = this.invoice_id
        }
        if (this.client_event_id) {
          this.filters.client_event_id.value = this.client_event_id
        }
        this.onFilter({filters: this.filters})
      } else {
        this.getProductBillingPeriods()
      }
    },
    onPage(event) {
      this.productBillingPeriodStore.serverOptions = event
      this.getProductBillingPeriods()
    },
    onFilter(data) {
      this.filters = data.filters
      this.getProductBillingPeriods()
    },
    onSort(event) {
      this.productBillingPeriodStore.serverOptions.sortField = event.sortField
      this.productBillingPeriodStore.serverOptions.sortOrder = event.sortOrder
      this.getProductBillingPeriods()
    },
    getProductBillingPeriods() {
      const filters = {...this.filters}
      const clientId = this.$route.query.client_id || this.client_id
      // clientId is expected to be null in various contexts
      if (clientId) {
          filters.client_id = { value: clientId }
      }
      this.loading = true
      this.productBillingPeriodStore.index(filters).then(({data}) => {
        this.pbps = data.product_billing_periods
        this.productBillingPeriodStore.copy_of_product_billing_periods = this.pbps
        this.itemLength = data.itemLength
        this.loading = false
        this.setClientEventNeedsRenewalState()
      })
    },
    setClientEventNeedsRenewalState(){
      if (!this.client_event_id) {
        return
      }
      this.productBillingPeriodStore.client_event_needs_renewal[this.client_event_id] = this.pbps.some((pbp) => pbp.needs_renewal)
    },
    // getProducts() {
    //   if (this.$route.query.client_id || this.client_id) {
    //     const clientId = this.$route.query.client_id || this.client_id
    //     this.productStore.index(clientId)
    //   } else {
    //     this.productStore.index()
    //   }
    // },
  }
}
</script>

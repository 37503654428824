<template>
  <div>
    <DataTable stripedRows :lazy="true"
               :paginator="true"
               :rows="invoiceStore.serverOptions.rows"
               :totalRecords="invoiceStore.itemLength"
               dataKey="id"
               filterDisplay="row"
               v-model:filters="filters"
               @filter="onFilter($event)"
               :value="invoiceStore.invoices"
               :loading="invoiceStore.loading"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               :rowsPerPageOptions="[10,20,50,100]"
               currentPageReportTemplate="Listet {first} bis {last} von {totalRecords}"
               @page="onPage($event)"
               @sort="onSort($event)"
               responsiveLayout="scroll">
      <TableColumn field="id" :header="this.$t('invoices.id')"></TableColumn>
      <TableColumn field="client_id" style="min-width: 220px" :header="this.$t('clients.client')">
        <template #filter="{filterModel, filterCallback}">
          <Multiselect class="p-0"
                       :searchable="true"
                       :options="kdnrOptions"
                       v-model="filterModel.value"
                       @search-change="fetchClients"
                       @select="filterCallback"
          />
        </template>
        <template #body="{ data }">
          {{ data.client.kdnr }}
          {{ data.client.name }}
        </template>
      </TableColumn>
      <TableColumn field="name" :header="this.$t('invoices.name')">
        <template #filter="{filterModel,filterCallback}">
          <input type="text" v-model="filterModel.value" @input="filterCallback()" class="form-control"/>
        </template>
        <template #body="{data}">
          {{ data.name }}
        </template>
      </TableColumn>
      <TableColumn field="number" :sortable="true" :header="this.$t('invoices.number')">
        <template #body="{data}">
          {{ data.number || `-- ${$t('draft')} ${data.id} --` }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <input type="text" v-model="filterModel.value" @input="filterCallback()"
                 class="form-control"
                 style="max-width:10em"/>
        </template>
      </TableColumn>
      <TableColumn field="status" :header="this.$t('invoices.status')">
        <template #filter="{filterModel,filterCallback}">
          <select v-model="filterModel.value" @change="filterCallback()" class="form-select">
            <option :value="status.id" v-bind:key="key" v-for="(status, key) in setupStore.invoiceStatuses">
              {{ status.name }}
            </option>
          </select>
        </template>
        <template #body="slotProps">
          {{ setupStore.getInvoiceStatusById(slotProps.data.invoice_status_id)?.name }}
        </template>
      </TableColumn>
      <TableColumn field="invoice_date" :sortable="true" :header="this.$t('invoices.date')">
        <template #body="{data}">
          {{ formatDate(data.invoice_date, 'short2') || '-' }}
        </template>
      </TableColumn>
      <TableColumn field="net_total" :sortable="true" :header="this.$t('invoices.net_total')">
        <template #body="{data}">
          <div class="currency">
            {{ formatCurrency(data.net_total) }}
          </div>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('invoices.gross_total')">
        <template #body="{data}">
          <div class="currency">
            {{ formatCurrency(data.gross_total) }}
          </div>
        </template>
      </TableColumn>
      <TableColumn header="NoPBP">
        <template #body="{data}">
          <div class="currency">
            {{ data.number_of_product_billing_periods || '?' }}
          </div>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('accounts.operations')">
        <template #body="{data}">
          <div class="operations-wrapper">
            <template v-if="userStore.user.hasPermission(PERMISSIONS.INVOICES.EDIT)">
              <span class="operation-item" @click="clone(data.id)" :title="$t('actions.clone')">
                <i class="fa-fw fa-solid fa-clone" @click.prevent=""/>
              </span>
            </template>

            <template v-if="data.number">
                <span class="operation-item" @click="openInvoice(data)">
                  <i class="fa-fw fa-solid fa-file-pdf"></i>
                </span>
            </template>

            <template v-if="userStore.user.hasPermission(PERMISSIONS.INVOICES.EDIT)">
              <router-link class="operation-item" :class="{'text-warning': data.number}"
                           :to="{name: ROUTES.INVOICES.EDIT, params: {id: data.id}}"
                           :title="$t('invoices.edit.edit')">
                <i class="fa-fw fa-solid fa-edit"/>
              </router-link>

              <router-link v-if="data.number" class="operation-item"
                           :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: data.id}}"
                           :title="$t('invoices.dunning_edit')">
                <i class="fa-fw fa-solid fa-file-invoice-dollar"/>
              </router-link>
            </template>
          </div>
        </template>
      </TableColumn>
      <ColumnGroup type="footer">
        <TableRow>
          <TableColumn :colspan="7" footerStyle="text-align:right">
            <template #footer>
              <i class="fa-regular fa-sigma"></i>
            </template>
          </TableColumn>
          <TableColumn :footer="formatCurrency(rowSums.netto)" footerStyle="text-align:right"/>
          <TableColumn :footer="formatCurrency(rowSums.brutto)" footerStyle="text-align:right"/>
        </TableRow>
      </ColumnGroup>
    </DataTable>
  </div>
</template>

<script>
import {useInvoiceStore} from "@/stores/invoice";
import {useDunningStore} from "@/stores/dunning";
import {useSetupStore} from "@/stores/setup";
import {useClientStore} from "@/stores/client";
import {FilterMatchMode} from "primevue/api";
import {PERMISSIONS, ROUTES} from "@/scripts/constants";
import Multiselect from "@vueform/multiselect";
import {useUserStore} from "@/stores/user";
import {Helpers} from "@/helpers";

export default {
  name: 'InvoicesTable',
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    kdnrOptions() {
      let clients = []
      for (let i = 0; i < this.clientSearchResults.length; i++) {
        const client = this.clientSearchResults[i]
        const msClient = {
          label: `${client.kdnr} - ${client.name}`,
          value: client.id
        }
        clients.push(msClient)
      }
      return clients
    },
    rowSums() {
      const sums = {
        netto: 0,
        brutto: 0
      }
      for (let i = 0; i < this.invoiceStore.invoices.length; i++) {
        const netto = parseFloat(this.invoiceStore.invoices[i].net_total)
        const brutto = parseFloat(this.invoiceStore.invoices[i].gross_total)
        if (!isNaN(netto)) {
          sums.netto += netto
        }
        if (!isNaN(brutto)) {
          sums.brutto += brutto
        }
      }
      return sums
    },
  },
  components: {
    Multiselect
  },
  setup() {
    return {
      clientStore: useClientStore(),
      dunningStore: useDunningStore(),
      invoiceStore: useInvoiceStore(),
      setupStore: useSetupStore(),
      userStore: useUserStore(),
    }
  },
  props: {
    client_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      clientSearchResults: [],
      filters: {
        'status': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'number': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'client_id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      },
    }
  },
  created() {
    this.invoiceStore.serverOptions.filters = {}
    if (this.$route.query.name || this.$route.query.product_status_key || this.$route.query.product_type_key || this.$route.query.client_id || this.client_id) {

      if (this.$route.query.client_id || this.client_id) {
        this.filters.client_id.value = this.$route.query.client_id || this.client_id
        this.clientStore.get(this.filters.client_id.value).then((result) => {
          this.clientSearchResults = [result.data.client]
        })
      }
      if (this.$route.query.status) {
        this.filters.status.value = this.$route.query.status
      }
      if (this.$route.query.name) {
        this.filters.name.value = this.$route.query.name
      }
      this.onFilter({filters: this.filters})
    } else {
      this.invoiceStore.index();
    }

    this.setupStore.getInvoiceStatuses();
    this.setTitle(this.$tc('invoices.invoice', 2).capitalize())
  },
  methods: {
    fetchClients(query) {
      if (query) {
        this.clientStore.search(query).then((result) => {
          this.clientSearchResults = result.data.clients
        })
      }
    },
    clone(invoiceId) {
      this.invoiceStore.clone(invoiceId).then(({data}) => {
        Helpers.emitter.emit('success', this.$t('invoices.clone.success'))
        this.$router.push({name: ROUTES.INVOICES.EDIT, params: {id: data.id}})
      })
    },
    openInvoice(invoice) {
      this.dunningStore.pdf(invoice)
    },
    onPage(event) {
      this.invoiceStore.serverOptions = event
      this.invoiceStore.index();
    },
    onSort(event) {
      this.invoiceStore.serverOptions.sortField = event.sortField
      this.invoiceStore.serverOptions.sortOrder = event.sortOrder
      this.invoiceStore.index();
    },
    onFilter(data) {
      this.$nextTick(() => {
        this.invoiceStore.serverOptions.filters = data.filters
        this.invoiceStore.index();
      })
    },
  },
  watch: {
    // not sure why this has been added - shouldn't be necessary
    // 'invoiceStore.serverOptions'() {
    //   this.invoiceStore.index()
    // }
  }
}
</script>

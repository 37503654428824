<template>
  <div class="card-body p-0">
    <iframe :src="autoSrc" class="w-100" style="height: 80vh"></iframe>
  </div>
</template>

<script>

export default {
  name: 'FilePreview',
  setup() {
    return {}
  },
  props: {
    src: {
      type: [Blob, String],
    }
  },
  created() {
  },
  computed: {
    autoSrc() {
      // download filename is just document.pdf
      // could be fixed using https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
      if (this.src) {
        return window.URL.createObjectURL(this.src);
      }
      return 'about:blank'
    }
  },
}
</script>

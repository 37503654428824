<template>
  <div class="container">

    <div class="row">
      <div class="mt-5 col-12 col-lg-6 mx-auto d-flex justify-content-center">
        <div class="d-flex flex-column" v-if="status === 'confirmed'">
          <h2 class="text-center mb-5">
            <i18n-t keypath="confirm.confirmed" scope="global" for="confirm.confirmed_link">
              <router-link :to="{name: ROUTES.LOGIN}">
                {{ $t('confirm.confirmed_link') }}
              </router-link>
            </i18n-t>
          </h2>
          <img class="img-fluid" :src="require('@/assets/confirm/confirmed.svg')" alt="confirm email graphics"/>
        </div>
        <div class="d-flex flex-column" v-if="status === 'denied'">
          <h2 class="text-center mb-5">
            <i18n-t keypath="confirm.denied" for="confirm.denied_link" scope="global">
              <a :href="`/${$route.params.locale}/contact`">
                {{ $t('confirm.denied_link') }}
              </a>
            </i18n-t>
          </h2>
          <img class="img-fluid" :src="require('@/assets/confirm/denied.svg')" alt="confirm email graphics"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/user";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ConfirmPage',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  setup() {
    const userStore = useUserStore()
    return {userStore}
  },
  data() {
    return {
      status: 'pending'
    }
  },
  created() {
    this.userStore.confirm(this.$route.params.id).then(() => {
      this.status = 'confirmed'
    }).catch(() => {
      this.status = 'denied'
    })
  },
}
</script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card mt-3">
        <div class="card-header">
          Filter
        </div>
        <div class="card-body row">
          <div class="col-12 col-lg-3">
            <DatePicker v-model="filters.daterange[0]" :locale="$route.params.locale" text-input :format-locale="de"
                        format="dd.MM.yyyy" :enable-time-picker="false"
                        @update:modelValue="setDateRange"/>
            <DatePicker v-model="filters.daterange[1]" :locale="$route.params.locale" text-input :format-locale="de"
                        format="dd.MM.yyyy" :enable-time-picker="false"
                        @update:modelValue="setDateRange"/>
          </div>
          <div class="col-12 col-lg-9">
           <span class="badge interactive me-2" v-for="(tag, index) in availableTags" :key="index"
                 @click="toggleTag(tag.name)"
                 :class="{'bg-dark': !filters.tags.includes(tag.name), 'bg-success': filters.tags.includes(tag.name)}">
              {{ tag.name }}
           </span>
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header d-flex justify-content-between">
          <span>{{ $t('accounts.analytics.heading') }}</span>
          <div class="me-auto ms-4">
          <span class="badge interactive me-2" v-for="(type, index) in ANALYTICS_CHART_TYPES" :key="index"
                @click="toggleChartTag(type)"
                :class="{'bg-dark': activeChartTag !== type, 'bg-success': activeChartTag === type}">
              {{ type }}
           </span>
          </div>
          <div class="">
           <span class="badge interactive me-2" v-for="(timerange, index) in timeranges" :key="index"
                 @click="toggleTimerange(timerange.key)"
                 :class="{'bg-dark': !timerange.active, 'bg-success': timerange.active}">
              {{ timerange.name }}
           </span>
          </div>
        </div>
        <div class="card-body">
          <v-chart class="chart" :option="getOption()"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useAccountTransactionTagStore} from "@/stores/account_transaction_tag";
import {useAccountStore} from "@/stores/account";
import ECharts from 'vue-echarts'
import dayjs from "dayjs";
import i18n from '@/i18n'
import {de} from 'date-fns/locale';
import {use} from "echarts/core"
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  LineChart,
  BarChart
} from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  MarkLineComponent
} from 'echarts/components';
import {ANALYTICS_CHART_TYPES} from "@/scripts/constants";
import Decimal from "decimal.js";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  MarkLineComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  BarChart,
  DataZoomComponent
])
export default {
  name: 'AccountAnalytics',
  components: {
    'v-chart': ECharts
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      accountTransactionTagStore: useAccountTransactionTagStore()
    }
  },
  data() {
    return {
      de,
      timeranges: [
        {name: i18n.global.t('accounts.analytics.week'), active: false, key: 'week'},
        {name: i18n.global.t('accounts.analytics.month'), active: true, key: 'month'},
        {name: i18n.global.t('accounts.analytics.quarter'), active: false, key: 'quarter'},
        {name: i18n.global.t('accounts.analytics.year'), active: false, key: 'year'},
      ],
      filters: {
        daterange: [dayjs().subtract(6, 'months').toDate(), dayjs().toDate()],
        timerange: 'month',
        tags: []
      },
      activeChartTag: ANALYTICS_CHART_TYPES.LINE
    }
  },
  created() {
    this.accountTransactionTagStore.index(null, 999)
    if (this.$route.query.start && this.$route.query.end) {
      this.filters.daterange[0] = dayjs(this.$route.query.start).toDate()
      this.filters.daterange[1] = dayjs(this.$route.query.end).toDate()
    }
    if (this.$route.query.timerange) {
      this.setTimeRange(this.$route.query.timerange)
    }
    if (this.$route.query.tags) {
      this.filters.tags = this.$route.query.tags.split(',')
    }
    this.getAnalyticsData()
  },
  computed: {
    ANALYTICS_CHART_TYPES() {
      return ANALYTICS_CHART_TYPES
    },
    availableTags() {
      return this.accountTransactionTagStore.tags
    },
    dataKeys() {
      const keys = [].concat(
          Object.keys(this.accountStore.analyticsData.revenues),
          Object.keys(this.accountStore.analyticsData.expenses)
      )
      const uniqKeys = [...new Set(keys)].sort()
      return uniqKeys
    },
    revenues() {
      return this.dataKeys.map(date => this.accountStore.analyticsData.revenues[date] || 0)
    },
    expenses() {
      return this.dataKeys.map(date => Math.abs(this.accountStore.analyticsData.expenses[date] || 0))
    },
    sums() {
      const sums = this.dataKeys.map(date => new Decimal(0)
          .plus(new Decimal(this.accountStore.analyticsData.revenues[date] || 0))
          .plus(new Decimal(this.accountStore.analyticsData.expenses[date] || 0))
          .toFixed(2)
      )
      return sums
    },
    description() {
      if (Object.keys(this.accountStore.analyticsData.revenues).length > Object.keys(this.accountStore.analyticsData.expenses).length) {
        return Object.keys(this.accountStore.analyticsData.revenues)
      } else {
        return Object.keys(this.accountStore.analyticsData.expenses)
      }
    }
  },
  methods: {
    sumObjectValues(object) {
      return Object.values(object).map(v => parseFloat(v)).reduce((partialSum, a) => partialSum + a, 0);
    },
    sumLegend(name) {
      let data
      switch (name) {
        case 'Einnahmen':
          data = this.revenues
          break
        case 'Ausgaben':
          data = this.expenses
          break
        case 'Summe':
          data = this.sums
          break
      }
      return `${name}: ${this.formatCurrency(this.sumObjectValues(data))}`
    },
    formatCurrencyWithoutFactionDigits(value) {
      return this.formatCurrency(value, {maximumFractionDigits: 0})
    },
    getOption() {
      let labelOptions = {show: false}
      if (this.activeChartTag === ANALYTICS_CHART_TYPES.BAR || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED) {
        labelOptions = {
          show: true,
          position: 'insideBottom',
          distance: 5,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: ({value}) => this.formatCurrency(value),
          fontSize: 14,
        }
      }
      const tooltipOptions = {
        valueFormatter: (value) => this.formatCurrency(value)
      }

      let xAxis = {
        type: 'category',
        data: this.description
      }

      if (this.activeChartTag === ANALYTICS_CHART_TYPES.LINE_MIRRORED || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED) {
        xAxis = [
          {
            type: 'category',
            // boundaryGap: false,
            axisLine: {onZero: true},
            data: this.description
          },
          {
            gridIndex: 1,
            type: 'category',
            // boundaryGap: false,
            axisLine: {onZero: true},
            data: this.description,
            position: 'top'
          }
        ]
      }
      let yAxis = {
        type: 'value',
        axisLabel: {
          formatter: this.formatCurrencyWithoutFactionDigits,
        }
      }
      if (this.activeChartTag === ANALYTICS_CHART_TYPES.LINE_MIRRORED || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED) {
        yAxis = [
          {
            name: 'Einnahmen',
            type: 'value',
            axisLabel: {
              formatter: this.formatCurrencyWithoutFactionDigits,
            },
          },
          {
            gridIndex: 1,
            name: 'Ausgaben',
            type: 'value',
            inverse: true
          }
        ]
      }

      let grid = {
        left: 60,
        right: 50,
      }
      if (this.activeChartTag === ANALYTICS_CHART_TYPES.LINE_MIRRORED || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED) {
        grid = [
          {
            left: 60,
            right: 50,
            height: '35%'
          },
          {
            left: 60,
            right: 50,
            top: '55%',
            height: '35%'
          }
        ]
      }

      const series = []
      series.push({
        markLine: {
          data: [{type: 'average', name: 'Avg'}],
          label: {
            formatter: ({value}) => this.formatCurrencyWithoutFactionDigits(value),
          },
        },
        name: 'Einnahmen',
        // barGap: 0,
        label: labelOptions,
        tooltip: tooltipOptions,
        data: this.revenues,
        color: [
          '#00844F'
        ],
        type: this.activeChartTag === ANALYTICS_CHART_TYPES.BAR || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED ? 'bar' : 'line',
      })
      series.push({
        markLine: {
          data: [{type: 'average', name: 'Avg'}],
          label: {
            formatter: ({value}) => this.formatCurrencyWithoutFactionDigits(value),
          },
        },
        xAxisIndex: this.activeChartTag === ANALYTICS_CHART_TYPES.LINE_MIRRORED || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED ? 1 : 0,
        yAxisIndex: this.activeChartTag === ANALYTICS_CHART_TYPES.LINE_MIRRORED || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED ? 1 : 0,
        name: 'Ausgaben',
        label: labelOptions,
        tooltip: tooltipOptions,
        data: this.expenses,
        formatter: ({value}) => this.formatCurrencyWithoutFactionDigits(value),
        color: [
          '#B81C13',
        ],
        type: this.activeChartTag === ANALYTICS_CHART_TYPES.BAR || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED ? 'bar' : 'line',
      })
      if ([ANALYTICS_CHART_TYPES.BAR, ANALYTICS_CHART_TYPES.LINE].includes(this.activeChartTag)) {
        series.push({
              markLine: {
                data: [{type: 'average', name: 'Avg'}],
                label: {
                  formatter: ({value}) => this.formatCurrencyWithoutFactionDigits(value),
                },
              },
              xAxisIndex: this.activeChartTag === ANALYTICS_CHART_TYPES.LINE_MIRRORED || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED ? 1 : 0,
              yAxisIndex: this.activeChartTag === ANALYTICS_CHART_TYPES.LINE_MIRRORED || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED ? 1 : 0,
              name: 'Summe',
              label: labelOptions,
              tooltip: tooltipOptions,
              data: this.sums,
              color: [
                '#5C5031', // mix red & green
              ],
              type: this.activeChartTag === ANALYTICS_CHART_TYPES.BAR || this.activeChartTag === ANALYTICS_CHART_TYPES.BAR_MIRRORED ? 'bar' : 'line',
            }
        )
      }

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#ccc',
              borderColor: '#aaa',
              borderWidth: 1,
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              color: '#222'
            }
          },
        },
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 30,
        //     end: 70,
        //     xAxisIndex: [0, 1]
        //   },
        //   {
        //     type: 'inside',
        //     realtime: true,
        //     start: 30,
        //     end: 70,
        //     xAxisIndex: [0, 1]
        //   }
        // ],

        legend: {
          top: '5%',
          left: 'center',
          formatter: (name) => this.sumLegend(name)
        },
        xAxis,
        yAxis,
        grid,
        series: series,
      }

      return option
    },
    setTimeRange(timerange) {
      this.timeranges.forEach(tr => {
        tr.active = tr.key === timerange
      })
      this.filters.timerange = timerange
    },
    setDateRange() {
      this.getAnalyticsData()
    },
    toggleTimerange(timerange) {
      this.setTimeRange(timerange)
      this.getAnalyticsData()
    },
    toggleChartTag(tag) {
      this.activeChartTag = tag
    },
    toggleTag(tag) {
      if (this.filters.tags.includes(tag)) {
        this.filters.tags = this.filters.tags.filter(t => t !== tag)
      } else {
        this.filters.tags.push(tag)
      }
      this.accountStore.analytics(this.$route.params.id, this.filters)
    },
    getAnalyticsData() {
      this.accountStore.analytics(this.$route.params.id, this.filters)
    }
  }
}
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>
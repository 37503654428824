<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('clients.edit.headline') }}</h2>
    </div>
    <ClientsForm @submitted="update"
                 ref="clientForm"
                 v-model:clientModel="client"
                 v-model:addressModel="address"
                 :contacts="contacts"
                 v-model:invoiceAddressModel="invoice_address"
                 v-model:legalAddressModel="legal_address"
                 v-if="dataLoaded"
                 :server-errors="serverErrors"
    />
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";
import ClientsForm from "./Form";
import {Helpers} from "@/helpers";
import {CLIENT_TAB_TYPES, ROUTES} from "@/scripts/constants";

export default {
  name: 'ClientsEdit',
  setup() {
    return {
      clientStore: useClientStore(),
    }
  },
  components: {
    ClientsForm
  },
  data() {
    return {
      dataLoaded: false,
      client: {
        name: '',
        email: '',
        kdnr: '',
        phones: [],
        fax: '',
        url: '',
        manet_url: '',
        client_type_id: null,
        client_status_id: null,
        invoice_delivery_method_id: null,
        invoice_mail_delivery_price: null,
        billing_company: '',
        billing_contact: '',
        billing_email: '',
        payment_info: '',
        payment_performance: null,
        subdomains: [],
        social_media_profiles: [],
      },
      address: {
        street: '',
        postcode: '',
        country_code: '',
        city: '',
      },
      invoice_address: {},
      legal_address: {},
      contacts: [],
      serverErrors: {},
    }
  },
  created() {
    this.clientStore.get(this.$route.params.id).then(({data}) => {
      this.mapClientData(data)
      this.setCurrentClient(data.client)
      this.dataLoaded = true
    })
  },
  methods: {
    mapClientData(data) {
      this.client = data.client;
      this.address = data.address;
      this.contacts = data.contacts;
      this.invoice_address = data.invoice_address;
      this.legal_address = data.legal_address;
    },
    update(formValues) {
      // console.log("EDIT->update", values)
      if (this.client.parent) {
        this.client.parent_id = this.client.parent.id
      }
      this.clientStore.update(this.$route.params.id, formValues.contacts, this.client, this.address, this.invoice_address, this.legal_address)
          .then(() => {
            Helpers.emitter.emit('success', this.$t('clients.edit.success'))
            this.clientStore.get(this.$route.params.id).then(({data}) => {
              this.serverErrors = []
              // this.mapClientData(data)
              this.$router.push({
                name: ROUTES.CLIENTS.SHOW,
                params: {id: data.id},
                query: {tab: CLIENT_TAB_TYPES.OVERVIEW}
              })
            })
          }).catch(({response}) => {
        if (response.data.errors) {
          this.serverErrors = response.data.errors
        }
        Helpers.emitter.emit('error', response.data.messages)
      })
    }
  }
}
</script>

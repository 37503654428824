<template>
  <BootstrapCard headline="Quick-Links">
    <div class="btn-group-vertical">
      <template v-if="showDefault">
        <router-link class="btn btn-sm btn-primary text-start"
                     :to="{name: ROUTES.CLIENT_EVENTS.INDEX, query: {filters: JSON.stringify({assigned_users: [userStore.user.id], resubmission_date_present: 1})}}">
          <i class="fas fa-list"/>
          {{ $t('client_events.filter_link_labels.my_resubmissions') }}
          <span class="badge text-bg-danger text-white me-1" v-if="resubmissionCount()?.danger">{{ resubmissionCount()?.danger }}</span>
          <span class="badge text-bg-warning text-white me-1" v-if="resubmissionCount()?.warning">{{ resubmissionCount()?.warning }}</span>
          <span class="badge text-bg-success text-white" v-if="resubmissionCount()?.info">{{ resubmissionCount()?.info }}</span>
        </router-link>
        <template v-for="(month, id) in months" :key="id">
          <router-link class="btn btn-sm btn-primary text-start"
                       :to="{name: ROUTES.CLIENT_EVENTS.INDEX, query: {filters: JSON.stringify({
                                  assigned_users: [userStore.user.id],
                                  resubmission_date_present: 1,
                                  resubmission_date_gte: month.from,
                                  resubmission_date_lte: month.to,
                         })}}">
            <i class="fas fa-list"/>
            {{ $t('client_events.filter_link_labels.my_resubmissions') }} - {{ month.label }}
            <span class="badge text-bg-danger text-white me-1" v-if="resubmissionCount(month)?.danger">{{ resubmissionCount(month)?.danger }}</span>
            <span class="badge text-bg-warning text-white me-1" v-if="resubmissionCount(month)?.warning">{{ resubmissionCount(month)?.warning }}</span>
            <span class="badge text-bg-success text-white" v-if="resubmissionCount(month)?.info">{{ resubmissionCount(month)?.info }}</span>
          </router-link>
        </template>

        <router-link class="btn btn-primary text-start"
                     :to="{name: ROUTES.PRODUCTS.INDEX, query: { product_status_key: 'in_progress' }}">
          Bericht: Liste Produkte in Arbeit
        </router-link>

        <router-link class="btn btn-primary text-start"
                     :to="{name: ROUTES.PRODUCT_BILLING_PERIODS.INDEX, query: {product_billing_status_key: 'billing', scheduled_billing_date_today: true }}">
          Buchhaltung: Liste heute abzurechnender Leistungszeiträume
        </router-link>
        <!--
      <router-link class="btn btn-primary text-start"
                   :to="{name: ROUTES.PRODUCT_BILLING_PERIODS.INDEX, query: {product_billing_status_key: 'billing', scheduled_billing_date_lte: today }}">
        Buchhaltung: Liste abzurechnender Leistungszeiträume vor {{ formatDate(today, 'short2') }}
      </router-link>
-->

        <router-link class="btn btn-primary text-start"
                     :to="{name: ROUTES.CLIENT_EVENTS.INDEX, query: { filters: JSON.stringify({event_types: [6], resubmission_date_present:1, resubmission_date_lte: techDateForBillingLink}) } }">
          Buchhaltung: Zu stellende Rechnungen / offene Rechnungen {{ niceDateForBillingLink }}
        </router-link>
      </template>

      <CsvActions/>
    </div>
  </BootstrapCard>
</template>
<script>
import BootstrapCard from "@/components/BootstrapCard.vue"
import CsvActions from "@/components/CsvActions.vue"
import {ROUTES} from "@/scripts/constants";
import dayjs from "dayjs";
import {Helpers} from "@/helpers";
import {useUserStore} from "@/stores/user";
import {useClientEventStore} from "@/stores/client_event";

export default {
  name: 'QuickLinks',
  components: {BootstrapCard, CsvActions},
  props: {
    showDefault: {
      type: Boolean,
    },
  },
  setup() {
    return {
      clientEventStore: useClientEventStore(),
      userStore: useUserStore(),
    }
  },
  created() {
    this.clientEventStore.getDueStats()
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    dateForBillingLink() {
      return dayjs().toDate()
    },
    months() {
      const daysInMonths = [
        dayjs(),
        dayjs().add('1', 'month'),
        dayjs().add('2', 'month'),
      ]

      return daysInMonths.map((d) => {
        return {
          label: d.locale(this.$route.params.locale).format('MMMM'),
          from: d.startOf('month').format('YYYY-MM-DD'),
          to: d.endOf('month').format('YYYY-MM-DD'),
        }
      })
    },
    niceDateForBillingLink() {
      return this.formatDate(this.dateForBillingLink, 'short2')
    },
    techDateForBillingLink() {
      return Helpers.formatDateTech(this.dateForBillingLink)
    },
  },
  methods: {
    resubmissionCount(month) {
      if (month) {
        const dateWithoutDay = month.from.replace(/-01$/, '')
        // console.log(dateWithoutDay)
        // console.log(JSON.stringify(this.clientEventStore.client_event_due_stats))
        return this.clientEventStore.client_event_due_stats[dateWithoutDay]
      } else {
        return this.clientEventStore.client_event_due_stats.current
      }
    }
  },
}
</script>
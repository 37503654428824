import {defineStore} from 'pinia'

export const useAccountProviderStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'account_provider',
    // a function that returns a fresh state
    state: () => ({

    }),
    // optional getters
    getters: {

    },
    // optional actions
    actions: {
        connect(accountProviderId, code = null) {
            return this.apiClient.post(`/account_providers/${accountProviderId}/connect`, {code})
        },
        disconnect(accountProviderId) {
            return this.apiClient.post(`/account_providers/${accountProviderId}/disconnect`)
        },
        requisitionNordigen(data) {
            return this.apiClient.post(`/account_providers/ng/requisition?institution_id=${data.id}`)
        },
        getBanks() {
            return this.apiClient.get(`/account_providers/ng/banks`)
        },
        getAccounts(ref) {
            return this.apiClient.get(`/account_providers/ng/accounts?ref=${ref}`)
        }
    },
})

<template>
  <div class="client-nav pb-3 ps-3 bg-white d-flex">
    <i class="fa-solid fa-arrow-turn-down-right"></i>
    <div class="d-flex ps-2 item-wrapper">
      <template v-if="clientId">
        <div class="item" :key="index" v-for="(tab, index) in tabs">
          <router-link class="px-2" :class="{ active: tab.active }"
                       :to="{ name: ROUTES.CLIENTS.SHOW, params: {id: clientId}, query:{tab: tab.type }}"
                       @click.prevent="switchTab(tab)">{{ tab.name }}
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";
import {useUserStore} from "@/stores/user";
import {CLIENT_TAB_TYPES, NANO_EVENT_TYPES, ROUTES} from "@/scripts/constants";
import {Helpers} from "@/helpers";

export default {
  name: 'ClientTabNav',
  components: {},
  props: {},
  setup() {
    return {
      clientStore: useClientStore(),
      userStore: useUserStore(),
    }
  },
  data() {
    return {
      tabs: [
        {type: CLIENT_TAB_TYPES.HISTORY, name: this.$t('clients.history'), active: false},
        {type: CLIENT_TAB_TYPES.OVERVIEW, name: this.$t('clients.overview'), active: false},
        {type: CLIENT_TAB_TYPES.CONTACTS, name: this.$t('clients.contacts'), active: false},
        {type: CLIENT_TAB_TYPES.CONTRACTS, name: this.$t('clients.contracts'), active: false},
        {type: CLIENT_TAB_TYPES.PRODUCTS, name: this.$t('clients.products'), active: false},
        {
          type: CLIENT_TAB_TYPES.PRODUCT_BILLING_PERIODS,
          name: this.$t('clients.product_billing_periods'),
          active: false
        },
        {type: CLIENT_TAB_TYPES.INVOICES, name: this.$t('clients.invoices'), active: false},
        {type: CLIENT_TAB_TYPES.DIRECT_DEBIT_MANDATES, name: this.$t('clients.direct_debit_mandates'), active: false},
      ]
    }
  },
  created() {
    if (this.$route.query?.tab) {
      this.switchTab(this.tabs.find((currentTab) => {
        return currentTab.type === this.$route.query.tab
      }), true)
    } else {
      if (this.$route.name === ROUTES.CLIENTS.SHOW) {
        this.switchTab(this.tabs[0], true)
      }
    }
    this.emitListenerUnbind = Helpers.emitter.on(NANO_EVENT_TYPES.CLIENT_CLICKED, () => {
      this.switchTab(this.tabs[0], true)
    })

  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    clientId() {
      return this.clientStore.client?.id
    },
  },
  beforeUnmount() {
    this.emitListenerUnbind()
  },
  methods: {
    async switchTab(tab, initial = false) {
      const tabQuery = {tab: tab.type}
      let mergeQuery = {}
      if (initial) {
        mergeQuery = this.$route.query
      }
      await this.$router.push({
        name: ROUTES.CLIENTS.SHOW,
        params: {id: this.clientId},
        query: {...mergeQuery, ...tabQuery}
      })
      this.updateTab(tab.type)
      this.setTitle(tab.name)
    },
    updateTab(tabKey) {
      // console.log('this.updateTab()', tabKey)
      this.clientStore.activeTab = {}
      this.tabs.forEach(currentTab => {
        currentTab.active = currentTab.type === tabKey;
        if (currentTab.active) {
          this.clientStore.activeTab = currentTab
        }
      });
    },
  },
  watch: {
    '$route.query.tab'(newValue) {
      // console.log("tab", newValue)
      this.updateTab(newValue)
    },
  }
}
</script>
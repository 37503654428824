import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
import {FilterMatchMode} from "primevue/api";
import {isNumber} from "@tiptap/vue-3";

export const useTransactionStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'transaction',
    // a function that returns a fresh state
    state: () => ({
        transactions: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 50,
            filters: {},
            sortBy: 'age',
            sortType: 'desc',
        }
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        getForAccount(accountId, csv) {
            return this.get(`/accounts/${accountId}`, csv)
        },
        getForInvoice(invoiceId) {
            return this.get(`/invoices/${invoiceId}`)
        },
        async get(basePath,  csv = false) {
            this.loading = true
            let params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }
            let filters = {}
            if(this.serverOptions.filters.tags?.value) {
                filters['tags'] = this.serverOptions.filters.tags.value.join(',')
            } else {
                await Helpers.removeQueryFromUrl("tags")
            }
            if(this.serverOptions.filters.remittance_info?.value) {
                filters['remittance_info'] = this.serverOptions.filters.remittance_info.value
            } else {
                await Helpers.removeQueryFromUrl("remittance_info")
            }
            if(this.serverOptions.filters.counterparty_name?.value) {
                filters['counterparty_name'] = this.serverOptions.filters.counterparty_name.value
            } else {
                await Helpers.removeQueryFromUrl("counterparty_name")
            }
            if (isNumber(this.serverOptions.filters.amount?.value) && this.serverOptions.filters.amount.matchMode === FilterMatchMode.EQUALS) {
                filters['amount'] = this.serverOptions.filters.amount.value
            } else {
                await Helpers.removeQueryFromUrl("amount")
            }
            if (isNumber(this.serverOptions.filters.amount?.value) && this.serverOptions.filters.amount.matchMode === FilterMatchMode.LESS_THAN_OR_EQUAL_TO) {
                filters['amount_lte'] = this.serverOptions.filters.amount.value
            } else {
                await Helpers.removeQueryFromUrl("amount_lte")
            }
            if (isNumber(this.serverOptions.filters.amount?.value) && this.serverOptions.filters.amount.matchMode === FilterMatchMode.GREATER_THAN_OR_EQUAL_TO) {
                console.log('GTE')
                filters['amount_gte'] = this.serverOptions.filters.amount.value
            } else {
                await Helpers.removeQueryFromUrl("amount_gte")
            }
            if(typeof this.serverOptions.filters.audited !== "undefined" && this.serverOptions.filters.audited.value !== null) {
                filters['audited'] = this.serverOptions.filters.audited.value
            }  else {
                await Helpers.removeQueryFromUrl("audited")
            }
            if(this.serverOptions.filters.start?.value) {
                filters['start'] = this.serverOptions.filters.start.value
            } else {
                await Helpers.removeQueryFromUrl("start")
            }
            if(this.serverOptions.filters.end?.value) {
                filters['end'] = this.serverOptions.filters.end.value
            } else {
                await Helpers.removeQueryFromUrl("end")
            }
            Helpers.addQueryToParams(filters, params)
            await Helpers.setUrlQuery(filters)

            let responseType = "json"
            if(csv) {
                params["csv"] = true
                responseType = "blob"
            }
            return this.apiClient.get(`${basePath}/account_transactions`, {
                params,
                responseType
            }).then(({data}) => {
                if(csv) {
                    const url = URL.createObjectURL(data);
                    // window.open(url)
                    window.location.href = url
                } else {
                    this.transactions = data.transactions
                    this.itemLength = data.itemLength
                }
                this.loading = false
            })
        },
        update(accountId, transactionId, data) {
            return this.apiClient.put(`/accounts/${accountId}/account_transactions/${transactionId}`, {transaction: data})
        },
    },
})

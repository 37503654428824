import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
import {saveAs} from 'file-saver';

export const useClientEventFileStore = defineStore({
    id: 'clientEventStore',
    state: () => ({
        previewData: null,
    }),
    getters: {},
    actions: {
        preview(id, version, attachment_name) {
            return this.apiClient.get(`/client_events/${id}/file`, {params: {version, attachment_name}}).then(({data}) => {
                console.log(`preview for ${data.type}`)
                if (data.type === 'application/pdf' || data.type.match(/^image\//)) {
                    this.previewData = Helpers.base64ToFile(data, data.filename)
                } else {
                    saveAs(Helpers.base64ToBlob(data), data.filename);
                    this.previewData = null
                }
            }).catch((e) => {
                this.previewData = new Blob([e.message], {
                    type: 'text/plain'
                });
            })
        },
    },
})

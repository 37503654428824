import {createPinia} from 'pinia'
import {useRouter} from 'vue-router';
import {ApiClient} from "@/scripts/api";
import {API_URL} from "@/scripts/constants";

function attachApi() {
    return {apiClient: new ApiClient(API_URL)}
}

export const pinia = createPinia()
pinia.use(attachApi)
pinia.use(({store}) => {
    store.$router = useRouter()
});
import {defineStore} from 'pinia'

export const useAllUsersStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'allUsers',
    // a function that returns a fresh state
    state: () => ({
        users: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 1,
            rowsPerPage: 250,
        }
    }),
    // optional getters
    getters: {
        activeUsers() {
            return this.users.filter((u) => u.active)
        }
    },
    // optional actions
    actions: {
        index() {
            this.loading = true
            return this.apiClient.get(`/users`, {
                params: {
                    page: this.serverOptions.page,
                    size: this.serverOptions.rowsPerPage
                }
            }).then(({data}) => {
                this.users = data.users
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        autoLoad() {
            if (this.users.length || this.loading) {
                return
            }
            this.index()
        },
        user(id) {
            return this.users.find((u) => u.id === id)
        },
    },
})

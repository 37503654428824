<template>
  <div class="row">
    <div class="col-12">
      <h2>{{$t('accounts.import_transactions')}}</h2>
    </div>
    <div class="col-12">
      <div class="row">
        <h3>Datev</h3>
        <DropZone @upload="upload"/>
      </div>
    </div>
  </div>
</template>

<script>
import {useAccountStore} from "@/stores/account";
import {useOrganizationStore} from "@/stores/organization";
import DropZone from "@/components/DropZone.vue";
export default {
  name: 'AccountsImportTransactions',
  components: {
    DropZone
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      organizationStore: useOrganizationStore(),
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },

  created() {
  },
  methods: {
    upload(files) {
      files.forEach((assetData) => {
        this.accountStore.importTransactions(assetData, this.$route.params.id)
      })
    }
  },
  watch: {
  }
}
</script>

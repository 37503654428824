<template>
  <div class="row">
    <h2>Chats</h2>
    <div class="col-12">
      <span class="new-message cursor-pointer" @click.stop="hideContacts = !hideContacts">
        <i class="fa-solid fa-message-plus"></i>
      </span>
    </div>
    <div class="col-4 left-side p-0">
      <div class="contacts" :class="{'d-none': hideContacts}">
        <div class="col-12">
          <div class="d-flex p-3 justify-content-center align-items-center">
            <span class="close-contacts me-2 cursor-pointer" @click.stop="hideContacts = true">
              <i class="fas fa-arrow-left"></i>
            </span>
            <input class="search-input" type="text" v-on:keyup.enter="searchContacts"/>
          </div>
          <ul>
            <li class="d-flex flex-column" v-for="(contact, contactKey) in contactSearchResults" :key="contactKey">
              <span class="contact-name">
                {{contact.first_name}} {{contact.last_name}}
              </span>
              <span class="contact-mobile-phone" v-if="contact.phone_mobile" @click="newMessage(contact, 'phone_mobile')">
                <i class="fa-solid fa-message-plus"></i> {{contact.phone_mobile}}
              </span>
              <span class="contact-phone" v-if="contact.phone" @click="newMessage(contact, 'phone')">
                <i class="fa-solid fa-message-plus"></i> {{contact.phone}}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="conversations">
        <div class="list-group-item contact d-flex flex-column justify-content-between conversation" :class="{'active': conversation.active, 'has_unread': conversation.has_unread_messages}" :key="conversation.id" v-for="conversation in conversations" @click.stop="loadConversation(conversation.id)">
          <div class="top-row d-flex justify-content-between">
            <span class="customer-phone-number">
              {{conversation.customer_phone_number}}
            </span>
            <span class="date">
              {{formatDate(conversation.messages[0]?.created_at, 'short2')}}
            </span>
          </div>
          <div class="bottom-row" v-if="conversation.messages[conversation.messages.length - 1].message && templates.length">
            <span v-if="conversation.messages[conversation.messages.length - 1].message_type === 'text'">
             {{conversation.messages[conversation.messages.length - 1].message.truncate(50)}}
            </span>
            <span v-if="conversation.messages[conversation.messages.length - 1].message_type === 'template'" v-html="parseTemplate(conversation.messages[conversation.messages.length - 1].message).truncate(50)">
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 right-side p-0" v-if="!currentRecipient.isE || currentConversation">
      <div class="header p-3">
        {{currentRecipient?.name}}
        {{currentRecipient?.number || currentConversation?.customer_phone_number}}
        <div class="ms-2 position-relative d-inline-block">
          <input type="text" v-model="searchInput" :placeholder="$t('conversations.search_client')" v-on:keyup.enter="searchClient" />
          <div class="search-results" v-if="clientSearchResults.length > 0">
            <span class="search-result" v-for="sResult in clientSearchResults" :key="sResult.id" @click.stop="setConversationClientId(sResult)">
              {{sResult.kdnr}} - {{sResult.name}}
            </span>
          </div>
        </div>
        <div class="ms-2 d-inline-block" v-if="currentConversation.has_unread_messages">
          <button class="btn btn-success" type="button" @click="setConversationRead">
            {{$t('conversations.set_read')}}
          </button>
        </div>
      </div>
      <div class="chat-history" v-if="currentConversation">
        <div class="message" :class="{sent: message.sent, received: !message.sent}" v-for="message in currentConversation.messages" :key="message.id">
          <span v-if="message.message_type === 'text'">
             {{message.message}}
          </span>
          <span v-if="message.message_type === 'template'" v-html="parseTemplate(message.message)">
          </span>
          <div v-if="message.message_type === 'image'">
            <img style="object-fit: contain" class="img-fluid" :src="message.file_url" />
            <div class="mt-2" v-if="message.message">
              {{message.message}}
            </div>
          </div>
          <div v-if="message.message_type === 'document'">
            <VuePdfEmbed :page="1" :source="message.file_url"/>
            <div class="mt-2" v-if="message.message">
              {{message.message}}
            </div>
            <a target="_blank" :href="message.file_url">
              {{$t('invoices.download')}}
            </a>
          </div>
          <span class="metadata">
            <span class="time">
              {{formatDate(message.created_at, 'short2')}} {{formatDate(message.created_at, 'time')}}
            </span>
            <span class="status ms-2" v-if="message.status === 'failed'">
              <i class="fa-solid fa-triangle-exclamation error-icon"></i><span class="error-message">{{ dig(message.api_response, 'error_data')?.details || dig(message.api_response, 'message') }}</span>
            </span>
          </span>
        </div>
      </div>
      <div v-if="!currentRecipient.isE" class="conversation-compose d-flex p-3">
        <input class="input-msg" type="text" v-model="inputMsg" v-on:keyup.enter="sendMessage"/>
        <button type="button" :title="$t('conversations.template.select')" class="ms-2 templates toggle-template-list">
            <i class="fa-solid fa-shelves"></i>
            <div class="template-list flex-column align-items-baseline">
              <h4>Templates</h4>
              <div class="d-flex align-items-center template" v-for="(template, templateKey) in templates" :key="templateKey" @click.stop="configureTemplate(template)">
                <i class="fa-light fa-hand-point-right me-2"></i><span>{{template.name}}</span>
              </div>
            </div>
        </button>
        <button type="button" class="send" @click.stop="sendMessage">
          <div class="circle">
            <i class="fas fa-paper-plane-top"></i>
          </div>
        </button>
      </div>
    </div>
  </div>

  <Dialog v-if="showTemplateConfigureDialog" v-model:visible="showTemplateConfigureDialog" :style="{width: '40em'}" :modal="true" class="p-fluid">
    <template #header>
      <h3>{{ $t('conversations.template.configure') }}</h3>
    </template>
    <div class="template-content">
      <div v-for="(component, componentKey) in currentTemplate.components_json" :key="componentKey">
        <b>{{component.type}}</b>
        <p>
          {{component.text}}
        </p>
        <div class="template-replace" v-if="component.example && component.example.body_text">
          <template v-for="(example, exampleKey) in component.example.body_text[0]" :key="exampleKey">
            <input class="form-control mb-2" type="text" v-model="currentTemplatePlaceholders[exampleKey]" :placeholder="example">
          </template>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="sendTemplate">{{
            $t('conversations.template.send')
          }}
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {useContactStore} from "@/stores/contact";
import {useMessageStore} from "@/stores/message";
import {useConversationStore} from "@/stores/conversations";
import {useClientStore} from "@/stores/client";
import Dialog from "primevue/dialog";
import {Helpers} from "@/helpers";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: 'MessagesIndex',
  components: {
    Dialog,
    VuePdfEmbed
  },
  setup() {
    return {
      contactStore: useContactStore(),
      messageStore: useMessageStore(),
      clientStore: useClientStore(),
      conversationStore: useConversationStore(),
    }
  },
  data() {
    return {
      hideContacts: true,
      contactSearchResults: [],
      currentRecipient: {},
      currentConversation: null,
      conversations: [],
      inputMsg: '',
      templates: [],
      clientSearchResults: [],
      searchInput: '',
      showTemplateConfigureDialog: false,
      currentTemplate: {},
      currentTemplatePlaceholders: []
    }
  },
  created() {
    this.conversationStore.index().then((result) => {
      this.conversations = result.data.conversations
    })
    this.messageStore.templates().then((response) => {
      this.templates = response.data.templates.records
    })
  },
  mounted() {
    // this.$cable.subscribe({
    //   channel: "DefaultChannel",
    //   // room: "public",
    // });
  },
  channels: {
    DefaultChannel: {
      connected() {
        // console.log("ws connected messages")
      },
      rejected() {
        // console.log("ws rejected messages")
      },
      received(data) {
        // console.log("index", data)
        if (data.event === 'updated' && data.model === 'Conversation') {
          this.loadConversation(data.id)
        }
      },
      disconnected() {
        // console.log("ws disconnected")
      },
    },
  },
  computed: {
  },
  methods: {
    parseTemplate(msgJson) {
      const msg = JSON.parse(msgJson)
      const msgTemplate = this.templates.find((template) => {
        return template.name === msg.template
      })
      const replacePlaceholders = (text, values) => {
        return text.replace(/{{(\d+)}}/g, function(match, number) {
          const index = parseInt(number, 10) - 1; // Platzhalter {{1}} -> Array index 0
          return values[index] && values[index].type === 'text' ? values[index].text : match;
        });
      }
      let composedMessage = ''
      msgTemplate.components_json.forEach(component => {
        if(component.type === 'HEADER') {
          composedMessage += `<b>${component.text}</b><br>`
        }
        else if(component.type === 'BODY') {
          composedMessage += `${replacePlaceholders(component.text, msg.components[0].parameters)}`
        } else {
          composedMessage += `${component.text}`
        }
      })
      return composedMessage
    },
    searchClient(e) {
      this.clientStore.search(e.target.value).then((result) => {
        this.clientSearchResults = result.data.clients
      })
    },
    searchContacts(e) {
      this.contactStore.search(e.target.value).then((result) => {
        this.contactSearchResults = result.data.contacts
      })
    },
    newMessage(contact, phoneKey) {
      this.hideContacts = true
      this.currentConversation = null
      this.currentRecipient.recipient_number = contact[phoneKey]
      if(contact.client_ids.length === 1) {
        this.currentRecipient.client_id = contact.client_ids[0]
      }
      this.currentRecipient.name = `${contact.first_name} ${contact.last_name}`
    },
    sendMessage() {
      this.currentRecipient.message = this.inputMsg
      this.messageStore.create(this.currentRecipient).then(() => {
        if(this.currentConversation.id) {
          this.loadConversation(this.currentConversation.id)
          this.inputMsg = ''
        } else {
          this.conversationStore.index().then((result) => {
            this.conversations = result.data.conversations
          })
        }
      })
    },
    setConversationClientId(client) {
      this.conversationStore.update(this.currentConversation.id, {client_id: client.id}).then((result) => {
        this.searchInput = `${client.kdnr} - ${client.name}`
        this.currentConversation.client_id = client.id
        this.clientSearchResults = []
        this.setCurrentClientById(client.id)
        Helpers.emitter.emit('success', result.data.message)
      })
    },
    setConversationRead() {
      this.conversationStore.update(this.currentConversation.id, {has_unread_messages: false}).then((result) => {
        Helpers.emitter.emit('success', result.data.message)
        this.currentConversation.has_unread_messages = false
      })
    },
    sendTemplate() {
      const components = [
        {
          type: 'body',
          parameters: []
        }
      ]
      if(this.currentTemplatePlaceholders.length > 1) {
        this.currentTemplatePlaceholders.forEach(placeholder => {
          components[0].parameters.push({
            type: "text",
            text: placeholder
          })
        })
      }
      const params = {
        name: this.currentTemplate.name,
        recipient_number: this.currentRecipient.recipient_number,
        components
      }
      this.messageStore.sendTemplate(params).then((response) => {
        //TODO
        console.log(response)
      })
    },
    configureTemplate(template) {
      this.currentTemplate = template
      this.showTemplateConfigureDialog = true
    },
    loadConversation(id) {
      this.conversationStore.show(id).then((result) => {
        this.currentConversation = result.data
        this.conversations.forEach((conversation) => {
          conversation.active = conversation.id === this.currentConversation.id;
        })
        this.currentRecipient.recipient_number = result.data.customer_phone_number
        if(this.currentConversation.client_id) {
          this.setCurrentClientById(this.currentConversation.client_id, (client) => {
            this.searchInput = `${client.kdnr} - ${client.name}`
          })
        } else {
          this.searchInput = ''
          this.resetClient()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.right-side {
  background: url('~@/assets/whatsapp/bg-bright.png');
  height: calc(100% - 12px);
  position: relative;
  padding: 0 1rem 1rem 1rem;
  .search-results {
    position: absolute;
    top: 37px;
    left: 0;
    background: white;
    max-height: 200px;
    overflow-y: scroll;
    padding: 10px;
    z-index: 1;
    .search-result {
      display: inline-block;
      &:hover {
        cursor: pointer;
        background: #f0f2f5;
      }
    }
  }
  .header {
    background: #f0f2f5;
    font-size: 1.25rem;
  }
  .chat-history {
    height: calc(100vh - 68px);
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;
    .message {
      color: #000;
      clear: both;
      line-height: 18px;
      font-size: 15px;
      padding: 8px;
      position: relative;
      margin: 8px 0;
      max-width: 85%;
      word-wrap: break-word;
      .metadata {
        display: inline-block;
        float: right;
        padding: 0 0 0 7px;
        position: relative;
        bottom: -4px;
        .time {
          color: rgba(0, 0, 0, .45);
          font-size: 11px;
          display: inline-block;
        }
        .status {
          color: red;
          position: relative;
          .error-message {
            display: none;
            position: absolute;
            background: white;
            color: grey;
            min-width: 250px;
            top: 5px;
            left: 15px;
            padding: 10px;
          }
          &:hover {
            cursor: pointer;
            .error-message {
              display: block;
            }
          }

        }
      }
      &.sent {
        background: #e1ffc7;
        border-radius: 5px 0px 5px 5px;
        float: right;
        &:after {
          border-width: 0px 0 10px 10px;
          border-color: transparent transparent transparent #e1ffc7;
          top: 0;
          right: -10px;
        }
      }
      &.received {
        background: #fff;
        border-radius: 0px 5px 5px 5px;
        float: left;
        white-space: pre-line;
        &:after {
          border-width: 0px 10px 10px 0;
          border-color: transparent #fff transparent transparent;
          top: 0;
          left: -10px;
        }
      }
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
      }
    }
  }
  .conversation-compose {
    .input-msg {
      border: 0;
      flex: 1 1 auto;
      font-size: 16px;
      margin: 0;
      outline: none;
      min-width: 50px;
      border-radius: 5px;
      position: relative;
      padding: 10px 15px;
    }
    .templates {
      background: white;
      border: 0;
      padding: 0 15px;
    }
    .send {
      background: transparent;
      border: 0;
      cursor: pointer;
      flex: 0 0 auto;
      margin-left: 8px;
      margin-right: 8px;
      padding: 0;
      position: relative;
      outline: none;
      .circle {
        background: #008a7c;
        border-radius: 50%;
        color: #fff;
        position: relative;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .toggle-template-list {
      position: relative;
      .template-list {
        display: none;
        bottom: 45px;
        left: -120%;
        position: absolute;
        background: white;
        border-radius: 5px;
        padding: 0.5rem 0.75rem;
        .template {
          &:hover {
            background: #f0f2f5;
          }
        }
      }
      &:hover {
        .template-list {
          display: flex;
        }
      }
    }
  }
}
.left-side {
  background: white;
  position: relative;
  .contacts {
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    .search-input {
      border: 0;
      flex: 1 1 auto;
      font-size: 16px;
      outline: none;
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      position: relative;
      background-color: #f0f2f5;
    }
    ul {
      list-style-type: none;
      margin: 0 10px;
      padding: 0;
      li {
        padding: 10px;
        border-bottom: 1px solid grey;
        .contact-phone, .contact-mobile-phone {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .conversations {
    .conversation {
      padding: 1rem;
      border-bottom: 1px solid #f0f2f5;
      &.has_unread {
        font-weight: bold;
      }
      &.active {
        background: #f0f2f5;
      }
      &:hover {
        cursor:pointer;
        background: #f0f2f5;
      }
      .customer-phone-number {
        font-size: 1.2rem;
      }
      .last-message {
        font-style: italic;
        font-size: 1rem;
      }
    }
  }
}
.template-content {
  p {
    white-space: pre-line;
  }
}
</style>
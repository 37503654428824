import JwtDecode from 'jwt-decode'
import {AUTH_TOKEN_NAME, PERMISSIONS} from "@/scripts/constants";

export default class User {
    static from(token) {
        try {
            const obj = JwtDecode(token)
            const user = new User(obj)
            if (user.expired()) {
                console.warn("token expired!")
                this.prototype.logout()
                // return User.loggedOutUser()
            }
            return user;
        } catch (_) { // token parse error
            this.prototype.logout()
            // return User.loggedOutUser()
        }
    }

    // static loggedOutUser() {
    //     return new User({}) // loggedOut user
    // }

    expired() {
        // console.log(this.expiration * 1000, (new Date()).getTime(), this.expiration * 1000 < (new Date()).getTime())
        return this.expiration * 1000 < (new Date()).getTime();
    }

    expiresSoon() {
        // console.log(this.expiration * 1000, (new Date()).getTime(), this.expiration * 1000 < (new Date()).getTime())
        const buffer = 60 * 60 * 1000 // 1 h
        return this.expiration * 1000 - buffer < (new Date()).getTime();
    }

    constructor({user_id, name, expiration, refresh_token, organization, permissions, avatar_url, special_role}) {
        this.id = user_id // eslint-disable-line camelcase
        this.name = name
        this.expiration = expiration
        this.refreshToken = refresh_token
        this.organization = organization || {}
        this.permissions = permissions
        this.permissionNames = permissions.map((p) => p.name)
        this.isAdmin = this.permissionNames.includes(PERMISSIONS.ADMIN.ADMIN)
        this.avatar_url = avatar_url
        this._special_role = special_role
        //use comment below to debug permissions
        // this.permissions = [
        //     {id: 1, name: 'users.view'}
        // ]
    }

    logout() {
        localStorage.removeItem(AUTH_TOKEN_NAME)
        // return User.loggedOutUser()
    }

    hasPermission(permission) {
        return this.permissionNames.includes(permission) || this.isAdmin
    }

    get specialRole() {
        return this._special_role;
    }
}
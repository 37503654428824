<template>
  <div class="timeline-comment-box">
    <UserAvatar :id="userStore.user.id" class="user"/>
    <div class="input">
      <Form @submit="storeEvent" v-slot="{ setValues, errors }" :validation-schema="schema"
            :initial-values="initialValues">
        <div class="d-flex flex-wrap">
          <div class="me-2 mb-2 mb-lg-0">
            <ClientEventTypeSelect
                :item-key="itemKey"
                :event-types="availableClientEventTypes"
                v-model="new_client_event_type_id"
            />
          </div>
          <div class="flex-grow-1">
            <Field id="title" name="title" class="form-control" :placeholder="$t('client_events.title')"/>
            <div v-if="errors.title" class="invalid-feedback">
              {{ errors.title }}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-lg-9">
            <div class="h-100">
              <Field id="info" name="info" as="textarea" class="form-control "
                     :class="{'is-invalid': errors.info, 'h-100-no-important': !errors.info}"
                     :placeholder="$t(mode === 'append' ? 'client_events.append_placeholder' : 'client_events.update_placeholder')"
              />
              <div v-if="errors.info" class="invalid-feedback">
                {{ errors.info }}
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <Field name="file" v-slot="{ handleChange, handleBlur }">
              <DropZone ref="dropzone" :show-preview="false" :multiple="false" :show-buttons="false"
                        text=""
                        :accept-files="['.eml', '.jpg', '.png', '.pdf']"
                        @change="handleChange"
                        @onDrop="handleChange"
                        @blur="handleBlur"
              />
            </Field>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-12 order-md-2 col-xs-10 col-lg-6 mt-2">
            <Field id="ticket_link" name="ticket_link" class="form-control"
                   :placeholder="$t('client_events.ticket_link_placeholder')"/>
            <div v-if="errors.ticket_link" class="invalid-feedback">
              {{ errors.ticket_link }}
            </div>
          </div>
          <div class="col-12 order-md-1 col-lg-6 mt-2">
            <button class="btn" :class="mode==='update' ? 'btn-warning' : 'btn-primary'" type="submit">
              {{ $t('client_events.append_submit') }}
            </button>
            <template v-if="mode!=='update' && parentEvent">
              <template v-if="parentEvent.resubmission_date">
                <button class="btn btn-secondary ms-2"
                        type="submit"
                        @click="setValues({resubmission_date: '', assigned_user_id: ''})">
                  {{ $t('client_events.append_submit_with_close') }}
                </button>
              </template>
            </template>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {useSetupStore} from "@/stores/setup";
import {useUserStore} from "@/stores/user";
import UserAvatar from "@/components/UserAvatar.vue";
import {useClientEventStore} from "@/stores/client_event";
import {
  CLIENT_EVENT_TYPES_CREATE,
  CLIENT_EVENT_TYPES_APPEND,
  CLIENT_EVENT_TYPES_APPEND_INVOICE,
  NANO_EVENT_TYPES,
  ROUTES
} from "@/scripts/constants";
import {Helpers} from "@/helpers";
import ClientEventTypeSelect from "@/components/ClientEventTypeSelect.vue";
import DropZone from "@/components/DropZone.vue";
import {Form, Field} from "vee-validate";
import * as Yup from "yup";
import {useClientStore} from "@/stores/client";

export default {
  name: 'ClientEventForm',
  components: {
    DropZone,
    Form,
    Field,
    ClientEventTypeSelect,
    UserAvatar,
  },
  props: {
    parentEvent: {
      type: Object,
    },
    eventableType: {
      type: String,
    },
    eventableId: {
      type: Number,
    },
    initialValues: {
      type: Object,
    },
    mode: {
      type: String,
      default: "append"
    },
    itemKey: {
      type: Number,
    },
  },
  setup() {
    return {
      setupStore: useSetupStore(),
      userStore: useUserStore(),
      clientStore: useClientStore(),
      clientEventStore: useClientEventStore(),
    }
  },
  data() {
    return {
      new_client_event_type_id: '',
      schema: Yup.object().shape({
        // FIXME: require info OR file
        // info: Yup.string().required(this.$t('validation.required')),
        // file: Yup.mixed().required('validation.required'), // hat noch nicht funktioniert
      }),
    }
  },
  created() {
    this.setupStore.getClientEventTypes()
  },
  computed: {
    availableClientEventTypes() {
      const list = this.setupStore.clientEventTypes || [];
      const allowList = this.createMode ? CLIENT_EVENT_TYPES_CREATE :
          (this.parentEvent.eventable_type === 'Invoice' ? CLIENT_EVENT_TYPES_APPEND_INVOICE : CLIENT_EVENT_TYPES_APPEND)
      let currentClientEventTypeId = null
      if (this.initialValues && !allowList.includes(this.initialValues.client_event_type_id)) {
        currentClientEventTypeId = this.initialValues.client_event_type_id
      }
      return list.filter((type) => type.id === currentClientEventTypeId || allowList.includes(type.key))
    },
    createMode() {
      return !this.parentEvent?.id
    }
  },
  methods: {
    storeEvent(values, sthg) {
      this.createMode ? this.addEvent(values) : this.updateEvent(values, sthg)
    },
    addEvent(values) {
      const client_event = {
        client_id: this.clientStore.client?.id,
        eventable_type: this.eventableType,
        eventable_id: this.eventableId,
        client_event_type_id: this.new_client_event_type_id || this.availableClientEventTypes[0].id,
        title: values.title,
        info: values.info,
        ticket_link: values.ticket_link,
      }
      if (values.file) {
        client_event.file = values.file
      }
      this.clientEventStore.store(client_event).then(({data}) => {
        this.new_text = ''
        this.new_client_event_type_id = null
        Helpers.emitter.emit('success', this.$t('client_events.created'))
        this.$router.push({name: ROUTES.CLIENT_EVENTS.SHOW, params: {id: data.id}})
      })
    },
    updateEvent(values, {resetForm}) {
      const eventTypeId = values.client_event_type_id || this.new_client_event_type_id || this.availableClientEventTypes[0].id
      const clientEventPayload = {
        client_event_type_id: eventTypeId,
        info: values.info,
        title: values.title,
        ticket_link: values.ticket_link,
      }
      if (values.file) {
        clientEventPayload.file = values.file
      }
      let payload = {}
      switch (this.mode) {
        case 'append':
          payload = {
            proceed_events: [],
            children_attributes: [
              clientEventPayload,
            ]
          }
          break
        case 'update':
          payload = clientEventPayload
          break
      }
      payload = {
        resubmission_date: values.resubmission_date,
        assigned_user_id: values.assigned_user_id,
        ...payload,
      }
      // console.log(values)
      // console.log(payload, Helpers, NANO_EVENT_TYPES)
      this.clientEventStore.update(this.parentEvent.id, payload).then(() => {
        this.new_client_event_type_id = null
        this.$refs.dropzone.reset()
        resetForm()
        Helpers.emitter.emit('success', this.$t('client_events.appended'))
        Helpers.emitter.emit(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED)
      })
    }
  },
}
</script>

<style lang="scss">
.timeline-comment-box {
  background: #f2f3f4;
  padding: 20px 25px;

  .user {
    float: left;
    overflow: hidden;
    border-radius: 30px;
  }

  .user + .input {
    margin-left: 44px;
  }

  .h-100-no-important {
    height: 100%;
  }
}
</style>
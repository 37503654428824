<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('products.show') }}</h2>

      <div class="row my-3" v-if="product">
        <div class="col-12 col-lg-6">
          <ShowRow :label="$t('products.parent_product_select_prompt')" class="opacity-50">
            <template v-if="product.parent_id">
              {{ product.parent_name }} {{ product.parent_id }}
              <router-link :to="{name: ROUTES.PRODUCTS.SHOW, params: {product_id: product.parent_id }}"
                           class="btn btn-primary btn-sm">
                <i class="fas fa-eye fa-fw"/>
              </router-link>
            </template>
            <template v-else>
              -
            </template>
          </ShowRow>
          <ShowRow :label="$t('products.product_type')"
                   :value="productType?.name"/>
          <ShowRow :label="$t('products.name')" :value="product?.name"/>
          <ShowRow :label="$t('products.description')" :value="product?.description"/>
          <ShowRow :label="$t('products.setup_price')">
            {{ formatCurrency(product?.setup_price) }}
            <small class="d-inline-block ms-3"
                   :title="formatPercentage(product?.vat)"><small>{{ formatPercentage(product?.vat) }}
              {{ $t('products.vat') }}</small></small>
          </ShowRow>
          <ShowRow :label="$t('products.product_duration')"
                   :value="setupStore.getProductDurationById(product?.product_duration_id)?.name"/>
          <ShowRow :label="$t('products.product_renewal_setting')"
                   :value="setupStore.getProductRenewalSettingById(product?.product_renewal_setting_id)?.name"/>
          <ShowRow :label="$t('products.price')">
            {{ formatCurrency(product?.price) }}
            <template v-if="Number(product?.price) !== 0">
              <small class="d-inline-block ms-3">
                ({{ setupStore.getProductDurationById(product?.product_duration_id)?.name }})
              </small>
              <small class="d-inline-block ms-3"
                     :title="formatPercentage(product?.vat)"><small>{{ formatPercentage(product?.vat) }}
                {{ $t('products.vat') }}</small></small>
            </template>
          </ShowRow>
          <ShowRow :label="$t('products.product_payment_cycle')"
                   :value="setupStore.getProductPaymentCycleById(product?.product_payment_cycle_id)?.name"/>
          <ShowRow :label="$t('products.zoho_infos')">
            <CollapsedInfos :data="product?.zoho_infos"/>
          </ShowRow>
          <hr>
          <ShowRow :label="$t('products.product_status')"
                   :value="setupStore.getProductStatusById(product?.product_status_id)?.name"/>
          <ShowRow :label="$t('products.start_at')" :value="formatDate(product?.start_at, 'short2')"/>
          <ShowRow :label="$t('products.end_at')" :value="formatDate(product?.end_at, 'short2')"
                   :class="{ 'opacity-50': !product?.end_at }"
          />
          <ShowRow :label="$t('products.product_termination_reason_id')"
                   :value="setupStore.getProductTerminationReasonById(product?.product_termination_reason_id)?.name"
                   :class="{ 'opacity-50': !product?.product_termination_reason_id }"
          />
          <ShowRow :label="$t('products.operations')">
            <div class="operations-wrapper">
              <template v-if="product?.zoho_sales_order_id">
                <div class="operation-item">
                  <a :href="`https://crm.zoho.eu/crm/org20062499282/tab/SalesOrders/${product?.zoho_sales_order_id}`"
                     target="_blank">
                    <i class="fas fa-handshake"/>
                  </a>
                </div>
              </template>

              <template v-if="product?.admin2_product_config_id">
                <div class="operation-item">
                  <a :href="`https://admin2.manet-marketing.de/product/configs/${product?.admin2_product_config_id}?kdnr=${clientStore.client?.kdnr}`"
                     target="_blank">
                    <i class="fas fa-a fa-sm w-auto"/><i class="fas fa-2 fa-sm w-auto"/>
                  </a>
                </div>
              </template>
            </div>
          </ShowRow>
        </div>

        <div class="col-12 col-lg-6">
          <ShowRow :label="$t('products.invoice_label_once')" :value="product?.auto_invoice_label_once"
                   :labelColumns="4" :raw-html="true" class="" value-class="pb-3"/>

          <ShowRow :label="$t('products.invoice_label_recurring')" :value="product?.auto_invoice_label_recurring"
                   :labelColumns="4" :raw-html="true" class="mt-3" value-class="pb-3"/>
        </div>
      </div>

      <template v-if="isPackage">
        <div class="col-12">
          <h2>{{ $t('products.package_components') }}</h2>
          <ProductsTable
              :show-filters="false"
              :parent_id="product.id"
              :paginator="false"
              :rows="productStore.serverOptions.rows"
              :totalRecords="productStore.itemLength"
              dataKey="id"
              :value="productStore.products"
              :loading="productStore.loading"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              responsiveLayout="scroll">
          </ProductsTable>
        </div>
      </template>

      <div class="col-12 mt-3" v-if="product?.client_id">
        <router-link :to="{name: ROUTES.PRODUCTS.EDIT, params: {id: $route.params.product_id}}"
                     :title="$t('products.edit.headline')" class="btn btn-primary me-3">
          <i class="fas fa-edit text-white"/>
        </router-link>

        <router-link :to="{name: ROUTES.INVOICES.CREATE, query: {client_id: product.client_id}}"
                     class="btn btn-primary me-3">
          {{ $t('invoices.create') }}
        </router-link>

        <button class="btn btn-primary me-3" @click.prevent="credit">
          {{ $t('products.credit') }}
        </button>

        <button class="btn btn-primary me-3" @click.prevent="setOnline" :disabled="!allowSetOnline">
          {{ $t('products.setOnline') }}
        </button>

        <template v-if="userStore.user.hasPermission(PERMISSIONS.PRODUCTS.ADMIN)">
          <button class="btn btn-primary me-3" @click.prevent="mergeProduct">
            {{ $t('products.merge_by_name.headline') }}
          </button>
        </template>

        <template v-if="userStore.user.hasPermission(PERMISSIONS.PRODUCTS.ADMIN)">
          <button class="btn btn-primary me-3" @click.prevent="copyProductTypeToProductBillingPeriods">
            {{ $t('products.copy_product_type_to_product_billing_periods.headline') }}
          </button>
        </template>
      </div>

      <ContractsTable :value="productStore.product_contracts"/>
      <ProductBillingPeriodsTable :product_id="product.id" v-if="product" ref="productBillingPeriodsTable"/>

      <template v-if="clientEvents">
        <ClientEventTimeline :items="clientEvents" :allow-comment="true"/>
      </template>
      <template v-if="product?.id">
        <ClientEventAppendButton eventable-type="Product" :eventable-id="product.id" />
      </template>
    </div>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";
import {useProductStore} from "@/stores/product";
import {useSetupStore} from "@/stores/setup";
import ContractsTable from "@/components/tables/ContractsTable.vue";
import ProductBillingPeriodsTable from "@/components/tables/ProductBillingPeriodsTable.vue";
import ShowRow from "@/components/ShowRow.vue";
import ClientEventTimeline from "@/components/ClientEventTimeline.vue";
import {Helpers} from "@/helpers";
import {NANO_EVENT_TYPES, PERMISSIONS, ROUTES} from "@/scripts/constants";
import CollapsedInfos from "@/components/CollapsedInfos.vue";
import ClientEventAppendButton from "@/components/ClientEventAppendButton.vue";
import ProductsTable from "@/components/tables/ProductsTable.vue";
import {useUserStore} from "@/stores/user";

export default {
  name: 'ProductsShow',
  components: {
    ProductsTable,
    ClientEventAppendButton,
    CollapsedInfos,
    ClientEventTimeline,
    ContractsTable,
    ProductBillingPeriodsTable,
    ShowRow,
  },
  setup() {
    return {
      productStore: useProductStore(),
      setupStore: useSetupStore(),
      clientStore: useClientStore(),
      userStore: useUserStore(),
    }
  },
  data() {
    return {
      clientEvents: null,
      emitListenerUnbind: null,
    }
  },
  created() {
    this.setupStore.getProductTypes()
    this.setupStore.getProductDurations()
    this.setupStore.getProductPaymentCycles()
    this.setupStore.getProductRenewalSettings()
    this.setupStore.getProductTerminationReasons()
    this.emitListenerUnbind = Helpers.emitter.on(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED, this.load)
    this.emitListenerUnbind2 = Helpers.emitter.on(NANO_EVENT_TYPES.PRODUCT_CHANGED, this.load)
    this.load()
  },
  beforeUnmount() {
    this.emitListenerUnbind()
    this.emitListenerUnbind2()
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    productId() {
      return this.$route.params.product_id
    },
    product() {
      return this.productStore.product
    },
    productType() {
      return this.product?.product_type_id ? this.setupStore.getProductTypeById(this.product.product_type_id) : null
    },
    productStatus() {
      return this.productStore.product?.product_status_id ?
          this.setupStore.getProductStatusById(this.productStore.product.product_status_id) : null
    },
    isPackage() {
      return this.productType?.key?.match(/package/)
    },
    allowSetOnline() {
      return this.productStatus?.allow_set_online
    },
  },
  methods: {
    load() {
      this.productStore.getProduct(this.productId, (data) => {
        this.clientEvents = data.client_events
        this.$refs.productBillingPeriodsTable?.getProductBillingPeriods()
      }, this.$t('products.show'))
    },
    credit() {
      this.productStore.credit(this.productId)
    },
    setOnline() {
      const productStatusId = this.setupStore.productStatuses.find((ps) => ps.key === 'active').id
      this.productStore.setOnline(this.productId, productStatusId)
    },
    mergeProduct() {
      this.productStore.mergeProduct(this.productId).then(() => {
        Helpers.emitter.emit('success', this.$t('clients.merge_by_name.success'))
      })
    },
    copyProductTypeToProductBillingPeriods() {
      if (!confirm(this.$t('products.copy_product_type_to_product_billing_periods.confirm'))) {
        return
      }
      this.productStore.copyProductTypeToProductBillingPeriods(this.productId).then(() => {
        Helpers.emitter.emit('success', this.$t('products.copy_product_type_to_product_billing_periods.success'))
      })
    },
  },
  watch: {
    '$route.params.product_id'() {
      // console.log('id changed to', newValue)
      if (this.$route.name === ROUTES.PRODUCTS.SHOW) {
        this.load()
      }
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      {{ $t('invoices.data_header') }}
    </div>
    <div class="card-body">
      <!--      <div class="hidden-fields d-none">-->
      <!--        <Field type="hidden" name="invoice.data.client.name" :value="client.auto_billing_company" />-->
      <!--        <Field type="hidden" name="invoice.data.client.contact" :value="client.billing_contact" />-->
      <!--        <Field type="hidden" name="invoice.data.client.email" :value="client.billing_email" />-->
      <!--        <Field type="hidden" name="invoice.data.client.id" :value="client.id" />-->
      <!--        <Field type="hidden" name="invoice.data.address.id" :value="address.id" />-->
      <!--        <Field type="hidden" name="invoice.data.address.street" :value="address.street" />-->
      <!--        <Field type="hidden" name="invoice.data.address.country_code" :value="address.country_code" />-->
      <!--        <Field type="hidden" name="invoice.data.address.postcode" :value="address.postcode" />-->
      <!--        <Field type="hidden" name="invoice.data.address.city" :value="address.city" />-->
      <!--      </div>-->
      <div class="client-data mt-4">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h5>{{ $t('clients.client').capitalize() }}:</h5>
            <p>
              {{ client?.auto_billing_company }}<br>
              <template v-if="client?.billing_contact">{{ client?.billing_contact }}<br></template>
              {{ address?.street }}<br>
              {{ address?.postcode }} {{ address?.city }}<br>
            </p>
            <b>{{ $t('clients.billing_email') }}:</b> {{ client?.billing_email }}<br>
            <div class="mt-2">
              <b>{{ $t('clients.billing_info') }}:</b> {{ client?.billing_info }}<br>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-12">
                <h5>{{ $t('invoices.additional_header').capitalize() }}:</h5>
                <div class="form-floating mt-2">
                  <Field name="invoice.name"
                         :class="{'is-invalid': errors[`invoice.name`]}"
                         class="form-control" id="invoice.name" placeholder=""/>
                  <label for="invoice.name">{{ $t('invoices.name') }}</label>
                  <div v-if="errors[`invoice.name`]" class="invalid-feedback">
                    {{ errors[`invoice.name`] }}
                  </div>
                </div>
              </div>
            </div>
            <CollapsedContent id="invoice-special-fields">
              <template v-slot:action><i class="fa-solid fa-screwdriver-wrench"></i></template>
              <div class="row">
                <div class="col-12 col-xl-4">
                  <div class="form-floating mt-2">
                    <Field name="invoice.invoice_date"
                           :class="{'is-invalid': errors[`invoice.invoice_date`]}"
                           class="form-control" type="date" id="invoice.invoice_date" placeholder=""/>
                    <label for="invoice.invoice_date">{{ $t('invoices.date') }}</label>
                    <div v-if="errors[`invoice.invoice_date`]" class="invalid-feedback">
                      {{ errors[`invoice.invoice_date`] }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="form-floating mt-2">
                    <Field class="form-select"
                           as="select"
                           id="invoice_status_id"
                           name="invoice.invoice_status_id"
                           :class="{'is-invalid': errors[`invoice_status_id`]}"
                    >
                      <option v-for="(invoiceStatus, key) in setupStore.invoiceStatuses" :key="key"
                              :value="invoiceStatus.id">
                        {{ invoiceStatus.name }}
                      </option>
                    </Field>
                    <label for="invoice.invoice_status">{{ $t('invoices.status') }}</label>
                    <div v-if="errors[`invoice.invoice_status_id`]" class="invalid-feedback">
                      {{ errors[`invoice.invoice_status_id`] }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="form-floating mt-2">
                    <Field name="invoice.resubmission_date"
                           :class="{'is-invalid': errors[`invoice.resubmission_date`]}"
                           class="form-control" type="date" id="invoice.resubmission_date" placeholder=""/>
                    <label for="invoice.resubmission_date">{{ $t('invoices.resubmission_date') }}</label>
                    <div v-if="errors[`invoice.resubmission_date`]" class="invalid-feedback">
                      {{ errors[`invoice.resubmission_date`] }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-4">
                  <div class="form-floating mt-2">
                    <Field name="invoice.original_invoice_id"
                           type="number"
                           :class="{'is-invalid': errors[`invoice.original_invoice_id`]}"
                           class="form-control" id="invoice.original_invoice_id" placeholder=""/>
                    <label for="invoice.original_invoice_id">{{ $t('invoices.original_invoice_id') }}</label>
                    <div v-if="errors[`invoice.original_invoice_id`]" class="invalid-feedback">
                      {{ errors[`invoice.original_invoice_id`] }}
                    </div>
                  </div>
                </div>
              </div>
            </CollapsedContent>
            <div class="row">

              <!-- DirectDebitMandate -->
              <div class="col-12 col-lg-4 mt-2 mb-3">
                <DirectDebitMandateFields :ddms="ddms" v-if="clientStore.client?.id"/>
              </div>

              <!-- PaymentMethod -->
              <div class="col-12 col-lg-4 mt-2 mb-3">
                <div class="form-floating">
                  <Field name="invoice.payment_method_id"
                         :class="{'is-invalid': errors[`invoice.payment_method_id`]}"
                         class="form-select" as="select" id="invoice.payment_method_id">
                    <option :value="null">-</option>
                    <option v-for="(paymentMethod, key) in setupStore.paymentMethods" :key="key"
                            :value="paymentMethod.id">
                      {{ paymentMethod.name }}
                    </option>
                  </Field>
                  <label for="invoice.payment_method_id">{{ $t('invoices.payment_method') }}</label>
                  <div v-if="errors[`invoice.payment_method_id`]" class="invalid-feedback">
                    {{ errors[`invoice.payment_method_id`] }}
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="form-floating mt-2">
                  <Field name="invoice.invoice_headline"
                         :class="{'is-invalid': errors[`invoice.invoice_headline`]}"
                         class="form-control" id="invoice.invoice_headline" placeholder=""/>
                  <label for="invoice.invoice_headline">{{ $t('invoices.invoice_headline') }}</label>
                  <div v-if="errors[`invoice.invoice_headline`]" class="invalid-feedback">
                    {{ errors[`invoice.invoice_headline`] }}
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="form-floating mt-2">
                  <datalist id="additional-text-options-list">
                    <template v-for="(option, idx) in additionalTextOptions" :key="idx">
                      <option>{{ option }}</option>
                    </template>
                  </datalist>
                  <Field name="invoice.additional_text"
                         list="additional-text-options-list"
                         :class="{'is-invalid': errors[`invoice.additional_text`]}"
                         class="form-control" id="invoice.additional_text" placeholder=""/>
                  <label for="invoice.additional_text">{{ $t('invoices.additional_text') }}</label>
                  <div v-if="errors[`invoice.additional_text`]" class="invalid-feedback">
                    {{ errors[`invoice.additional_text`] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useInvoiceStore} from "@/stores/invoice";
import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import {Field} from "vee-validate";
import DirectDebitMandateFields from "@/pages/invoices/components/DirectDebitMandateFields.vue";
import CollapsedContent from "@/components/CollapsedContent.vue";

export default {
  setup() {
    return {
      invoiceStore: useInvoiceStore(),
      setupStore: useSetupStore(),
      clientStore: useClientStore(),
    }
  },
  components: {
    CollapsedContent,
    Field,
    DirectDebitMandateFields,
  },
  props: {
    ddms: {
      type: Array,
      default() {
        return []
      }
    },
    errors: {
      type: Object,
      default() {
        return {}
      }
    },
    clientId: {
      type: Number
    }
  },
  data() {
    return {
      search: null,
    }
  },
  computed: {
    client() {
      return this.clientStore.client
    },
    address() {
      return this.clientStore.address
    },
    additionalTextOptions() {
      return [
        'Wir überweisen den Betrag in den nächsten Tagen auf Ihr Konto.',
        'Die korrigierte Rechnung erhalten Sie als separates Dokument.',
        'Den zu viel abgebuchten Betrag erlauben wir uns mit der Rechnung XXX verrechnen.',
        'Für Rückfragen stehen wir Ihnen gerne zur Verfügung',
      ]
    },
  },
  created() {
    this.setupStore.getPaymentMethods()
    this.setupStore.getInvoiceStatuses()
    if (!this.clientId) {
      throw "missing clientId"
    }
    this.clientStore.getClient(this.clientId, this.$t('invoices.data_header', {invoice_number: this.invoiceStore.invoiceNumber}))
  },
  methods: {},
  watch: {}
}
</script>

<template>
  <div class="card">
    <div class="card-header d-flex">
      <slot name="header">
        <h5 class="me-3">{{ headline }}</h5>
      </slot>
      <slot name="actions">
      </slot>
    </div>
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: "BootstrapCard",
  components: {},
  props: {
    headline: {
      type: String,
    }
  },
  setup() {
    return {}
  },
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {},
}
</script>

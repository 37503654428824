<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $tc('accounts.account', 2).capitalize() }}</h2>
    </div>
    <div class="col-12" v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.CREATE)">
      <button @click="$router.push({name: ROUTES.ACCOUNTS.CREATE})" class="btn btn-primary me-2">
        {{ $t('accounts.link_new') }}
      </button>
      <button @click="$router.push({name: ROUTES.ACCOUNTS.TAGS})" class="btn btn-primary">
        {{ $tc('accounts.tag', 2).capitalize() }}
      </button>
    </div>
    <div class="col-12">
      <DataTable stripedRows :lazy="true"
                 :rows="10"
                 :totalRecords="accounts.length"
                 dataKey="id"
                 :value="accounts"
                 responsiveLayout="scroll">
        <TableColumn field="name" :header="$t('accounts.account_name')"></TableColumn>
        <TableColumn field="bank" :header="$t('accounts.bank_name')"></TableColumn>
        <TableColumn field="bic" :header="$t('accounts.bic')"></TableColumn>
        <TableColumn field="iban" :header="$t('accounts.iban')"></TableColumn>
        <TableColumn :header="$t('accounts.operations')">
          <template #body="{data}">
            <div class="operations-wrapper">
              <template v-if="data.needs_connection">
                <button  @click="connect(data.account_provider_id)" class="btn btn-primary">
                  {{ $t('accounts.connect_bank') }}
                </button>
              </template>
              <template v-else>
                <button  @click="disconnect(data.account_provider_id, true)" class="btn btn-warning">
                  {{ $t('accounts.disconnect_bank') }}
                </button>
              </template>

              <router-link class="btn btn-primary ms-3"
                           name="accounts.show"
                           :to="{name: ROUTES.ACCOUNTS.SHOW, params: {id: data.id}}">
                {{ $t('accounts.show') }}
              </router-link>
            </div>
          </template>
        </TableColumn>
      </DataTable>

    </div>
  </div>
</template>

<script>
import {useAccountStore} from "@/stores/account";
import {useAccountProviderStore} from "@/stores/account_provider";
import {useUserStore} from "@/stores/user";
import {Helpers} from "@/helpers";
import {PERMISSIONS, ROUTES} from "@/scripts/constants";

export default {
  name: 'AccountsIndex',
  components: {
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      userStore: useUserStore(),
      accountProviderStore: useAccountProviderStore()
    }
  },
  data() {
    return {
      organization: null
    }
  },
  created() {
    this.accountStore.getAccounts()
    this.setTitle(this.$tc('accounts.account', 2).capitalize())
  },
  computed: {
      PERMISSIONS() {
          return PERMISSIONS
      },
    ROUTES() {
      return ROUTES
    },
    accounts() {
      let parsedAccounts = []
      for (let i = 0; i < this.accountStore.accounts; i++) {
        const account = this.accountStore.accounts[i]
        parsedAccounts.push({
          bank: account.bank,
          bic: account.bic,
          iban: account.iban,
        })
      }
      return this.accountStore.accounts
    },
  },
  methods: {
    connect(accountProviderId) {
      this.accountProviderStore.connect(accountProviderId).then(({data}) => {
        //
        if(data.auth_url) {
          window.location.replace(data.auth_url)
        } else {
          this.accountStore.accounts = data.accounts
          Helpers.emitter.emit('success', data.message)
        }
      })
    },
    disconnect(accountProviderId) {
      if (!confirm("really?")) {
        return
      }
      this.accountProviderStore.disconnect(accountProviderId).then(() => {
        this.accountStore.getAccounts()
      })
    },
  }
}
</script>

import {defineStore} from 'pinia'

export const useMessageStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'message',
    // a function that returns a fresh state
    state: () => ({
        loading: false,
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        create(message) {
            return this.apiClient.post(`/messages`, {message})
        },
        sendTemplate(params) {
            return this.apiClient.post(`/messages/send_template`, {message: params})
        },
        templates() {
            return this.apiClient.get(`/messages/templates`)
        },
    },
})

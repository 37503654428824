<template>
  <div class="row">
    <div class="col-12" :key="clientId" v-if="activeTab.type === CLIENT_TAB_TYPES.HISTORY">
      <div class="row">
        <div class="col-12 mt-2">
          <ClientEventTimelineHeader :newEventRef="$refs.newEvent"/>
          <ClientEventTimelineFilters/>
        </div>
        <ClientEventTimeline :items="clientEventStore.client_events" :allow-comment="true"></ClientEventTimeline>
        <template v-if="clientId">
          <div ref="newEvent" id="newEvent" class="col-12">
            <ClientEventAppendButton eventable-type="Client" :eventable-id="Client" />
          </div>
        </template>
      </div>
    </div>

    <div class="col-12" v-else-if="activeTab.type === CLIENT_TAB_TYPES.OVERVIEW">
      <div>
        <div class="d-flex">
          <h2>Kunde:</h2>
          <div>
            <router-link :to="{name: ROUTES.CLIENTS.EDIT, param: {id: clientId}}" class="ms-3 btn btn-primary btn-sm">
              <i class="fas fa-edit"/>
            </router-link>
          </div>
        </div>

        <div class="row">
          <template v-for="(keys, groupKey) in groupedClientKeysToOutput" :key="groupKey">
            <div class="col-12 col-xxl-6 mb-3">
              <!--          {{ groupKey }}-->

              <div class="d-flex" v-for="(key, index) in keys" :key="index">
                <div class="w-25 text-truncate pe-3" :title="$t(`clients.${key}`)">
                  <b>{{ $t(`clients.${key}`) }}:</b>
                </div>
                <template v-if="key.match(/address/)">
                  <div>
                    {{ clientStore.clientAddresses[key]?.street }}<br>
                    {{ clientStore.clientAddresses[key]?.postcode }} {{ clientStore.clientAddresses[key]?.city }}<br>
                    {{ clientStore.clientAddresses[key]?.country_code }}
                  </div>
                </template>
                <template v-else-if="key === 'user_id'">
                  <UserAvatar :id="clientStore.client[key]" class="user"/>
                </template>
                <template v-else-if="key.match(/url/)">
                  <div class="list-group" v-if="clientStore.client[key]">
                    <div class="list-group-item">
                      <a class="d-block" :href="`${clientStore.client[key]}`" target="_blank">{{
                          clientStore.client[key]
                        }}</a>
                    </div>
                  </div>
                </template>
                <template v-else-if="key === 'client_type'">
                  {{ setupStore.getClientTypeById(clientStore.client[`${key}_id`])?.name }}
                </template>
                <template v-else-if="key === 'invoice_delivery_method'">
                  {{ setupStore.getInvoiceDeliveryMethodById(clientStore.client[`${key}_id`])?.name }}
                </template>
                <template v-else-if="key === 'client_status'">
                  {{ setupStore.getClientStatusById(clientStore.client[`${key}_id`])?.name }}
                </template>
                <template v-else-if="key === 'legal_info_type'">
                  {{ setupStore.legalInfoTypes[clientStore.client[key]] }}
                </template>
                <template v-else-if="key === 'contacts'">
                  <div v-if="clientId">
                    <router-link class="btn btn-primary btn-sm" target="_blank"
                                 :to="{name: ROUTES.CLIENTS.SHOW, params: {id: clientId}, query: { tab: CLIENT_TAB_TYPES.CONTACTS}}">
                      <i class="fa-solid fa-arrow-up-right-from-square"></i> {{ $tc('contacts.contact', 2) }}
                    </router-link>
                  </div>
                </template>
                <template v-else-if="key === 'phones'">
                  <div>
                    <template v-for="(phone, idx) in clientStore.client[key]" :key="idx">
                      <div class="mb-2">
                        <PhoneNumber :number="phone.number"/>
                        {{ phone.label }}
                        <show-bool :value="phone.publish" />
                        <template v-if="phone.note">
                          <br><small>{{ phone.note }}</small>
                        </template>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else-if="key === 'social_media_profiles'">
                  <div>
                    <template v-for="(profile, idx) in clientStore.client[key]" :key="idx">
                      <div>
                        <a :href="profile.url" target="_blank" rel="noopener noreferrer">
                          <i :class="`fab fa-${profile.type} fa-fw me-1`" :title="profile.type"></i>{{
                            profile.url
                          }}</a>
                        {{ profile.info }}
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else-if="key === 'subdomains'">
                  <div class="list-group">
                    <template v-for="(subdomain, idx) in clientStore.client[key] || []" :key="idx">
                      <div class="list-group-item">
                        <a :href="`http://${subdomain}`" target="_blank" class="d-block">{{ subdomain }}</a>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else-if="key === 'payment_performance'">
                  <PaymentPerformance v-model="clientStore.client[key]" :readonly="true"/>
                </template>
                <template v-else>
                  <div class="w-75" style="white-space: pre-wrap">
                    {{ clientStore.client[key] }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="col-12 mt-3">
        <router-link :to="{name: ROUTES.CLIENTS.EDIT, param: {id: clientId}}" class="btn btn-primary">
          {{ $t('clients.edit.headline') }}
        </router-link>
      </div>
      <template v-if="clientStore.client?.children?.length">
        <div class="mb-5">
          <h2 class="mt-3">Untergeordnete Kunden:</h2>  <!-- FIXME: i18n -->
          <template v-for="child in clientStore.client.children" :key="child.id">
            <div>
              <router-link class="operation-item" @click.prevent="clickClient(child.id)"
                           :to="{name: ROUTES.CLIENTS.SHOW, params: {id: child.id}}">
                {{ child.kdnr }} {{ child.name }}
              </router-link>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="col-12" v-else-if="activeTab.type === CLIENT_TAB_TYPES.CONTACTS">
      <ContactsIndex :client_id="clientId" mode="assignment"/>
    </div>
    <div class="col-12" v-else-if="activeTab.type === CLIENT_TAB_TYPES.CONTRACTS">
      <ContractsIndex :client_id="clientId"/>
    </div>
    <div class="col-12" v-else-if="activeTab.type === CLIENT_TAB_TYPES.PRODUCTS">
      <ProductsIndex :client_id="clientId"/>
    </div>
    <div class="col-12" v-else-if="activeTab.type === CLIENT_TAB_TYPES.PRODUCT_BILLING_PERIODS">
      <ProductBillingPeriodsIndex :client_id="clientId"/>
    </div>
    <div class="col-12" v-else-if="activeTab.type === CLIENT_TAB_TYPES.INVOICES">
      <InvoicesIndex :client_id="clientId"/>
    </div>
    <div class="col-12" v-else-if="activeTab.type === CLIENT_TAB_TYPES.DIRECT_DEBIT_MANDATES">
      <DirectDebitMandatesIndex :client_id="clientId"/>
    </div>
  </div>
</template>

<script>
import {CLIENT_TAB_TYPES, NANO_EVENT_TYPES, ROUTES} from "@/scripts/constants"
import {Helpers} from "@/helpers";
import {useAllUsersStore} from "@/stores/all_users";
import {useClientEventStore} from "@/stores/client_event";
import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";

import ClientEventAppendButton from "@/components/ClientEventAppendButton.vue";
import ClientEventTimeline from "@/components/ClientEventTimeline";
import ClientEventTimelineFilters from "@/components/ClientEventTimelineFilters.vue";
import ClientEventTimelineHeader from "@/components/ClientEventTimelineHeader.vue";
import ContactsIndex from "@/pages/contacts/Index"
import ContractsIndex from "@/pages/contracts/Index"
import DirectDebitMandatesIndex from "@/pages/direct_debit_mandates/Index"
import InvoicesIndex from "@/pages/invoices/Index"
import PaymentPerformance from "@/pages/clients/components/PaymentPerformance.vue";
import ProductBillingPeriodsIndex from "@/pages/product_billing_periods/Index"
import ProductsIndex from "@/pages/products/Index"
import UserAvatar from "@/components/UserAvatar.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import ShowBool from "@/components/ShowBool.vue";

export default {
  name: 'ClientsShow',
  components: {
    ShowBool,
    PhoneNumber,
    ClientEventAppendButton,
    ClientEventTimeline,
    ClientEventTimelineFilters,
    ClientEventTimelineHeader,
    ContactsIndex,
    ContractsIndex,
    DirectDebitMandatesIndex,
    InvoicesIndex,
    PaymentPerformance,
    ProductBillingPeriodsIndex,
    ProductsIndex,
    UserAvatar,
  },
  setup() {
    return {
      clientEventStore: useClientEventStore(),
      clientStore: useClientStore(),
      setupStore: useSetupStore(),
      allUserStore: useAllUsersStore(),
    }
  },
  data() {
    return {
      showAllUsers: false,
      clientKeysToOutput: [],
      groupedClientKeysToOutput: {
        common: [
          'kdnr', 'name',
          'user_id',
          'client_type',
          'client_status',
          'address',
          'manet_url',
          'url',
          'subdomains',
        ],
        contact: [
          'contacts',
          'email',
          'phones',
          'fax',

          'social_media_profiles',
          'info',
        ],
        billing: [
          'billing_company', 'billing_contact',
          'invoice_address',
          'billing_info',
          'invoice_delivery_method',
          'invoice_mail_delivery_price',
          'billing_email',

          'payment_performance',
          'debtor',
          'revenue_per_year',
        ],
        legalInfo: [
          'legal_info_type',
          'legal_info_name',
          'legal_form',
          'legal_address',
          'legal_info_phone', 'legal_info_fax',
          'legal_info_email',
          'representative', 'representative_function',
          'register_court', 'register_number',
          'vat_id',
          'legal_info_text',
          'data_protection_officer',
          'privacy_policy_version'
        ],
      },
      filteredByEventTypes: [],
      filteredByUsers: [],
      CLIENT_TAB_TYPES,

    }
  },
  created() {
    this.setupStore.getClientEventTypes()
    this.setupStore.getClientTypes()
    this.setupStore.getClientStatuses()
    this.setupStore.getInvoiceDeliveryMethods()
    if (this.$route.query?.kdnr) {
      this.clientStore.get('auto', this.$route.query.kdnr).then(({data}) => {
        this.clickClient(data.client.id)
      }).catch((e) => {
        Helpers.emitter.emit('warning', e.response.data.message)
      })
    } else {
      this.clientStore.getClient(this.$route.params.id, '')
    }
  },
  // beforeRouteUpdate(to) {
  //   if (parseInt(to.params.id, 10) !== this.clientStore.client.id) {
  //     this.clientStore.getClient(to.params.id, '')
  //   }
  // },
  computed: {
    ROUTES() {
      return ROUTES
    },
    activeTab() {
      return this.clientStore.activeTab
    },
    clientId() {
      return parseInt(this.$route.params.id, 10)
    }
  },
  methods: {
    clickClient(id) {
      this.clientStore.getClient(id, '').then(() => {
        Helpers.emitter.emit(NANO_EVENT_TYPES.CLIENT_CLICKED)
      })

    }
  },
  // unmounted() {
  //     this.resetClient()
  // }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h2>{{$t('receipts.create')}}</h2>
    </div>
    <div class="col-12 mt-5">
      <Form @submit="createReceipt" v-slot="{ errors }" :validation-schema="schema" class="row" :initial-values="initialTransaction">
        <div class="mb-3 col-12 col-lg-4">
          <div class="form-floating">
            <Field id="creditor_name" name="creditor_name" class="form-control" :class="{'is-invalid': errors.creditor_name}"/>
            <label for="creditor_name" class="form-label">{{ $t('receipts.creditor') }}</label>
            <div v-if="errors.creditor_name" class="invalid-feedback">
              {{ errors.creditor_name }}
            </div>
          </div>
        </div>
        <div class="mb-3 col-12 col-lg-4">
          <div class="form-floating">
            <Field type="date" id="paid_at"
                   name="paid_at"
                   placeholder="{{$t('receipts.paid_at')}}" class="form-control"
                   :class="{'is-invalid': errors['paid_at']}"/>
            <label for="contract.contract_date">{{ $t('receipts.paid_at') }}</label>
            <div v-if="errors['paid_at']" class="invalid-feedback">
              {{ errors['paid_at'] }}
            </div>
          </div>
        </div>
        <div class="mb-3 col-12 col-lg-4">
          <div class="form-floating">
            <Field type="date" id="resubmission_date"
                   name="resubmission_date"
                   placeholder="{{$t('receipts.resubmission_date')}}" class="form-control"
                   :class="{'is-invalid': errors['resubmission_date']}"/>
            <label for="contract.contract_date">{{ $t('receipts.resubmission_date') }}</label>
            <div v-if="errors['resubmission_date']" class="invalid-feedback">
              {{ errors['resubmission_date'] }}
            </div>
          </div>
        </div>
        <div class="mb-3 col-12 col-lg-4">
          <div class="form-floating">
            <Field type="number"
                   id="amount"
                   name="amount"
                   step="0.01"
                   placeholder="{{$t('receipts.amount')}}" class="form-control"
                   :class="{'is-invalid': errors['amount']}"/>
            <label for="contract.contract_date">{{ $t('receipts.amount') }}</label>
            <div v-if="errors['amount']" class="invalid-feedback">
              {{ errors['amount'] }}
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <div class="form-floating">
            <Field class="form-select" name="intermediate_user_id" as="select" id="intermediate_user_id" :class="{'is-invalid': errors['intermediate_user_id']}">
              <option v-for="(user, key) in users" :key="key" :value="user.id">{{ user.name }}</option>
            </Field>
            <label for="intermediate_user_id">{{ $tc('users.user', 2) }}</label>
            <div v-if="errors['intermediate_user_id']" class="invalid-feedback">
              {{ errors['intermediate_user_id'] }}
            </div>
          </div>
        </div>
        <Field name="file" v-slot="{ handleChange, handleBlur }">
          <DropZone class="col-12" :class="{'is-invalid': errors.file}" :show-buttons="false" :accept-files="['.pdf', '.jpg']" @onDrop="handleChange" @change="handleChange" @blur="handleBlur"/>
          <div v-if="errors.file" class="invalid-feedback">
            {{ errors.file }}
          </div>
        </Field>

        <Field type="hidden" id="transaction_id" name="transaction_id" />
        <div class="col-12 col-lg-3">
          <button class="btn btn-success mt-3" type="submit">
            {{ $t('save') }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import DropZone from "@/components/DropZone.vue";
import {useReceiptStore} from "@/stores/receipt";
import {Form, Field} from "vee-validate";
import * as Yup from "yup";
import {useAllUsersStore} from "@/stores/all_users";
// import {ROUTES} from "@/scripts/constants";
export default {
  name: 'ReceiptsCreate',
  components: {
    DropZone,
    Form,
    Field
  },
  setup() {
    return {
      receiptStore: useReceiptStore(),
      allUsersStore: useAllUsersStore(),
    }
  },
  data() {
    return {
      initialTransaction: {
        creditor_name: '',
        transaction_id: null
      },
      schema: Yup.object().shape({
        file: Yup.mixed().required(this.$t('validation.required')),
        creditor_name: Yup.string().required(this.$t('validation.required')),
        paid_at: Yup.string().nullable(),
        intermediate_user_id: Yup.number().nullable(),
        amount: Yup.number().required(this.$t('validation.required')),
      }),
    }
  },
  computed: {
    users() {
      return this.allUsersStore.activeUsers
    }
  },

  created() {
    this.allUsersStore.index()
    if (this.$route.query.name) {
      this.initialTransaction.creditor_name = this.$route.query.name
    }
    if (this.$route.query.transaction_id) {
      this.initialTransaction.transaction_id = this.$route.query.transaction_id
    }
    if (this.$route.query.amount) {
      this.initialTransaction.amount = this.$route.query.amount
    }
    if (this.$route.query.paid_at) {
        this.initialTransaction.paid_at = this.$route.query.paid_at
    }
    if (this.$route.query.intermediate_user_id) {
        this.initialTransaction.intermediate_user_id = this.$route.query.intermediate_user_id
    }
  },
  methods: {
    createReceipt(data) {
      this.receiptStore.create(data).then(() => {
          this.$router.back()
      })
    }
  },
  watch: {
  }
}
</script>

<template>
  <div class="p-4">
    <div class="d-flex">
      <div v-if="invoiceData?.prev_invoice_id">
        <router-link class="me-5"
                     @click="loadInvoice(invoiceData.prev_invoice_id)"
                     :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: invoiceData.prev_invoice_id}}"
                     :title="$t('dunning.edit_prev')">
          <i class="fa-solid fa-left"></i>
        </router-link>
      </div>

      <div v-if="invoiceData?.next_invoice_id">
        <router-link class="me-5"
                     @click="loadInvoice(invoiceData.next_invoice_id)"
                     :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: invoiceData.next_invoice_id}}"
                     :title="$t('dunning.edit_next')">
          <i class="fa-solid fa-right"></i>
        </router-link>
      </div>
    </div>

    <h2>{{ $t('invoices.dunning') }}</h2>

    <div class="row">
      <div class="col-12 col-lg-5">
        <DunningData v-model:invoiceModel="invoiceData" v-if="invoiceData" @updated="loadInvoice"
                     v-model:showPreview="showPreview"/>
      </div>

      <div class="col-12 col-lg-7">
        <ProductBillingPeriodsTable :invoice_id="invoiceId" :invoice-actions="false"/>
        <InvoicePreview :id="invoiceId" v-if="showPreview"/>
      </div>

      <div class="col-12" v-if="showAccountTransactionsTable">
        <AccountTransactionsTable/>
      </div>

      <div class="col-12">
        <ClientEventTimeline :items="invoiceStore.invoiceClientEvents" :allow-comment="true"/>

        <template v-if="$route.params.invoice_id">
          <ClientEventAppendButton eventable-type="Invoice" :eventable-id="parseInt($route.params.invoice_id, 10)" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client";
import {useInvoiceStore} from "@/stores/invoice";
import DunningData from "./DunningData";
import InvoicePreview from "@/pages/invoices/components/InvoicePreview.vue";
import ClientEventTimeline from "@/components/ClientEventTimeline.vue";
import ProductBillingPeriodsTable from "@/components/tables/ProductBillingPeriodsTable.vue";
import {Helpers} from "@/helpers";
import {NANO_EVENT_TYPES, ROUTES} from "@/scripts/constants";
import ClientEventAppendButton from "@/components/ClientEventAppendButton.vue";
import AccountTransactionsTable from "./components/AccountTransactionsTable.vue";

export default {
  name: 'DunningEdit',
  inject: {
    dialogRef: {
      default: null
    }
  },
  components: {
    AccountTransactionsTable,
    ClientEventAppendButton,
    ProductBillingPeriodsTable,
    DunningData,
    InvoicePreview,
    ClientEventTimeline,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      invoiceStore: useInvoiceStore(),
    }
  },
  props: {
  //     invoiceIdProp: {
  //         type: Number,
  //         default: null,
  //     },
  },
  data() {
    return {
      positions: [],
      invoiceData: null,
      showPreview: false,
      //this should rather be a real prop but primevue can't pass props to components just data
      // invoiceIdProp: null,
    }
  },
  created() {
    this.$cable.subscribe({
      channel: "DefaultChannel",
      // room: "public",
    });
    this.emitListenerUnbind = Helpers.emitter.on(NANO_EVENT_TYPES.CLIENT_EVENT_CHANGED, this.loadInvoice)
    this.loadInvoice()
  },
  beforeUnmount() {
    this.emitListenerUnbind()
  },
  channels: {
    DefaultChannel: {
      connected() {
        // console.log("ws connected")
      },
      rejected() {
        // console.log("ws rejected")
      },
      received(data) {
        if (data.event === 'updated' && data.model === 'Invoice' && data.id === this.invoiceId) {
          this.loadInvoice()
        } else {
          console.log("ws received unknown event", data)
        }
      },
      disconnected() {
        // console.log("ws disconnected")
      },
    },
  },
  computed: {
    ROUTES() {
      return ROUTES
    },
    invoiceId() {
      const dialogData = this.dialogRef?.data;
      return dialogData?.invoiceIdProp || parseInt(this.$route.params.invoice_id, 10)
    },
    showAccountTransactionsTable() {
      return !this.dialogRef?.data
    },
  },
  mounted() {
  },
  methods: {
    loadInvoice(id) {
      const currentId = id || this.invoiceId
      // console.log("loadInvoice")
      this.invoiceStore.get(currentId).then(({data}) => {
        this.clientStore.getClient(data.invoice.client_id, this.$t('invoices.dunning', { invoice_number: data.invoice.number }))
        this.positions = data.invoice.positions
        this.invoiceStore.invoiceClientEvents = data.client_events
        this.invoiceData = data.invoice
        console.log(data)
      });
    },
  },
}
</script>

import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";

export const useContactStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'contact',
    // a function that returns a fresh state
    state: () => ({
        contacts: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 25,
            filters: {},
            sortField: null,
            sortOrder: null,
        }
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        search(search) {
            return this.apiClient.get(`/contacts/search`, {params: {search}})
        },
        async index(clientId = null) {
            this.loading = true
            const params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }
            if (clientId) {
                Helpers.addQueryToParams({client_id: clientId}, params)
            }
            if (this.serverOptions.filters.first_name?.value) {
                Helpers.addQueryToParams({first_name: this.serverOptions.filters.first_name.value}, params)
                params['first_name'] = this.serverOptions.filters.first_name?.value
            } else {
                await Helpers.removeQueryFromUrl("first_name")
            }
            if (this.serverOptions.filters.last_name?.value) {
                Helpers.addQueryToParams({last_name: this.serverOptions.filters.last_name.value}, params)
                params['last_name'] = this.serverOptions.filters.last_name?.value
            } else {
                await Helpers.removeQueryFromUrl("last_name")
            }
            if (this.serverOptions.filters.email?.value) {
                Helpers.addQueryToParams({email: this.serverOptions.filters.email.value}, params)
                params['email'] = this.serverOptions.filters.email?.value
            } else {
                await Helpers.removeQueryFromUrl("email")
            }
            if(this.serverOptions.sortField) {
                Helpers.addQueryToParams({sort_by: this.serverOptions.sortField, sort_order: this.serverOptions.sortOrder}, params)
            }

            await Helpers.setUrlQuery(params)
            return this.apiClient.get(`/contacts`, {
                params
            }).then(({data}) => {
                this.contacts = data.contacts
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        show(id) {
            return this.apiClient.get(`/contacts/${id}`)
        },
        update(id, data) {
            return this.apiClient.put(`/contacts/${id}`, data)
        },
    },
})

<template>
  <div class="p-4">
    <Form @submit="submit" v-slot="{ errors }" :validation-schema="schema" :initial-values="initialFormData"
          v-if="initialFormData">
      <template v-if="clientId">
        <InvoiceFormFields :ddms="initialFormData.direct_debit_mandates" :errors="errors" :client-id="clientId"/>
      </template>
      <div class="card mt-3">
        <div class="card-header">
          {{ $t('invoices.product_billing_periods_header') }}
        </div>
        <div class="card-body">
<!--          &#45;&#45; {{ initialFormData.invoice.client_id }} &#45;&#45;-->
          <ProductBillingPeriodsTable :client_id="initialFormData.invoice.client_id"
                                      :initial-filters="initialFilters"
                                      :invoice-edit-actions="true"
                                      @addProductBillingPeriod="useProductBillingPeriod"
                                      :usedProductBillingPeriodIds="usedProductBillingPeriodIds"
                                      :showFilters="true"
          />
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">
          {{ $t('invoices.positions_header') }}
        </div>
        <div class="card-body">
          <PositionEditor :errors="errors" ref="editor" @removeProductBillingPeriodId="removeProductBillingPeriodId"/>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button class="btn btn-success me-3" type="submit">
            {{ $t('invoices.save') }}
          </button>

          <slot>
          </slot>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import {useClientStore} from "@/stores/client";
import {useInvoiceStore} from "@/stores/invoice";
import PositionEditor from "./PositionEditor.vue";
import InvoiceFormFields from "./InvoiceFormFields.vue";
import {INVOICE_FORM_SCHEMA} from "@/scripts/schemas";
import {Form} from "vee-validate";
import ProductBillingPeriodsTable from "@/components/tables/ProductBillingPeriodsTable.vue";
import {v4 as uuidv4} from "uuid";

export default {
  name: 'InvoicesCreate',
  components: {
    PositionEditor,
    ProductBillingPeriodsTable,
    InvoiceFormFields,
    Form,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      invoiceStore: useInvoiceStore(),
    }
  },
  props: {},
  data() {
    return {
      // positions: [],
      schema: INVOICE_FORM_SCHEMA,
      initialFormData: null,
      usedProductBillingPeriodIds: [],
      initialFilters: {
        'scheduled_billing_date_today': {value: true, matchMode: FilterMatchMode.STARTS_WITH},
        'product_billing_status_key': {value: 'billing', matchMode: FilterMatchMode.STARTS_WITH},
      },
    }
  },
  created() {
    // create: fetch empty invoice (id: 0) only to get productBillingPeriods
    this.invoiceStore.get(this.invoiceId, this.clientId, true).then(({data}) => {
      this.initialFormData = data
      this.invoiceStore.invoiceNumber = data.invoice.number
      this.usedProductBillingPeriodIds = data.invoice?.positions?.map((p) => p.product_billing_period_id) || []
    })
  },
  computed: {
    clientId() {
      if (this.initialFormData?.invoice.data?.client?.id) {
        return this.initialFormData.invoice.data.client.id
      } else if (this.$route.query.client_id) {
        return parseInt(this.$route.query.client_id, 10)
      }
      return null;
    },
    invoiceId() {
      return Number(this.$route.params.id) || 0;
    },
  },
  mounted() {
  },
  methods: {
    submit(values) {
      this.$emit('updated', values)
    },
    useProductBillingPeriod(productBillingPeriodData) {
      const period = [
        this.formatDate(productBillingPeriodData.start_on, 'short2'),
        this.formatDate(productBillingPeriodData.end_on, 'short2')
      ].filter((value, index, array) => array.indexOf(value) === index)
      let productDescription = productBillingPeriodData.invoice_label
      // FIXME: i18n
      productDescription += `<br>LZ: ${period.join(' - ')}`
      if (productBillingPeriodData.number_of_installments > 1) {
        // FIXME: i18n
        productDescription += ` - Teilbetrag ${productBillingPeriodData.installment} von ${productBillingPeriodData.number_of_installments}`
      }
      // const productDescription = JSON.stringify(productBillingPeriodData)
      this.usedProductBillingPeriodIds.push(productBillingPeriodData.id)
      this.$refs['editor'].$refs['fa'].push({
        // item_number: `${productBillingPeriodData.product.id}`,
        content: productDescription,
        count: 1,
        type: "wholesale",
        price: Number(productBillingPeriodData.price),
        vat: Number(productBillingPeriodData.vat),
        uuid: uuidv4(),
        product_billing_period_id: productBillingPeriodData.id,
      })
    },
    removeProductBillingPeriodId(id) {
      this.usedProductBillingPeriodIds = this.usedProductBillingPeriodIds.filter((v) => v != id)
    },
  },
}
</script>

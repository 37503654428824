<template>
  <div class="list-group-item contact d-flex justify-content-between">
    <div>
      <span v-html="checkForMarking(client.kdnr)"/> <span v-html="checkForMarking(client.name)"/>
      <template v-if="client.email">
        - <span v-html="checkForMarking(client.email)"/>
      </template>
    </div>
    <router-link class="ms-3 btn btn-primary btn-sm"
                 :to="{name: ROUTES.CLIENTS.SHOW, params: {id: client.id}}">
      <i class="fa-solid fa-users-between-lines"></i> {{ $t('clients.show') }}
    </router-link>
  </div>
</template>
<script>

import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ClientButton',
  props: {
    searchString: String,
    client: Object,
  },
  setup() {
    return {
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  methods: {
    checkForMarking(text) {
      if (text) {
        const regex = new RegExp(this.searchString, 'gi');
        text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
        return text.replace(regex, '<mark class="highlight">$&</mark>');
      }
      return ''
    },
  },
}
</script>
export default {
  "actions": {
    "export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export CSV"])},
    "clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klonen"])},
    "initiate_call": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Über mein ", _interpolate(_named("provider")), " Telefon anrufen"])}
  },
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufklappen"])},
  "quick_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnell anlegen"])},
  "day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tag"]), _normalize(["Tage"])])},
  "common": {
    "installment_separator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])}
  },
  "dashboard": {
    "organizations_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast bisher keine Organisation erstellt. Du benötigst mindestens eine Organisation um tantum crm verwenden zu können."])},
    "fullsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["möchtest Du etwas finden?"])},
    "search_intentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen, Kontakte, Kunden"])}
  },
  "product_types": {
    "type": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Produkt-Typ"]), _normalize(["Produkt-Typen"])])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "invoice_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsbezeichnung"])},
    "invoice_label_context": {
      "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtung"])},
      "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederkehrend"])}
    },
    "setup_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtungspreis"])},
    "list_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listenpreis"])},
    "duration_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lfz. (Monate)"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
    "number_of_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])}
  },
  "products": {
    "create": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt erstellen"])}
    },
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktdetails bearbeiten"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt erfolgreich aktualisiert"])}
    },
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt erfolgreich dupliziert"])}
    },
    "merge_by_name": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte zusammenfassen (merge by name)"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte erfolgreich zusammengefasst"])}
    },
    "copy_product_type_to_product_billing_periods": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkttyp auf Leistungszeiträume übertragen"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirklich ale Leistungszeiträume ändern?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkttyp erfolgreich übertragen"])}
    },
    "assign_contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verträge zuordnen"])},
    "product": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Produkt"]), _normalize(["Produkte"])])},
    "package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket"])},
    "parent_product_select_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehört zu Paket: "])},
    "parent_product_select_blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- gehört zu keinem Paket -"])},
    "package_components": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket-Bestandteile"])},
    "product_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt-Status"])},
    "product_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt-Typ"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt anzeigen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktname"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergeordnete ID"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt-ID"])},
    "setup_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtungspreis"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederkehrender Preis / Laufzeit"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt"])},
    "contract_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrags-ID"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-ID"])},
    "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitpunkt der Onlinestellung"])},
    "end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitpunkt der Löschung"])},
    "product_termination_reason_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigungsgrund"])},
    "product_renewal_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlängerungseinstellung"])},
    "product_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit"])},
    "product_payment_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsintervall"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "invoice_label_intro_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsposition (Text davor einfügen)"])},
    "invoice_label_outro_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsposition (Text danach einfügen)"])},
    "invoice_label_once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungstext (einmaliger Posten)"])},
    "invoice_label_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungstext (wiederkehrender Posten)"])},
    "zoho_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos aus ZohoCRM"])},
    "admin2_product_config_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin2-Produkt-ID"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt hinzufügen"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift für bezahlte Restlaufzeit erstellen"])},
    "setOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivieren (Onlinestellung heute)"])}
  },
  "product_billing_periods": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "product_billing_period": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Leistungszeitraum"]), _normalize(["Leistungszeiträume"])])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt"])},
    "product_billing_period_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungszeitraum-Status"])},
    "product_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkttyp"])},
    "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktname"])},
    "scheduled_billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplantes Abrechnungsdatum"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilbetragnr."])},
    "number_of_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Teilbeträge"])},
    "new_number_of_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufteilen in x Teilbeträge"])},
    "add_to_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Rechnung hinzufügen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungszeitraum bearbeiten"])},
    "edit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungszeitraum erfolgreich aktualisiert"])},
    "start_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startet am"])},
    "end_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ended am"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zug. Rechnungen"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "still_need_renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch sind Leistungszeiträume offen."])},
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungszeitraum erfolgreich dupliziert"])}
    }
  },
  "organizations": {
    "organization": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Organisation"]), _normalize(["Organisationen"])])},
    "create": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation erstellen"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib Details zu Deiner Organisation ein."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation erfolgreich aktualisiert"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummern"])},
    "vat_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UST-ID"])},
    "tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
    "register_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registernummer"])},
    "register_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registergericht"])}
  },
  "addresses": {
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])}
  },
  "accounts": {
    "link_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto verknüpfen"])},
    "import_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen importieren"])},
    "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Konten gefunden:"])},
    "connect_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zur Bank herstellen"])},
    "disconnect_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zur Bank trennen"])},
    "create_sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandboxaccount erstellen"])},
    "request_requisition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigung einfordern"])},
    "account": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Konto"]), _normalize(["Konten"])])},
    "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzten Monat auswählen"])},
    "tag": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tag"]), _normalize(["Tags"])])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto authentifizieren"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto anzeigen"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontobezeichnung"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Bank"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "analytics": {
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quartal"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswertung"])}
    },
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "search_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags durchsuchen"])},
    "search_invoices": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nach passender Rechnung über <span class='amount'>", _interpolate(_named("amount")), "</span> suchen"])},
    "search_receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach passendem Beleg suchen"])}
  },
  "account_transaction_tags": {
    "include_positive_amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf positive Beträge anwenden"])},
    "include_negative_amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf negative Beträge anwenden"])},
    "manual_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelles Tag (wird vom Autotagger übersprungen)"])},
    "autotagging_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autotagging Einstellungen"])}
  },
  "contracts": {
    "contract": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vertrag"]), _normalize(["Verträge"])])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt"])},
    "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatt"])},
    "duration_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit (Monate)"])},
    "important_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Hinweise"])},
    "contract_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsdatum"])},
    "contract_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsstatus"])},
    "product": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Produkt"]), _normalize(["Produkte"])])},
    "product_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position mit Produktvorlage überschreiben"])},
    "termination_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigung erhalten am"])},
    "products_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkategorie"])},
    "termination_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund der Kündigung auswählen"])},
    "create": {
      "base_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisdaten für Vertrag eingeben"])},
      "termination_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigungsdaten eingeben"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsprodukte konfigurieren"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenvertrag erstellen"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsdokumente"])},
      "documents_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Vertragsdokumente im Anschluss als Notiz hochladen!"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag erfolgreich angelegt"])}
    },
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsdetails bearbeiten"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag erfolgreich aktualisiert"])}
    },
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag anzeigen"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrags-Status"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "setOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivieren (Onlinestellung heute)"])}
  },
  "dunning": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahnung bearbeiten"])},
    "edit_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahnwesen - vorherige Rechnung"])},
    "edit_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahnwesen - nachfolgende Rechnung"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahnwesen"])}
  },
  "invoices": {
    "invoice": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rechnung"]), _normalize(["Rechnungen"])])},
    "invoice_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsüberschrift (Standard: Rechnung)"])},
    "additional_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlusstext"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung erstellen"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "original_invoice_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original-Rg.-ID"])},
    "client_kdnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kdnr"])},
    "client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenname"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
    "add_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position hinzufügen"])},
    "remove_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position entfernen"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt"])},
    "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])},
    "net_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettobetrag"])},
    "gross_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttobetrag"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsnummer"])},
    "item_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
    "pagebreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenumbruch nach dieser Position"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
    "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stück"])},
    "wholesale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pauschal"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung speichern"])},
    "positions_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungspositionen hinzufügen"])},
    "data_header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("invoice_number")), " Rechnungsdaten hinzufügen"])},
    "additional_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Rechnungsdaten hinzufügen"])},
    "current_due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Fälligkeit"])},
    "current_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfängeradresse"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fällig am"])},
    "over_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfällig"])},
    "due_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeit in Tagen"])},
    "sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesendet am"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungszeitraum"])},
    "search_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippen zur Kundensuche"])},
    "product_billing_periods_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungszeiträume"])},
    "edit": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung bearbeiten"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung erfolgreich aktualisiert"])}
    },
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung erfolgreich dupliziert"])}
    },
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF-Download"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF-Ansicht"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung aktualisieren"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung fixieren"])},
    "send_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung per Post versenden"])},
    "send_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung per E-Mail versenden"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsdatum"])},
    "resubmission_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedervorlage"])},
    "resubmission_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedervorlage nach Tagen"])},
    "missing_delivery_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatischer Versand nicht möglich: Beim Kunden ist keine Zustellmethode konfiguriert"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "statuses": {
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["festgeschrieben"])},
      "invoice_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesendet"])},
      "invoice_reminded1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erinnert"])},
      "invoice_reminded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gemahnt"])},
      "outsourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsanwalt"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])},
      "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storniert"])},
      "cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgebucht"])}
    },
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart"])},
    "payment_methods": {
      "sepa_direct_debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastschrift"])},
      "bank_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])}
    },
    "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatum"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiterführen"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["versenden"])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung versenden"])},
    "resend_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung erneut senden"])},
    "reminder1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungserinnerung"])},
    "reminder1_sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungserinnerung gesendet am"])},
    "reminder1_due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungserinnerung fällig am"])},
    "reminder2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Zahlungserinnerung"])},
    "reminder2_sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Zahlungserinnerung gesendet am"])},
    "reminder2_due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Zahlungserinnerung fällig am"])},
    "source_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergabe an Rechtsanwalt"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abwarten"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung suchen"])},
    "set_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auf bezahlt setzen"])},
    "via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per E-Mail"])},
    "via_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per Post"])},
    "dunning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("invoice_number")), " Mahnwesen"])},
    "dunning_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahnwesen"])},
    "amount_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beträge ungleich!"])}
  },
  "client_events": {
    "confirm_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lieber neue Notiz hinzufügen!\nWirklich das Event vom ", _interpolate(_named("date")), " ändern?\nHeutiges Datum wird dann nicht vermerkt."])},
    "client_events": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Aktivität"]), _normalize(["Aktivitäten"])])},
    "client_event_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Aktivität"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
    "timeline_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitstempel Timeline"])},
    "client_event_threads": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thema"]), _normalize(["Themen"])])},
    "show_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Benutzer anzeigen"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent-ID"])},
    "eventable_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventable-Typ"])},
    "eventable_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventable-ID"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten-Filter einblenden"])},
    "filters_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter aktiv"])},
    "filter_labels": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach Typ"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textsuche"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach Ersteller"])},
      "assigned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach zugewiesenem Benutzer"])},
      "has_resubmission_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur mit Wiedervorlage anzeigen"])},
      "resubmission_date_gte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WV-Datum ab"])},
      "resubmission_date_lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WV-Datum bis"])}
    },
    "filter_link_labels": {
      "my_resubmissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine offenen Aktivitäten"])}
    },
    "no_resubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Wiedervorlage"])},
    "resubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedervorlage"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität bearbeiten"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bearbeiten"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bearbeiten (Admin)"])}
    },
    "clone": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität erfolgreich dupliziert"])}
    },
    "message_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht bearbeiten"])},
    "append_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info hinzufügen"])},
    "create_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Info anlegen"])},
    "update_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info bearbeiten"])},
    "ticket_link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link ins Ticket-System"])},
    "append_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "append_submit_with_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["✓ (speichern und Wiedervorlage entfernen)"])},
    "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlegen"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Event anlegen"])},
    "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info angelegt"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event löschen"])},
    "appended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info hinzugefügt"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info geändert"])},
    "resubmission_dialog": {
      "action_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschließen / WV entfernen"])},
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedervorlage-Datum & -User"])},
      "action_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedervorlage gespeichert"])}
    },
    "mode_tree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darstellung: nach Themen gruppiert"])},
    "mode_flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darstellung: Rein chronologisch"])}
  },
  "direct_debit_mandates": {
    "direct_debit_mandate": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lastschriftmandat"]), _normalize(["Lastschriftmandate"])])},
    "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine ... "])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandat erstellen"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandat erstellt"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandat bearbeiten"])},
    "mandate_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatsdatum"])},
    "account_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Bank"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats-Referenz"])},
    "creditor_uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gläubiger-ID"])},
    "recurring_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig für wiederkehrende Zahlungen?"])},
    "recurring_payments_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wiederkehrende Zahlungen"])},
    "one_time_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig für einmalige Zahlungen?"])},
    "one_time_payments_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einmalige Zahlungen"])},
    "upload_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Das Hochladen eines weiteren Dokumentes wird das alte überschreiben. Zur Korrektur von Eingabefehler ist das ok. Falls der Kunde ein vollständig neues Mandat eingerichtet hat, muss auch in tantum ein neues angelegt werden, um das aktuelle abzulösen."])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endet am"])}
  },
  "clients": {
    "client": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kunde"]), _normalize(["Kunden"])])},
    "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitzer"])},
    "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Interessent"]), _normalize(["Interessenten"])])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenübersicht"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenhistorie"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-Produkte"])},
    "product_billing_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungszeiträume"])},
    "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-Verträge"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-Kontakte"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Kunden"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenvertrag hinzufügen"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "kdnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennumer"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id übergeordneter Kunde"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen"])},
    "invoice_delivery_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsversand"])},
    "direct_debit_mandates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastschriftmandate"])},
    "invoice_mail_delivery_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufpreis für Versand per Post"])},
    "billing_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsanschrift: Firma"])},
    "billing_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsanschrift: Kontakt"])},
    "billing_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung per E-Mail an"])},
    "billing_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweise zur Rechnungsstellung"])},
    "revenue_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresumsatz"])},
    "debtor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debitor"])},
    "payment_performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsverhalten"])},
    "payment_performance_bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schlecht"])},
    "payment_performance_good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gut"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "vat_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ust-Id"])},
    "register_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registernummer"])},
    "register_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registergericht"])},
    "phones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummern"])},
    "phone_number": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Telefonnummer"]), _normalize(["Telefonnummern"])])},
    "phone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung (wird veröffentlicht)"])},
    "phone_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz (intern)"])},
    "phone_publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veröffentlichen"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschrift"])},
    "address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausdatenbezeichnung (z.B. Schiffsanleger)"])},
    "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressumsanschrift"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "manet_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manet URL"])},
    "client_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-Typ"])},
    "client_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-Status"])},
    "subdomain": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Subdomain"]), _normalize(["Subdomains"])])},
    "subdomains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdomains"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-Details anzeigen"])},
    "legal_info_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum & Datenschutzerklärung"])},
    "invoice_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsanschrift"])},
    "legal_info_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressumsdarstellung (nur CMS2)"])},
    "legal_info_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - Firma"])},
    "legal_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - Rechtsform"])},
    "legal_info_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - Telefon"])},
    "legal_info_fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - Fax"])},
    "legal_info_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - E-Mail"])},
    "legal_info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - Zusatztext"])},
    "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - gesetzl. Vertreter"])},
    "representative_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum - Funktion Vertreter"])},
    "data_protection_officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbeauftragter"])},
    "privacy_policy_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "social_media_profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social-Media-Profile"])},
    "social_media_profiles_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "social_media_profiles_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "social_media_profiles_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speichern"])},
    "parent_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergeordneten Kunden einstellen"])},
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden bearbeiten"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde erfolgreich aktualisiert"])}
    },
    "create": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden erstellen"])},
      "base_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisdaten für den Kunden eingeben"])},
      "additional_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatzdaten für den Kunden eingeben"])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt(e) hinzufügen"])},
      "add_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdomain hinzufügen"])},
      "remove_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdomain entfernen"])},
      "add_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon hinzufügen"])},
      "remove_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon entfernen"])},
      "add_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt hinzufügen"])},
      "remove_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt entfernen"])},
      "create_new_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Kontakt erstellen"])},
      "add_social_media_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social-Media-Profil hinzufügen"])},
      "remove_social_media_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social-Media-Profil entfernen"])},
      "contact_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "dummy_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewählter Kontakt"])},
      "add_existing_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach existierendem Kontakt suchen (Nachname)"])},
      "search_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt suchen"])},
      "contact_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv?"])},
      "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter senden?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde erfolgreich angelegt"])}
    }
  },
  "messages": {
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])}
  },
  "users": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "sip_uid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIP-URL wie 7771234567", "@", "fpbx.de"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "phone_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil"])},
    "special_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonderrolle"])},
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer bearbeiten"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrechte"])},
      "override_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrechte mit Vorlage überschreiben"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erfolgreich aktualisiert"])}
    },
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])}
  },
  "transactions": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "audited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüft?"])},
    "booking_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsdatum"])},
    "counterparty_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger/Auftraggeber"])},
    "last_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen zuletzt aktualisiert"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
    "remittance_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])},
    "upload_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auf das Feld ziehen oder per Dialog auswählen"])},
    "value_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertstellungsdatum"])}
  },
  "contacts": {
    "contact": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontakt"]), _normalize(["Kontakte"])])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "phone_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "send_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter senden?"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "pronouns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronomen"])},
    "pronoun_options": {
      "he_him": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er/ihm"])},
      "she_her": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sie/ihr"])},
      "dey_denen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dey/denen"])},
      "they_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["they/them"])}
    },
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "salutations": {
      "he_him": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr geehrter Herr"])},
      "she_her": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr geehrte Frau"])},
      "dey_denen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr geehrt*"])},
      "they_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr geehrt*"])}
    },
    "show": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
    },
    "edit": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt bearbeiten"])}
    },
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt erstellen"])},
    "zoho_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos aus ZohoCRM"])}
  },
  "receipts": {
    "receipt": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Beleg"]), _normalize(["Belege"])])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beleg anlegen"])},
    "creditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditor"])},
    "paid_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt am"])},
    "resubmission_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedervorlage am"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
    "is_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesen"])},
    "is_not_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesen"])},
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisen"])},
    "assign_and_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisen & Rechnung bezahlt setzen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beleg suchen"])}
  },
  "auth": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "login_manet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manet Login"])},
    "success": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung erfolgreich! :)"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Dein E-Mail-Postfach und folge dem Aktivierungslink um Dein Benutzerkonto zu aktivieren."])}
    }
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Pflichtfeld."])},
    "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort stimmt nicht überein."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ungültig."])}
  },
  "confirm": {
    "confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vielen Dank für die Bestätigung Deiner E-Mailadresse. Du kannst dich nun ", _interpolate(_list(0)), "."])},
    "confirmed_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einloggen"])},
    "denied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Bestätigung Deiner E-Mailadresse ist fehlgeschlagen. Bitte ", _interpolate(_list(0)), " uns falls Du Hilfe benötigst."])},
    "denied_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontaktiere"])}
  },
  "conversations": {
    "template": {
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten-Template auswählen"])},
      "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template konfigurieren"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template versenden"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzhalter ersetzen"])}
    },
    "set_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als gelesen markieren"])},
    "search_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden suchen"])},
    "assign_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden zuweisen"])}
  }
}
<template>
  <div class="row">
    <h2 class="col-12">{{ $t('client_events.edit.headline') }}</h2>
    <Form @submit="update" v-if="clientEventStore.client_event" v-slot="{ errors }" :validation-schema="schema"
          :initial-values="{client_event: clientEventStore.client_event}" class="col-12" autocomplete="off">
      <div class="row">
        <div class="col-12 col-lg-4 col-xl-3 mb-3">
          <div class="form-floating">
            <Field name="client_event.client_event_type_id" id="client_event.client_event_type_id"
                   v-slot="{ field, handleChange }">
              <ClientEventTypeSelect2
                  v-model="field.value"
                  @update:modelValue="handleChange"
                  :class="{'is-invalid': errors['client_event.client_event_type_id']}"
              />
            </Field>
            <label for="client_event.client_event_type_id">{{ $t('client_events.client_event_type') }}</label>
          </div>
        </div>
        <div class="col-12 col-lg-8 col-xl-9 mb-3">
          <div class="form-floating">
            <Field id="client_event.title" name="client_event.title"
                   :placeholder="$t('client_events.title')" class="form-control"
                   :class="{'is-invalid': errors['client_event.title']}"/>
            <label for="client_event.title">{{ $t('client_events.title') }}</label>
            <div v-if="errors['client_event.title']" class="invalid-feedback">
              {{ errors['client_event.title'] }}
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div class="form-floating">
            <Field id="client_event.info" name="client_event.info"
                   as="textarea"
                   :placeholder="$t('client_events.info')"
                   class="form-control h-100"
                   rows="10"
                   :class="{'is-invalid': errors['client_event.info']}"/>
            <label for="client_event.info">{{ $t('client_events.info') }}</label>
            <div v-if="errors['client_event.info']" class="invalid-feedback">
              {{ errors['client_event.info'] }}
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div class="form-floating">
            <Field id="client_event.ticket_link" name="client_event.ticket_link"
                   :placeholder="$t('client_events.ticket_link')"
                   class="form-control"
                   :class="{'is-invalid': errors['client_event.ticket_link']}"/>
            <label for="client_event.ticket_link">{{ $t('client_events.ticket_link_placeholder') }}</label>
            <div v-if="errors['client_event.ticket_link']" class="invalid-feedback">
              {{ errors['client_event.ticket_link'] }}
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-4 col-xl-3 mb-3">
          <Field name="client_event.user_id" id="client_event.user_id" required
                 v-slot="{ field, handleChange }">
            <UserSelect v-model="field.value" @update:modelValue="handleChange"
                        :class="{'is-invalid': errors['client_event.user_id']}"/>
          </Field>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mb-3">
          <Field id="client_event.created_at" name="client_event.created_at">
            {{ $t('client_events.created_at') }}
            <DatePicker name="client_event.created_at" time-picker-inline text-input
                        v-model="clientEventStore.client_event.created_at" :locale="$route.params.locale"
                        :format-locale="de" format="dd.MM.yyyy HH:mm"/>
          </Field>
          <div v-if="errors['client_event.created_at']" class="invalid-feedback">
            {{ errors['client_event.created_at'] }}
          </div>
        </div>

        <div class="col-12 col-lg-4 col-xl-3 mb-3">
          <Field id="client_event.timeline_time" name="client_event.timeline_time">
            {{ $t('client_events.timeline_time') }}
            <DatePicker name="client_event.timeline_time" time-picker-inline text-input
                        v-model="clientEventStore.client_event.timeline_time" :locale="$route.params.locale"
                        :format-locale="de" format="dd.MM.yyyy HH:mm"/>
          </Field>
          <div v-if="errors['client_event.timeline_time']" class="invalid-feedback">
            {{ errors['client_event.timeline_time'] }}
          </div>
        </div>

        <div class="col-12"></div>

        <div class="col-12 col-lg-4 col-xl-1 mb-3">
          <div class="form-floating">
            <Field id="client_event.parent_id" name="client_event.parent_id"
                   type="number"
                   :placeholder="$t('client_events.parent_id')" class="form-control"
                   :class="{'is-invalid': errors['client_event.parent_id']}"/>
            <label for="client_event.parent_id">{{ $t('client_events.parent_id') }}</label>
          </div>
          <div v-if="errors['client_event.parent_id']" class="invalid-feedback">
            {{ errors['client_event.parent_id'] }}
          </div>
        </div>

        <div class="col-12 col-lg-4 col-xl-1 mb-3">
          <div class="form-floating">
            <Field class="form-select" name="client_event.eventable_type" as="select"
                   id="client_event.eventable_type"
                   :class="{'is-invalid': errors['client_event.eventable_type']}">
              <template v-for="eventableType in eventableTypes" :key="eventableType.name">
                <option :value="eventableType.name">{{ eventableType.label }}</option>
              </template>
            </Field>
            <label for="client_event.eventable_type">{{ $t('client_events.eventable_type') }}</label>
          </div>
          <div v-if="errors['client_event.eventable_type']" class="invalid-feedback">
            {{ errors['client_event.eventable_type'] }}
          </div>
        </div>

        <div class="col-12 col-lg-4 col-xl-1 mb-3">
          <div class="form-floating">
            <Field id="client_event.eventable_id" name="client_event.eventable_id"
                   type="number"
                   :placeholder="$t('client_events.eventable_id')" class="form-control"
                   :class="{'is-invalid': errors['client_event.eventable_id']}"/>
            <label for="client_event.eventable_id">{{ $t('client_events.eventable_id') }}</label>
          </div>
          <div v-if="errors['client_event.eventable_id']" class="invalid-feedback">
            {{ errors['client_event.eventable_id'] }}
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-2">
        <button type="submit" class="btn btn-primary">{{ $t('save') }}</button>
      </div>
    </Form>
  </div>
</template>

<script>
import {useClientEventStore} from "@/stores/client_event";
import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import {useAllUsersStore} from "@/stores/all_users";
import {CLIENT_EVENT_TYPES, CLIENT_EVENTABLE_TYPES, ROUTES} from "@/scripts/constants";
import {useClientEventFileStore} from "@/stores/client_event_file";
import * as Yup from "yup";
import {Field, Form} from "vee-validate";
import {de} from 'date-fns/locale';
import {Helpers} from "@/helpers";
import UserSelect from "@/components/UserSelect.vue";
import ClientEventTypeSelect2 from "@/components/ClientEventTypeSelect2.vue";

export default {
  name: 'ClientEventsShow',
  components: {
    ClientEventTypeSelect2,
    UserSelect,
    Field,
    Form,
  },
  setup() {
    return {
      clientEventFileStore: useClientEventFileStore(),
      clientEventStore: useClientEventStore(),
      clientStore: useClientStore(),
      setupStore: useSetupStore(),
      allUserStore: useAllUsersStore(),
      CLIENT_EVENT_TYPES,
    }
  },
  data() {
    return {
      de,
      emitListenerUnbind: null,
      schema: Yup.object().shape({
        client_event: Yup.object().shape({
          user_id: Yup.number().required(this.$t('validation.required')),
          title: Yup.string().required(this.$t('validation.required')),
          // info: Yup.string().required(this.$t('validation.required')),
          created_at: Yup.string().required(this.$t('validation.required')),
          timeline_time: Yup.string().required(this.$t('validation.required')),
          // eventable_type: Yup.string().required(this.$t('validation.required')),
        })
      })
    }
  },
  created() {
    this.fetchEvent()
  },
  computed: {
    eventableTypes() {
      return Object.values(CLIENT_EVENTABLE_TYPES).map(name => {
        const singular = `${name.toLowerCase()}`
        const plural = `${singular}s`
        return {
          name: name,
          label: this.$tc(`${plural}.${singular}`, 1)
        }
      })
    }
  },
  methods: {
    fetchEvent() {
      this.clientEventStore.getClientEvent(this.$route.params.id, this.$t('client_events.edit.headline'))
    },
    update(values) {
      //normalize values by hand to drop not needed data in order to keep using the existing endpoint
      //(otherwise things like children will be parsed as well)
      const allowedAttributes = [
        'client_event_type_id',
        'title', 'info',
        'ticket_link',
        'user_id', 'parent_id',
        'created_at', 'timeline_time',
        'eventable_type', 'eventable_id',
      ]
      const normalizedValues = allowedAttributes.reduce((memo, attribute) => {
        memo[attribute] = values.client_event[attribute]
        return memo
      }, {})
      this.clientEventStore.update(this.$route.params.id, normalizedValues).then(() => {
        Helpers.emitter.emit('success', this.$t('client_events.updated'))
        const parentId = values.client_event.parent_id || this.$route.params.id
        console.log(values.client_event.parent_id, parentId, values)
        this.$router.push({name: ROUTES.CLIENT_EVENTS.SHOW, params: {id: parentId}})
      }).catch(({response}) => {
        Helpers.emitter.emit('error', response.data.messages)
      })
    }
  },
}
</script>

import {defineStore} from 'pinia'

export const useOrganizationStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'organization',
    // a function that returns a fresh state
    state: () => ({
        organizations: [],
    }),
    // optional getters
    getters: {

    },
    // optional actions
    actions: {
        index() {
            return this.apiClient.get(`/organizations`).then(({data}) => {
                this.organizations = data.organizations;
            })
        },
        get(id) {
            return this.apiClient.get(`/organizations/${id}`)
        },
        store(organization, address) {
            return this.apiClient.post(`/organizations`, {organization, address})
        },
        connectNordigen() {
            return this.apiClient.post(`/organizations/ng/connect`)
        },
        requisitionNordigen(data) {
            return this.apiClient.post(`/organizations/ng/requisition?institution_id=${data.id}`)
        },
        getBanks() {
            return this.apiClient.get(`/organizations/ng/banks`)
        },
        getAccounts(ref) {
            return this.apiClient.get(`/organizations/ng/accounts?ref=${ref}`)
        }
    },
})

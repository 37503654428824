<template>
  <div class="row">
    <div class="col-12">
      <BootstrapCard class="mt-3" :headline="$t('contacts.edit.headline')">
        <template #actions v-if="contact">
          <router-link :to="{ name: ROUTES.CONTACTS.SHOW, query: { id: $route.query.id } }"
                       class="btn btn-sm btn-primary"
                       :title="$t('contacts.show.headline')">
            <i class="fas fa-fw fa-lg fa-eye"/>
          </router-link>
        </template>
        <Form v-if="contact" @submit="update" v-slot="{ errors, setFieldValue }" :validation-schema="schema" class="row"
              autocomplete="off" :initial-values="{contact}">
          <ContactFormFields :errors="errors" :setFieldValue="setFieldValue"/>
          <div class="col-12 col-lg-2">
            <button type="submit" class="btn btn-primary">Update</button>
          </div>
        </Form>
      </BootstrapCard>
      <BootstrapCard class="mt-3" :headline="$tc('clients.client', 0)">
        <ClientsTable v-if="contactLoaded"/>
      </BootstrapCard>
    </div>
  </div>
</template>

<script>
import {useContactStore} from "@/stores/contact";
import {Form} from "vee-validate";
import {Helpers} from "@/helpers";
import ContactFormFields from "./components/FormFields"
import {CONTACT_FORM_SCHEMA} from "@/scripts/schemas";
import ClientsTable from "@/components/tables/ClientsTable";
import BootstrapCard from "@/components/BootstrapCard.vue";
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ContactEdit',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  components: {
    BootstrapCard,
    ClientsTable,
    Form,
    ContactFormFields
  },
  setup() {
    return {
      contactStore: useContactStore(),
    }
  },
  data() {
    return {
      contact: null,
      schema: CONTACT_FORM_SCHEMA,
      clientIds: [],
      contactLoaded: false,
    }
  },
  created() {
    this.contactStore.show(this.$route.params.id).then(({data}) => {
      this.contact = data.contact
      this.clientIds = data.contact.client_ids
      this.contactLoaded = true
    })
  },

  methods: {
    update(values) {
      this.contactStore.update(this.$route.params.id, values).then(({data}) => {
        Helpers.emitter.emit('success', data.message)
      })
    }
  }
}
</script>
